import { SpotPrivate } from "../../../../api/spot/lib/private";
import { Camera, Transform } from "@ali/tidejs";
import { mat3, mat4, quat, vec3, vec4 } from "gl-matrix";
import { Application } from "../../../../Application";
import { CameraPrivate } from "../../../../api/camera/lib/private";

//TODO 性能可以提升，去除内存申请
export class Spot2NormalInterpolator {
  private _endCameraPosition = vec3.create();
  private _srcCenter = vec3.create();
  private _normalViewPhi = 40.0;
  private _startTarget = vec3.create();
  private center = vec3.create();

  private _camera: Camera;
  private _trans: Transform;

  constructor() {
    this._camera = Camera.MainCamera!;
    this._trans = this._camera.entity.transform;
  }
  public setdest() {
    const worldMatrix = this._trans.localToWorldMatrix();

    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    this._srcCenter = spotAPI.currentSpot.opticalCenter;
    this._tmpDirectionHomo = vec4.create();
    vec4.transformMat4(this._tmpDirectionHomo, vec4.fromValues(0, 0, -1, 0), worldMatrix); //forwardHomo
    const radius = 10;
    this._tmpDirection = vec3.fromValues(radius * this._tmpDirectionHomo[0], radius * this._tmpDirectionHomo[1], radius * this._tmpDirectionHomo[2]); // forward
    vec3.add(this._startTarget, this._trans.position, this._tmpDirection);

    const theta = Math.atan2(-this._tmpDirection[0], -this._tmpDirection[2]);
    const phi = this._normalViewPhi * Math.PI / 180.0;

    const posZ = Math.cos(theta) * Math.cos(phi);
    const posX = Math.sin(theta) * Math.cos(phi);
    const posY = Math.sin(phi);
    this._tmpDirection = vec3.fromValues(-posX, -posY, -posZ); // normalCameraForward
    const distFromInitialNormalCameraPosToSceneCenter = vec3.dist(cameraAPI.center, cameraAPI.normalCameraPostion);

    this.center = cameraAPI.center;
    this._endCameraPosition = vec3.clone(cameraAPI.normalCameraPostion);
    vec3.sub(this._endCameraPosition, this.center, vec3.scale(this._tmpDirection, this._tmpDirection, distFromInitialNormalCameraPosToSceneCenter));
  }

  private _tempQuat: quat = quat.create();
  private _tmpPos = vec3.create();
  private _tmpDirectionHomo = vec4.create();
  private _tmpDirection = vec3.create();
  private _tmpRotationMatrix = mat3.create();
  private _tmpTransformMatrix = mat4.create();

  public update(ratio: number): void {
    this._tmpDirection = vec3.fromValues(0, 1, 0); // up
    vec3.lerp(this._tmpPos, this._srcCenter, this._endCameraPosition, ratio);
    this._trans.position = vec3.clone(this._tmpPos);
    vec3.lerp(this._tmpPos, this._startTarget, this.center, ratio); // targetPosition
    mat4.targetTo(this._tmpTransformMatrix, this._trans.position, this._tmpPos, this._tmpDirection);
    quat.fromMat3(this._tempQuat, mat3.fromMat4(this._tmpRotationMatrix, this._tmpTransformMatrix)); // originQ

    this._trans.localRotation = quat.clone(this._tempQuat);
    this._camera.fov = this._camera.fov * (1.0 - ratio) + CameraPrivate.Config.normalViewFov * ratio
  }
}
