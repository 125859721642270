import { Component, IOnStart } from "@ali/tidejs";
import { TransitionEventParams, TransitionState } from "../api/transition/lib/interface";
import { BaseElement } from "../lib/utils/eca";
import { BaseBehaviorVR } from "../Behaviors/lib";


// todo : 设置 includeSpots spots
@Component("SetVisibleBySpot")
export class SetVisibleBySpot extends BaseBehaviorVR implements IOnStart {
  element!: BaseElement;
  public isShowInTopView = false;
  public isShowInNormalView = false;

  private _visibleSpots = [];
  get visibleSpots(){
    return this._visibleSpots;
  }
  set visibleSpots(value){
    this._visibleSpots = value;
  }

  onStart(): void {

  }
  preAwake(spotId: string){
    this.setVisibleBySpot(spotId);
  }
  setVisibleBySpot(spotID: string){
    let includeSpots: string[] = [];
    if(this._visibleSpots.length > 0){
      includeSpots = this._visibleSpots
    } else if(this.element && this.element.elementConfig && this.element.elementConfig.includeSpots){
      includeSpots = this.element.elementConfig.includeSpots
    }
    if(includeSpots.includes(spotID)){
      this.log("SetVisibleBySpot", "setVisibleBySpot", `元素${this.element.id}, 当前SpotID:${spotID} 在可见的Spot范围内，设置了可见性${true}`)
      this.element.setVisible(true);
    } else if(this.isShowInTopView && spotID === "top"){
      this.log("SetVisibleBySpot", "setVisibleBySpot",  `元素${this.element.id} 当前SpotID:${spotID} 不在可见的Spot范围内，但设置了在 normal 视图可见 ${true}`)
      this.element.setVisible(true);
    } else if(this.isShowInNormalView && spotID === "normal"){
      this.log("SetVisibleBySpot", "setVisibleBySpot",  `元素${this.element.id} 当前SpotID:${spotID} 不在可见的Spot范围内，但设置了在 normal 视图可见 ${true}`)
      this.element.setVisible(true);
    } else {
      this.log("SetVisibleBySpot", "setVisibleBySpot", `元素${this.element.id} 当前SpotID:${spotID} 不在任何视图可见 ${false}`)
      this.element.setVisible(false);
    }
  }
  onTransition(event: TransitionEventParams){
    const { state, to } = event;

    if(!state || !to){ return }
    if(state === TransitionState.beforeTransition){
      if( !this.isShowInTopView && !this.isShowInNormalView && to.spotId === "normal"){
        this.element.setVisible(false);
      }
      // this.log("SetVisibleBySpot", "setVisibleBySpot", `元素${this.element.id} 跳转之前先隐藏 ${false}`)
      // this.element.setVisible(false);
    } else {
      this.setVisibleBySpot(to.spotId);
    }
  }

  update() {

  }
}
