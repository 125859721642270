import { Camera, Constants, Entity, Renderer, TextureCube, TextureFormat, TextureCubeFace, ShaderMaterial } from '@ali/tidejs'
import { mat3, mat4 } from 'gl-matrix';
import { SpotToSpotTransitionMaterial } from '../../Material/SpotToSpotTransitionMaterial';

import { UpdatePanoImage } from './UpdatePanoImage';
import { PANORAMA_FACES } from '../../GameLogic/WanHuaTongUtility';
import { CameraPrivate } from '../../api/camera/lib/private';
import { SpotPrivate } from '../../api/spot/lib/private';
import { performanceMark } from '../../lib/utils';
import { ImageCache } from '../../lib/TaskQueue/LoadImageTask';
import { MessageCenter } from '../../GameLogic/MessageCenter';
import { PanoImageApp } from '../../PanoImageApp';

async function updateSubTextureCube512(texture1: TextureCube, texture2?: TextureCube) {
  const promises = [];
  const images: any = []
  for (let i = 0; i < PANORAMA_FACES.length; i++) {
    promises.push(new Promise<void>(async (resolve) => {
      const url = await PanoImageApp.Config.publicResourceResolver.resolve(`panoImage/1/${PANORAMA_FACES[i]}.jpg`)
      ImageCache.getImage(url).then(image => {
        images[i] = image;
        resolve();
      })
    }))
  }
  await Promise.all(promises);
  for (let i = 0; i < PANORAMA_FACES.length; i++) {
    texture1.setTextureImageSource(TextureCubeFace.PositiveX + i, images[i]);
    if (texture2) {
      texture2.setTextureImageSource(TextureCubeFace.PositiveX + i, images[i]);
    }
  }
  return
}

export function isSupportCubeImageFilter(): boolean {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('Windows') > -1 || ua.indexOf('Android') > -1 || ua.indexOf('like Mac OS X') > -1)
    return true;
  return false;
}

export function isMobileDevice(): boolean {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('Android') > -1 || ua.indexOf('like Mac OS X') > -1)
    return true;
  return false;
}

/**
 * LOD 层次信息存储
 * @param name
 * @param x
 * @param y
 * @param initData
 */
export function createDataTextureCube(name: string, x: number, y: number, initData: number[]) {
  if (x === 2048 && y === 2048) {
    let tex: TextureCube;
    if (isSupportCubeImageFilter())
      tex = new TextureCube(x, y, TextureFormat.R8G8B8, true);
    else
      tex = new TextureCube(x, y, TextureFormat.R8G8B8, false);

    return tex;
  }
  if (x === 512 && y === 512) {
    const tex = new TextureCube(x, y, TextureFormat.R8G8B8, false);
    return tex;
  }
  const initLevelData: number[] = [];
  for (let i = 0; i < x; i++) {
    for (let j = 0; j < y; j++) {
      initLevelData.push(...initData);
    }
  }
  const initBuf: Uint8ClampedArray = new Uint8ClampedArray(initLevelData);
  const initRawData: ImageData = new ImageData(initBuf, x, y);
  const tex = new TextureCube(x, y, TextureFormat.R8G8B8A8, false);
  for (let i = 0; i < 6; i++) {
    tex.setTextureImageSource(TextureCubeFace.PositiveX + i, initRawData);
  }
  return tex;
}
export interface IInterpolator {
  setSrc(parameter: any): void;
  setDest(parameter: any): void;
  update(progress: number): void;
  changeTo(target: IInterpolator): void;
}
export class PanoImageCubeInterpolator {

  private static _cubeInterpolator: PanoImageCubeInterpolator;
  public static getInstance() {
    if (!PanoImageCubeInterpolator._cubeInterpolator) {
      PanoImageCubeInterpolator._cubeInterpolator = new PanoImageCubeInterpolator();
    }
    return PanoImageCubeInterpolator._cubeInterpolator;
  }
  private _srcCube!: TextureCube;
  private _loading2K = false;

  private _currentEntityRenderList: Renderer[] = [];
  private _currentMaterial: any;

  /**
     * 为正时，从0-1渐变，false时，1-0渐变
     */
  private _flip = false;
  private _perspective2orthographicFlip = false;

  private _material!: SpotToSpotTransitionMaterial;

  //2k cube纹理处理
  private _need2K = true;
  private _cubeSrcLevel!: TextureCube;
  private _cubeSrcMipFlag!: TextureCube;
  private _cubeSrc2K!: TextureCube;
  private _cubUpdateTool: UpdatePanoImage;

  get modelMaterial() {
    return this._material;
  }

  constructor() {
    this._material = new SpotToSpotTransitionMaterial();
    this._cubUpdateTool = new UpdatePanoImage();

    this.initCube();
    MessageCenter.DisposeEvent.once("dispose", () => {
      (PanoImageCubeInterpolator as any)._cubeInterpolator = undefined;
    })
  }
  dispose() {
    this._cubeSrc2K.dispose();
    this._cubeSrcLevel.dispose();
    this._cubeSrcMipFlag.dispose();
    this._material.release();
  }
  setModel(entity: Entity) {
    const render = entity.getComponentByType(Renderer)! as Renderer;
    render.material = (this._material.clone() as ShaderMaterial);
    render.material.cullface = -1;
    this._currentEntityRenderList.push(render);

    if (!this._need2K) { return }
    this.init2K();

    this._material.cubeSource2K = this._cubeSrc2K;
    this._material.cubeSourceLevel = this._cubeSrcLevel;
    this._material.cubeSourceMipFlag = this._cubeSrcMipFlag;
    this.loadAndApplay2KCube(this._cubeSrcLevel, this._cubeSrcMipFlag, this._cubeSrc2K);
  }

  init2K() {
    this._cubeSrcLevel = createDataTextureCube("_srcCubeLevel", 16, 16, [255, 0, 255, 255]);

    this._cubeSrcLevel.minFilter = Constants.LINEAR;
    this._cubeSrcLevel.magFilter = Constants.LINEAR;

    this._cubeSrcMipFlag = createDataTextureCube("_srcCubeMipFlag", 4, 4, [0, 0, 0, 255]);

    this._cubeSrcMipFlag.minFilter = Constants.LINEAR;
    this._cubeSrcMipFlag.magFilter = Constants.LINEAR;

    this._cubeSrc2K = createDataTextureCube("_srcCube2K", 2048, 2048, [0, 0, 0, 0]);
    this._cubeSrc2K.minFilter = Constants.LINEAR;
    this._cubeSrc2K.magFilter = Constants.LINEAR;
    this._cubeSrc2K.wrapS = Constants.REPEAT;
    this._cubeSrc2K.wrapT = Constants.REPEAT;

  }
  private setCurrentRenderMaterial() {
    this._currentEntityRenderList.forEach((item) => {
      item.material = this._currentMaterial.clone();
    })
  }

  private initCube() {
    this._srcCube = createDataTextureCube("_srcCube", 512, 512, [0, 0, 0, 0]);
  }


  public getSpotMatrix(spotId: string) {
    const spotAPI = PanoImageApp.getInstance<SpotPrivate>("hotSpotAPI");
    const toSpot = spotAPI.getHotSpotById(spotId)!;
    const { upwardDirection, forwardDirection } = toSpot;
    const srcMatrix = mat3.fromMat4(mat3.create(), mat4.lookAt(mat4.create(), [0, 0, 0], forwardDirection, upwardDirection));
    return srcMatrix;

  }

  public async setDest() {
    this._currentMaterial = this._material;
    this._flip = false;
    this._need2K = true;

    performanceMark('getImage-begin');

    // 加载512的贴图
    await updateSubTextureCube512(this._srcCube);
    this._material.cubeSource = this._srcCube;
    this.setCurrentRenderMaterial()
  }

  public update(ratio: number, p2oratio?: number) {
    const _temp = ratio;
    if (this._flip) {
      ratio = 1 - ratio;
    }
    this._currentMaterial.time = ratio;

    if (p2oratio !== undefined) {
      this._currentMaterial.time = 2 * Math.min(0.5, ratio);
      if (p2oratio) {
        this._currentMaterial.perspectiveToOrthographicTime = 2 * Math.min(1.0, Math.max(0, p2oratio - 0.5));
        if (this._perspective2orthographicFlip) {
          this._currentMaterial.perspectiveToOrthographicTime = 1.0 - this._currentMaterial.perspectiveToOrthographicTime;
        }
        if (p2oratio == 1.0) { // set to Orthographic
          Camera.MainCamera!.orthographic = true;
          const cameraAPI = PanoImageApp.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
          const length = cameraAPI.topViewHeight;
          Camera.MainCamera!.orthographicSize = length * 0.5 / cameraAPI.yRatio;
        } else {
          Camera.MainCamera!.orthographic = false;
        }
      }
    }

    if (_temp === 1) {
      // this.finish();
    }
  }


  async loadAndApplay2KCube(level: TextureCube, mipFlag: TextureCube, cube2K: TextureCube) {
    this._loading2K = true;
    this._cubUpdateTool.updateSubTextureCube(level, mipFlag, cube2K);
  }

  public cancle2KCube() {
    this._loading2K = false;
    //
  }
}
