export function isIOS(){
  const navigator = window.navigator;
  if(!navigator || !navigator.userAgent){ return false}
  const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}
export function performanceMark(str: string){
  return performance && performance.mark && performance.mark(str);
}

export class Utils{
  /**
     * 测试时在 electron 上不会自动加 http: 导致图片请求错误
     * Gets protocol
     * @returns  
     */
  static autoAddProtocol(url: string){
    if(!url || !url.startsWith){
      return url;
    }
    if(!url.startsWith("//")){
      return url;
    }
    if((window as any).isElectron){
      return "http:" + url
    }
    return location.protocol + url;
  } 
}