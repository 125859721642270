import { BasicState, expJson, InjectState } from '../../../StatesFrame';
import { VGInfo } from '../../VGInfo';
import { ConsoleLog, ErrorType } from '../../../../lib/log';

@InjectState('VGCheck')
export class VGCheck extends BasicState<VGInfo> {
  @expJson('info')
  public info = 'check';

  @expJson('onPassState')
  public onPassState: BasicState<VGInfo> | undefined;

  @expJson('onFailedState')
  public onFailedState: BasicState<VGInfo> | undefined;


  /**
     * 测试类状态，如果返回true，则执行onPassState，否则执行onFailedState
     */
  protected test() {
    return true;
  }

  onEnter () {
    if (this.test()) {
      if (this.onPassState) {
        this.onPassState.changeTo();
        return;
      }
    }else {
      if (this.onFailedState) {
        this.onFailedState.changeTo();
        return;}
    }
    ConsoleLog.error('VGCheck', 'onEnter', ErrorType.InternalError, '条件判断没有达成,阻塞进程;或者test函数返回不当，或者onPassState、onFailedState不完整', this.info);
  }

  //检查状态并非持续态，因此不太可能被调用 pause, resume
  pause(){
    this.onEnter();
  }

  resume(){
    this.onEnter();
  }

  onExit () {
    ConsoleLog.log('state', this.info,'exit');
  }

}
