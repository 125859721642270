
import { expJson, InjectState } from '../../StatesFrame';
import { VGWait } from './VGIWait';
import { ConsoleLog } from '../../../lib/log';

@InjectState('VGHidden')
export class VGHidden extends VGWait{
  @expJson('info')
  public info = 'hidden';

  onEnter () {
    this._time = 0;
    ConsoleLog.log('state', this.info,'enter');
    //停止动画
    this.srcInfo.stopAnimation()
    //隐藏对象
    this.srcInfo.entity.deactivate();

    this.srcInfo.stopSound();

    if (this.info === 'hidden-pathEnd') {
      this.srcInfo.endPath();
    }
  }

  pause() {
    //
  }

  resume() {
    //
  }

}
