import { InputContext, Camera, RayCasterMgr } from "@ali/tidejs";
import { SpotPrivate } from "../../../api/spot/lib/private";
import { ConfigPrivate } from '../../../api/config/lib/private';
import { CameraPrivate } from "../../../api/camera/lib/private";
import { TransitionPrivate, defaultActiveFloor } from "../../../api/transition/lib/private";
import { Spot } from "../../spot/Spot";
import { DataCenter } from "../../../GameLogic/DataCenter";
import { CubeInterpolator } from "../CubeInterpolator";
import { OrbitControls } from "../../../Behaviors/OrbitControls";
import { BaseState, CameraStateType } from "./BaseState";
import { Normal2SpotInterpolator } from "./TransformInterpolator/Normal2SpotInterpolator";
import { Top2NormalInterpolator } from "./TransformInterpolator/Top2NormalInterpolator";
import { WanHuaTongUtility } from "../../../GameLogic/WanHuaTongUtility";
import { Application } from "../../../Application";

export class TopState  extends BaseState{
  //top -> spot 与normal使用相同的插值器
  private _orbitControl!: OrbitControls;
  private _top2SpotInterpolator !: Normal2SpotInterpolator;
  private _top2Normal!: Top2NormalInterpolator;
  private _spot: Spot | undefined;

  async setDefault(data: any){
    throw new Error("Method not implemented.");
  }

  constructor(spotMgr: SpotPrivate) {
    super(spotMgr);
    this._top2SpotInterpolator = new Normal2SpotInterpolator();
    this._top2Normal = new Top2NormalInterpolator();

    this._orbitControl = Camera.MainCamera!.entity.addComponent<OrbitControls>(OrbitControls);
    this._orbitControl.isActive = false;
  }

  exit() {
    this._orbitControl.isActive = false;
    this._orbitControl.enableRotation = true;
  }
  enter() {
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    this._orbitControl.isActive = true;
    this._orbitControl.enableRotation = false;
    this._orbitControl.init(cameraAPI.center, cameraAPI.size, cameraAPI.normalCameraPostion);
  }
  /**
     * 鼠标点击事件，特定状态下，需要做不同的事情；比如，normal2top的状态下，该事件什么也不做
     */
  click(inputCtx: InputContext): void {
    if(!DataCenter.RootModelMF){ return }
    const info = RayCasterMgr.GetInstance().intersectioinEntityMesh(inputCtx,DataCenter.RootModelMF);
    if (info) {
      const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
      const activeFloor = transitionAPI.activeFloor;
      if (ConfigPrivate.Config.multiFloor && activeFloor !== defaultActiveFloor) {// 多楼层模型的单层激活状态下，跳转到激活层的最近点
        this._spot = this.spotMgr.getNearestSpotByWorldPosFloor(info.position, activeFloor);
      } else {
        this._spot = this.spotMgr.getNearestSpotByWorldPosAll(info.position);
      }
      if (!this._spot) {
        return;
      }
      this._spot.onClick();
    }
  }

  move(inputCtx: InputContext): void {
    return;
  }

  getState(): CameraStateType {
    return CameraStateType.top;
  }
  /**
     * 设置目标参数
     * @param parameter array,[pos,up,forward,spotId]
     */
  async setDest(parameter: any){
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    this.targetType = parameter[0];
    if (this.targetType === CameraStateType.panorama) {
      this._orbitControl.isActive = false;
      await this._top2SpotInterpolator.setDest(parameter[1], true);
      // jump to label at top view, use code below.
      // await this._top2SpotInterpolator.setDest(parameter[1], true, parameter[3]);

      await CubeInterpolator.getInstance().setNormalAtStart(parameter[4],parameter[1]);
      const buildingBase = configAPI.getBuildingBase();
      CubeInterpolator.getInstance().setMultiFloorMaterial(buildingBase, -1);
    }
    if (this.targetType === CameraStateType.normal) {
      this._orbitControl.isActive = false;
      await this._top2Normal.setdest();
    }
    CubeInterpolator.getInstance().materialNormal.perspectiveToOrthographicTime = 0.0;
  }
  update(ratio: number): void {
    if (this.targetType === CameraStateType.panorama) {
      this._top2SpotInterpolator.update(this.curveFunc(ratio));
      CubeInterpolator.getInstance().update(Math.pow(ratio, 3));
      WanHuaTongUtility.changeTransparencyOfTheWall(0.67 *(1.0 - ratio));
    }
    if (this.targetType === CameraStateType.normal) {
      this._top2Normal.update(ratio);
    }
  }
}
