import { RenderQueue, IShader, UniformType} from "@ali/tidejs";

const TextureShader: IShader = {
  name: "StandarShader",
  renderQueue: RenderQueue.Transparent,

  uniform: {
    u_diffuse: { type: UniformType.Texture2D, value: undefined},
  },

  vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        //attribute vec3 color;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;

        varying vec2 v_texCoord;

        void main() {
            v_texCoord = texcoord;
            gl_Position = u_projection * u_view * u_model * vec4(position, 1.0);
        }
    `,

  fragmentShader: `
        precision highp float;

        uniform sampler2D u_diffuse;
        varying vec2 v_texCoord;

        void main() {
            vec4 color = texture2D(u_diffuse, vec2(1.0 - v_texCoord.x, v_texCoord.y)).rgba;
            gl_FragColor = vec4(color);
        }
    `,
};

export default TextureShader;
