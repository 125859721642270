import { Camera, TextureCube, TextureCubeFace, Constants, PlantForm } from '@ali/tidejs';
import { ImageCache, LoadImageQueue } from '../../lib/TaskQueue/LoadImageTask';
import { PanoImageApp } from '../../PanoImageApp';
import { SpotPrivate } from '../..//api/spot/lib/private';
import { vec3 } from 'gl-matrix';
import PQueue from 'p-queue';
import { PANORAMA_FACES } from '../../GameLogic/WanHuaTongUtility';
import { CameraPrivate } from '../../api/camera/lib/private';
import { MessageCenter } from '../../GameLogic/MessageCenter';
import { isSupportCubeImageFilter } from './CubeInterpolator';

function getSortedNameArr(spotID?: string) {
  if (!spotID) {
    { return PANORAMA_FACES }
  }
  const spotAPI = PanoImageApp.getInstance<SpotPrivate>("hotSpotAPI");
  const spot = spotAPI.getHotSpotById(spotID)
  if (!spot) { return PANORAMA_FACES }
  const forward = spot.forwardDirection;
  const up = vec3.fromValues(0, 1, 0);
  const down = vec3.fromValues(0, -1, 0);
  const back = vec3.negate(vec3.create(), forward);
  const left = vec3.cross(vec3.create(), back, up);
  const right = vec3.negate(vec3.create(), left);

  const arrDir = [
    {
      name: "f",
      dir: forward
    },
    {
      name: "b",
      dir: back
    },
    {
      name: "l",
      dir: left
    },
    {
      name: "r",
      dir: right
    },
    {
      name: "u",
      dir: up
    },
    {
      name: "d",
      dir: down
    },
  ];

  let currentDir = Camera.MainCamera!.entity.transform.forward();
  currentDir = [-currentDir[0], -currentDir[1], -currentDir[2]];

  arrDir.sort((pre, cur) => {

    const preA = vec3.angle(currentDir, pre.dir)
    const nextA = vec3.angle(currentDir, cur.dir)
    return preA - nextA;
  })
  return arrDir.map(item => item.name);
}
const delay = () => new Promise(resolve => requestAnimationFrame(resolve));
export class UpdatePanoImage {
  static instance: UpdatePanoImage;
  static getInstance() {
    return UpdatePanoImage.instance;
  }

  private _imageData2k: ImageData; //2k标记位
  private _imageData512: ImageData; //512标记位
  private _imageData512_face: ImageData;
  private _imageDataMip: ImageData;
  private _imageDataNoneMip: ImageData;
  private _count = 96;
  private sideSortedList: string[] = [];
  /**
     * 这个是每个面的下载队列
     * Update queue of update panorama
     */
  private updateQueue!: PQueue[];
  private enable2K = true;

  private levelCube!: TextureCube;
  private cube2K!: TextureCube;
  private mipFlag!: TextureCube;

  private stop = false;
  /**
     * 这个是所有的渲染队列，渲染队列和下载队列分开，保证每次只有一个全景图上传
     * Queue render of update panorama
     */
  private queueRender = new PQueue({
    concurrency: 1,
  })

  constructor() {
    this._imageData2k = this.createData();
    this._imageData512 = this.createData(false);
    this._imageData512_face = this.createDataFace();
    this._imageDataMip = this.createDataMip();
    this._imageDataNoneMip = this.createDataMip(false);
    UpdatePanoImage.instance = this;
    MessageCenter.DisposeEvent.once("dispose", () => {
      this.dispose();
      (UpdatePanoImage as any).instance = undefined;
    })
    return this;
  }
  addEventListener() {
    const cameraAPI = PanoImageApp.getInstance<CameraPrivate>("cameraAPI");
    cameraAPI.cameraRotationObserve.subscribe(() => {
      this.onCameraRotate();
    })
    this.enable2K = true;
    this.execQueue();
  }
  private createData(is2k = true) {
    const initLevelData: number[] = [];
    for (let l = 0; l < 4; l++) {
      for (let m = 0; m < 4; m++) {
        if (is2k) initLevelData.push(0, 0, 255, 255);
        else initLevelData.push(255, 0, 255, 255);
      }
    }
    const initBuf: Uint8ClampedArray = new Uint8ClampedArray(initLevelData);
    const initRawData: ImageData = new ImageData(initBuf, 4, 4);
    return initRawData;
  }

  private createDataFace() {
    const initLevelData: number[] = [];
    for (let l = 0; l < 16; l++) {
      for (let m = 0; m < 16; m++) {
        initLevelData.push(255, 0, 255, 255);
      }
    }
    const initBuf: Uint8ClampedArray = new Uint8ClampedArray(initLevelData);
    const initRawData: ImageData = new ImageData(initBuf, 16, 16);
    return initRawData;
  }

  private createDataMip(isMipMap = true) {
    const initLevelData: number[] = [];
    for (let l = 0; l < 4; l++) {
      for (let m = 0; m < 4; m++) {
        if (isMipMap) initLevelData.push(255, 255, 255, 255);
        else initLevelData.push(0, 0, 0, 255);
      }
    }
    const initBuf: Uint8ClampedArray = new Uint8ClampedArray(initLevelData);
    const initRawData: ImageData = new ImageData(initBuf, 4, 4);
    return initRawData;
  }

  updateSubTextureCubeByFace(face: string) {
    const webGLCubeIndex = PANORAMA_FACES.indexOf(face);

    const queue = new PQueue({
      autoStart: false,
    });
    // 设为3 功耗会低一点，因为要用worker解析
    if (PlantForm.IsPc())
      queue.concurrency = 4;
    else
      queue.concurrency = 1;
    for (let j = 0; j < 4; j++) {
      for (let k = 0; k < 4; k++) {
        queue.add(() => {
          return new Promise<void>(async (resolve, reject) => {
            if (this.stop) { resolve(); return }
            const url = await PanoImageApp.Config.publicResourceResolver.resolve(`panoImage/3_4x4/${face}${j}_${k}.jpg`);
            ImageCache.getImage(url).then(async image => {
              if (this.stop) { resolve(); return }
              await this.queueRender.add(async () => {
                if (this.stop) { resolve(); return }
                this.levelCube.setTextureImageSource(TextureCubeFace.PositiveX + webGLCubeIndex, this._imageData2k, 0, false, false, k * 4, j * 4);
                this.cube2K.setTextureImageSource(TextureCubeFace.PositiveX + webGLCubeIndex, image, 0, false, false, k * 512, j * 512);
                this._count += 1;
                await delay();
              });
              resolve()
            }).catch((error) => {
              // 队列清空的回调
            })
          })
        })
      }
    }

    return queue;
  }
  public execQueue() {
    this.pauseAllQueue();
    if (!this.enable2K) {
      return
    }
    for (let i = 0; i < 6; i++) {
      const index = PANORAMA_FACES.indexOf(this.sideSortedList[i]);
      if (index === -1) { continue }
      const queue = this.updateQueue[index];

      if (queue && queue.size > 0) {
        queue.start();
        queue.onIdle().then(() => {
          this.execQueue();
          if (isSupportCubeImageFilter()) {
            if (i === 0) {
              this.cube2K.minFilter = Constants.LINEAR_MIPMAP_LINEAR;
              this.cube2K.generateMipmaps();
              this.mipFlag.setTextureImageSource(TextureCubeFace.PositiveX + index, this._imageDataMip, 0, false, false, 0, 0);
            } else if (this._count === 96) {
              this.cube2K.minFilter = Constants.LINEAR_MIPMAP_LINEAR;
              this.cube2K.generateMipmaps();
              for (let j = 0; j < 6; j++)
                this.mipFlag.setTextureImageSource(TextureCubeFace.PositiveX + j, this._imageDataMip, 0, false, false, 0, 0);
            }
          }
        })
        return;
      }
    }
  }
  /**
     * 仅暂停下载队列，用于相机旋转，重新计算下载队列优先级
     * Pauses all queue
     */
  public pauseAllQueue() {
    if (!this.updateQueue || this.updateQueue.length < 1) {
      return
    }
    for (let i = 0; i < 6; i++) {
      const queue = this.updateQueue[i];
      queue.pause();
    }
  }
  /**
     * 清空所有的队列，用于点位跳转和销毁场景
     * Clears all queue
     */
  public clearAllQueue() {
    if (this.queueRender) {
      this.queueRender.clear();
    }
    if (!this.updateQueue || this.updateQueue.length < 1) {
      return
    }
    for (let i = 0; i < 6; i++) {
      const queue = this.updateQueue[i];
      queue.clear();
    }
    this.updateQueue = [];
  }
  /**
     * 根据 SpotID 计算全景图下载队列优先级
     * Updates sub texture cube
     * @param spotID
     * @param levelCube
     * @param cube2K
     */
  public async updateSubTextureCube(levelCube: TextureCube, mipFlag: TextureCube, cube2K: TextureCube) {
    this.stop = false
    this.updateQueue = [];
    this._count = 0;
    this.sideSortedList = getSortedNameArr();

    if (isSupportCubeImageFilter()) {
      cube2K.minFilter = Constants.LINEAR;
    }

    for (let i = 0; i < 6; i++) {
      const queue = this.updateSubTextureCubeByFace(PANORAMA_FACES[i]);
      this.updateQueue.push(queue);
    }

    this.levelCube = levelCube;
    this.mipFlag = mipFlag;
    this.cube2K = cube2K;
    this.execQueue();
  }
  public onCameraRotate() {

    const spotAPI = PanoImageApp.getInstance<SpotPrivate>("hotSpotAPI");
    const currentSpotID = spotAPI.getCurrentSpotId();
    this.pauseAllQueue();
    this.sideSortedList = getSortedNameArr(currentSpotID);
    this.execQueue();
  }

  /**
     * 检查是否还有下载的资源
     */
  public inDownloading() {
    if (this._count === 96) return false;
    return true;
  }

  /**
     * TODO :终止正在下载的图片
     */
  public cancelDownload() {
    this.stop = true;
    this.pauseAllQueue();
    LoadImageQueue.clear();
    //
  }

  public resetLevelCubeTo512(levelCube: TextureCube, mipFlag: TextureCube) {
    for (let i = 0; i < 6; i++) {
      levelCube.setTextureImageSource(TextureCubeFace.PositiveX + i, this._imageData512_face, 0, false, false, 0, 0);
      mipFlag.setTextureImageSource(TextureCubeFace.PositiveX + i, this._imageDataNoneMip, 0, false, false, 0, 0);
    }
  }
  dispose() {
    this.cancelDownload();
    this.clearAllQueue();
  }
}
