export enum SDKElementType {
  annotation2d = "annotation2d",
  audio = "audio",
  sky = "sky",
  line = "line",
  image = "image",
  hot = "hot",
  video = "video", // 不算一种类型，只是值被用到
}
export enum AnnotationElementResource {
  image = "//gw.alicdn.com/tfs/TB1IG51DAL0gK0jSZFAXXcA9pXa-420-140.png",
  video = "//gw.alicdn.com/tfs/TB1XlO1Dxn1gK0jSZKPXXXvUXXa-420-140.png",
  commodity = "//gw.alicdn.com/tfs/TB1toLrfIKfxu4jSZPfXXb3dXXa-204-104.png"
}
export enum imageElementResource {
  image = "//img.alicdn.com/tfs/TB123c3CYj1gK0jSZFOXXc7GpXa-328-328.png",
  video = "//virtualbuy-cdn.alibaba.com/annotationSource/float-afe9d2d8-5d6c-423b-a474-58337914d8bb.mp4"
}
export interface IAnnotationConfigItem {
  id: string;
  info: {
    src: string;
    dotSrc: string,
    title: string;
    screenHeight?: number;
    height?: number;
    width?: number;
    type?: "audio" | "sky"; // 老数据字段

    // 线标签
    line_length?: number;
    highlightSrc?: string;
    // 浮层视频标
    startTime?: number
    endTime?: number
    muted?: boolean
    control?: boolean

    // 热点标签
    titleConfig: {
      content: string,
      fontSize: number,
      backgroundColor: string,
      opacity: number,
      showStrategy: 'always' | 'hover',
    }
    iconConfig: {
      url: string,
      width: string,
      height: string,
      opacity: string,
    }
  };
  label: string;
  labelOrientation: -1 | 1 | 2 | -2;
  anchor: { x: number; y: number; z: number };
  type: "COMMODITY_MARK_V2" | "LINE_MARK" | "SPACE_MARK" | "line" | "image" | "annotataion2d" | "sky" | "audio" | "hot"; // 前面3个是兼容老数据，使用以后面为准
  mediaType: "COMMODITY" | "VIDEO" | "IMAGE"; // 老数据字段
  normal: { x: number; y: number; z: number };
  scale: { x: number; y: number; z: number };
  rotation: { x: number; y: number; z: number };
  includeSpots: string[];
  extra?: {
    visibleSpots?: string[] | undefined;
  }
  spotId?: string
}
