import { BaseBehavior, Camera, Entity, InputContext, IOnStart, IUpdatable, Octree, Ray, Renderer, Transform, Viewer } from "@ali/tidejs";
import { vec3 } from "gl-matrix";

export class TowardsNormal extends BaseBehavior implements IUpdatable, IOnStart {
  private _normal: vec3 = [0, 1, 0];
  private _position: vec3 = [0, 0, 0];
  private _baseMeshOctree: Octree | undefined;
  private _baseMeshEntity: Entity | undefined;
  public isShowEntity = true; // 手动控制是否显示entity

  onStart(): void {
    // 有需要的时候再加上
    // const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    // transitionAPI.transitionProcessObserve.subscribe((params) => {
    //     if(!params || !params.to || !params.from){ return }
    //     if (params.state === TransitionState.beforeTransition) {
    //     }
    // });
  }

  public get normal() {
    return this._normal;
  }
  public get position() {
    return this._position;
  }
  public get baseMeshEntity(): Entity | undefined {
    return this._baseMeshEntity;
  }
  public set baseMeshEntity(value: Entity | undefined) {
    this._baseMeshEntity = value;
  }
  public get baseMeshOctree(): Octree | undefined {
    return this._baseMeshOctree;
  }
  public set baseMeshOctree(value: Octree | undefined) {
    this._baseMeshOctree = value;
  }
  private _isIntersect = false;
  public get isIntersect() {
    return this._isIntersect;
  }
  update(deltaTime: number, inputContext: InputContext) {
    if (this._baseMeshOctree && this._baseMeshEntity) {
      const ndcPos: vec3 = Viewer.GetScreenPos(inputContext.position[0], inputContext.position[1]);
      const render = this.entity.getComponentByType<Renderer>(Renderer);
      if (!render) { return }
      if (Math.abs(ndcPos[0]) >= 1 && Math.abs(ndcPos[1]) >= 1) {
        render.isActive = false;
        return
      } else {
        render.isActive = true;
      }
      const ray: Ray = Camera.MainCamera!.screenPointToRay(ndcPos);
      const d = this._baseMeshEntity.transform.inverseTransformDirection(ray.direction);
      const o = this._baseMeshEntity.transform.inverseTransformPoint(ray.origin);
      const r: Ray = new Ray(o, d);
      const its = this._baseMeshOctree.rayIntersect(r);
      if (its) {
        this._isIntersect = true;
        this._normal = this._baseMeshEntity.transform.transformDirection(its.normal);
        this._position = this._baseMeshEntity.transform.transformPoint(its.position);
        const curPosition = vec3.create();
        const curNormal = vec3.create();
        this.entity.transform.position = vec3.add(curPosition, this._position, vec3.scale(curNormal, this._normal, 0.0));

        if (Math.abs(vec3.dot(this._normal, Transform.Up)) > 0.999) {
          this.entity.transform.setForward(this._normal, [1, 0, 0]);
        } else {
          this.entity.transform.setForward(this._normal, [0, 1, 0]);
        }
      } else {
        this._isIntersect = false;
        const render = this.entity.getComponentByType<Renderer>(Renderer)
        if (!render) { return }
        render.isActive = false;
      }

      if (!this.isShowEntity) {
        render.isActive = false;
      }
    }
  }
}
