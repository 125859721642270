import { BaseBehavior, Camera, Component, Entity, InputContext, IUpdatable, PrimitiveType, Transform, Renderer, Viewer } from "@ali/tidejs"
import { TransitionState } from "../../api/transition/lib/interface";
import { TransitionPrivate } from "../../api/transition/lib/private";
import { Application } from "../../Application";
import { mat4, quat, vec2, vec3, vec4 } from "gl-matrix";
import { StateMgr } from '../StatesFrame';
import { EmotionMgr } from "./Emotion";
import { PEvent } from "./PEvent";
import { KeepPos } from "./KeepPos";
import { SpotPrivate } from "../../api/spot/lib/private";
import { StateEvent } from "../../GameLogic/StateEvent";
import { RobotDialog } from "../../Components/RobotDialog";
import { ConsoleLog, ErrorType } from "../../lib/log";


class DescInfo {
  url = '';
  spotID = '';
  finished = false;
  position: vec3 | undefined;
}
/**
 * 多状态共享数据源
 *
 * 根据业务需求自由定制
 */
export class RobotInfo {
  public entity!: Entity;
  public entityTransform!: Transform;
  public targetPoint!: Transform;
  public targetScreenPosition = vec3.fromValues(0.6,0,0.5);
  public targetWorldPosition!: vec3;
  public spotID = '0';
  public distance = -0.5;

  public camera!: Camera;
  public cameraTransfrom!: Transform;
  public descJson: any;

  //表情控制
  public emotionMgr!: EmotionMgr;

  //语言控制

  //提供给移动类的状态机使用
  public startPos: vec3 = vec3.create();
  public endPos: vec3 = vec3.create();
  public startQuat: quat = quat.create();
  public endQuat: quat = quat.create();
  public upDir: vec3 = vec3.fromValues(0,1,0);

  //通用静态变量，仅限于临时使用； 不要存放一些常驻数据
  public static TempVec31: vec3 = vec3.create();
  public static TempVec32: vec3 = vec3.create();
  public static TempVec41: vec4 = vec4.create();
  public static TempVec21: vec2 = vec2.create();
  public static TempMat41: mat4 = mat4.create();
  public static TempMat42: mat4 = mat4.create();
  public static TempQuat1: quat = quat.create();
  public static TempQuat2: quat = quat.create();

  //解说词
  private descInfoMap: Map<string,DescInfo> | undefined = new Map<string, DescInfo>();

  //一些事件
  public onJumpPoint: PEvent<(spotID: string) => void> = new PEvent<(spotID: string) => void>();
  public onExitPanorama: PEvent<() => void> = new PEvent<() => void>();
  public onEnterPanorama: PEvent<() => void> = new PEvent<() => void>();

  //交互对话框
  public dialog: RobotDialog | undefined;
  public enableDialog = false;

  public init() {
    if (this.descJson === undefined) {
      ConsoleLog.info('RobotCtr', 'init', '解说无配置');
      this.descInfoMap = undefined;
      return;
    }
    if (this.descJson.version === 0) {
      this.descJson.list.forEach((p: { spot: string; url: string }) => {
        const d = new DescInfo();
        d.url = p.url;
        d.spotID = p.spot;
        d.finished = false;
        this.descInfoMap!.set(d.spotID, d);
      });
    }
    ConsoleLog.info('RobotCtr', 'init', '解说字典', this.descInfoMap);
  }

  /**
     * 返回面是否朝向某个点
     * @param position 目标点
     * @param dotThreshold 点乘时，大于该值，则近似认为是朝向
     * @returns
     */
  public checkoutFaceDir(dotThreshold = 0.95,position?: vec3) {
    if (!position) {
      position = this.cameraTransfrom.position;
    }
    const dir = vec3.transformQuat(vec3.create(),vec3.fromValues(0,0,1),this.entityTransform.rotation);

    vec3.sub(RobotInfo.TempVec31,position,this.entity.transform.position);
    vec3.normalize(RobotInfo.TempVec31,RobotInfo.TempVec31);

    if (vec3.dot(dir, RobotInfo.TempVec31) > dotThreshold) {
      return true
    }
    return false;
  }

  public getRoundPoint(distance = 0.5, angle?: number, center?: vec3 ) {
    if (!center) {
      center = this.cameraTransfrom.position;
    }
    if (!angle) {
      angle = Math.random() * Math.PI * 2;
    }
    RobotInfo.TempVec31[0] = Math.cos(angle) * distance + center[0];
    RobotInfo.TempVec31[2] = Math.sin(angle) * distance + center[2];
    RobotInfo.TempVec31[1] = center[1];
    return vec3.clone(RobotInfo.TempVec31);
  }



  public getFacePoint(distance = 0.5, angle?: number) {
    //this.targetWorldPosition = Viewer.getScreen2World(this.targetScreenPosition);
    //return this.targetWorldPosition;
    return this.targetPoint.position;
  }

  /**
     * 获取当前spot点，是否需要解说
     * @returns
     */
  public allowDescribe(): boolean {
    if (!this.descInfoMap) return false;
    if (this.spotID === '0') {
      this.spotID = SpotPrivate.getInstance().state.currentSpotID;
    }
    const a = this.descInfoMap.get(this.spotID);

    if (a) {
      //return true; //直接播放
      return !a.finished;   // 只播放一次
    }
    return false;
  }

  public resetAudio() {
    if (!this.descInfoMap) return;
    const a = this.descInfoMap.get(this.spotID);

    if (a) {
      a.finished = false;
    }
  }

  /**
     * 获取解说配置中，当前spot点音频url
     * @returns
     */
  public getAudioURLInDescInfo(): string {
    if (!this.descInfoMap) return '';
    const a = this.descInfoMap.get(this.spotID);
    if (a) {
      return a.url;
    }
    return '';
  }

  /**
     * 获取解说配置中的解说点位置
     *
     * 解说配置json: 1.0 版本中，没有位置，因此会计算一个在屏幕中的位置
     * @returns
     */
  public getPosInDescInfo(): vec3 {
    if (!this.descInfoMap) return [0,0,0];
    const a = this.descInfoMap.get(this.spotID);
    if (a) {
      return a.position || this.getFacePoint();
    }
    return [0,0,0];
  }

  /**
     * 设置当前spot点是否已经解说过
     * @param isfinished
     */
  public setAudioPlayFinishedByDescInfo(isfinished: boolean) {
    if (!this.descInfoMap) return;
    const a = this.descInfoMap.get(this.spotID)!;
    if (a) {
      a.finished = isfinished;
    }
  }

  /**
     * 获取机器人的世界坐标
     */
  public getRobotWorldPosition() {
    return this.entityTransform.position;
  }

  /**
     * 获取机器人的屏幕坐标
     */
  public getRobotScreenPosition() {
    return Viewer.GetWorld2Screen(this.entityTransform.position);
  }

  /**
     * 初始化机器人交互对话框
     * @param target 实例
     * @param dialogDom 对话框dom
     * @returns
     */
  public initRobotDialog(target: any, dialogDom: HTMLElement) {
    if (!dialogDom) {
      ConsoleLog.error("virtualGuider", "initRobotDialog", ErrorType.ParamsError, "对话框dom参数为空")
      return false;
    }

    if (!this.enableDialog) {
      return;
    }

    if (this.dialog && this.dialog.isAwaked()) {
      if (this.dialog.isDisplayed()) {
        this.dialog.hide();
      } else {
        this.dialog.show();
      }

    } else {
      this.dialog = target.addComponent(RobotDialog);
      this.dialog!.container = Application.instance.container;
      this.dialog!.getPosition = this.getRobotScreenPosition.bind(this);
      this.dialog!.elementNode = dialogDom;
      this.dialog!.preAwake();
    }
  }

  /**
     * 设置对话框的显示or隐藏
     * @param visible
     */
  public setDialogVisible(visible: boolean) {
    if (visible) {
      this.dialog?.show();
    } else {
      this.dialog?.hide();
    }
  }
}

/**
 * 机器人状态机管理器组件
 *
 * 维持一个状态机管理器，一个共享数据实例
 */
@Component("RobotCtr")
export class RobotCtr extends BaseBehavior implements IUpdatable {
  private _stateMgr!: StateMgr<RobotInfo> | undefined;
  private _info!: RobotInfo;
  public emotionMgr!: EmotionMgr;
  public descJson: any;
  public behaviorJson: any

  async init() {
    this._stateMgr = new StateMgr<RobotInfo>();
    //构建一个共享数据
    {
      this._info = new RobotInfo();
      this._info.entity = this.entity.transform.children[0].entity
      this._info.entityTransform = this._info.entity.transform;
      this._info.camera = Camera.MainCamera!;
      this._info.cameraTransfrom = this._info.camera.entity.transform;
      this._info.emotionMgr = this.emotionMgr;
      const entity =Entity.CreatePrimitive(PrimitiveType.Cube);
      if (entity) {
        entity.transform.localScale = [0.01, 0.01, 0.01];
        entity.addComponent<KeepPos>(KeepPos);
        this._info.targetPoint = entity.transform;
        this._info.distance = -0.1;
        const render = entity.getComponentByType(Renderer);
        render!.isActive = false;
        //SceneManager.GetInstance().currentScene.addEntity(entity);

        //entity.deactivate();
      }
      this._info.descJson = this.descJson;
      this._info.init();
    }
    //利用json 与 共享数据实例 来构建状态机
    this._stateMgr.fromJson(this.behaviorJson, this._info);
    const transitionAPI = Application.getInstance<TransitionPrivate>("transitionControlAPI");
    transitionAPI.transitionProcessObserve.subscribe((event)=>{

      const { state, to } = event;
      if(!state || !to){ return }
      if(state === TransitionState.beforeTransition){
        this._info.resetAudio();
        //跳转前，如果是到normal，top，则停止状态机
        if (to.spotId === 'normal' || to.spotId === 'top') {
          this._stateMgr?.pause();
        }
      } else {
        //在跳转开始的时候，就设置商品解说信息，robot就能直接到达商品，不会先进入idle状态
        this._info.spotID = to.spotId;
        this._info.onJumpPoint.emit(to.spotId);
        StateEvent.onFlyToSpot.emit('arrived', to.spotId);
        //跳转后，如果是回到panorama，则重启状态机
        if (to.spotId !== 'normal' && to.spotId !== 'top') {
          this._stateMgr?.resume();
        }
      }
    });
  }

  /**
     * 初始化飞行器对话框
     * @param target
     * @param dialogDom
     * @returns
     */
  public initRobotDialog(target: any, dialogDom: HTMLElement) {
    return this._info.initRobotDialog(target, dialogDom);
  }

  /**
     * 设置飞行器对话框显示or隐藏
     * @param visible
     */
  public setRobotDialogVisible(visible: boolean) {
    this._info.setDialogVisible(visible)
  }

  /**
     * 手动放置spot点
     * @param spotID spot点
     */
  public placeToSpot(spotID: string) {
    const spotPos = this.getSpotPos(spotID);
    if (!spotPos) {
      throw new Error(`找不到spot点, spotID:${spotID}`)
    }
    this._stateMgr?.pause();
    this._info.entityTransform.position = spotPos;
    if (!this._info.entity.isActive) {
      this._info.entity.activate();
    }
    this._stateMgr?.resume();
  }

  /**
     * 获取spot点坐标
     * @param id 取得spot点坐标
     * @returns
     */
  private getSpotPos(id: string): vec3 | undefined {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getHotSpotById(id);
    if (spot) {
      const temp = vec3.create();
      temp[0] = spot.groundCoord[0];
      temp[1] = spot.groundCoord[1];
      temp[2] = spot.groundCoord[2];
      return vec3.clone(temp);
    }
  }


  update(deltaTime: number, inputContext: InputContext): void {
    if (this._stateMgr) {
      this._stateMgr.update(deltaTime);
    }
  }

}
