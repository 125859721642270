
import { AnimateUVMaterial } from './AnimateUVMaterial';
import { BaseBehavior, Component, IUpdatable, InputContext, Renderer, Texture2D } from '@ali/tidejs';
import { vec4 } from 'gl-matrix';

@Component('AnimatedUV', true)
export class AnimatedUV extends BaseBehavior implements IUpdatable {
  private _feedDate = vec4.create();

  // 横排数量
  private _rowCount = 1;
  private _preRowWidth = 1;
  // 纵列数量
  private _columnCount = 1;
  private _preColumnHeight = 1;
  private _material: AnimateUVMaterial | undefined;

  // 动画相关
  private _isRunning = false;
  private _pos = 0;
  private _rowIndex = 0;
  private _columnIndex = 0;
  private _interval = 100;
  private _subTime = 0;
  private _loop = true;
  private _duration = 1000;

  private _subDelayTime = 0;
  private _delayTime = 2;
  private _delay = 2;
  private _delayRandom = 0.2;
  private _isDelaying = false;

  private _tex: Texture2D | undefined;
  private onComplete: Function | undefined;

  // public event: EventEmitter = new EventEmitter();

  /**
     * 循环后，延迟时间
     */
  public set delay(t: number) {
    this._delay = t;
  }

  /**
     * 循环延迟时间随机值，0~1之间
     */
  public set delayRandom(t: number) {
    this._delayRandom = t;
  }

  public get columnCount (): number {
    return this._columnCount;
  }

  /**
     * 设置纵列数量
     */
  public set columnCount (columnCount: number) {
    if (columnCount < 1) columnCount = 1;
    this._columnCount = columnCount;
    this._preColumnHeight = 1 / columnCount;
    this._feedDate[1] = this._preColumnHeight;
  }

  public get rowCount () {
    return this._rowCount;
  }

  /**
     * 设置横排数量
     */
  public set rowCount (rowCount: number) {
    if (rowCount < 1) rowCount = 1;
    this._rowCount = rowCount;
    this._preRowWidth = 1 / rowCount;
    this._feedDate[0] = this._preRowWidth;
  }

  public set tex (tex: Texture2D | undefined) {
    this._tex = tex;
  }

  public set duration(duration: number) {
    this._duration = duration;
  }

  update (deltaTime: number, inputContext: InputContext): void {
    if (this._isRunning && this._material) {
      if (this._isDelaying) {
        this._subDelayTime += deltaTime;
        if (this._subDelayTime > this._delayTime) {
          this._isDelaying = false;
          this._subDelayTime = 0;
        }
        return;
      }
      this._subTime += deltaTime;
      if (this._subTime >= this._interval) {
        this._subTime = 0;
        this.updatePos();
      }
    }
  }
  /**
     * 播放序列帧
     * @param duration 播放时长
     * @param loop 是否循环
     * @returns
     */
  public play (loop = true, duration?: number, ) {
    if (!this._material) {
      const renderer = this.entity.getComponentByType<Renderer>(Renderer);
      if (renderer) {
        this._material = renderer.material as AnimateUVMaterial;
        if (!(this._material instanceof AnimateUVMaterial)) {
          this._material = undefined;
        }
      }
    }

    if (!this._material || !this._tex) {
      return;
    }
    if (duration) this._duration = duration;

    this._material.albedo = this._tex!;

    const totalCount = this._columnCount * this._rowCount;
    this._interval = this._duration / totalCount / 1000;
    this._pos = 0;
    this._subTime = 0;
    this._isRunning = true;
    this._isDelaying = false;
    this._subDelayTime = 0;
    this._loop = loop;
  }

  public stop () {
    this._isRunning = false;
    this._delay
  }

  private updatePos () {
    ++this._pos;
    if (this._pos > this._columnCount * this._rowCount) {
      this._pos = 0;
    }

    ++this._rowIndex;
    if (this._rowIndex === this._rowCount) {
      this._rowIndex = 0;
      ++this._columnIndex;
      if (this._columnIndex === this._columnCount) {
        this._columnIndex = 0;
        if (this._isRunning) {
          if (this.onComplete) {
            this.onComplete();
          }
        }
        if (!this._loop) this.stop();
        else {
          //播放完毕后，可以随机一个延迟时间，再重新播放
          if (this._delay > 0) {
            this._isDelaying = true;
            this._delayTime = this._delay + (Math.random() * 2 - 1) * this._delayRandom * this._delay;
            this._delayTime /= 1000;
          }
        }
      }
    }

    if (this._material) {
      this._feedDate[2] = this._rowIndex * this._preRowWidth;
      this._feedDate[3] = this._columnIndex * this._preColumnHeight;
      this._material.uvOffset = this._feedDate;
    }
  }
  public dispose () {
    if (this._material && !this._material.destroyed) {
      this._material.release();
    }
    if (this._tex && !this._tex.destroyed) {
      this._tex.release();
    }
    this._material = undefined;
    this._tex = undefined;
    //组件只需要释放材质，而该材质，由Renderer组件来释放，所以本组件无需任何操作
  }
}
