import { Application } from "../../Application";
import EventEmitter from "eventemitter3";
import { IAnnotationConfigItem, SDKElementType } from "./interface";
import { Annotation2DPrivate } from "./annotation2d/lib/private";
import { Subject } from "rxjs"
import { ConsoleLog, ErrorType } from "@sdkCore/lib/log";
import { API, APIBaseClass, ElementMap } from "@sdkCore/lib/utils/eca";
import { SpotPrivate } from '@sdkCore/api/spot/lib/private'

export interface IState {
  annotationConfig: {
    [name: string]: IAnnotationConfigItem;
  };
  areaConfig: {
    [name: string]: IAnnotationConfigItem;
  };
}
function checkConfig(name: string, config: { [name: string]: IAnnotationConfigItem }) {
  if (!config) {
    return false
  }
  return true;
}
/**
 * 应当弱化ElementsAPI的作用，放到每个标签的API里面。
 * 将来可能不会存在ElementsAPI，请谨慎使用。
 * @module element
 * Api
 */
@API()
export class ElementsManager extends APIBaseClass {
  public allVisible:boolean = true
  static ModuleName = "elementsAPI";

  public state: Partial<IState> = {
    annotationConfig: {},
    areaConfig: {},
  }

  /**
   * @event
   * Event  of elements manager
   */
  public event = new EventEmitter<"loaded" | "click" | "show" | "hide" | "annotationAdd" | "annotation-no-intersection" | "moveStart" | "moving" | "moveEnd", string>();

  static Config = {
    maxDistance: 30,
    path: "annotations/annotation.json",
    areaPath: "areas/areas.json",
  }

  /**
   * 标签点击事件。兼容老工程，可能新加的标签无法监听到。
   * Determines whether click on
   */
  public onClick = new Subject();

  private _elements: any[] = [];
  /**
   * @private
   * Adds element
   * @param element
   * @returns
   */
  private _addElement(element: any) {
    if (this.getElementById(element.id)) {
      element.dispose();
      return;
    }
    this._elements.push(element)
  }
  /**
   * @private
   * Deletes element
   * @param element
   */
  private _deleteElement(element: any) {
    if (!element) { return }
    element.dispose();
    const index = this._elements.indexOf(element);
    if (index === -1) { return }
    this._elements.splice(index, 1);
  }
  /**
   * 获取场景中所有的Element
   * Gets elements
   * @returns  Element[]
   */
  public getElements() {
    return this._elements;
  }
  /**
   * 获取场景中指定类型的所有Element
   * Gets elements
   * @returns  Element[]
   */
  public getElementsByType(type: SDKElementType) {
    return this._elements.filter(value => value.sdkType === type)

  }
  /**
   * 获取标签配置文件
   * Gets config
   * @returns
   */
  public getConfig() {
    return this.state.annotationConfig;
  }
  /**
   * 根据ID获取Element
   * Gets element by id
   * @param id
   * @returns
   */
  public getElementById(id: string) {
    return this._elements.find(value => value.id === id);
  }

  /**
   * 标签加载初始化
   */
  public async init(annotationConfig: any) {
    const annotation2D = Application.getInstance<Annotation2DPrivate>(Annotation2DPrivate.ModuleName)

    await this.loadConfig(annotationConfig);
    await this.loadAnnotation();
    this.event.emit("loaded");

    annotation2D.event.emit("loaded");
    annotation2D.event.on("click", (id) => this.event.emit("click", id));
    annotation2D.event.on("show", (id) => this.event.emit("show", id));
    annotation2D.event.on("hide", (id) => this.event.emit("hide", id));

    this.event.on("click", (id: string) => {
      ConsoleLog.log("elementsAPI", "onClick", `标签点击:${id}`);
      const element = this.getElementById(id);
      this.onClick.next(element)
    })
    this.event.on("show", (id: string) => {
      ConsoleLog.log("elementsAPI", "onShow", `标签显示:${id}`);
    })
    this.event.on("hide", (id: string) => {
      ConsoleLog.log("elementsAPI", "onHide", `标签隐藏:${id}`);
    })
  }

  /**
   * 删除所有标签，释放内存
   */
  public dispose() {
    for (const element of this.getElements()) {
      element.dispose();
    }
    this._elements = [];
  }
  /**
   * 删除元素Element，名字兼容老接口
   * Removes annotation by id
   * @param id
   */
  public removeAnnotationById(id: string) {
    const element = this.getElementById(id);
    element && this._deleteElement(element);
  }

  /**
   * 通过id删除元素
   * @param id
   */
  public removeElementById(id: string) {
    const element = this.getElementById(id);
    element && this._deleteElement(element);
  }

  private async loadAnnotation() {
    if (!this.state.annotationConfig) { return }
    const promises = [];

    for (const value of Object.values(this.state.annotationConfig)) {
      const f = this.addElement(value);
      promises.push(f);
    }
    // todo: 不使用all
    await Promise.all(promises as any).catch((error) =>
      ConsoleLog.error("elementsAPI", "loadAnnotation", ErrorType.ExternalError, "标签添加错误", error)
    )
  }
  /**
   * 添加元素Element
   * Adds element
   * @param value
   * @returns
   */
  public async addElement(value: IAnnotationConfigItem) {
    let build = undefined;
    if (!value) { return }
    const id = value.id;
    if (!id) { return }
    this.removeAnnotationById(id);
    build = ElementMap.get(value.type);
    // 如果根据type拿不到，那就是老数据的标签配
    if (!build) {
      const { mediaType, info, type } = value;
      if (!value.includeSpots || value.includeSpots.length < 1) {
        ConsoleLog.warn("elementsAPI", "addElement", "未配置includeSpots");
      }
      if (type === "LINE_MARK") {
        build = ElementMap.get(SDKElementType.line);
      } else if (info && info.type === "audio") {
        build = ElementMap.get(SDKElementType.audio);
      } else if (info && info.type === "sky") {
        build = ElementMap.get(SDKElementType.sky);
      } else if (mediaType === "IMAGE" || mediaType === "VIDEO") {
        build = ElementMap.get(SDKElementType.image);
      } else if (mediaType === "COMMODITY") {
        build = ElementMap.get(SDKElementType.annotation2d);
      }
    }
    if (!build) {
      return undefined
    }
    const element = await build(value);
    this._addElement(element);
    return element;
  }

  private async loadConfig(config: any) {
    if (!checkConfig("annotation", config)) {
      this.deactivate();
      return
    }
    this.setState({
      annotationConfig: config
    })
    ConsoleLog.info("elementsAPI", "loadConfig", "annotation.json 加载完毕");
    return config;
  }

  /**
   * 隐藏所有标签
   */
  public hiddenAllElement(enforce = false) {
    const elements = this.getElements();
    elements.forEach((item) => {
      item.deactivate();
    })
    if(!enforce) {
      this.allVisible = false
    }
  }
  /**
   * 显示所有标签
   */
  public showAllElement(enforce = false) {
    const elements = this.getElements();
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const currentSpotId = spotAPI.getCurrentSpotId();
    elements.forEach((item) => {
      if (!item.elementConfig.extra) {
        item.elementConfig.extra = {}
      }
      if (!item.elementConfig.extra.visibleSpots) {
        item.elementConfig.extra.visibleSpots = item.elementConfig.includeSpots
      }
      if (item.elementConfig.extra.visibleSpots!.includes(currentSpotId)) {
        item.activate();
      }
    })
    if(!enforce) {
      this.allVisible = true
    }
  }
  /**
   * 按类型隐藏标签
   * @param type 标签类型
   */
  public hiddenElementByType(type: SDKElementType) {
    const elements = this.getElementsByType(type);
    elements.forEach((item) => {
      item.deactivate();
    })
  }
  /**
   * 按类型显示标签
   * @param type 标签类型
   */
  public showElementByType(type: SDKElementType) {
    const elements = this.getElementsByType(type);
    elements.forEach((item) => {
      item.activate();
    })
  }

  public hiddenElementExceptType(type: SDKElementType) {
    const elements = this.getElements();
    elements.forEach((item) => {
      if(item.type !== type) {
        item.deactivate();
      } else {
        item.activate();
      }
    })
  }

  /**
   * 按id隐藏标签
   * @param id
   */
  public hiddenElementById(id: string) {
    const element = this.getElementById(id);
    element.deactivate();
  }
  /**
   * 按id显示标签
   * @param id
   */
  public showElementById(id: string) {
    const element = this.getElementById(id);
    element.activate();
  }
}
