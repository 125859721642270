import { SDKElementType } from "../../../../api/elements/interface";
import { API, ElementAPIBaseClass } from "../../../../lib/utils/eca";
import { AudioElement } from "./AudioElement";
import  EventEmitter from "eventemitter3";

@API()
export class AudioElementPrivate extends  ElementAPIBaseClass<AudioElement,any> {

  public elementType = SDKElementType.audio;

  public event = new EventEmitter<"enter" | "exit", string>();

  static ModuleName = "audioElementAPI";
  static Config = {
    maxDistance : 3,
    maxPlayCount: 1,
  }
  dispose(){

  }
}
