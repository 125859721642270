import { IAnnotationConfigItem, SDKElementType } from "../../../../api/elements/interface";
import { MediaElement } from "../../../../api/elements/media-element/lib/MediaElement";
import { addImageElement } from "../../../../api/elements/lib/util";
import { AudioPlayCom } from "../../../../Behaviors/AudioPlayCom";
import { LookAtCameraCom } from "../../../../Behaviors/LookAtCameraCom";
import { Element } from "../../../../lib/utils/eca";
import { Entity, Renderer, RenderQueue, MeshCollider } from "@ali/tidejs";
import { AudioElementPrivate } from "./private";
import { Application } from "../../../../Application";
import { AudioPlayManager } from "../../../../lib/audio/AudioPlayManager";
import { vec3 } from "gl-matrix";

function addAudio(entity: Entity, url: string){
  const audioPlayCom = entity.addComponent<AudioPlayCom>(AudioPlayCom);
  audioPlayCom.audioSrc = url;
  const audioApi = Application.getInstance<AudioPlayManager>(AudioPlayManager.ModuleName);
  audioPlayCom.playAudio = (audio: any)=>{
    audioApi.playAudio(audio)
  }
  audioPlayCom.createAudio = (url: any)=>{
    return audioApi.createAudio(url);
  }
  audioPlayCom.pauseAudio = (audio: any)=>{
    audioApi.pauseAudio(audio)
  }
  return audioPlayCom;
}
async function build(item: IAnnotationConfigItem){
  const { id, info } = item;
  const buildEntity = await addImageElement(item);
  if(!buildEntity){ return undefined}

  const com = buildEntity?.addComponent<MeshCollider>(MeshCollider);
  com?.triggerEvent.on("enter", () => {
    api.event.emit("enter", id);
  })
  com?.triggerEvent.on("exit", () => {
    api.event.emit("exit", id);
  })

  const element = new AudioElement(item, buildEntity as Entity, SDKElementType.image);
  const entity: Entity = element.entity;
  addAudio(entity,(info as any).audioUrl)

  element.entity.addComponent(LookAtCameraCom);

  const render = entity.getComponentByType<Renderer>(Renderer)
  if(render){
    const material = render.material;
    material!.renderQueue = RenderQueue.Transparent;
    material!.transparent = true;
    material!.depthTest = false;
    material!.depthWrite = false;
  }
  element.sdkType = SDKElementType.audio;
  const api = Application.getInstance<AudioElementPrivate>(AudioElementPrivate.ModuleName);
  api.addElement(element);
  return element;
}
@Element({
  type: SDKElementType.audio,
  build,
})
export class AudioElement extends MediaElement {
  private get audioSrc() {
    const audioPlayCom = this.entity.getComponentByType<AudioPlayCom>(AudioPlayCom);
    return audioPlayCom!.audioSrc
  }

  private set audioSrc (url: string) {
    const audioPlayCom = this.entity.getComponentByType<AudioPlayCom>(AudioPlayCom);
    if (audioPlayCom) {
      this.entity.removeComponent(audioPlayCom);
      audioPlayCom.dispose();
    }
    addAudio(this.entity, url);
  }

  /**
     * 获取音频的url
     */
  public getAudioSrc() {
    return this.audioSrc;
  }

  /**
     * 设置音频的url
     * @param src
     */
  public setAudioSrc(src: string) {
    this.audioSrc = src;
  }

  /**
     * 设置标签的位置
     * @param anchor
     */
  public setPositon(anchor: any) {
    this.entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
  }
}
