
import { mat4, quat, vec3 } from 'gl-matrix';
import { BasicState, expJson, InjectState } from '../StatesFrame';
import { RobotInfo } from './RobotCtr';
import { ConsoleLog } from "@sdkCore/lib/log";
import { fadeInOut } from "../../lib/utils/tools";

@InjectState('RobotWalk')
export class RobotWalk extends BasicState<RobotInfo> {
  @expJson('info')
  public info = 'walk';

  @expJson('onArrivedState')
  public onArrivedState: BasicState<RobotInfo> | undefined;

  @expJson('onStopState')
  public onStopState: BasicState<RobotInfo> | undefined;

  @expJson('speed')
  public speed = 1;

  protected _time = 0;
  protected _progress = 0;

  protected _endPos = vec3.fromValues(10,0,0);
  protected _startPos!: vec3;

  protected static _TempPos: vec3 = vec3.create();
  protected _startQuat!: quat;
  protected _endQuat: quat = quat.create();

  protected _angle = 0;

  update (deltaTime: number) {
    this._progress += (deltaTime /this._time);
    if (this._progress < 0.2) {
      const rratio = fadeInOut(this._progress * 5);
      quat.slerp(RobotInfo.TempQuat1,this._startQuat,this._endQuat,rratio);
      this.srcInfo.entityTransform.rotation = quat.clone(RobotInfo.TempQuat1);
    }else {
      this.srcInfo.entityTransform.rotation = quat.clone(this._endQuat);
    }
    let progress= fadeInOut(this._progress);
    if (progress >= 1.0) {
      progress = 1.0;
    }
    this.srcInfo.entity.transform.position = vec3.lerp(RobotWalk._TempPos,this._startPos,this._endPos,progress);
    if (progress === 1.0){
      if (this.onArrivedState) {
        this.onArrivedState.changeTo();
      }
    }
  }

  onEnter () {
    ConsoleLog.info('RobotWalk', 'onEnter', this.info);
    this._startPos = vec3.clone(this.srcInfo.entityTransform.position);
    this._startQuat = quat.clone(this.srcInfo.entityTransform.rotation);
    this._startQuat[3] = -this._startQuat[3];

    this.calculatEndInfo();
    //计算耗时
    const distance = vec3.distance(this._startPos, this._endPos);
    this._time = distance / this.speed;

    //最低也要1秒走到
    if (this._time < 1.0) {
      this._time =1.0;
    }

    //this._time = 1.3 + (Math.random() * 2 - 1) * 0.2;
    this._progress = 0;
    ConsoleLog.info('RobotWalk', 'onEnter', 'state','飞行目标点',this._endPos,'距离',distance,'预计耗时',this._time);

    this.srcInfo.enableDialog = false;
  }

  onExit () {
    ConsoleLog.info('RobotWalk', 'onExit', this.info);
  }

  calculatEndInfo() {
    const a = Math.random();
    const angle = Math.PI * (a * 0.3 + 0.2);
    if (a>0) {
      this._angle += angle;
    }else
      this._angle -= angle;
    this._endPos = this.srcInfo.getFacePoint(0.5, this._angle);
    mat4.lookAt(RobotInfo.TempMat41,this._endPos,this.srcInfo.entityTransform.position,this.srcInfo.upDir);
    mat4.transpose(RobotInfo.TempMat41,RobotInfo.TempMat41);
    mat4.getRotation(this._endQuat,RobotInfo.TempMat41);
  }

  pause() {
    this.onStopState?.changeTo();
  }
}
