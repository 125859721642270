import { BasicState, expJson, InjectState } from '../../StatesFrame';
import { vec3 } from 'gl-matrix';
import { VGInfo } from '../VGInfo';
import { ConsoleLog } from '../../../lib/log';
import { Camera } from '@ali/tidejs';

@InjectState('VGStop')
export class VGStop extends BasicState<VGInfo> {
  @expJson('info')
  public info = 'stop';

  @expJson('stay')
  public stay = 3;

  @expJson('onStopOver')
  public onStopOver: BasicState<VGInfo> | undefined;


  protected _time = 0
  private _stopMark: any;
  private _deltaStay = 1; // 因为到达mark点的触发时间在摄像机跳转聚焦后才对外触发，因此停留时间会有误差，手动修正一个值

  faceToCamera() {
    const personPos = this.srcInfo.entityTransform.position;
    const camPos = Camera.MainCamera?.entity.transform.position as vec3;
    const dirVec3 = vec3.create();
    vec3.sub(dirVec3, camPos, personPos);
    dirVec3[1] = 0;
    this.srcInfo.entityTransform.setForward(dirVec3, [0,1,0]);
  }

  update(deltaTime: number) {
    if (!this.srcInfo.soundMgr2.isFinished()) {
      this.srcInfo.playAnimation('introduce_mark')
    } else {
      this.srcInfo.playAnimation('stand')
    }

    this._time += deltaTime;
    this.faceToCamera();
    if (this._time > this.stay + this._deltaStay) {
      this.reset();
      this.srcInfo.endMarkStop(this._stopMark); // 达到指定时长的停留，对外触发事件
      if (this.onStopOver) {
        this.onStopOver.changeTo();
      }
    }

  }

  onEnter() {
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();
    this._time = 0;
    this._stopMark = this.srcInfo.getCurStopMark();
    if (!this.srcInfo.entity.isActive) {
      this.srcInfo.entity.activate();
    }

    ConsoleLog.log('state', this.info, 'enter');
  }

  onExit() {

  }

  pause() {
    if (this.srcInfo.entity.isActive) this.srcInfo.entity.deactivate();
    this.srcInfo.stopAnimation();
  }

  resume() {
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();
    this.srcInfo.resumeAnimation();
  }

  reset(){
    this._time = 0;
  }

}
