import { Viewer } from '@ali/tidejs'
import { vec3 } from 'gl-matrix';
import { BasicState, expJson, InjectState } from '../StatesFrame';
import { RobotInfo } from './RobotCtr';
import { ConsoleLog } from "../../lib/log";


@InjectState('RobotIdle')
export class RobotIdle extends BasicState<RobotInfo> {
  @expJson('info')
  public info = 'idle';

  @expJson('time')
  public time = 30;

  @expJson('distance')
  public distance = 0.6;

  @expJson('onTimeState')
  public onTimeState: BasicState<RobotInfo> | undefined;

  @expJson('onDistanceState')
  public onDistanceState: BasicState<RobotInfo> | undefined;

  @expJson('onForwardState')
  public onForwardState: BasicState<RobotInfo> | undefined;

  @expJson('onSpeakState')
  public onSpeakState: BasicState<RobotInfo> | undefined;

  private _currentTime = 0;

  update (deltaTime: number) {
    this._currentTime += deltaTime;
    if (this._currentTime >= this.time) {
      this._currentTime = 0;
      //1，先尝试超时
      if (this.onTimeState) {
        this.onTimeState.changeTo();
        return;
      }

      //2,检查是否处于正确的待机位置
      if (this.onDistanceState) {
        const screenPos = Viewer.GetWorld2Screen(this.srcInfo.entityTransform.position);

        if (Math.abs(screenPos[0]) > 0.9) {
          this.onDistanceState.changeTo();
          return;
        }else if (Math.abs(screenPos[1]) > 0.9) {
          this.onDistanceState.changeTo();
          return;
        }else if (screenPos[2] > 0.9 || screenPos[2] < 0) {
          this.onDistanceState.changeTo();
          return;
        }else if (Math.abs(screenPos[0]) < 0.2 && Math.abs(screenPos[1]) < 0.2) {
          // 不能占据屏幕中间区域
          this.onDistanceState.changeTo();
          return;
        }
      }

      //3,检查是否面向观众
      if (!this.srcInfo.checkoutFaceDir()) {
        if (this.onForwardState) {
          this.onForwardState.changeTo();
          return;
        }
      }

    }
  }

  onEnter () {
    ConsoleLog.info('RobotIdle', 'onEnter', this.info);
    this._currentTime = 0;

    // 前置检查，如果飞行器和摄像机距离超过10米，机器人移动到距离摄像机10米的位置
    if (vec3.distance(this.srcInfo.entityTransform.position,this.srcInfo.cameraTransfrom.position) > 10) {
      const dir = vec3.sub(vec3.create(), this.srcInfo.entityTransform.position, this.srcInfo.cameraTransfrom.position);
      vec3.normalize(dir, dir);
      vec3.scale(dir, dir, 10);
      vec3.add(dir, dir, this.srcInfo.cameraTransfrom.position);
      this.srcInfo.entityTransform.position = dir;
    }

    //1,检查是否处于正确的待机位置
    if (this.onDistanceState) {
      const screenPos = Viewer.GetWorld2Screen(this.srcInfo.entityTransform.position);
      if (Math.abs(screenPos[0]) > 0.9) {
        this.onDistanceState.changeTo();
        return;
      }else if (Math.abs(screenPos[1]) > 0.9) {
        this.onDistanceState.changeTo();
        return;
      }else if (screenPos[2] > 0.9 || screenPos[2] < 0) {
        this.onDistanceState.changeTo();
        return;
      }else if (Math.abs(screenPos[0]) < 0.2 && Math.abs(screenPos[1]) < 0.2) {
        // 不能占据屏幕中间区域
        this.onDistanceState.changeTo();
        return;
      }
    }
    //2,检查是否面向观众
    if (!this.srcInfo.checkoutFaceDir()) {
      if (this.onForwardState) {
        this.onForwardState.changeTo();
        return;
      }
    }
    //3,尝试商品解说
    if (this.onSpeakState && this.srcInfo.allowDescribe()) {
      this.onSpeakState.changeTo();
      return;
    }

    // 4.开启交互弹框
    this.srcInfo.enableDialog = true;
  }

  onExit () {
    ConsoleLog.info('RobotIdle', 'onExit', this.info);
  }
}


