import { ShaderMaterial, Texture2D } from '@ali/tidejs';
import { vec4 } from 'gl-matrix';
import AnimateUVShader from './AnimateUVShader';

export class AnimateUVMaterial extends ShaderMaterial {
  public get albedo (): Texture2D {
    return this._tmpUniforms.u_diffuse.value;
  }

  public set albedo (albedo: Texture2D) {
    this._tmpUniforms.u_diffuse.value = albedo;
  }

  public get color (): vec4 {
    return this._tmpUniforms.u_color.value;
  }

  public set color (color: vec4) {
    this._tmpUniforms.u_color.value = color;
  }

  public get uvOffset () {
    return this._tmpUniforms.u_uvOffset.value;
  }

  /**
     * x,y = 缩放比例；zw = UV偏移
     */
  public set uvOffset (uvOffset: vec4) {
    this._tmpUniforms.u_uvOffset.value = uvOffset;
  }

  constructor () {
    super(AnimateUVShader);
    this.cullface = -1;
  }
}
