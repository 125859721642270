import { Component, BaseBehavior, IOnStart } from "@ali/tidejs";
import { AudioElementPrivate } from "../api/elements/audio-element/lib/private";
import { SpotPrivate } from "../api/spot/lib/private";
import { TransitionState } from "../api/transition/lib/interface";
import { TransitionPrivate } from "../api/transition/lib/private";
import { Application } from "../Application";
import { vec3 } from "gl-matrix";
import { SDKAudio } from "../lib/audio/audio";
import { ConsoleLog } from "../lib/log";
@Component("AudioPlayCom", true)
export class AudioPlayCom extends BaseBehavior implements IOnStart{
  public audioSrc = "";
  private audioSource!: SDKAudio;

  createAudio(url: string){
    throw new Error("未实现 createAudio")
  }
  playAudio(audio: any){
    throw new Error("未实现 playAudio")
  }
  pauseAudio(audio: any){
    throw new Error("未实现 pauseAudio")
  }

  playAudioByDistance(){
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const currentSpotID = spotAPI.getCurrentSpotId()
    const currentPosition = spotAPI.getPoseBySpotId(currentSpotID)?.pos;
    if(!currentPosition){ return }
    const audioPosition = this.entity.transform.position;
    const distance = vec3.distance(audioPosition, currentPosition) ;
    if(distance < AudioElementPrivate.Config.maxDistance){
      ConsoleLog.log("AudioPlayCom","playAudioByDistance","音频标签在距离内，播放", this.audioSource)
      this.playAudio(this.audioSource);
    } else {
      ConsoleLog.log("AudioPlayCom","playAudioByDistance","音频标签超出距离，暂停播放", this.audioSource, distance, AudioElementPrivate.Config.maxDistance)
      this.pauseAudio(this.audioSource);
    }
  }
  onStart(): void {
    if(!this.audioSrc){
      ConsoleLog.warn("AudioPlayCom","onStart","音频标签添加播放组件失败，没有配置url", this.audioSrc);
      return
    }
    this.audioSource = this.createAudio(this.audioSrc) as any;
    this.playAudioByDistance();

    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);

    transitionAPI.transitionProcessObserve.subscribe((params) => {
      if(!params || !params.to || !params.from){ return }
      if (params.state === TransitionState.afterTransition) {
        this.playAudioByDistance();
      }
    });
  }
  play() {
    this.audioSource?.audio.play();
  }
  pause() {
    this.audioSource?.audio.pause();
  }
  dispose(){
    this.audioSource?.audio.dispose();
    super.dispose();
  }
}
