import { ShaderMaterial } from "@ali/tidejs";
import TextureShader from "./Shader/TextureShader";

export class TextureMaterial extends ShaderMaterial {
  constructor() {
    super(TextureShader);
    this.cullface = -1;
  }

  public get albedo(): WebGLTexture {
    return this._tmpUniforms["u_diffuse"];
  }
  public set albedo(albedo: WebGLTexture) {
    this._tmpUniforms["u_diffuse"].value = albedo;
  }
}