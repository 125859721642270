import { ShaderMaterial } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import ElementHighLightShader from "./Shader/ElementHighLightShader";

export class ElementHighLightMaterial extends ShaderMaterial {
  constructor() {
    super(ElementHighLightShader);
    this.cullface = -1;
  }

  public get highLightStrength(): number {
    return this._tmpUniforms["u_highLightStrength"].value;
  }
  public set highLightStrength(value: number) {
    this._tmpUniforms["u_highLightStrength"].value = value;
  }

  public get highLightColor(): vec3 {
    return this._tmpUniforms["u_highLightColor"].value;
  }
  public set highLightColor(value: vec3) {
    this._tmpUniforms["u_highLightColor"].value = value;
  }

  public get albedo(): WebGLTexture {
    return this._tmpUniforms["u_diffuse"];
  }
  public set albedo(albedo: WebGLTexture) {
    this._tmpUniforms["u_diffuse"].value = albedo;
  }
}