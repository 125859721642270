import { BasicState, expJson, InjectState } from '../../StatesFrame';
import { VGInfo } from '../VGInfo';
import { ConsoleLog } from '../../../lib/log';

@InjectState('VGWait')
export class VGWait extends BasicState<VGInfo> {
  @expJson('info')
  public info = 'wait';

  @expJson('stay')
  public stay = 1;

  @expJson('animationName')
  public animationName = '';

  @expJson('onOverTimeState')
  public onOverTimeState: BasicState<VGInfo> | undefined;

  @expJson('faceToCamera')
  public faceToCamera = false;

  protected _time = 0


  update (deltaTime: number) {
    if (this.faceToCamera)
      this.srcInfo.faceToCamera();
    if(this.stay < 0) return ;
    this._time += deltaTime;
    if (this._time > this.stay) {
      if (this.onOverTimeState) {
        this.onOverTimeState.changeTo();
      }
    }

  }

  onEnter () {
    ConsoleLog.log('vgwait-animation', this.animationName)
    this._time = 0;
    if (!this.srcInfo.entity.isActive) {
      this.srcInfo.entity.activate();
    }
    if (this.animationName != '') {
      this.srcInfo.playAnimation(this.animationName);
    }
    if (this.faceToCamera)
      this.srcInfo.faceToCamera();
    ConsoleLog.log('state', this.info,'enter');
  }

  onExit () {

  }

  pause() {
    if (this.srcInfo.entity.isActive) this.srcInfo.entity.deactivate();
    if (this.animationName != '') {
      this.srcInfo.stopAnimation();
    }
  }

  resume() {
    ConsoleLog.log('state','wait','resume',this.isPausing,this.animationName);
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();
    if (this.animationName != '') {
      this.srcInfo.resumeAnimation();
    }
  }

}
