import { Player, Entity, Renderer, Camera, PrimitiveType, MeshFilter, PlantForm, RayCasterMgr } from '@ali/tidejs';
import EventEmitter from 'eventemitter3';
import { ConsoleLog, ErrorType } from './lib/log';
import { APIInstanceContainer, ecaDispose, ecaReload  } from './lib/utils/eca';
import { performanceMark } from './lib/utils';
import { MessageDispose, MessageReload } from './GameLogic/MessageCenter';
import { ApplicationModeBase } from './api/mode/ApplicationModeBase';
import { PanoramaOpt } from "./Behaviors/PanoramaOpt";
import { ProgressReporter } from "./lib/progress/ProgressReporter";
import { OssResolver } from './lib/oss';
import { isNumber } from "lodash"
import { PanoImageCubeInterpolator } from './Behaviors/jumpAnimation/PanoImageCubeInterpolator';
import { createMouseStyle } from './Behaviors/GameEnter';
import { ElementsManager } from './api/elements/ElementsManager';

export interface IRenderOption{
  preserveDrawingBuffer?: boolean;
  antialias?: boolean;
  dpr?: number;
}

export interface IRotateOption {
  autoRotate?: boolean;
  rotateRate?: number;
}

export interface IOssParams {
  oss_path: string;
  endpoint?: string;
  access_key_id?: string;
  access_key_secret?: string;
  security_token?: string;
}

export interface IPanoImageAppInitOptions {
  ossParams?: IOssParams;
  publicResourceResolver?: OssResolver;
  rotateOption?: IRotateOption;
  renderOption?: IRenderOption
}

function createSphere() {
  const Sphere = Entity.CreatePrimitive(PrimitiveType.Sphere);
  if (!Sphere) { return }
  Sphere.transform.localScale = [10, 10, 10];
  const renderer = Sphere.getComponentByType<Renderer>(Renderer);
  if (!renderer) { return }
  const meshFilter = Sphere.getComponentByType<MeshFilter>(MeshFilter);
  if (meshFilter && meshFilter.mesh) {
    meshFilter.mesh.upLoad();
  }
  // SceneManager.GetInstance().currentScene.addEntity(Sphere);
  return Sphere;
}


export class PanoImageApp {
  static mode: "runtime" | "editor";
  static instance: PanoImageApp;
  static sceneID = "";
  static Config = {
    publicResourceResolver: new OssResolver({ oss_path: "oss://virtualbuy-cdn/datastore/8fb41e07-477f-4794-8dab-57e55f857bcf/latest" }),
  }

  static getInstance<T>(moduleName: string): T {
    const instance = APIInstanceContainer.get(moduleName);
    if (!instance) {
      ConsoleLog.error("PanoImageApp","getInstance",ErrorType.InternalError,"没有找到API：" + moduleName)
    }
    return instance;
  }

  public container!: HTMLElement;
  public canvas!: HTMLCanvasElement;
  public player!: Player;
  public event = new EventEmitter<"loaded">();
  private appMode!: ApplicationModeBase;

  constructor(container: HTMLElement, public options: IPanoImageAppInitOptions) {
    performanceMark('show-panoimage-begin');

    this.container = container;
    if (options.ossParams || options.publicResourceResolver) {
      this._init(options);
    } else {
      throw new Error('初始化至少需要ossParams或者publicResourceResolver中的一个');
    }


  }

  getInstance<T>(moduleName: string): T {
    return PanoImageApp.getInstance(moduleName);
  }

  /**
     * 程序初始化
     * Starts application
     * @returns
     */
  async _init(options: IPanoImageAppInitOptions) {
    const {ossParams, publicResourceResolver, rotateOption } = options;
    this.appMode = new ApplicationModeBase();
    ProgressReporter.onProgress.next({ progress: 20 });
    await this.appMode.initPanoImage(this);
    ProgressReporter.onProgress.next({ progress: 60 });

    if (ossParams) {
      PanoImageApp.Config.publicResourceResolver = new OssResolver(ossParams);
    }
    if (publicResourceResolver) {
      PanoImageApp.Config.publicResourceResolver = publicResourceResolver;
    }
    await this._load()
    ProgressReporter.onProgress.next({ progress: 80 });
    this._addPanoramaOpt();
    if (rotateOption) {
      this._setRotate(rotateOption);
    }
    ProgressReporter.onProgress.next({ progress: 100 });
    ProgressReporter.onLoaded.next();
    PanoImageApp.instance = this;
    this.event.emit('loaded');
    performanceMark('show-panoimage-complete');
  }

  private _addPanoramaOpt() {
    if (!Camera.MainCamera) {
      return
    }
    Camera.MainCamera!.entity.addComponent(PanoramaOpt);
  }

  private async _load() {
    const sceneSphere = createSphere() as Entity;

    if (PlantForm.IsPc()) {
      RayCasterMgr.GetInstance().asyncMode = true;
      RayCasterMgr.GetInstance().activate();
      const filter: MeshFilter = sceneSphere.getComponentByType<MeshFilter>(MeshFilter)!;

      setTimeout(() => {
        filter.mesh!.buildOctree(6, 32);
        const ele = (ElementsManager as any).getInstance();
        createMouseStyle(sceneSphere, filter.mesh!.octree).then((com: any) => {
          (ele as any).mouseTowardsCom = com;
        });
        if (PanoImageApp.mode == "editor") {
          (ele as any).enableEditorMode(filter.mesh!.octree, sceneSphere.transform);
        }
      }, 16)
    }

    await  PanoImageCubeInterpolator.getInstance().setDest();
    PanoImageCubeInterpolator.getInstance().setModel(sceneSphere);
  }

  /**
     * 设置相机旋转状态
     * @param option
     */
  private _setRotate(option: IRotateOption) {
    const { autoRotate, rotateRate} = option;
    if (autoRotate) {
      this.enableCameraAutoRotate(true);
    }
    if (isNumber(rotateRate)) {
      this.setCameraRotateRate(rotateRate)
    }
  }

  /**
     * 设置相机自动旋转
     * @param enable 是否自动旋转
     * @returns
     */
  public enableCameraAutoRotate(enable: boolean) {
    const panoramaOpt = Camera.MainCamera?.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) {
      return;
    }
    panoramaOpt.enableAutoRotate = enable;
  }

  /**
     * 设置相机自动旋转速率
     * @param rate 速率
     * @returns
     */
  public setCameraRotateRate(rate: number) {
    const panoramaOpt = Camera.MainCamera?.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) {
      return;
    }
    panoramaOpt.autoRotateRate = rate;
  }

  reload(){
    this.dispose();
    MessageReload();
    ecaReload()
  }
  /**
     * 销毁当前场景
     * Disposes application
     */
  dispose(){
    ecaDispose()
    MessageDispose();
  }
}
