import { Camera, SceneManager } from "@ali/tidejs";
import { vec3, vec4 } from "gl-matrix";

/**
  * 获取世界坐标对应的屏幕坐标
  * @param pos
  * @returns
  */
const getScreenXYFromVec3 = function (pos: vec3): vec3 {
  let point = vec3.fromValues(0, 0, 0);
  const camera = Camera.MainCamera
  if (!camera) { return [0, 0, 0]; }
  camera.aspect = SceneManager.GetInstance().context.viewer.getWidth() / SceneManager.GetInstance().context.viewer.getHeight()
  const localMatrix = camera.worldToCameraMatrix();
  let localPoint = vec4.create();
  const ndcPoint = vec4.create();
  localPoint = vec4.transformMat4(ndcPoint, vec4.fromValues(pos[0], pos[1], pos[2], 1.0), localMatrix);
  vec4.transformMat4(ndcPoint, localPoint, camera.projectionMatrix());
  point = vec3.fromValues(ndcPoint[0] / ndcPoint[3], ndcPoint[1] / ndcPoint[3], ndcPoint[2] / ndcPoint[3]);
  return point
}

/**
 * 淡入淡出过渡, r 在 0-1之间
 */
const fadeInOut = function(r:number) {
  return 1 - Math.pow ((1 - r * r), 3);
}

export {
  getScreenXYFromVec3,
  fadeInOut
}
