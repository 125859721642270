import PQueue from 'p-queue';
import { DecodeWorkerManager } from '../worker/image-decode';

export function bitmapSupported() {
  return typeof window.createImageBitmap === 'function';
}
export function blobToImage(blob: Blob): Promise<ImageBitmap | HTMLImageElement> {
  if (bitmapSupported()) {
    return DecodeWorkerManager.getInstance().decodeInWorker(blob);
  }
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const src = URL.createObjectURL(blob);
    const image = new Image();
    image.src = src;
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
}

export class LoadImageTask {
  private xhr: XMLHttpRequest = new XMLHttpRequest();
  constructor(
    public src: string,
  ) {
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  loadImage(): Promise<Blob> {
    this.xhr = new XMLHttpRequest();
    this.xhr.open('GET', this.src);
    return new Promise((resolve, reject) => {
      this.xhr.send();
      this.xhr.responseType = 'blob';
      this.xhr.onload = () => {
        if (this.xhr.status === 200) {
          resolve(this.xhr.response as Blob);
        } else {
          reject(this.xhr.statusText);
        }
      };
      this.xhr.onerror = (reason) => {
        reject(reason);
      };
      this.xhr.onabort = () => {
        const error = `Abort ${this.src} downloading`;
        reject(error);
      };
    });
  }
}

export class LoadImageQueue {
  static queue = new PQueue({ concurrency: 6 });

  static async loadImage(url: string) {
    const task = new LoadImageTask(url);
    return await LoadImageQueue.queue.add(async() => { 
      const blob = await task.loadImage();
      return blobToImage(blob)
    });
  }
  static clear() {
    LoadImageQueue.queue.clear();
  }
}
export class ImageCache{
  static caches = new Map();
  static async getImage(url: string): Promise<HTMLImageElement>{
    //   const cached = ImageCache.caches.get(url);
    //   if(cached){ return cached }
    const image = await LoadImageQueue.loadImage(url);
    //   ImageCache.caches.set(url, image);
    return image as HTMLImageElement;
  }
  static async cacheImagesBySpotId(spotId: string){
    //   const cached = ImageCache.caches.get(url);
    //   if(cached){ return cached }
    //   const image = await LoadImageQueue.loadImage(url);
    //   ImageCache.caches.set(url, image);
    //   return image;

  }
}