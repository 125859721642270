import { Entity,Transform, AnimationPlayer, AudioPlayer} from "@ali/tidejs";
import { SpotPrivate } from "../../api/spot/lib/private";
import { Application } from "../../Application";
import { vec3 } from "gl-matrix";

import { Camera } from '@ali/tidejs'
import { AudioPlayerMgr } from "../../GameLogic/AudioPlayMgr";
import { VGPath, PathGroup, VGRoamingConfg } from "./Area";
import { StateEvent } from "../../GameLogic/StateEvent";
import { ElementsManager } from "../../api/elements/ElementsManager";
import { ConsoleLog } from "../../lib/log";




/**
 * 状态机之间的共享数据
 */
export class VGInfo {

  private static current: VGInfo;
  static GetInstance() {
    return this.current;
  }

  public entity!: Entity;
  public entityTransform!: Transform;
  //public spotID = '-1';
  public distance = -0.5;
  public yOffset = 0.01;

  //动画控制
  private aniCom: AnimationPlayer | undefined;

  public soundMgr2!: AudioPlayerMgr;

  //路线数据
  //private configJson: any | undefined = undefined;
  public configJsonURL = ''; //路线配置URL，在没有 _configJson 时，会从该路径下获取_configJson
  public speed = 0; //移动速度
  public configJson: any;// 由 configJsonURL 加载而来


  private temp: vec3 = vec3.create();

  public placeSpot  = '';
  private paths: Map<string,VGPath> = new Map<string,VGPath>();
  private pathsIDs: string[] = [];
  public currentPath: VGPath | undefined;
  private currentPathIndex = -1;

  constructor(entity: Entity,soundMgr: AudioPlayerMgr) {
    VGInfo.current = this;
    this.entity = entity;
    this.entityTransform = this.entity.transform;
    this.aniCom = this.entity.transform.children[0]?.entity.getComponentByType<AnimationPlayer>(AnimationPlayer);
    const player = this.entity.addComponent<AudioPlayer>(AudioPlayer);
    this.soundMgr2 = soundMgr;
    this.soundMgr2.audioPlayer = player;
  }

  /**
     * 重新设置动画组件
     */
  public setAniCom() {
    this.aniCom = this.entity.transform.children[0].entity.getComponentByType<AnimationPlayer>(AnimationPlayer);
  }


  public placeToSpot(index: number) {
    if (!this.currentPath) return;
    const r = this.currentPath.placeToSpotByIndex(index);
    if (!r) return;
    StateEvent.onArrivedSpot.emit('arrived',this.currentPath.id,this.currentPath.currentSpot);
  }

  public placeToSpotByName(spotid: string) {
    if (!this.currentPath) return;
    const r = this.currentPath.placeToSpot(spotid);
    if (!r) return;
    StateEvent.onArrivedSpot.emit('arrived',this.currentPath.id,this.currentPath.currentSpot);
  }
  /**
     * 发路径走完的事件
     */
  public endPath() {
    if (!this.currentPath) return;
    StateEvent.onArrivedSpot.emit('finish',this.currentPath.id,this.currentPath.currentSpot);
  }
  /**
     *
     * @param aniName 动画名
     * @returns
     * ~~~
     * 动画名目前有：
     * idle, walk, walk_speak, introduce, byebye
     * ~~~
     */
  public playAnimation(aniName: string) {
    if (!this.aniCom) return;
    // ConsoleLog.info('VGInfo', 'playAnimation', this.aniCom.animationClips, this.aniCom.getCurrentTime())

    switch (aniName) {
      case 'walk':
        this.aniCom.animatedClip = this.filterAnimationClip('walk');
        break;
      case 'byebye':
        this.aniCom.animatedClip = this.filterAnimationClip('byebye');
        break;
      case 'introduce':
        const introduceNameList = ['introduce01', 'introduce02', 'introduce03', 'introduce04', ]; // 介绍的动画提供了4种，随机选取一种
        const randomIndex = Math.floor(Math.random() * 4);
        const introduceName = introduceNameList[randomIndex];
        this.aniCom.animatedClip = this.filterAnimationClip(introduceName);
        // this.aniCom.animatedClip = this.filterAnimationClip('introduce');
        break;
      case 'walk_speak':
        this.aniCom.animatedClip = this.filterAnimationClip('walk_speak');
        break;
      case 'introduce_mark':
        this.aniCom.animatedClip = this.filterAnimationClip('introduce01');
        break;
      default:
        this.aniCom.animatedClip = this.filterAnimationClip('stand');
        break;
    }
    this.aniCom.resumeClip();
  }

  private filterAnimationClip(aniName: string) {
    const animationClips = this.aniCom?.animationClips;
    const filterClip = animationClips?.filter((item: any) => {
      return item._animationClipName === aniName
    })
    return filterClip?.length ? filterClip[0] : undefined
  }

  public stopAnimation() {
    if (!this.aniCom) return;
    this.aniCom.pauseClip();
  }

  public resumeAnimation() {
    if (!this.aniCom) return;
    this.aniCom.resumeClip();
  }

  public faceToCamera() {
    const ss = vec3.sub(vec3.create(), Camera.MainCamera!.entity.transform.position,this.entityTransform.position);
    ss[1] = 0;
    vec3.normalize(ss,ss);
    this.entityTransform.setForward(ss,[0,1,0]);
  }

  /**
     *
     * @param id 取得spot点坐标，会叠加偏y方向的移值
     * @returns
     */
  public getSpotPos(id: string): vec3 | undefined {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getHotSpotById(id);
    if (spot) {
      this.temp[0] = spot.groundCoord[0];
      this.temp[1] = spot.groundCoord[1] + this.yOffset;
      this.temp[2] = spot.groundCoord[2];
      return vec3.clone(this.temp);
    }
  }

  public getNearbySpots() {
    if (!this.currentPath) return;
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const s = spotAPI.getHotSpotById(this.currentPath.currentSpot);
    if (!s) return undefined;
    return s.neighbors;
  }

  public getAllSpot() {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const  c = spotAPI.getHotSpots().values();
    return Array.from(c);
  }

  /**
     * 得到将要移动的起点与终点坐标
     * @returns [p1,p2] p1为起点坐标，p2为终点坐标
     * 如果到达终点，返回undefined
     */
  public getNextMoveInfo() {
    if (!this.currentPath) return undefined;
    return this.currentPath.MoveToNextSpot();
  }

  /**
     * 获取下一个移动的spot点
     * 如果到达终点，返回undefined
     */
  public getNextMoveSpot() {
    if (!this.currentPath) return undefined;
    return this.currentPath.getNextPathSpot();
  }

  /**
   * 获取下一个移动的spot点id, 如果到达终点，返回undefined
   * @returns
   */
  public getNextMoveSpotId() {
    if (!this.currentPath) return undefined;
    return this.currentPath.getNextPathSpotId();
  }

  /**
     * 到达目标
     * @returns
     */
  public ArriedTarget() {
    if (!this.currentPath) return;
    this.currentPath.ArriedTarget();
    StateEvent.onArrivedSpot.emit('arrived',this.currentPath.id,this.currentPath.currentSpot);
  }

  /**
     * 切换到路径
     * @param index
     * @returns
     */
  public switchToPath(pathName: string) {
    const path = this.paths.get(pathName);
    if (!path) return undefined;
    this.currentPathIndex = this.pathsIDs.findIndex(v=>v=== pathName);
    this.currentPath = path;
    this.currentPath.currentSpotIndex = 0;

    this.soundMgr2.stop();//切换路径，停止一切声音
    this.placeToSpot(0);
    return true;
  }

  /**
     * 切换到下一个路径，当处于末尾时，会回到第一个路径
     */
  public switchToNextPath() {
    this.currentPathIndex += 1;
    if (this.currentPathIndex >= this.pathsIDs.length) {
      this.currentPathIndex = 0;
    }
    this.switchToPath(this.pathsIDs[this.currentPathIndex]);
    return true;
  }


  public switchToGroup(groupName: string) {
    this.soundMgr2.stop();//切换group，停止一切声音
    return this.currentPath?.placeToGroupByName(groupName);
  }

  public switchToGroupByIndex(groupIndex: number) {
    this.soundMgr2.stop();//切换group，停止一切声音
    return this.currentPath?.placeToGroup(groupIndex);
  }

  public switchToNextGroup() {
    this.soundMgr2.stop();//切换group，停止一切声音
    return this.currentPath?.palceToNextGroup();
  }

  //#region  deal sound
  /**
     * 播放当前路径的声音；依据当前路径、当前spot点，进行声音的选择
     */
  public playCurrentGroupSound(){
    if (!this.currentPath) return;
    const group = this.currentPath.currentGroup;
    if (group){
      //无论是否一个声音，都播放
      if (this.soundMgr2.url === group.url) return;
      this.soundMgr2.play(group.url);
    }
  }

  public pauseSound() {
    this.soundMgr2.pause();
  }

  public resumeSound() {
    if (!this.soundMgr2.isFinished())
      this.soundMgr2.resume();
  }

  public stopSound() {
    this.soundMgr2.stop();
  }

  /**
     * 如果是最后一个spot，并且当前声音还没有播完
     */
  public isLastSpotAndSoundIsPlaying() {
    if (!this.currentPath) return false;
    const a = this.currentPath.currentGroup;
    if (!a) return false;
    const r = this.currentPath.getRateInPath() ;
    //如果有声音，且声音是最后一个，并还在继续播放，返回true
    if (r === 1 && !this.soundMgr2.isFinished() && this.soundMgr2.url && this.soundMgr2.url === a.url) {
      return true;
    }
    return false;
  }

  public isFirstSpotInGroup() {
    if (!this.currentPath) return false;
    const r = this.currentPath.isFirstSpotInGroup();

    if (r) {
      StateEvent.onArrivedSpot.emit('groupChaned',this.currentPath.id,this.currentPath.currentGroup?.name, this.currentPath.currentGroupIndex);
    }
    return r;
  }

  public isLastSpotInGroup() {
    if (!this.currentPath) return false;
    const r = this.currentPath.isLastSpotInGroup();
    return r;
  }
  //#endregion

  async Init (): Promise<boolean> {
    return new Promise<boolean>(async (resolve,reject)=>{
      //初始化json
      if (this.configJson === undefined && this.configJsonURL !== '') {
        this.configJson  = await new Promise((resolve,reject)=>{
          const xhr = new XMLHttpRequest();
          xhr.open('GET', this.configJsonURL, true);
          xhr.responseType = 'json';
          xhr.onload = function() {
            const status = xhr.status;
            if (status === 200) {
              resolve(xhr.response);
            } else {
              ConsoleLog.warn('VGInfo', 'init', '加载configJson失败', status);
              reject(false);
            }
          };
          xhr.send();
        });
      }
      //如果配置有异常，返回false;
      if (this.configJson === false) {
        return reject(false);
      }

      //开始配置
      this.speed = this.configJson.speed;
      for (const pathJson of this.configJson.paths) {
        const path = new VGPath(this);
        path.id = pathJson.id;

        //解析路径
        for (const spot of pathJson.spots) {
          path.spots.push(new VGRoamingConfg(spot))
        }

        for (const groupInfo of pathJson.groups) {
          const group = new PathGroup();
          group.area = groupInfo.area;
          // 避免资源缓存
          if (groupInfo.groupURL && groupInfo.groupURL.indexOf('?') > -1) {
            group.url = groupInfo.groupURL + `&time=${+new Date()}`;
          } else {
            group.url = groupInfo.groupURL + `?time=${+new Date()}`;
          }
          group.name = groupInfo.groupName;
          group.indices = groupInfo.indices
          path.groups.push(group);
        }
        this.paths.set(path.id,path);
        this.pathsIDs.push(path.id);
      }

      this.currentPath = this.paths.get(this.pathsIDs[0]);
      if (this.currentPath)
        this.placeToSpot(0);

      this.soundMgr2.callBack = (t,total)=>{
        StateEvent.onTimeChange.emit('groupTimeChanged',this.currentPath?.id,this.currentPath?.currentGroup?.name,t,total);
      }
      this.soundMgr2.playChangeCallBack = (type, t, total)=>{
        StateEvent.onTimeChange.emit('soundPlayChanged', type, t, total);
      }
      resolve(true);
    })
  }

  reset() {
    this.pathsIDs = [];
    this.currentPath = undefined;
    this.currentPathIndex = -1;
    this.paths.clear()
  }

  /**
     * 获取场景中标签的坐标
     * @param id
     * @returns
     */
  public getAnnotationPos(id: string): undefined | vec3 {
    const elementAPI = Application.getInstance<ElementsManager>(ElementsManager.ModuleName);
    const annotation = elementAPI.getElementById(id);
    if (annotation) {
      const pos = vec3.create();
      // 注意数据坐标系的对应 y = z， z = -y
      pos[0] = annotation.elementConfig?.anchor?.x;
      pos[1] = annotation.elementConfig?.anchor?.z;
      pos[2] = -annotation.elementConfig?.anchor?.y;
      return pos;
    }
  }

  /**
     * 获取当前路径上已到达的当前spot点
     * @returns
     */
  public getCurArrivedSpot() {
    return this.currentPath?.currentSpot;
  }


  /**
     * 获取当前路径上下一个要驻留的标识点
     * @returns
     */
  public getNextStopMark() {
    return this.currentPath?.getNextStopMark();
  }

  /**
     * 获取当前路径上当前要驻留的标识点
     * @returns
     */
  public getCurStopMark() {
    return this.currentPath?.getCurStopMark();
  }

  /**
     * 到达路径上的标识点
     * @param target
     */
  public arrivedMark(mark: any) {
    StateEvent.markEvent.emit('arrivedMark', mark);
  }

  /**
     * 结束在标识点的停留
     * @param target
     */
  public endMarkStop(mark: any) {
    StateEvent.markEvent.emit('endMarkStop', mark);
  }

}
