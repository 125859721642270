
import { mat4, quat, vec3 } from 'gl-matrix';
import { Space, Transform } from '@ali/tidejs';
import { BasicState, expJson, InjectState } from '../StatesFrame';
import { RobotInfo } from './RobotCtr';
import { ConsoleLog } from "../../lib/log";
/**
 * 这是一种跟踪模式，当目标发生变化时，会动态调整
 *
 * 相比较 walk 模式，StyleB 加速、减速不会特别平滑，特别是在加速、减速阶段，目标移动后，可能会改变加速状态
 */
@InjectState('RobotWalkStyleB')
export class RobotWalkStyleB extends BasicState<RobotInfo> {
  @expJson('info')
  public info = 'RobotWalkStyleB';

  @expJson('onArrivedState')
  public onArrivedState: BasicState<RobotInfo> | undefined;

  @expJson('speed')
  protected speed = 1;
    // 每秒加速 米
  protected accelerate = 2.2;
  // 加速到预定速度，需要的时长
  private _acceTime = 0;

  private _realyAcc = 0;
  private _speed = 0;
  private _speedVec3 = vec3.fromValues(0,0,1);


  private speedScale = 1.0;
  protected _trans!: Transform;
  protected _target!: Transform;
  private _endQuat: quat = quat.create();
  private _lastQuat!: quat;
  private _arrivedMinDis = false;

  //加速阶段
  private _accStage = true;
  private _lastDis = 0;
  private _expectTime = 0;
  private _reMainTime = 1;

  private _tempSpeed = 0;
  update (deltaTime: number) {
    if (!this._target) {
      if (this.onArrivedState) {
        this.onArrivedState.changeTo();
      }
    }
    if (this._accStage) {
      //加速阶段，计算当前速度
      if (this._speed < this.speed) {
        this._speed += deltaTime * this.accelerate;
        if (this._speed > this.speed) {
          this._speed = this.speed;
        }
      }
    } else {
      //减速阶段，计算当前速度
      if (this._speed > 0.2) {
        this._speed -= deltaTime * (this.accelerate * 0.25 + this._speed);
        if (this._speed < 0.2) this._speed = 0.2;
      }
    }

    this._speedVec3[2] = this._speed * deltaTime * this.speedScale;

    //更新方向与坐标
    mat4.lookAt(RobotInfo.TempMat41,this._target.position,this._trans.position,this.srcInfo.upDir);
    mat4.getRotation(this._endQuat,RobotInfo.TempMat41);
    this._trans.rotation = this._endQuat;
    this._trans.translate(this._speedVec3,Space.Self);

    const distance = vec3.distance(this._target.position,this._trans.position);

    //快要抵达，进入减速阶段，否则，保持加速状态
    //判断标准是，按当前速度,还有1秒达到;
    const expectTime = distance / this._speed;
    if (expectTime < 1 ) {
      this._accStage = false;
      this._tempSpeed = this._speed;
    } else {
      this._accStage = true;
      // ConsoleLog.info('RobotWalkStyleB', 'update', this.info, 'state','加速时长',this._acceTime,'跟踪目标点,',this._speed,distance,this.speedScale);
    }

    //达到判断
    if ( distance < 0.05) {
      this._arrivedMinDis = true;
    }

    if (this._arrivedMinDis && distance > this._lastDis) {
      ConsoleLog.info('RobotWalkStyleB', 'update', '到达');
      this._trans.position = this._target.position;
      this._trans.rotation = this._lastQuat;
      if (this.onArrivedState) {
        this.onArrivedState.changeTo();
      }
    }
    this._lastQuat = quat.clone(this._endQuat);
    this._lastDis = distance;
    this._expectTime = expectTime;
  }

  onEnter () {
    this._speed = 0;
    this._trans = this.srcInfo.entityTransform;
    this._acceTime = this.speed / this.accelerate;
    this._target = this.srcInfo.targetPoint;
    this.speedScale = 1 / this._trans.localScale[0];
    this._arrivedMinDis = false;
    this._reMainTime = 1;
    ConsoleLog.info('RobotWalkStyleB', 'onEnter', this.info);
  }

  onExit () {
    ConsoleLog.info('RobotWalkStyleB', 'onExit', this.info);
  }

}
