import { Player, Entity, Renderer, VideoPlayer, Camera, PrimitiveType, MeshFilter, SceneManager } from '@ali/tidejs';
import EventEmitter from 'eventemitter3';
import { ConsoleLog, ErrorType } from './lib/log';
import { APIInstanceContainer, ecaDispose } from './lib/utils/eca';
import { performanceMark } from './lib/utils';
import { ApplicationModeBase } from './api/mode/ApplicationModeBase';
import { PanoramaOpt } from "./Behaviors/PanoramaOpt";
import { PanoVideoMaterial } from './Material/PanoVideoMaterial';
import { ProgressReporter } from "./lib/progress/ProgressReporter";
import { isNumber } from "lodash"


export interface IRenderOption {
  preserveDrawingBuffer?: boolean;
  antialias?: boolean;
  dpr?: number;
}

export interface IRotateOption {
  autoRotate?: boolean;
  rotateRate?: number;
}

export interface IPanoVideoAppInitOption {
  videoUrl: string;
  autoPlay?: boolean;
  isLoop?: boolean;
  rotateOption?: IRotateOption;
  renderOption?: IRenderOption;
}

function createSphere() {
  const Sphere = Entity.CreatePrimitive(PrimitiveType.Sphere);
  if (!Sphere) { return }
  Sphere.transform.localScale = [10, 10, 10];
  const renderer = Sphere.getComponentByType<Renderer>(Renderer);
  if (!renderer) { return }
  const meshFilter = Sphere.getComponentByType<MeshFilter>(MeshFilter);
  if (meshFilter && meshFilter.mesh) {
    meshFilter.mesh.upLoad();
  }
  return Sphere;
}


export class PanoVideoApp {
  static mode: "runtime" | "editor";
  static instance: PanoVideoApp;

  static getInstance<T>(moduleName: string): T {
    const instance = APIInstanceContainer.get(moduleName);
    if (!instance) {
      ConsoleLog.error("PanoVideoApp","getInstance",ErrorType.InternalError,"没有找到API：" + moduleName)
    }
    return instance;
  }

  public container!: HTMLElement;
  public canvas!: HTMLCanvasElement;
  public player!: Player;
  public event = new EventEmitter<"loaded">();
  private appMode!: ApplicationModeBase;
  private videoSphere: Entity | undefined = undefined;
  private videoPlayer: any;

  constructor(container: HTMLElement, public options: IPanoVideoAppInitOption) {
    performanceMark('show-panovideo-begin');
    this.container = container;
    if (!options.videoUrl) {
      throw new Error('缺少全景视频资源url');
    }
    this._init(options);
  }

  getInstance<T>(moduleName: string): T {
    return PanoVideoApp.getInstance(moduleName);
  }

  /**
     * 视频播放器底层实体(浏览器video，可以通过这个属性操作原生video)
     */
  get panoVideo() {
    return this.videoPlayer?.videotexture.data;
  }

  /**
     * 程序初始化
     * Starts application
     * @returns
     */
  async _init(options: IPanoVideoAppInitOption) {
    const {videoUrl, autoPlay = true, isLoop = true, rotateOption } = options;
    this.appMode = new ApplicationModeBase();
    ProgressReporter.onProgress.next({ progress: 20 });
    await this.appMode.initPanoVideo(this);
    ProgressReporter.onProgress.next({ progress: 60 });
    this._addPanoramaOpt();
    await this._load(videoUrl, isLoop);

    if (autoPlay) {
      this.play();
    }
    ProgressReporter.onProgress.next({ progress: 80 });
    if (rotateOption) {
      this._setRotate(rotateOption);
    }
    ProgressReporter.onProgress.next({ progress: 100 });
    ProgressReporter.onLoaded.next();
    PanoVideoApp.instance = this;
    this.event.emit('loaded', videoUrl);
    performanceMark('show-panovideo-complete');
  }

  /**
     * 加载视频
     * @param url 视频链接
     * @param isLoop 是否循环
     * @returns
     */
  private async _load(url: string, isLoop: boolean) {
    this.videoSphere = createSphere() as Entity;
    SceneManager.GetInstance().currentScene.addEntity(this.videoSphere);
    const videoPlayer = this._getVideoPlayer();
    const render = this.videoSphere.getComponentByType<Renderer>(Renderer);
    if(!render){ return }
    const texture = await videoPlayer.loadVideo(url, false);
    if(!texture){ return }
    const m = new PanoVideoMaterial();
    // const srcMatrix = mat3.fromMat4(mat3.create(), mat4.lookAt(mat4.create(), [0, 0, 0], [1, 0, 0], [0, 1, 0]));
    // m.worldToCameraMatrix = srcMatrix;
    m.center = [0, 0, 0];
    m.albedo = texture;
    render.material = m;
    render.material.cullface = -1;
    videoPlayer.videotexture.data.muted = false;
    videoPlayer.videotexture.data.preload = true;
    videoPlayer.videotexture.data.loop = isLoop;
  }

  /**
     * 获取当前视频播放器
     * @returns
     */
  private _getVideoPlayer() {
    if(!this.videoPlayer){
      this.videoPlayer = this.videoSphere!.addComponent<VideoPlayer>(VideoPlayer);
    }
    return this.videoPlayer;
  }

  /**
     * 添加相机控制组件
     * @returns
     */
  private _addPanoramaOpt() {
    if (!Camera.MainCamera) {
      return
    }
    Camera.MainCamera!.entity.addComponent(PanoramaOpt);
  }

  /**
     * 设置相机旋转状态
     * @param option
     */
  private _setRotate(option: IRotateOption) {
    const { autoRotate, rotateRate} = option;
    if (autoRotate) {
      this.enableCameraAutoRotate(true);
    }
    if (isNumber(rotateRate)) {
      this.setCameraRotateRate(rotateRate)
    }
  }

  /**
     * 播放视频
     */
  public play() {
    const videoPlayer = this._getVideoPlayer();
    videoPlayer.play();
  }

  /**
     * 暂停视频
     */
  public pause() {
    const videoPlayer = this._getVideoPlayer();
    videoPlayer.pause();
  }

  /**
     * 重置视频
     */
  public reset() {
    const videoPlayer = this._getVideoPlayer();
    videoPlayer.reset();
    this.play();
  }

  /**
     * 设置视频是否禁音
     * @param muted 是否禁音
     */
  public setVideoMuted(muted: boolean) {
    const videoPlayer = this._getVideoPlayer();
    videoPlayer.videotexture.data.muted = muted;
  }

  /**
     * 获取视频总时长
     * @returns
     */
  public getTotalTime() {
    const videoPlayer = this._getVideoPlayer();
    return videoPlayer.getTotalTime();
  }

  /**
     * 获取视频当前播放时间
     * @returns
     */
  public getCurrentTime() {
    const videoPlayer = this._getVideoPlayer();
    return videoPlayer.getCurrentTime();
  }

  /**
     * 跳转到视频指定时间
     * @param time 视频时间点
     */
  public jumpTo(time: number) {
    const videoPlayer = this._getVideoPlayer();
    const totalTime = this.getTotalTime();
    if (time >= totalTime) {
      videoPlayer.jump(totalTime);
    } else if (time < 0) {
      videoPlayer.reset();
    } else {
      videoPlayer.jump(time);
    }
    this.play();
  }

  /**
     * 更换视频链接
     * @param url 视频链接
     * @returns
     */
  public async setVideoUrl(url: string) {
    if (this.videoPlayer) {
      this.videoPlayer.pause();
      this.videoPlayer.dispose();
    }
    const videoPlayer = this._getVideoPlayer();
    const render = this.videoSphere!.getComponentByType<Renderer>(Renderer);
    if(!render){ return }
    const texture = await videoPlayer.loadVideo(url, false);
    if (!texture) { return }
    (render.material as PanoVideoMaterial as any).albedo?.dispose();
    (render.material as PanoVideoMaterial).albedo = texture;
    this.play();
    this.event.emit('loaded', url);
  }

  /**
     * 设置视频播放速度
     * @param rate 播放速度
     */
  public setPlaybackRate(rate: number) {
    const videoPlayer = this._getVideoPlayer();
    videoPlayer.setVideoSpeed(rate)
  }

  /**
     * 设置相机自动旋转
     * @param enable 是否自动旋转
     * @returns
     */
  public enableCameraAutoRotate(enable: boolean) {
    const panoramaOpt = Camera.MainCamera?.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) {
      return;
    }
    panoramaOpt.enableAutoRotate = enable;
  }

  /**
     * 设置相机自动旋转速率
     * @param rate 速率
     * @returns
     */
  public setCameraRotateRate(rate: number) {
    const panoramaOpt = Camera.MainCamera?.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) {
      return;
    }
    panoramaOpt.autoRotateRate = rate;
  }

  /**
     * 销毁当前场景
     * Disposes application
     */
  dispose(){
    ecaDispose()
    this.videoPlayer.pause();
    this.videoPlayer.dispose();
    this.videoSphere?.dispose();
  }

  reload() {

  }
}
