import { Camera, Entity, MeshFilter } from "@ali/tidejs";
import { MessageCenter } from "./MessageCenter";
export class DataCenter {
  static RootModel: Entity;
  public static RootModelMF: MeshFilter | undefined;
  /**
     * 所有要在全景图中有点击响应的物体，都必须把 MeshFilter 放到这里
     * Entity filters of data center
     */
  public static PanoramaMeshFilters: MeshFilter[] = [];
  public static RootCamera: Camera | undefined;
  public static RootCameraEntity: Entity | undefined;
  /**
     * OrbitControls 的实例，放在这里是为了避免eca.ts直接引用，打包会有问题
     * Orbit controls instance of data center
     */
  public static OrbitControlsInstance: any | undefined;

  public static octree: any = undefined;
  public static trans: any = undefined;
  
}
MessageCenter.DisposeEvent.on("dispose", ()=>{
  DataCenter.PanoramaMeshFilters = [];
  DataCenter.RootModelMF = undefined;
  DataCenter.RootCamera = undefined;
  Entity.Destroy( DataCenter.RootCameraEntity! );
  DataCenter.RootCameraEntity = undefined;
})
