import EventEmitter from "eventemitter3";
import { MessageCenter } from "../../GameLogic/MessageCenter";
import { checkAudioSupport, ContextAudio } from "./ContextAudio";
import { HTMLAudio } from "./HTMLAudio";

export class SDKAudio{

  public audio!: ContextAudio | HTMLAudio;
  public type: "context-audio" | "html-audio" = "context-audio";
  public event = new EventEmitter<"play" | "pause" | "end" | "loading" | "loaded" | "error">();

  set src(value: string){
    this.audio.src = value;
  }
  get src(){
    return this.audio.src;
  }

  get duration(){
    return this.audio.duration;
  }
  get paused(){
    return this.audio.paused;
  }
  get ended(){
    return this.audio.ended;
  }
  get isError(){
    return this.audio.isError;
  }
  get isLoading(){
    return this.audio.isLoading;
  }
  get isLoaded(){
    return this.audio.isLoaded;
  }

  set onended(value: any){
    this.audio.onended = value;
  }
  get onended(){
    return this.audio.onended;
  }
  constructor(src?: string){
    if(!checkAudioSupport()){
      this.audio = new HTMLAudio(src);
      this.event = this.audio.event;
    } else {
      this.audio = new ContextAudio(src);
      this.event = this.audio.event;
    }
    MessageCenter.DisposeEvent.once("dispose", ()=>{
      this.audio.dispose();
    })
  }
  get readyState(){
    return this.audio.readyState;
  }
  set currentTime(value: number){
    this.audio.currentTime = value;
  }
  get currentTime(){
    return this.audio.currentTime;
  }
  load(){
    return this.audio.load() as any;
  }
  play(){
    return this.audio.play()
  }
  pause(){
    return this.audio.pause()
  }
  resume(){
    return this.audio.resume()
  }
}
