import { CameraPrivate } from "../../../api/camera/lib/private";
import { TransitionPrivate } from "../../../api/transition/lib/private";
import { Application } from "../../../Application";
import { AsyncTask, IOldRoamingItem, IRoamingItem, Mode, Roaming } from "../../../api/roaming/lib/Roaming";
import { API } from "../../../lib/utils/eca";
import { ConsoleLog, ErrorType } from "../../../lib/log";
import EventEmitter from "eventemitter3";
import { SDKAudio } from "../../../lib/audio/audio";
import { APIBaseClass } from "../../../lib/utils/eca";
import { AudioPlayManager } from "../../../lib/audio/AudioPlayManager";
import { FlyGuider } from "../../../api/flyGuider/lib/private";

export function checkAnimation(animation: IOldRoamingItem["animation"]["from"] | undefined){
  if(animation && animation.fov && animation.theta && animation.phi ){
    return true
  }
  return false;
}
/**
 * 将中台漫游配置转换成SDK需要的
 * @param path
 * @returns
 */
export function parseOldToNew(path: IOldRoamingItem){
  const item: IRoamingItem = {
    spotId: "p0",
  };
  const duration = path.transitionParams ? path.transitionParams.duration : 0.75;
  item.spotId = path.spotId;
  item.transition_duration = duration;
  if (!path.animation) {
    return item;
  }
  if (checkAnimation(path.animation.from)) {
    const {  theta, phi, fov } = path.animation.from;
    item.from = [theta, phi, fov];
  }
  if (checkAnimation(path.animation.to)) {
    const { theta, phi, fov } = path.animation.to;
    item.to = [theta, phi, fov];
  }
  item.animation_duration = path.animation.duration || 1;
  item.stay = RoamingPrivate.Config.stay;
  return item;
}
/**
 * 解析单条路径的Config
 * @param config
 * @returns
 */
function parseConfig(config: IOldRoamingItem[]) {
  const roaming_config = [];
  if (!config || !(config instanceof Array)) {
    ConsoleLog.error("roamingAPI", "parseConfig" ,ErrorType.DataError,"loadFromConfig 解析错误")
    return []
  }
  for (const path of config) {
    const item = parseOldToNew(path)
    roaming_config.push(item);
  }
  return roaming_config;
}
function checkRoamingConfig(config: IRoamingItem[]) {
  if (!config || !(config instanceof Array)) { return false }
  return true;
}
/**
 * RoamingAPI 提供了各种设置漫游的接口，用于需要对路线进行修改的情况
 * # 路线和分组的概念
 * * 路线： roaming.json 里面是整条路径，默认会沿着这条路径一直走下去
 * * 分组： roamingGroup.json 里面是根据路线索引建立的分组，播放完一个分组，继续播放下一个分组。
 */
@API()
export class RoamingPrivate extends APIBaseClass{

  static ModuleName = "roamingAPI";

  static Config = {
    configPath: "roaming/roaming.json",
    groupPath: "roaming/roamingGroup.json",
    loop: false,
    stay: 3000,
  }

  public state = {
    config: {},
  }
  /**
     * 当前漫游路径中配置的音频，返回的不是 HTMLAudio 而是SDK内部的Audio
     * Current audio of roaming private
     */
  public currentAudio: SDKAudio | undefined;
  public isPaused = false;
  public isPlaying = false;
  public get playing() {
    return this.isPlaying;
  }
  protected roamingController = new Roaming();

  /**
     *
     * play 是每个分组开始播放的事件
     * 可能会在漫游开始后执行，因为要等待语音的加载
     * 回调: { index: number, audio: SDKAudio }
     *
     * end 是整条漫游路径的事件
     *
     * audioLoading 回调： SDKAudio
     *
     * audioLoaded 回调： SDKAudio
     *
     * "group-end"： 每条漫游分组结束事件 回调: index
     *
     * "to-spot-start" 准备漫游某个Spot点事件 回调：spotID
     *
     * "to-spot-end" 结束漫游某个Spot点事件 回调：spotID
     *
     * @event
     *
     */
  public event = new EventEmitter<"play" | "pause" | "end" | "audioLoading" | "audioLoaded" | "group-end" | "to-spot-start" | "to-spot-end">();
  protected transitionAPI!: TransitionPrivate;
  protected cameraAPI!: CameraPrivate;

  /**
     * 设置roamingConfig，兼容旧接口，
     * 传入是个数组 IOldRoamingItem []
     * 重要： 这里是没有分组的概念的，分组就是相当于一条漫游路径的分段，如果有分组，就将整条路径按分组拆成子路径出来，传入这个函数
     * @param config
     * @returns
     */
  public loadFromConfig(config:  IOldRoamingItem []) {
    ConsoleLog.info("roamingAPI","loadFromConfig", config)
    this.roamingController.config = parseConfig(config);
    if(!checkRoamingConfig(this.roamingController.config)){
      ConsoleLog.error("roamingAPI","loadFromConfig",ErrorType.DataError,"解析roaming.json时发生错误");
      this.deactivate();
    }
    this.transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    this.cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
  }
  /**
     * @ignore
     * 设置能直接给roaming用的config，
     * 内部方法，不对外暴露，外面使用loadFromConfig
     * @param config
     */
  protected setRoamingConfig(config: IRoamingItem[]){
    ConsoleLog.info("roamingAPI","setRoamingConfig", config)
    this.roamingController.config = config;
    this.transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    this.cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
  }
  /**
     * 开始漫游，在此之前必须调用 loadFromConfig
     * Starts roaming private
     * @param loop
     * @param [index]
     * @returns
     */
  public start(loop: boolean, index = 0) {
    return new Promise<void>(async resolve => {
      if(this.isPlaying){
        ConsoleLog.warn("roamingAPI","start","正在播放中，请勿重复调用");
        return Promise.resolve();
      }
      this.roamingController.api_event = this.event;
      // 清空之前的状态和事件，防止影响这一次
      this._clearEvent();
      ConsoleLog.log("roamingAPI","start","禁止用户跳转");
      this.roamingController.loop = loop;
      ConsoleLog.info("roamingAPI","start", "开始监听漫游结束");
      this._play();
      this.roamingController.event.once("end", () => {
        ConsoleLog.info("roamingAPI","start", "监听到漫游结束", this.currentAudio, this.currentAudio?.ended);
        if(!this.currentAudio || this.currentAudio.ended){
          ConsoleLog.info("roamingAPI","start", "音频已经完成，结束");
          this.pause(); resolve(); return
        }
        // 如果音频播放完成，自动漫游还没有播放完，那就等待自动漫游播放完成
        // 如果自动漫游先播放完成，等待音频播放完，再暂停
        ConsoleLog.info("roamingAPI","start","等待音频播放完成");
        this.currentAudio.onended = () => {
          ConsoleLog.info("roamingAPI","start", "音频播放完成，结束");
          this.pause(); resolve();
        }
      });
      // 先进行自动漫游，然后等待加载音频，否则会像BUG，这里根据业务逻辑来
      if(this.currentAudio){
        this.event.emit("audioLoading", this.currentAudio);
        await this.currentAudio.load();
        const result = await this._checkAudioStatus(this.currentAudio);
        if(this.isPlaying && result){
          this.currentAudio.currentTime = 0;
          AudioPlayManager.getInstance().playAudio(this.currentAudio);
          this._switchEmotion("speak");
          this.currentAudio.event.once("end",()=>{
            this._switchEmotion("idle");
          })
        } else if(!result){
          // 如果没有加载成功语音，则仅仅暂停语音的播放
          AudioPlayManager.getInstance().pauseAudio(this.currentAudio);
          this.currentAudio = undefined;
        } else if(!this.isPlaying){
          // 如果漫游已经暂停，就暂停漫游的播放
          this.pause()
          ConsoleLog.info("roamingAPI","start","语音加载完成，但是已经暂停");
          // 这里直接return，不能执行 emitEvent
          return
        }
      }
      this._emitEvent(index);
    }).then(()=>{
      this.event.emit("group-end", index);
    })
  }
  /**
     * @ignore
     * Clears event
     */
  private _clearEvent(){
    // 清空之前的回调事件，否则上一个语音播放完成还是会调用Pause进行暂停
    if(this.currentAudio){ this.currentAudio.onended = ()=>{}; }
  }
  /**
     * @ignore
     * Emits event
     */
  private _emitEvent(index: number){
    ConsoleLog.info("roamingAPI","play", index, this.currentAudio);
    this.event.emit("play", {
      index,
      audio: this.currentAudio,
    });
  }
  private _play(){
    this.changeToAutoMode();
    this.roamingController.rePlay();
    this.transitionAPI.state.disableUserTransition = true;
    this.isPlaying = true;
  }
  /**
     * 恢复，漫游和音频从上次暂停的地方继续播放
     * Resumes roaming private
     */
  public resume() {
    this.isPlaying = true;
    this.currentAudio?.resume();
    if(this.currentAudio && !this.currentAudio.ended){
      this._switchEmotion("speak");
    }
    this.transitionAPI.state.disableUserTransition = true;
    this.roamingController.play();
  }
  public pause() {
    this.isPlaying = false;
    ConsoleLog.log("roamingAPI"," pause", "pauseAudio", this.currentAudio);
    this._switchEmotion("idle");
    AudioPlayManager.getInstance().pauseAudio(this.currentAudio);
    this.transitionAPI.state.disableUserTransition = false;
    this.cameraAPI.setCameraControlEnable(true);
    this.roamingController.pause();
    this.event.emit("pause");
    this.isPaused = true;
  }
  /**
     * 切换为半自动模式，用户可以旋转相机，不能点击跳转
     * Changes to semi auto mode
     */
  public changeToSemiAutoMode() {
    ConsoleLog.log("roamingAPI ","changeToSemiAutoMode","切换到半自动模式，允许用户旋转相机");
    this.roamingController.mode = Mode.SemiAuto;
    this.cameraAPI.setCameraControlEnable(true);
  }
  /**
     * 切换为全自动带看模式，用户不能旋转相机，不能点击跳转
     * Changes to auto mode
     */
  public changeToAutoMode() {
    ConsoleLog.log("roamingAPI ","changeToAutoMode","切换到自动模式，禁止用户旋转相机");
    this.roamingController.mode = Mode.AUTO;
    this.cameraAPI.setCameraControlEnable(false);
  }
  /**
     * 设置当前分组漫游路径要播放的音频
     * 注意：这个音频只是一个，如果要设置多个分组漫游路径的语音，需要监听
     * Sets audio
     * @param src
     * @returns
     */
  public setAudio(src: string){
    return new Promise<void>((resolve, reject)=>{
      if(!src){
        this.currentAudio = undefined;
        resolve();
        return
      }
      const audio = new SDKAudio();
      audio.src = src;
      ConsoleLog.info("setAudio ", src)
      audio.load().then(()=>{
        this.currentAudio = audio;
        resolve();
      }).catch(()=>{
        this.currentAudio = undefined;
        resolve();
      })
    })
  }
  /**
     * @ignore
     * 检查音频有没有加载完成
     */
  private _checkAudioStatus(audio: SDKAudio){
    ConsoleLog.info("roamingAPI ","_checkAudioStatus","等待audio加载")
    if(!audio || audio.isError){ return false }
    if(audio.readyState > 2){
      ConsoleLog.info("roamingAPI ","_checkAudioStatus","音频 readyState 状态:" + audio.readyState)
      this.event.emit("audioLoaded", audio);
      return Promise.resolve(true);
    }
    return new Promise<boolean>( resolve =>{
      const checkTimer = setInterval(()=>{
        if(audio.isError){
          resolve(false)
          clearInterval(checkTimer);
          return
        }
        ConsoleLog.info("roamingAPI ","_checkAudioStatus","音频 readyState 状态:" + audio.readyState)
        if(audio.readyState == 4){
          ConsoleLog.info("roamingAPI ","_checkAudioStatus","音频 长度:" + audio.duration)
          clearInterval(checkTimer);
          this.event.emit("audioLoaded", audio);
          resolve(true);
        }
      },500);
    })
  }
  public getRoamingTask(){
    return this.roamingController._public_taskQueue;
  }
  public setRoamingTask(task: AsyncTask[]){
    this.roamingController._public_taskQueue = task;
  }
  dispose(){

  }
  private _switchEmotion(type: string){
    if(this.currentAudio){
      const flyGuiderAPI: FlyGuider  = Application.getInstance(FlyGuider.ModuleName);
      if(flyGuiderAPI && (flyGuiderAPI as any)._root){
        flyGuiderAPI.switchEmotion(type)
      }
    }
  }


}
