import { BaseBehavior, Camera, Component, InputContext, IOnStart, IUpdatable, SceneManager } from '@ali/tidejs';
import { vec4, vec3, vec2, mat4 } from 'gl-matrix';
import { ConsoleLog, ErrorType } from '../lib/log';
import EventEmitter from "eventemitter3";

/** 轨道控制，对三维场景进行缩放、平移、旋转操作,本质上改变的并不是场景,而是相机的参数 */
@Component("PanoramaOpt")
export class PanoramaOpt extends BaseBehavior implements IUpdatable, IOnStart {

  /**
     * 阻尼系数
     */
  private _dampingFactor = 0.90;

  /**
     * 使能旋转
     */
  public enableRotation = true;
  /**
     * 使能缩放
     */
  public enableZoom = true;

  /**
     * 使能自动旋转
     */
  public enableAutoRotate = false;

  public autoRotateRate = -0.5;

  /**
     * 攝像機FOV
     */
  public fov = 60;

  /**
     * 当前场景在xz平面，从z正方向绕着y轴逆时针旋转的角度
     */
  private _theta = 0;

  /**
     * 当前场景在垂直xz平面，从xz平面向正y方向旋转的角度
     */
  private _phi = 0;



  private _maxPhi = 0.5 * Math.PI - (60 / 2.0 + 5.0) / 180.0 * Math.PI;
  private _minPhi = -0.5 * Math.PI + (60 / 2.0 + 5.0) / 180.0 * Math.PI;

  private _maxFov = 99;
  private _minFov = 30;
  /**
     * theta角度的变化值
     */
  private _deltaTheta = 0;
  /**
     * phi角度的变化值
     */
  private _deltaPhi = 0;

  /**
     * 负z的方向
     */
  private _forwardDir = vec4.fromValues(0, 0, -1, 0);

  /**
     * 当前相机的forward
     */
  private _forward!: vec3;

  /** 当前渲染画布canvas的高 */
  private _screenHeight!: number;

  /** 当前渲染画布canvas的宽 */
  private _screenWidth!: number;

  /** 场景的中心点 */
  private _target: vec3 = vec3.fromValues(0, 0, 0);

  /**
     * RotateScene组件是否激活，如果激活，将影响自动漫游组件的相机朝向
     */
  private _activate = false;
  /**
     * 控制旋转的速度
     */
  private _rotateSpeed = 1.0;

  /**
     * 对外暴露的事件,
     * fov: fov改变
     * theta_phi: 相机旋转事件，有四个参数，前两个是theta phi，后两个是 _deltaTheta
     */
  public event = new EventEmitter<"fov" | "theta_phi", number[]>();

  public get rotateSpeed() {
    return this._rotateSpeed;
  }
  public set rotateSpeed(value) {
    this._rotateSpeed = value;
  }

  public get target(): vec3 {
    return this._target;
  }

  public set target(value: vec3) {
    this._target = value;
  }

  public get dampingFactor() {
    return this._dampingFactor;
  }

  public set dampingFactor(value) {
    this._dampingFactor = value;
  }

  public get activate(): boolean {
    return this._activate;
  }
  public set activate(value: boolean) {
    this._activate = value;
  }

  public get forward(): vec3 {
    return this._forward;
  }
  public set forward(value: vec3) {
    this._forward = value;
  }

  /**
     * 上一帧的输入信息
     */
  private _previousInputContext = new InputContext(undefined, undefined, vec2.fromValues(-1, -1),
    [], undefined, 0, [], false);
  private _fov!: number;
  private _cameraComponent: Camera | undefined;

  private setPhiRange(fov: number, limitAngle = 5.0) {
    this._maxPhi = 0.5 * Math.PI - (fov / 2.0 + limitAngle) / 180.0 * Math.PI;
    this._minPhi = -0.5 * Math.PI + (fov / 2.0 + limitAngle) / 180.0 * Math.PI;
  }
  onStart(): void {
    this._cameraComponent = this.entity.getComponentByType(Camera);
    this._fov = this._cameraComponent!.fov;
    this._screenWidth = SceneManager.GetInstance().context.viewer.getWidth();
    this._screenHeight = SceneManager.GetInstance().context.viewer.getHeight();
    const worldMatrix = this.entity.transform.localToWorldMatrix();
    const forward = vec4.transformMat4(vec4.create(), this._forwardDir, worldMatrix);
    this._forward = vec3.fromValues(forward[0], forward[1], forward[2]);
    this.setPhiRange(this.fov);
  }
  getCameraParams(){
    return [this._theta, this._phi , this._fov]
  }

  setCameraThetaPhi(theta: number, phi: number){
    if(!theta || !phi){
      ConsoleLog.error("PanoramaOpt","setCameraThetaPhi",ErrorType.UseError,"theta phi 未传入")
      return
    }
    this._theta = theta;
    this._phi = phi;
    this._deltaTheta = 0;
    this._deltaPhi = 0;

    this.updateCameraMatrix();
  }
  setCameraFov(fov: number){
    if(!fov){
      ConsoleLog.error("PanoramaOpt","setCameraFov",ErrorType.UseError,"fov 未传入")
      return
    }
    if (!this._cameraComponent) {
      ConsoleLog.error("PanoramaOpt","setCameraFov",ErrorType.UseError,"_cameraComponent 不存在")
      return
    }
    this._cameraComponent.fov = fov;
    this._fov = fov;
  }
  setCameraParams(theta: number, phi: number, thetaDelta: number, phiDelta: number){
    this.setCameraThetaPhi(theta, phi);
    this._deltaTheta = thetaDelta;
    this._deltaPhi = phiDelta;
  }
  /**
     * 更新相机旋转矩阵
     */
  updateCameraMatrix(): void {
    const posZ = Math.cos(this._theta) * Math.cos(this._phi);
    const posX = Math.sin(this._theta) * Math.cos(this._phi);
    const posY = Math.sin(this._phi);
    this.target = vec3.fromValues(posX, posY, posZ);
    this.target = vec3.add(vec3.create(), this.target, this.entity.transform.position);
    const newCameraMatrix = mat4.targetTo(mat4.create(), this.entity.transform.position, this.target, vec3.fromValues(0, 1, 0));
    const forwardHomo = vec4.transformMat4(vec4.create(), this._forwardDir, newCameraMatrix);
    this._forward = vec3.normalize(vec3.create(), vec3.fromValues(forwardHomo[0], forwardHomo[1], forwardHomo[2]));

    this.entity.transform.setLocalToWorldMatrix(newCameraMatrix);
  }

  /**
     * 获取当前相机姿态对应的theta和phi值
     */
  getThetaPhi(): [number, number] {
    const worldMatrix = this.entity.transform.localToWorldMatrix();
    const forward = vec4.transformMat4(vec4.create(), this._forwardDir, worldMatrix);

    this._forward = vec3.fromValues(forward[0], forward[1], forward[2]);
    this._theta = Math.atan2(forward[0], forward[2]);
    this._phi = Math.atan2(forward[1], Math.sqrt(forward[0] * forward[0] + forward[2] * forward[2]));
    return [this._theta, this._phi];
  }

  update(deltaTime: number, inputContext: InputContext): void {
    if (this.entity && this.isActive) {
      if (inputContext.isResize) {
        this._screenWidth = SceneManager.GetInstance().context.viewer.getWidth();
        this._screenHeight = SceneManager.GetInstance().context.viewer.getHeight();
      }
      this.getThetaPhi();
      let isZommActive = false;
      let ratio = 1.0;
      let isUserTouch = false;
      if (this.enableZoom) {
        if(inputContext.touchedNum === 2 && this._previousInputContext.touchedNum === 2){
          const touchDistance = vec2.dist(inputContext.touchedPosition[0], inputContext.touchedPosition[1]);
          const previousTouchDistance = vec2.dist(this._previousInputContext.touchedPosition[0], this._previousInputContext.touchedPosition[1]);
          ratio = previousTouchDistance / touchDistance;
          if (touchDistance > previousTouchDistance) {
            inputContext.zoom = -0.5;
          } else if (touchDistance < previousTouchDistance) {
            inputContext.zoom = 0.5;
          } else {
            inputContext.zoom = 0;
          }
          isUserTouch = true;
        }
        if (ratio === 1.0 && inputContext.zoom !== 0.0) {
          if (inputContext.zoom > 0) {
            ratio = 1.1;
          } else {
            ratio = 0.9;
          }
          isUserTouch = true;
        }


        if (this._cameraComponent && ratio !== 1.0) {
          this._fov = this._cameraComponent.fov;
          this._fov = this._fov * ratio;

          if (inputContext.zoom > 0) {
            this._cameraComponent.fov = Math.min(this._maxFov, this._fov);
          }
          if (inputContext.zoom < 0) {
            this._cameraComponent.fov = Math.max(this._minFov, this._fov);
          }
          this.setPhiRange(this._cameraComponent.fov);
          this._phi = Math.min(this._phi, this._maxPhi);
          this._phi = Math.max(this._phi, this._minPhi);
          isZommActive = true;
          // 只有在用户交互的时候才触发
          if(isUserTouch){
            this.event.emit("fov", this._cameraComponent.fov)
          }
        }
      }


      // rotate
      if (this.enableRotation) {
        if ((inputContext.leftDown === true && this._previousInputContext.leftDown === true) ||
                    (inputContext.rightDown === true && this._previousInputContext.rightDown === true)) {
          const deltaPosition = vec2.subtract(vec2.create(), inputContext.position, this._previousInputContext.position);
          this._deltaTheta = 1.0 / this._dampingFactor * deltaPosition[0] / this._screenWidth * (this._fov / 180.0 * this._screenWidth / this._screenHeight) * Math.PI;
          this._deltaPhi = 1.0 / this._dampingFactor * deltaPosition[1] / this._screenHeight * (this._fov / 180.0) * Math.PI;
          // 这里穿四个是因为要实现相机同步，在另一端也要获取_deltaTheta
          this.event.emit("theta_phi", [this._theta, this._phi, this._deltaTheta, this._deltaPhi])
        } else if (this.enableAutoRotate) {
          const deltaPosition =[this.autoRotateRate, 0];
          this._deltaTheta = 1.0 / this._dampingFactor * deltaPosition[0] / this._screenWidth * (this._fov / 180.0 * this._screenWidth / this._screenHeight) * Math.PI;
          this._deltaPhi = 1.0 / this._dampingFactor * deltaPosition[1] / this._screenHeight * (this._fov / 180.0) * Math.PI;
        }
      }

      const isRotateDampingOngoing = Math.abs(this._deltaTheta) > 0.001 || Math.abs(this._deltaPhi) > 0.001
      if (isRotateDampingOngoing) {
        this._deltaTheta *= this._dampingFactor;
        this._deltaPhi *= this._dampingFactor;
        this._theta += this._deltaTheta;
        this._phi += this._deltaPhi;

        if (this._theta > Math.PI * 2) {
          this._theta -= Math.PI * 2;
        } else if (this._theta < -Math.PI * 2) {
          this._theta += Math.PI * 2;
        }
        this._phi = Math.min(this._phi, this._maxPhi);
        this._phi = Math.max(this._phi, this._minPhi);
      }
      if (isRotateDampingOngoing || isZommActive) {
        this._activate = true;
        this.updateCameraMatrix();
        // 这里不触发事件了，观察同屏带看是否存在问题
        // this.event.emit("theta_phi", [ this._theta, this._phi, this._deltaTheta, this._deltaPhi]);
      } else {
        this._activate = false;
      }
    }
    this._previousInputContext = inputContext;
  }

  public dispose(): void {
  }
}
