import { mat4, quat, vec3 } from 'gl-matrix';
import { Transform } from '@ali/tidejs';
import { BasicState, expJson, InjectState } from '../StatesFrame';
import { RobotInfo } from './RobotCtr';
import { ConsoleLog } from "@sdkCore/lib/log";
import { fadeInOut } from "../../lib/utils/tools";

@InjectState('RobotRotate')
export class RobotRotate extends BasicState<RobotInfo> {
  @expJson('info')
  public info = 'rotate';

  @expJson('onArrivedState')
  public onArrivedState: BasicState<RobotInfo> | undefined;

  @expJson('time')
  public time = 1;
  public _realTime = 1;

  private _endQuat = quat.create();
  private _startQuat = quat.create();
  private _tempM4 = mat4.create();
  private _tempQuat = quat.create();
  private _up = vec3.fromValues(0,1,0);
  private _trans: Transform | undefined;
  private _progress = 0;

  update (deltaTime: number) {
    this._progress += (deltaTime /this._realTime);

    let progress= fadeInOut(this._progress);
    if (progress >= 1.0) {
      progress = 1.0;
    }

    if (this._trans) {
      quat.slerp(this._tempQuat ,this._startQuat,this._endQuat,progress);
      this._trans.rotation = quat.clone(this._tempQuat);
    }

    if (progress === 1.0){
      if (this.onArrivedState) {
        this.onArrivedState.changeTo();
      }
    }
  }

  onEnter () {
    ConsoleLog.info('RobotRotate', 'onEnter', this.info);
    if (!this._trans) {
      this._trans = this.srcInfo.entity.transform;
    }
    this._startQuat = quat.clone(this._trans.rotation);
    mat4.lookAt(this._tempM4,this.srcInfo.cameraTransfrom.position,this._trans.position,this._up);
    mat4.transpose(this._tempM4,this._tempM4);
    mat4.getRotation(this._endQuat,this._tempM4);

    this._realTime = this.time + (Math.random() * 2 - 1) * 0.2;
    this._progress = 0;

    this.srcInfo.enableDialog = false;
  }

  onExit () {
    ConsoleLog.info('RobotRotate', 'onExit', this.info);
  }
}
