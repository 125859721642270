import { BasicState, expJson, InjectState } from '../../StatesFrame';
import { VGInfo } from '../VGInfo';
import { VGWait } from './VGIWait';

@InjectState('VGWaitForSound')
export class VGWaitForSound extends  VGWait{ 
  @expJson('onSoundEnd')
  public onSoundEnd: BasicState<VGInfo> | undefined;

  update (deltaTime: number) {
    this.srcInfo.faceToCamera();
    if (this.srcInfo.soundMgr2.isFinished()) {
      if (this.onSoundEnd) this.onSoundEnd.changeTo();
    }       
  }

  onExit() {
    this.srcInfo.stopSound();
    super.onExit();
  }
}
