export const VGModelConfig = {
  modelConfig: {
    modelURL:  'https://virtualbuy-cdn.alibaba.com/glbSource/VirtualShoppers_normal/VirtualShoppers_normal.glb',
    scale: 0.94,
    commponentName: 'VirtualGuiderCtr',
  },
  actionConfig: {
    distance: 0.4
  }
}

export const VGModelConfigSkirt = {
  modelConfig: {
    modelURL: 'https://virtualbuy-cdn.alibaba.com/glbSource/VirtualShoppers_lyly/VirtualShoppers_lyly.glb',
    scale: 0.94,
    commponentName: 'VirtualGuiderCtr',
  },
  actionConfig: {
    distance: 0.4
  }
}

export const VGModelConfigSuit = {
  modelConfig: {
    modelURL: 'https://virtualbuy-cdn.alibaba.com/glbSource/VirtualShoppers_ManSuit/VirtualShoppers_manSuit.glb',
    scale: 0.94,
    commponentName: 'VirtualGuiderCtr',
  },
  actionConfig: {
    distance: 0.4
  }
}
export const VGModelConfigJiashan = {
  modelConfig: {
    modelURL: 'https://virtualbuy-cdn.alibaba.com/glbSource/VirtualShoppers_smurfs/smurfs.glb',
    scale: 0.94,
    commponentName: 'VirtualGuiderCtr',
  },
  actionConfig: {
    distance: 0.4
  }
}

export const VGModelConfigAnt = {
  modelConfig: {
    modelURL: 'https://virtualbuy-cdn.alibaba.com/glbSource/VirtualShoppers_ant/ant.glb',
    scale: 0.94,
    commponentName: 'VirtualGuiderCtr',
  },
  actionConfig: {
    distance: 0.4
  }
}

export const vgbJson = {
  version: '0.0.1',
  root: '03',
  autoPlay: false,
  list: [
    {
      className: 'VGMove',
      id: '01',
      parameters: {
        info: 'move',
      },
      events: [
        {
          name: 'onFinished',
          delay: 0,
          target: '05',
        },
        {
          name: 'onArrivedSpot',
          delay: 0,
          target: '03',
        }
      ]
    },
    {
      className: 'VGWaitForSound',
      id: '02',
      parameters: {
        info: 'idle-VGWaitForSound',
        stay: -1,
        animationName: 'introduce',
        faceToCamera: true,
      },
      events: [
        {
          name: 'onSoundEnd',
          delay: 0,
          target: '06',
        }
      ]
    },
    {
      className: 'VGSoundCheck',
      id: '03',
      parameters: {
        info: 'sound check,是否没有播完声音',
      },
      events: [
        {
          name: 'onPassState',
          delay: 0,
          target: '02',
        },
        {
          name: 'onFailedState',
          delay: 0,
          target: '06',
        }
      ]
    },
    {
      className: 'VGWait',
      id: '05',
      parameters: {
        info: 'VGWait-byebye',
        stay: 2.0,
        animationName: 'byebye',
        faceToCamera: true,
      },
      events: [
        {
          name: 'onOverTimeState',
          delay: 0,
          target: '04',
        }
      ]
    },
    {
      className: 'VGHidden',
      id: '04',
      parameters: {
        info: 'hidden-pathEnd',
        stay: -1,
      },
      events: [
        {
          name: 'onOverTimeState',
          delay: 0,
          target: '01',
        }
      ]
    },
    {
      className: 'VGMoveToMark',
      id: '06',
      parameters: {
        stay: -1,
        info: 'VGMoveToMark',
      },
      events: [
        {
          name: 'onArrivedMark',
          delay: 0,
          target: '07',
        },
        {
          name: 'noMark',
          delay: 0,
          target: '08',
        }
      ]
    },
    {
      className: 'VGStop',
      id: '07',
      parameters: {
        stay: 3,
        info: 'VGStop',
      },
      events: [
        {
          name: 'onStopOver',
          delay: 0,
          target: '06',
        }
      ]
    },
    {
      className: 'VGMoveToCurSpot',
      id: '08',
      parameters: {
        stay: -1,
        info: 'moveToCurSpot',
      },
      events: [
        {
          name: 'onArrivedSpot',
          delay: 0,
          target: '01',
        }
      ]
    }
  ]
}

// 第二种状态机模式
export const vgbJson2 = {
  version: '0.0.1',
  root: '03',
  autoPlay: false,
  list: [
    {
      className: 'VGMove',
      id: '01',
      parameters: {
        info: 'move',
      },
      events: [
        {
          name: 'onFinished',
          delay: 0,
          target: '05',
        },
        {
          name: 'onArrivedSpot',
          delay: 0,
          target: '03',
        }
      ]
    },
    {
      className: 'VGWaitForSound',
      id: '02',
      parameters: {
        info: 'idle-VGWaitForSound',
        stay: -1,
        animationName: 'introduce',
        faceToCamera: true,
      },
      events: [
        {
          name: 'onSoundEnd',
          delay: 0,
          target: '09',
        }
      ]
    },
    {
      className: 'VGSoundCheck',
      id: '03',
      parameters: {
        info: 'sound check,是否没有播完声音',
      },
      events: [
        {
          name: 'onPassState',
          delay: 0,
          target: '02',
        },
        {
          name: 'onFailedState',
          delay: 0,
          target: '09',
        }
      ]
    },
    {
      className: 'VGWait',
      id: '05',
      parameters: {
        info: 'VGWait-byebye',
        stay: 2.0,
        animationName: 'byebye',
        faceToCamera: true,
      },
      events: [
        {
          name: 'onOverTimeState',
          delay: 0,
          target: '04',
        }
      ]
    },
    {
      className: 'VGHidden',
      id: '04',
      parameters: {
        info: 'hidden-pathEnd',
        stay: -1,
      },
      events: [
        {
          name: 'onOverTimeState',
          delay: 0,
          target: '01',
        }
      ]
    },
    {
      className: 'VGMoveByMark',
      id: '06',
      parameters: {
        stay: -1,
        info: 'VGMoveByMark',
      },
      events: [
        {
          name: 'onArrivedMark',
          delay: 0,
          target: '07',
        }
      ]
    },
    {
      className: 'VGStop',
      id: '07',
      parameters: {
        stay: 3,
        info: 'VGStop',
      },
      events: [
        {
          name: 'onStopOver',
          delay: 0,
          target: '09',
        }
      ]
    },
    {
      className: 'VGMoveToNextSpot',
      id: '08',
      parameters: {
        info: 'moveToNextSpot',
      },
      events: [
        {
          name: 'onFinished',
          delay: 0,
          target: '05',
        },
        {
          name: 'onArrivedSpot',
          delay: 0,
          target: '03',
        }
      ]
    },
    {
      className: 'VGMarkCheck',
      id: '09',
      parameters: {
        info: 'mark check,是否附近有需要停留的标识点',
      },
      events: [
        {
          name: 'onPassState',
          delay: 0,
          target: '06',
        },
        {
          name: 'onFailedState',
          delay: 0,
          target: '08',
        }
      ]
    },
  ]
}



//  sceneId: "f85397d0-37db-4676-bc95-cff03afb1a8b"
export const roamingTestJson = {
  "version": 1,
  "speed": 1,
  "paths": [{
    "id": "default",
    "groups": [{
      "groupURL": "https://virtualbuy-prod.oss-cn-hangzhou.aliyuncs.com/datastore/f85397d0-37db-4676-bc95-cff03afb1a8b/audio/0dd20595-4f20-43f9-8a5c-a7e33dba902e.mp3",
      "groupName": "ds",
      "area": ["p0"]
    }],
    "spots": [{
      "name": "p0",
      // "stop": [{
      //     "markId": "line-c2441520-1c3c-11ec-bb47-3914b53ea5f6"
      // }]
    }]

  }]
}

//  sceneId: "9c946217-b62f-475d-bf05-4e6a946e80c6"
// export const roamingTestJson = {
//     "version": 1,
//     "speed": 1,
//     "paths": [{
//         "id": "default",
//         "groups": [{
//             "groupURL": "https://virtualbuy-prod.oss-cn-hangzhou.aliyuncs.com/datastore/9c946217-b62f-475d-bf05-4e6a946e80c6/audio/2c536721-7a0b-4d6d-9711-ee71e28fef31.mp3",
//             "groupName": "scene1",
//             "area": ["p0", "p1", "p2", "p3", "p4", "p5", "p6"]
//         }, {
//             "groupURL": "https://virtualbuy-prod.oss-cn-hangzhou.aliyuncs.com/datastore/9c946217-b62f-475d-bf05-4e6a946e80c6/audio/b0ae5ff8-69f2-4121-b49b-de142ebbd136.mp3",
//             "groupName": "scene2",
//             "area": ["p7", "p8", "p9", "p10", "p11"]
//         }],
//         "spots": [{
//             "name": "p0"
//         }, {
//             "name": "p1"
//         }, {
//             "name": "p2"
//         }, {
//             "name": "p3"
//         }, {
//             "name": "p4"
//         }, {
//             "name": "p5"
//         }, {
//             "name": "p6"
//         }, {
//             "name": "p7"
//         }, {
//             "name": "p8"
//         }, {
//             "name": "p9",
//             "stop": [{
//                 "markId": "line-72c91eb0-f8be-11eb-89e6-75e95ff398b4"
//             },{
//                 "markId": "line-0794f790-f8be-11eb-89e6-75e95ff398b4"
//             }]

//         }, {
//             "name": "p10",
//             "stop": [{
//                 "markId": "line-98874510-fe76-11eb-b131-0b938d1dc027"
//             }]
//         }, {
//             "name": "p11"
//         }, {
//             "name": "p12"
//         }, {
//             "name": "p13"
//         }, {
//             "name": "p14"
//         }]
//     }]
// }

