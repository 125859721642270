import { BasicState, expJson, InjectState } from '../../StatesFrame';
import { VGInfo } from '../VGInfo';
import { VGMove } from './VGMove';
import { vec3 } from 'gl-matrix';
import { ConsoleLog } from '../../../lib/log';

@InjectState('VGMoveToCurSpot')
export class VGMoveToCurSpot extends VGMove {
  @expJson('info')
  public info = 'moveToCurSpot';

  @expJson('onArrivedSpot')
  public onArrivedSpot: BasicState<VGInfo> | undefined;


  update(deltaTime: number) {
    if (!this._p1 || !this._p2) {
      return;
    }

    if (!this.srcInfo.soundMgr2.isFinished()) {
      this.srcInfo.playAnimation('walk_speak')
    } else {
      this.srcInfo.playAnimation('walk')
    }

    this._currentTime += deltaTime;
    this._ratio = this._currentTime / this._time;
    if (this._currentTime >= this._time) {
      this._ratio = 1;
    }
    vec3.lerp(this._temp, this._p1, this._p2, this._ratio);
    this.srcInfo.entityTransform.position = this._temp;

    if (this._ratio === 1) {
      if (this.onArrivedSpot) {
        this.reset();
        this.onArrivedSpot.changeTo();
      }
    }

  }

  onEnter() {
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();
    this._time = 0;
    const curArrivedSpot = this.srcInfo.getCurArrivedSpot();
    if (!curArrivedSpot) {
      return
    }
    const curArrivedSpotPos = this.srcInfo.getSpotPos(curArrivedSpot) as vec3;
    this._p1 = this.srcInfo.entityTransform.position;
    if (curArrivedSpotPos[0] === this._p1[0]) {
      // 如果当前到达的spot点的x坐标 和 行走人当前的x坐标一致，那说明行走人已经处于spot点上
      if (this.onArrivedSpot) {
        this.onArrivedSpot.changeTo();
        return
      }
    } else {
      // 否则走回到当前spot点上
      this._p2 = curArrivedSpotPos;
    }

    if (!this._p1 || !this._p2) {
      return
    }

    //计算起点终点，方向，时间长度，并朝向前进方向
    const distance = vec3.distance(this._p1, this._p2);
    this._time = distance / this.srcInfo.speed;

    vec3.subtract(this._dir, this._p2, this._p1);
    this._dir[1] = 0;
    vec3.normalize(this._dir, this._dir);
    this.faceToDir();
    ConsoleLog.log('state', this.info, 'enter');
  }


}
