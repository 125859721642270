import { Application } from "../../../Application";
import { vec3 } from "gl-matrix";
import { BasicCS } from "./BasicCS";
import { TransitionPrivate } from "../../../api/transition/lib/private";
import { SpotPrivate } from "../../../api/spot/lib/private";
import { getThetaPhiByDir } from "../../../lib/utils/eca";
import { ConsoleLog, ErrorType } from "../../../lib/log";
import { ICSStates } from './CSInterface'

export class CSJump2Mark extends BasicCS {
  private _finishFocusMark = false;
  public stateName = ICSStates.CSJump2Mark

  exit(): void {
    this.enableCameraRot(false);
    this.enablePickAndJump(false);
    this._finishFocusMark = false;
    (this.srcinfo.entity as any)._model.activate()
  }
  enter(): void {
    (this.srcinfo.entity as any)._model.deactivate()
    this.enableCameraRot(false);
    this.enablePickAndJump(false);
    this.jumpToArrivedSpotAndLookAtMark();
  }
  update(): void {
    if (!this.isJump) {
      this.finish();
    }
    if (this._finishFocusMark) {
      this._finishFocusMark = false;
      const curMark = this.srcinfo.getCurStopMark();
      // 当摄像机聚集在mark时，才对外触发到达mark点事件
      this.srcinfo.arrivedMark(curMark)
    }
  }



  /**
     * 跳转到当前到达点spot点
     * @returns
     */
  async jumpToArrivedSpot() {
    if (this.isJump) return;
    const curArrivedSpot = this.srcinfo.getCurArrivedSpot();
    if (curArrivedSpot) {
      this.isJump = true;
      const transAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
      await transAPI.transitionTo(curArrivedSpot);
      this.isJump = false;
    }
  }


  /**
     * 跳转到当前到达点spot点,并看向mark点
     * @returns
     */
  async jumpToArrivedSpotAndLookAtMark() {
    if (this.isJump) return;
    const mark = this.srcinfo.getCurStopMark() as any;
    const markPos = this.srcinfo.getAnnotationPos(mark?.markId) as vec3;
    if (!markPos) {
      ConsoleLog.error('CSJump2Mark', 'jumpToArrivedSpotAndLookAtMark', ErrorType.DataError, 'markPos不存在')
      return false;
    }
    const curArrivedSpot = this.srcinfo.getCurArrivedSpot();
    if (curArrivedSpot) {
      this.isJump = true;
      this._finishFocusMark = false;
      const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
      const toSpot = spotAPI.getHotSpotById(curArrivedSpot);
      if (!toSpot) {
        ConsoleLog.error('CSJump2Mark', 'jumpToArrivedSpotAndLookAtMark', ErrorType.DataError, 'toSpot不存在')
        return false
      }
      const dir = vec3.create();
      const toSpotPos = vec3.clone(toSpot.opticalCenter);
      vec3.sub(dir, markPos, toSpotPos);
      vec3.normalize(dir, dir);
      const [ theta, phi ] = getThetaPhiByDir(dir);
      const transAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
      await transAPI.transitionTo(curArrivedSpot, {
        cameraAnimation: {
          theta,
          phi,
        }
      }, true, true);
      this.isJump = false;
      this._finishFocusMark = true;
    }
  }
}


