import { RenderQueue, IShader, UniformType} from "@ali/tidejs";

const PanoVideoShader: IShader = {
  name: "PanoVideoShader",
  renderQueue: RenderQueue.Transparent,

  uniform: {
    u_diffuse: { type: UniformType.Texture2D, value: undefined},
    // u_worldToCameraMatrix: {type: UniformType.mat3, value: undefined},
    u_center: {type: UniformType.vec3, value: undefined}
  },

  vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        //attribute vec3 color;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;

        varying vec4 v_worldPos;
        varying vec2 v_texCoord;

        void main() {
            v_worldPos = u_model * vec4(position, 1.0);
            v_texCoord = texcoord;
            gl_Position = u_projection * u_view * u_model * vec4(position, 1.0);
        }
    `,

  fragmentShader: `
        precision highp float;

        uniform sampler2D u_diffuse;
        // uniform mat3 u_worldToCameraMatrix;
        uniform vec3 u_center;

        varying vec4 v_worldPos;
        varying vec2 v_texCoord;

        const float PI = 3.1415926;

        void main() {
            vec3 u_textureDir = normalize(v_worldPos.xyz - u_center);
            u_textureDir.z =  -u_textureDir.z;
         
            float theta = acos(u_textureDir.y);
            float phi = atan(u_textureDir.x, u_textureDir.z);
            float u = (phi + PI)/ (2.0 * PI);
            float v = theta / PI;
            vec2 uv = vec2(u, v);
            vec4 color = texture2D(u_diffuse, uv);
            gl_FragColor = vec4(color.rgb, 1.0);
        }
    `,
};

export default PanoVideoShader;