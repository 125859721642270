import { mat4 } from "gl-matrix";
import { InjectState } from "../StatesFrame";
import { RobotInfo } from "./RobotCtr";
import { RobotWalk } from "./RobotWalk";

@InjectState('RobotMoveToCommodity')
export class RobotMoveToCommodity extends RobotWalk {
  calculatEndInfo() {
    this._endPos = this.srcInfo.getPosInDescInfo();
    mat4.lookAt(RobotInfo.TempMat41,this._endPos,this.srcInfo.entityTransform.position,this.srcInfo.upDir);
    mat4.getRotation(this._endQuat,RobotInfo.TempMat41);
  }
}