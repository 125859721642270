import { BaseBehavior, Camera, Component, InputContext, IUpdatable} from "@ali/tidejs"
import { StateEvent } from "../../GameLogic/StateEvent";
import { CSMgr } from "./CameraStates/CSMgr";
import { VGMgr } from "./VGMgr";
import { VGInfo } from "./VGInfo";
import { AudioPlayerMgr } from "../../GameLogic/AudioPlayMgr";
import { vec3 } from "gl-matrix";
import { ConsoleLog } from "../../lib/log";

/**
 * 机器人状态机管理器组件
 *
 * 维持一个状态机管理器，一个共享数据实例
 */
@Component("VirtualGuiderCtr")
export class VirtualGuiderCtr extends BaseBehavior implements IUpdatable {
  private _stateMgr!: VGMgr | undefined;
  public _info!: VGInfo;
  public behaviorJson: any;
  public descJson: any;

  async init() {
    this._stateMgr = new VGMgr();
    const aa = this.entity.addComponent<AudioPlayerMgr>(AudioPlayerMgr);
    //构建一个共享数据,这个组件是添加在root对象上；
    this._info = new VGInfo(this.entity, aa);
    this._info.configJson = this.descJson;

    await this._info.Init();
    Camera.MainCamera!.entity.addComponent<CSMgr>(CSMgr);
    //利用json 与 共享数据实例 来构建状态机
    if (this.behaviorJson) {
      this._stateMgr.fromJson(this.behaviorJson, this._info);
    }else {
      ConsoleLog.warn('VirtualGuiderCtr', 'init', 'VirtualGuiderCtr','have not behaviour config json');
    }
    this._info.entity.deactivate();
  }

  update(deltaTime: number, inputContext: InputContext): void {
    if (this._stateMgr) {
      this._stateMgr.update(deltaTime);
    }
  }

  pause() {
    this._info.pauseSound();
    this._stateMgr?.pause();
    StateEvent.onStateChange.emit('pause',null,null);
  }
  resume() {
    this._info.resumeSound();
    this._stateMgr?.resume();
    StateEvent.onStateChange.emit('resume',null,null);
  }

  switchPath(pathName: string){
    if (this._info.switchToPath(pathName)){
      this._stateMgr?.changeToRoot();
    }
  }

  switchNextPath() {
    this._info.switchToNextPath();
    this._stateMgr?.changeToRoot();
  }

  switchGroup(groupName: string) {
    if (this._info.switchToGroup(groupName)) {
      this._stateMgr?.changeToRoot();
    }
  }

  switchGroupByIndex(groupIndex: number) {
    if (this._info.switchToGroupByIndex(groupIndex)) {
      this._stateMgr?.changeToRoot();
    }
  }

  switchNextGroup() {
    this._info.switchToNextGroup();
    this._stateMgr?.changeToRoot();
  }

  placeToSpot(spotID: string) {
    const spotPos = this._info.getSpotPos(spotID);
    if (!spotPos) {
      throw new Error(`找不到spot点, spotID:${spotID}`)
    }
    this._info.entityTransform.position = spotPos;
    if (!this._info.entity.isActive) {
      this._info.entity.activate();
    }
    this._info.playAnimation('stand');
  }

  faceToSpot(spotID: string) {
    const spotPos = this._info.getSpotPos(spotID);
    if (!spotPos) {
      return
    }
    const persionPos = this._info.entityTransform.position;
    const dir = vec3.create();
    vec3.sub(dir, spotPos, persionPos)
    vec3.normalize(dir, dir);
    dir[1] = 0;
    this._info.entityTransform.setForward(dir, [0,1,0])
  }

  async switchAndPlayByJson(json: any) {
    this._info.stopSound(); // 停止声音
    this._stateMgr?.reset(); // 重置状态
    this._info.reset();
    this._info.configJson = json;
    await this._info.Init();
    this._stateMgr?.changeToRoot();
  }

  updateAniCom() {
    this._info.setAniCom();
  }

  dispose() {
    this._stateMgr = undefined;
    this._info = undefined as any;
  }
}
