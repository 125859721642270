
import { TimeMgr } from "@ali/tidejs";
import { mat4, quat, vec3 } from "gl-matrix";
import { BasicCS } from "./BasicCS";
import { ICSStates } from './CSInterface'
import { fadeInOut } from "../../../lib/utils/tools";

export class CSRotate2Mark extends BasicCS {
  public stateName = ICSStates.CSRotate2Mark
  private startQuat:quat = quat.create();
  private endQuat:quat = quat.create();
  private temp:quat = quat.create();
  private ratio = 0;
  exit(): void {
    this.enableCameraRot(false);
    this.enablePickAndJump(false);
    this.ratio = 0;
  }
  enter(): void {
    this.enableCameraRot(false);
    this.enablePickAndJump(false);

    this.setUpInitInfo();
    this.ratio = 0;

  }
  update(): void {
    this.ratio += TimeMgr.GetInstance().deltaTime;
    const newRatio = fadeInOut(this.ratio);
    if (this.ratio >= 1) {
      this.entity.transform.rotation = this.endQuat;
    }else {
      quat.lerp(this.temp, this.startQuat, this.endQuat, newRatio);
      this.entity.transform.rotation = this.temp;
    }
    if (!this.isJump) {
      this.finish();
    }
  }

  protected setUpInitInfo() {
    const p0 = this.srcinfo.entity.transform.position;
    const mark = this.srcinfo.getCurStopMark() as any;
    const p1 = this.srcinfo.getAnnotationPos(mark?.markId) as vec3;
    const p =  this.entity.transform.position;
    p0[1] = p[1];

    mat4.getRotation(this.startQuat, mat4.targetTo(mat4.create(), p, p0, [0,1,0]));
    mat4.getRotation(this.endQuat, mat4.targetTo(mat4.create(), p, p1, [0,1,0]));
  }

}


