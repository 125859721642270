import { InputContext } from "@ali/tidejs";
import { SpotPrivate } from "../../../api/spot/lib/private";

export enum CameraStateType {
  normal,
  panorama,
  top,
  nullS,
}

export interface ICameraState {
  enter(): void;
  exit(): void;
  getState(): CameraStateType;
  setSrc(parameter: any): void;
  setDest(parameter: any): void;
  update(progress: number): void;
  click(inputCtx: InputContext, params?: any): void;
  move(inputCtx: InputContext): void;
  //默认姿态，比如，全景图的默认姿态，就是默认spot点的数据
  setDefault(data: any): void;
}

export class BaseState implements ICameraState {
  spotMgr: SpotPrivate;
  targetType!: CameraStateType;
  constructor(spoMgr: SpotPrivate) {
    this.spotMgr = spoMgr;
  }
  exit(): void {
    throw new Error("Method not implemented.");
  }
  enter() {
    throw new Error("Method not implemented.");
  }
  protected curveFunc = (t: number) => {
    return (Math.sin(Math.PI * t - Math.PI / 2) + 1) / 2;
  };
  async setDefault(data: any) {
    throw new Error("Method not implemented.");
  }

  click(inputCtx: InputContext): void {
    throw new Error("Method not implemented.");
  }
  move(inputCtx: InputContext): void {
    throw new Error("Method not implemented.");
  }
  getState(): CameraStateType {
    throw new Error("Method not implemented.");
  }
  setSrc(parameter: any): void {
    throw new Error("Method not implemented.");
  }
  async setDest(parameter: any) {
    throw new Error("Method not implemented.");
  }
  update(progress: number): void {
    throw new Error("Method not implemented.");
  }

}


