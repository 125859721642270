import { Application } from "../../Application";
import { LLEFUtilities } from "@ali/tidejs";
import { isIOS } from "../../lib/utils";
import { ConsoleLog, ErrorType } from "../../lib/log";
export const ScreenShot = {

  /**
   *
   * @param sx 当前场景截图的x
   * @param sy 当前场景截图的y
   * @param sWidth 当前场景截图的宽度
   * @param sHeight 当前场景截图的高度
   * @param dWidth 最终截图的宽
   * @param dHeight 最终截图的高
   */
  getScreenImage(sx: number, sy: number, sWidth: number, sHeight: number, dWidth: number, dHeight: number){
    const canvas = Application.instance?.canvas;
    if(!canvas){
      ConsoleLog.error("ScreenShot","getScreenImage",ErrorType.UseError,"程序未初始化")
      return
    }
    return new Promise((resolve, reject)=>{

      const dpr = window.devicePixelRatio;
      const url = canvas.toDataURL();
      if(!url){ return }
      const image = new Image();
      image.crossOrigin = null;
      image.onload = ()=>{
        const tempCanvas1 = document.createElement("canvas");
        const tempCtx1= tempCanvas1.getContext("2d");
        if(!tempCtx1){ return }
        const canvasWidth = Math.ceil(image.width / dpr);
        const canvasHeight = Math.ceil(image.height / dpr);

        tempCanvas1.height = canvasHeight;
        tempCanvas1.width = canvasWidth;

        if(!LLEFUtilities.IsWebGL2() && isIOS()){
          tempCtx1.translate(0, canvasHeight );
          tempCtx1.scale(1, -1);
        }
        tempCtx1.drawImage(image, 0,0,image.width, image.height, 0,0 , canvasWidth, canvasHeight)
        const tempCanvas = document.createElement("canvas");
        if(dWidth && dHeight){
          tempCanvas.width = dWidth;
          tempCanvas.height = dHeight;
        }
        const tempCtx = tempCanvas.getContext("2d");
        if(!tempCtx){ return }

        tempCtx.drawImage(tempCanvas1, sx ,sy , sWidth  , sHeight , 0 ,0, dWidth, dHeight );

        const targetUrl = tempCanvas.toDataURL('image/jpeg');


        resolve(targetUrl)
        return targetUrl;
      }
      image.onerror = reject
      image.src = url;
    })

  }

};
