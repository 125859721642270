import { API, APIBaseClass } from "../../lib/utils/eca";
import { ConsoleLog, ErrorType } from "../../lib/log";
import EventEmitter from "eventemitter3";
import { SDKAudio } from "./audio";


/**
 * 每次只播放一个音频，会自动暂停上个音频。
 * 如果要播放多个音频，建议自己管理，每次dispose的时候注意进行销毁。
 */
@API()
export class AudioPlayManager extends APIBaseClass{
  static ModuleName = "audioPlayAPI"
  public event = new EventEmitter<"play-failed">();
  public playingAudio: undefined | SDKAudio = undefined;

  get isAudioPlaying(){
    return !!this.playingAudio;
  }
  createAudio(url: string, preload = false){
    const audio = new SDKAudio(url);
    if(preload){
      audio.load();
    }
    return audio;
  }

  private successPlay(audio: SDKAudio){
    this.playingAudio = audio;
    ConsoleLog.info("Audio","successPlay","音频播放成功");
  }
  private failedPlay(audio: SDKAudio, error: any){
    this.event.emit("play-failed");
    ConsoleLog.error("Audio","pause",ErrorType.InternalError,"音频播放失败:",audio.src, error);
  }

  playAudio(audio: SDKAudio | undefined){
    if(!audio){ return }
    ConsoleLog.info("Audio","playAudio","音频准备播放", audio.src);
    this.pauseAudio(this.playingAudio)
    audio.play().then(()=>{
      this.successPlay(audio);
    }).catch((error)=>{
      this.failedPlay(audio, error);
    })

  }
  pauseAudio(audio: SDKAudio | undefined){
    if(!audio){ return }
    audio.pause();
    this.playingAudio = undefined;
  }
  dispose(){
    if(this.playingAudio){
      this.playingAudio.pause();
    }
    this.event.removeAllListeners()
  }
}
