import { Component, IPreRenderable } from "@ali/tidejs";
import EventEmitter from "eventemitter3";
import { vec3, } from "gl-matrix";
import { BaseBehaviorVR } from "../Behaviors/lib";
import { getScreenXYFromVec3 } from "../lib/utils/tools";

function setNodeStyle(elementNode: HTMLElement){
  elementNode.style.position = "absolute";
  elementNode.style.justifyContent = "center";
  elementNode.style.alignItems = "center";
  const children = elementNode.children;
  if(!children){ return }
  for(const element of children){
    (element as any).style.userSelect = "none";
  }
}
/** 对鼠标、键盘、触摸板的输入事件的响应 */
@Component("CSS2DElement")
export class CSS2DElement extends BaseBehaviorVR implements IPreRenderable {

  public _position: vec3 | undefined = undefined;
  public container: HTMLElement = document.createElement("div");
  public elementNode: HTMLElement = document.createElement("div");
  // 用户控制，是否显示该标签，不显示就在程序中一直不显示
  public visible = true;
  public event = new EventEmitter<"show"|"hide", string>();

  // display 内部标志位，是否在页面上显示，一直不停切换
  private display = true;
  private halfWidth: number = window.innerWidth / 2;
  private halfHeight: number = window.innerHeight / 2;
  private isAwake = false;

  get isElementDisplay(){
    return this.display;
  }
  get isVisible(){
    // 如果没有传入  element  则默认一直可见
    if(this.element && (this.element.isVisible !== undefined)){
      return this.element.isVisible
    } else {
      return this.visible;
    }
  }
  set position(value: vec3){
    this._position = value;
  }
  get position(){
    if(this._position){
      return this._position;
    }
    return this.entity.transform.position;
  }
  preAwake(): void {
    if(this.isAwake){ return }
    if(!this.container){ return }
    this.Css2DContainer?.appendToCss2dContainer(this.elementNode);
    setNodeStyle(this.elementNode);
    this.elementNode.onclick = this.onClick.bind(this);

    this.isAwake = true;
    this.resize();
    this.addEventListener();
    this.log("CSS2DElement", "preAwake",`元素${this.elementNode.id}是否可见：${this.element?.isVisible}`)
    this.preRender();
  }
  public removeEventListener(){ }
  public addEventListener(){
    const resize = ()=>{ this.resize(); }
    // 这里有些区别
    // 如果要显示->设置setVisible-> 组件监听 activate 事件-> 如果可见-> 触发show事件
    //如果要隐藏->设置setVisible -> 组件监听 deactivate -> 触发hide事件
    const show = ()=>{
      this.log("CSS2DElement", "addEventListener", `监听到元素${this.elementNode.id} activate 事件，触发 preRender`)
      this.preRender();
    }
    const hide = ()=>{
      this.log("CSS2DElement", "addEventListener", `监听到元素${this.elementNode.id} deactivate 事件，调用 hide `)
      this.hide();
    }

    this.MessageCenter.GlobalEvent.on("resize", resize)
    this.element?.event.on("activate", show);
    this.element?.event.on("deactivate",hide);
    this.removeEventListener = ()=>{
      this.MessageCenter.GlobalEvent.removeListener("resize", resize)
      this.element?.event.removeListener("activate",show);
      this.element?.event.removeListener("deactivate",hide);
    }
  }
  public dispose(){
    this.Css2DContainer?.removeChild(this.elementNode);
    this.removeEventListener();
  }
  public hide(){
    this.display = false;
    this.elementNode.style.display = "none";
    this.log("CSS2DElement", "hide", `调用元素${this.elementNode.id} hide方法`)
    this.element?.hide();
  }
  public show(){
    this.display = true;
    this.elementNode.style.display = "flex";
    // 这里调用 show 方法是因为希望触发元素的 show 事件，同时如果元素有自己的样式或者显示逻辑，可以再这个函数里设置
    this.log("CSS2DElement", "show", `调用元素${this.elementNode.id} show方法`)
    this.element?.show();
  }
  public resize(){
    let container = document.body;
    if(this.Css2DContainer && this.Css2DContainer.css2dContainer){
      container = this.Css2DContainer.css2dContainer;
    }
    const rect = container.getBoundingClientRect();
    // for ios 10
    if(!rect || !rect.width  || !rect.height){
      this.halfWidth =  container.clientWidth / 2;
      this.halfHeight = container.clientHeight/ 2;
    } else {
      this.halfWidth =  rect.width / 2;
      this.halfHeight = rect.height/ 2;
    }
  }

  public onClick(event: MouseEvent){
    // TransitionPrivate.getInstance().transitionTo
  }

  public preRender(): void {
    if(!this.isAwake){ return }
    const [x,y,z] = getScreenXYFromVec3(this.position);
    const isNotInView = (x< -1 || y < -1) ||  (x > 1|| y > 1) ||(z >1 || z<-1);
    // 正在显示，但是设置了不可见，则隐藏
    if( this.display && !this.isVisible ){
      this.log("CSS2DElement", "preRender",`${this.elementNode.id} 正在显示，但是设置了不可见，则隐藏`)
      this.hide();
    }
    // 正在显示，但是不在视野中,则隐藏
    else if( this.display && isNotInView ) {
      this.log("CSS2DElement", "preRender",`${this.elementNode.id} 正在显示，但是不在视野中，则隐藏`)
      this.hide();
    }
    // 不在显示，但是设置了可见，并且在视野内， 则显示
    else if( !this.display && !isNotInView && this.isVisible) {
      this.log("CSS2DElement", "preRender",`${this.elementNode.id} 不在显示，但是设置了可见，并且在视野内，`)
      this.show();
    }
    // 不在显示， 设置了可见， 不在视野内， 什么都不做
    // 在显示， 设置了可见， 不在视野内， 什么都不做

    const halfWidth = this.halfWidth;
    const halfHeight = this.halfHeight;
    this.elementNode.style.transform = ` translate(-50%, -50%) translate(${x * halfWidth + halfWidth}px, ${-y * halfHeight + halfHeight }px)`;
  }
}
