import { ConsoleLog } from "../../lib/log";

export class PEvent<T extends Function> {
  private _commands: T[] | undefined;
  constructor () {
    //
  }

  public emit (...args: any[]) {
    let needClean = false;
    if (!this._commands) return;
    for (let i = 0; i < this._commands.length; i++) {
      const command = (this._commands[i] as any);
      if (command.isDestroying) {
        needClean = true;
        continue;
      }
      if (command.once === true) {
        command.isDestroying = true;
        needClean = true;
      }

      this._commands[i](...args); //如果该方法涉及到off,则很有可能会导致问题
    }

    if (needClean) {
      for (let i = 0; i < this._commands.length; i++) {
        const command = (this._commands as any);
        if (command.isDestroying) {
          this._commands.splice(i, 1);
          i--;
        }
      }
    }

    if (this._commands.length <= 0) {
      ConsoleLog.warn('PEvent', 'emit', '_commands.length <= 0,deleted')
    }
  }

  public once (fun: T): T | undefined {
    if (!this._commands) {
      this._commands = new Array<T>();
    }
    if (this._commands.find(f => f === fun)) {
      return undefined;
    }
    (fun as any).once = true;
    this._commands.push(fun);
    return fun;
  }

  public on (fun: T): T | undefined {
    if (!this._commands) {
      this._commands = new Array<T>();
    }

    if (this._commands.find(f => f === fun)) {
      return undefined;
    }
    this._commands.push(fun);
    return fun;
  }

  public off (fun: T): boolean {
    (fun as any).isDestroying = true;
    return true;
  }
}
