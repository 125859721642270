import { guideTestJson, RobotJson, } from "../../../GameLogic/RobotStates";
import { AssetManager, Entity, Loader, SceneManager, Renderer, Texture2D, ComponentManager, PrimitiveType, MeshFilter, StandardMaterial, ShaderKeyword, TextureCube } from "@ali/tidejs";
import { AnimateUVMaterial, AnimatedUV, EmotionMgr } from "../../../GameLogic/RobotStates/Emotion";
import { vec3, vec4 } from "gl-matrix";
import { APIBaseClass } from "../../../lib/utils/eca";
import { StateEvent } from "../../../GameLogic/StateEvent";
import { DataCenter } from "../../../GameLogic/DataCenter";
import { MessageCenter } from "../../../GameLogic/MessageCenter";
import { ConsoleLog, ErrorType } from "../../../lib/log";
import { get } from 'lodash';

class EmotionConfig {
  constructor(public tex: Texture2D, public config: any) { }
}

/**
 * [[include: ./src/api/virtualGuider/lib/doc.md]]
*/
export class FlyGuider extends APIBaseClass {
  static ModuleName = "flyGuiderAPI";
  private _root: Entity | undefined;
  private _collider: Entity | undefined;
  private ctr: any;
  public ctrReady = false;
  private emotion: EmotionMgr | undefined;

  /**
     * 尝试切换到某个表情
     * 可通过 getEmotionList 获取到所有的emotion cname;
     *  @param cname 表情名称，"speak"（说话态） | "idle"（空闲态）
     */
  public switchEmotion(cname: string) {
    if (this.emotion) {
      this.emotion.changeTo(cname);
    }
  }

  /**
     * 获取表情的cname 列表
     * @returns 表情cname 列表
     */
  public getEmotionList() {
    return this.emotion?.getCnameList();
  }

  /**
     * 设置飞行器的可见状态
     * @param isVisible
     */
  public setGuiderVisible(isVisible: boolean) {
    if (isVisible) {
      this._root?.activate();
    } else {
      this._root?.deactivate();
    }
  }

  // 点击飞行器click事件自动触发
  public onClickGuiderEvent(callBack: () => void) {
    StateEvent.onInteract.on('click', callBack)
    return callBack
  }

  public offClickGuiderEvent(callBack: () => void) {
    StateEvent.onInteract.off('click', callBack)
  }


  /**
     * 获取飞行器初始化是否加载完成
     * Gets guider ready state
     * @returns
     */
  public getGuiderReadyState() {
    return this.ctrReady;
  }

  /**
     * 设置飞行器是否可点击
     * @param interactive
     */
  public setGuiderClickable(clickable: boolean) {
    if (this._collider) {
      const meshFilter = this._collider.getComponentByType(MeshFilter)! as MeshFilter;
      const targetIndex = DataCenter.PanoramaMeshFilters.indexOf(meshFilter);
      if (clickable) {
        if (targetIndex > -1) {
          return
        }
        DataCenter.PanoramaMeshFilters.push(meshFilter);
      } else {
        if (targetIndex === -1) {
          return
        }
        DataCenter.PanoramaMeshFilters.splice(targetIndex, 1)
      }
    }
  }



  /**
     * 初始化飞行器交互对话框
     * @param dialog
     */
  public initGuiderDialog(dialog: HTMLElement) {
    const p = get(this._root, 'transform.children[0].children[1].entity') // 飞行器主体，可打印出 this._root 查看具体结构
    const sf = Entity.CreatePrimitive(PrimitiveType.Sphere);
    this._collider = sf;
    if (!sf) { return }
    sf!.name = 'fayeCollider';
    const renderer = sf!.getComponentByType<Renderer>(Renderer);
    // renderer!.isActive = false;
    sf.removeComponent(renderer!); // 直接移除，而不是设置renderer!.isActive = false，是因为父级的isActive = ture会影响子级

    const meshFilter = sf.getComponentByType(MeshFilter)! as MeshFilter;
    DataCenter.PanoramaMeshFilters.push(meshFilter);
    sf!.setParent(p);
    sf!.transform.localPosition = [0, 0, 0];
    sf!.transform.localScale = [1.2, 1.2, 1.2];
    MessageCenter.GlobalEvent.on("click", (id) => {
      if (id === sf.uuid) {
        this.ctr && this.ctr.initRobotDialog(sf, dialog);
      }
    })
  }

  /**
     * 设置飞行器交互对话框的显示状态
     * @param visible
     */
  public setGuideDialogVisible(visible: boolean) {
    this.ctr.setRobotDialogVisible(visible);
  }

  /**
     * 放置飞行器到指定spot点上
     * @param spotID
     */
  public placeToSpot(spotID: string) {
    this.ctr.placeToSpot(spotID)
  }

  /**
     * 监听飞行器到达spot点事件
     * @param callBack
     * @returns
     */
  public onArrivedSpotEvent(callBack: (spotID: string) => void) {
    StateEvent.onFlyToSpot.on('arrived', callBack);
    return callBack;
  }

  /**
     * 卸载飞行器到达spot点事件
     * @param callBack
     * @returns
     */
  public offArrivedSpotEvent(callBack: (spotID: string) => void) {
    StateEvent.onFlyToSpot.off('arrived', callBack);
  }


  /**
     * 初始化虚拟导购
     * @param style 导购风格，默认是用built-in,将启用系统内置
     * @param behavior 导购行为模式，默认是built-in，导购行为模式将采用自动内置
     * @param descJson 特定json配置，飞行器当前传入undefined即可
     */
  public init(style = 'built-in', behavior = 'built-in', descJson: any) {
    return new Promise((resolve, reject) => {
      let sJson: any;
      let bJson: any;

      switch (style) {
        case 'built-in':
          sJson = guideTestJson;
          break;

        default:
          sJson = guideTestJson;
          break;
      }

      switch (behavior) {
        case 'built-in':
          bJson = RobotJson;
          break;

        default:
          bJson = RobotJson;
          break;
      }
      this.makeInstance(sJson, bJson, descJson).then((dd) => {
        resolve(true);
      }).catch(reason => {
        reject(reason);
      })
    });
  }

  /**
 * 实例化一个导购对象
 * @param json
 */
  private async makeInstance(style: any, behavior: any, descJson: any): Promise<Entity | undefined> {
    return new Promise<Entity | undefined>(async (resolve, reject) => {
      ConsoleLog.info('VirtualGuider', 'makeInstance', '开始实例化飞行器导购');
      Loader.LoadGLTF(style.modelConfig.modelURL).then(async model => {
        if (model) {
          model.name = 'virtualGuider';

          //虚拟角色会新增一个root
          this._root = new Entity('root');
          this._root.transform.position = model.transform.position;
          this._root.transform.rotation = model.transform.rotation;
          model.setParent(this._root);

          SceneManager.GetInstance().currentScene.addEntity(this._root);
          const scale = style.modelConfig.scale;
          model.transform.localScale = vec3.fromValues(scale, scale, scale);
          ConsoleLog.info('VirtualGuider', 'makeInstance', '实例化飞行器导购完毕');

          // 表情管理
          const emotionMgr = new EmotionMgr();
          const renderers = model.getComponentsByTypeRecursively<Renderer>(Renderer);
          let render: Renderer | undefined;
          for (const r of renderers) {
            if (r.entity.name.toLowerCase() === style.modelConfig.faceName) {
              render = r;
            } else {
              const material = r.material;
              if (material && material instanceof StandardMaterial) {
                material.enableKeywords(ShaderKeyword.TangentSpaceNormalMap);
                material.irradianceMap = AssetManager.GetInstance().getAsset<TextureCube>('irradianceCubemap')!;
                material.radianceMap = AssetManager.GetInstance().getAsset<TextureCube>('radianceCubemap')!;
                material.roughnessFactor = 0.95;
                const baseColorFactor = 0.8;
                material.baseColorFactor = [baseColorFactor, baseColorFactor, baseColorFactor];
              }
            }
          }
          if (render) {
            const allPromises = new Array<Promise<EmotionConfig>>();

            const material = new AnimateUVMaterial();
            material.uvOffset = vec4.fromValues(1, 1, 0, 0);
            render.setMaterial(material);
            style.modelConfig.emotion.forEach((emotion: { cname: string; texrul: string | string[] }) => {
              allPromises.push(new Promise<EmotionConfig>((resolve, reject) => {
                AssetManager.GetInstance().loadAsset<Texture2D>(emotion.cname, emotion.texrul).then(tex => {
                  if (tex && !Array.isArray(tex)) {
                    resolve(new EmotionConfig(tex, emotion));
                  }
                }).catch(info => {
                  reject(undefined);
                })
              }));
            });
            Promise.all(allPromises).then(data => {
              data.forEach(subData => {
                material.albedo = subData.tex!;
                const uv = render!.entity.addComponent<AnimatedUV>(AnimatedUV);
                uv.tex = subData.tex;
                uv.columnCount = subData.config.columnCount;
                uv.rowCount = subData.config.rowCount;
                uv.duration = subData.config.duration;
                uv.delay = subData.config.delayTime || 0;
                uv.delayRandom = subData.config.delayRandom || 0;
                emotionMgr.add(subData.config.cname, uv);
              })
              emotionMgr.changeTo(style.modelConfig.dufaultEmotion);
            })
          }

          //给root添加控制组件
          const com = ComponentManager.GetDef(style.modelConfig.commponentName);
          this.ctr = this._root.addComponent(com) as any;
          this.ctr.behaviorJson = behavior;
          this.ctr.descJson = descJson;
          this.ctr.emotionMgr = emotionMgr;
          this.emotion = emotionMgr;
          await this.ctr.init();
          this.ctrReady = true;

          resolve(this._root);
        }
      }).catch(e => {
        ConsoleLog.error('VirtualGuider', 'makeInstance', ErrorType.DataError, '加载飞行器模型出错', e);
        reject(e);
      })
    })
  }

  dispose() {

  }

}
