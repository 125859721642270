import { API } from "../../../lib/utils/eca";
import { Annotation2DPrivate } from "./lib/private";
/**
 * 商品标签运行时
 */
@API()
export class Annotation2DRuntime extends Annotation2DPrivate {

  constructor(){
    super();
    this.event.on("click",(id)=>{
      const annotation = this.getElementById(id);
      if(!annotation){ return }
      if(annotation.state.isDetailShow){
        annotation.hideDetail()
      } else {
        annotation.showDetail();
      }
    })
  }
}
