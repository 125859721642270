
import { Logger } from '@ali/tidejs'
enum ILogLevel{
  "none" = 0,
  "error" = 10,
  "warn" = 20,
  "info" = 30,
  "log" = 40,
  "autoLog" = 50,
  "tide" = 60,
}
Logger.GetInstance().setEnable(false);

function getSpace(str: string, targetLength = 10){
  const length = targetLength - str.length;
  if(length < 0){
    return str.substr(0, targetLength - 3) + "...";
  }
  for(let i = 0 ;i < length ;i ++){
    str = str + " ";
  }
  return str;
}
function getStack(s: string){
  const str = s.split("getFunctionName")[1];
  if(!str){ return ""}
  const arr = str.split("at ");
  const stack = [];
  for(const item of arr){
    stack.push(item.split("(")[0]);
  }
  return stack;
}
function getFunctionName(mode: string){
  const s = (new Error()).stack as string;
  let e = [];
  if(mode == "info"){
    e = /at Object.info[^\n]*\n\s*at (\S+)/.exec(s) as any;
  } else if(mode == "warn"){
    e = /at Object.warn[^\n]*\n\s*at (\S+)/.exec(s) as any;
  } else if(mode == "log"){
    e = /at Object.log[^\n]*\n\s*at (\S+)/.exec(s) as any;
  } else if(mode == "error"){
    e = /at Object.error[^\n]*\n\s*at (\S+)/.exec(s) as any;
  }
  const stack = getStack(s);
  if(!e || !e[1]){
    return [stack,""]
  }
  return [stack, e[1]]
}
export enum ErrorType {
  "DataError" = "数据源错误",
  "ParamsError" = "参数错误",
  "UseError" = "使用错误",
  "InternalError" = "内部错误",
  "ExternalError" = "外部错误",
}

function error(className: string, funcName: string,type: ErrorType,...params: any){
  const isSDKDebugger = (window as any).SDK_DEBUG;
  if(!isSDKDebugger && ConsoleLog.logLevel < ILogLevel.error){ return }
  const [functionName] = getFunctionName("error")
  if(!funcName){
    console.error("[error]|", getSpace(`${className}/${functionName}`, ConsoleLog.maxStrLength) , "|", ` 错误类型${type} `, "|", ...params);
  } else {
    console.error("[error]|", getSpace(`${className}/${funcName}`, ConsoleLog.maxStrLength) , "|", ...params);
  }
}
function warn(className: string, funcName: string,...params: any){
  const isSDKDebugger = (window as any).SDK_DEBUG;
  if(!isSDKDebugger || ConsoleLog.logLevel < ILogLevel.warn){ return }
  const [stack ,functionName,] = getFunctionName("warn")
  if(!functionName){
    console.warn("[warn]|", getSpace(`${className}/${functionName}`, ConsoleLog.maxStrLength) , "|", ...params,[stack]);
  } else {
    console.warn("[warn]|", getSpace(`${className}/${funcName}`, ConsoleLog.maxStrLength) , "|", ...params,[stack]);
  }
}

/**
 * 重复的用Log，非重复的用info
 * @param params
 * @returns
 */
function info(className: string, funcName: string,...params: any){
  const isSDKDebugger = (window as any).SDK_DEBUG;
  if(!isSDKDebugger && ConsoleLog.logLevel < ILogLevel.info){ return }
  const [stack ,functionName,] = getFunctionName("info")
  if(!funcName){
    console.info("[info]|", getSpace(`${className}/${functionName}`, ConsoleLog.maxStrLength) , "|", ...params,[stack]);
  } else {
    console.info("[info]|", getSpace(`${className}/${funcName}`, ConsoleLog.maxStrLength) , "|", ...params,[stack]);
  }
}


/**
 * 重复的用Log，非重复的用info
 * @param params
 * @returns
 */
function log(className: string, funcName: string,...params: any){
  const isSDKDebugger = (window as any).SDK_DEBUG;
  if(!isSDKDebugger && ConsoleLog.logLevel < ILogLevel.log){ return }
  const [stack ,functionName,] = getFunctionName("log")
  if(!funcName){
    console.log("[log ]|", getSpace(`${className}/${functionName}`, ConsoleLog.maxStrLength) , "|", ...params,[stack]);
  } else {
    console.log("[log ]|", getSpace(`${className}/${funcName}`, ConsoleLog.maxStrLength) , "|", ...params,[stack]);
  }
}

function autoLog(className: string, funcName: string,...params: any){
  const isSDKDebugger = (window as any).SDK_DEBUG;
  if(!isSDKDebugger && ConsoleLog.logLevel < ILogLevel.autoLog){ return }
  console.log("[auto]|[log ]|", getSpace(`${className}/${funcName}`, ConsoleLog.maxStrLength) , "|", ...params);
}

function time(...params: any) {
  const isSDKDebugger = (window as any).SDK_DEBUG;
  if(!isSDKDebugger && ConsoleLog.logLevel < ILogLevel.autoLog){ return }
  console.time(...params);
}

function timeEnd(...params: any) {
  const isSDKDebugger = (window as any).SDK_DEBUG;
  if(!isSDKDebugger && ConsoleLog.logLevel < ILogLevel.autoLog){ return }
  console.timeEnd(...params);
}

export const ConsoleLog = {
  log,
  autoLog,
  error,
  info,
  warn,
  time,
  timeEnd,
  logLevel: 20,
  maxStrLength: 30,
  setLogEnable(bool: ILogLevel){
    const isTideDebugger = (window as any).TIDE_DEBUG;
    ConsoleLog.logLevel = bool;
    if (isTideDebugger || ConsoleLog.logLevel > ILogLevel.tide) {
      Logger.GetInstance().setEnable(true);
    }
  },
};

(window as any).setLogEnable = ConsoleLog.setLogEnable;
