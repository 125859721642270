import { Transform, Camera } from "@ali/tidejs";
import { CameraPrivate } from "../../../../api/camera/lib/private";
import { Application } from "../../../../Application";
import { mat3, mat4, quat, vec3, vec4 } from "gl-matrix";
export class Normal2SpotInterpolator {
  private _tgtCenter = vec3.create();
  private _startCameraPosition = vec3.create();
  private _startUp = vec3.create();
  private _endTarget = vec3.create();
  private _right = vec3.create();
  private _finalRight = vec3.create();

  private _camera: Camera;
  private _trans: Transform;
  private center = vec3.create();

  constructor() {
    this._camera = Camera.MainCamera!;
    this._trans = this._camera.entity.transform;

  }

  /**
     * 设置目标
     * @param pos 跳转到的坐标
     * @param top2spot 是否从top跳转，false = 从normal跳转
     * @param destForward 目标前方向
     */
  setDest(pos: vec3, top2spot?: boolean, destForward?: vec3) {
    this._trans.updateMatrix();
    this._tgtCenter = vec3.clone(pos);
    const worldMatrix = this._trans.localToWorldMatrix();

    // set start camera position
    this._startCameraPosition = vec3.clone(this._trans.position);
    // set start camera rotation
    const forward = vec4.transformMat4(this._tmpDirection, vec4.fromValues(0, 0, -1, 0), worldMatrix);
    this._tmpDirection = vec4.create();
    const startUp = vec4.transformMat4(this._tmpDirection, vec4.fromValues(0, 1, 0, 0), worldMatrix);
    this._startUp = vec3.fromValues(startUp[0], startUp[1], startUp[2]);

    vec3.cross(this._right, vec3.fromValues(forward[0], forward[1], forward[2]), this._startUp);

    this._finalRight = vec3.clone(this._right);
    // set the end camera pose
    let theta = Math.atan2(-forward[0], -forward[2]);
    if (top2spot) {
      theta = Math.atan2(-startUp[0], -startUp[2]);
    }
    const radius = 3;
    let destPhi = 0;
    if (destForward) {
      theta = Math.atan2(-destForward[0], -destForward[2]);
      destPhi = Math.atan2(-destForward[1], Math.sqrt(destForward[0] * destForward[0] + destForward[2] * destForward[2]));
      // vec3.cross(this._finalRight, vec3.fromValues(-destForward[0], -destForward[1], -destForward[2]), vec3.fromValues(0, 1, 0));
      vec3.cross(this._finalRight, destForward, vec3.fromValues(0, 1, 0));
    }

    const posZ = radius * Math.cos(theta) * Math.cos(destPhi);
    const posX = radius * Math.sin(theta) * Math.cos(destPhi);
    const posY = radius * Math.sin(destPhi);
    this._tmpPos = vec3.fromValues(-posX, -posY, -posZ); // normalCameraForward

    vec3.add(this._endTarget, this._tgtCenter, this._tmpPos);

    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    this.center = cameraAPI.center;
  }

  private _tempQuat: quat = quat.create();
  private _tmpPos = vec3.create();
  private _tmpDirection = vec4.create();
  private _tmpRight = vec3.create();

  public update(ratio: number): void {
    this._tmpPos = vec3.create();
    vec3.lerp(this._tmpPos, this._startCameraPosition, this._tgtCenter, ratio);
    this._trans.position = vec3.clone(this._tmpPos);

    vec3.lerp(this._tmpPos, this.center, this._endTarget, ratio);
    vec3.lerp(this._tmpRight, this._right, this._finalRight, ratio);

    const up = vec3.cross(vec3.create(), this._right ,vec3.sub(vec3.create(), this._tmpPos, this._trans.position));
    // const up = vec3.cross(vec3.create(), this._tmpRight ,vec3.sub(vec3.create(), this._tmpPos, this._trans.position));

    vec3.lerp(up, up, vec3.fromValues(0, 1, 0), Math.pow(ratio, 3));

    const transformMatrix = mat4.targetTo(mat4.create(), this._trans.position, this._tmpPos, up);
    this._tempQuat = quat.fromMat3(quat.create(), mat3.fromMat4(mat3.create(), transformMatrix))
    this._trans.localRotation = quat.clone(this._tempQuat);
  }
}
