import { Transform, Camera } from "@ali/tidejs";
import { CameraPrivate } from "../../../../api/camera/lib/private";
import { Application } from "../../../../Application";
import { mat3, mat4, quat, vec3 } from "gl-matrix";

//TODO 性能可以提升，去除内存申请
export class Top2NormalInterpolator {

  private _endCameraPosition = vec3.create();
  private _startCameraPosition = vec3.create();
  private topForward = vec3.create();
  private center = vec3.create();

  private _camera: Camera;
  private _trans: Transform;

  private _offsetAngle = 0;

  constructor() {
    this._camera = Camera.MainCamera!;
    this._trans = this._camera.entity.transform;
  }
  public setdest() {
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);

    this._startCameraPosition = vec3.clone(this._trans.position);

    this._offsetAngle = cameraAPI.topAngle - cameraAPI.normalAngle;

    this.topForward = cameraAPI.topForward;
    this.center = cameraAPI.center;
    this._endCameraPosition = cameraAPI.normalCameraPostion;

    if (this._offsetAngle < -Math.PI) {
      this._offsetAngle = this._offsetAngle + 2 * Math.PI;
    }
    if (this._offsetAngle > Math.PI) {
      this._offsetAngle = this._offsetAngle - 2 * Math.PI;
    }
  }

  private _tempQuat: quat = quat.create();
  private _tmpPos = vec3.create();
  private _tmpDirection = vec3.create();
  private _tmpRotationMatrix = mat3.create();
  private _tmpTransformMatrix = mat4.create();
  private _offsetQuat: quat = quat.create();

  public update(ratio: number): void {
    this._tmpDirection = vec3.fromValues(0, 1, 0); // up

    if (ratio === 0.0) {
      this._tmpDirection = vec3.add(vec3.create(), vec3.fromValues(0, 0, 0), this.topForward);
    }
    vec3.lerp(this._tmpPos, this._startCameraPosition, this._endCameraPosition, ratio);
    this._trans.position = vec3.clone(this._tmpPos);

    mat4.targetTo(this._tmpTransformMatrix, this._trans.position, this.center, this._tmpDirection);
    const currentAngle = this._offsetAngle * (1.0 - ratio);

    quat.setAxisAngle(this._offsetQuat, vec3.fromValues(0, 0, 1), currentAngle); //offsetQ

    quat.fromMat3(this._tempQuat, mat3.fromMat4(this._tmpRotationMatrix, this._tmpTransformMatrix)); // originQ
    quat.mul(this._tempQuat, this._tempQuat, this._offsetQuat); // fixedQ
    this._trans.localRotation = quat.clone(this._tempQuat);
    this._camera.fov = this._camera.fov * (1.0 - ratio) + CameraPrivate.Config.normalViewFov * ratio
  }
}
