import { ShaderMaterial } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import PanoramaShader from "./Shader/PanoramaShader";

export class PanoramaMaterial extends ShaderMaterial {
  constructor() {
    super(PanoramaShader);
  }

  public get cameraPos(): vec3 {
    return this._tmpUniforms["u_cameraPos"].value;
  }
  public set cameraPos(value: vec3) {
    this._tmpUniforms["u_cameraPos"].value = value;
  }

  public get alphaRatio(): number {
    return this._tmpUniforms["u_alphaRatio"].value;
  }
  public set alphaRatio(time: number) {
    this._tmpUniforms["u_alphaRatio"].value = time;
  }

  public get panorama(): WebGLTexture {
    return this._tmpUniforms["u_panorama"];
  }
  public set panorama(albedo: WebGLTexture) {
    this._tmpUniforms["u_panorama"].value = albedo;
  }
}