import { StateEvent } from "../../GameLogic/StateEvent";
import { BasicState, StateMgr } from "../../GameLogic/StatesFrame";
import { VGInfo } from "./VGInfo";

export class VGMgr extends StateMgr<VGInfo> {
  changeTo (state: BasicState<VGInfo>) {
    if (this._current)  {
      this._current.onExit();
      StateEvent.onStateChange.emit('before',this._current.id);
    }
    this._current = state;
    this._current.onEnter();
    StateEvent.onStateChange.emit('after',this._current.id);
  }
}
