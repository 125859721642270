import { BasicState, expJson, InjectState } from '../../StatesFrame';
import { VGInfo } from '../VGInfo';
import { VGMove } from './VGMove';
import { vec3 } from 'gl-matrix';
import { ConsoleLog } from '../../../lib/log';

@InjectState('VGMoveByMark')
export class VGMoveByMark extends VGMove {
  @expJson('info')
  public info = 'moveByMark';

  @expJson('onArrivedMark')
  public onArrivedMark: BasicState<VGInfo> | undefined;

  @expJson('onFinished')
  public onFinished: BasicState<VGInfo> | undefined;

  private _p3: vec3 = vec3.create();


  update (deltaTime: number) {
    if (!this.srcInfo.soundMgr2.isFinished()) {
      this.srcInfo.playAnimation('walk_speak')
    } else {
      this.srcInfo.playAnimation('walk')
    }

    if (this._p1 && this._p2) {
      this._currentTime += deltaTime;
      this._ratio = this._currentTime / this._time;
      if (this._currentTime >= this._time) {
        this._ratio = 1;
      }
      vec3.lerp(this._temp,this._p1,this._p3,this._ratio);
      this.srcInfo.entityTransform.position = this._temp;

      if (this._ratio === 1) {
        if (this.onArrivedMark) {
          this.reset();
          this.onArrivedMark.changeTo();
        }
      }

    }

  }

  onEnter() {
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();
    this._p1 = this.srcInfo.entityTransform.position;
    this._p2 = this.srcInfo.getNextMoveSpot()

    //到达终点，则不需要移动
    if (!this._p2) {
      if (this.onArrivedMark) {
        this.reset();
        this.onArrivedMark.changeTo();
      }
      return;
    }

    // 选取前进路径方向上1m距离的点作为目的地，如果下一个点距离小于1m，则取距离的一半
    const moveVec3 = vec3.create();
    vec3.subtract(moveVec3, this._p2, this._p1);
    let distanceScale = 1;
    const spotDistance = vec3.distance(this._p1, this._p2);
    if (spotDistance < 1) {
      distanceScale = spotDistance / 2;
    }
    vec3.normalize(moveVec3, moveVec3)
    vec3.scale(moveVec3, moveVec3, distanceScale);

    vec3.add(this._p3, moveVec3, this._p1);

    // 计算起点终点，方向，时间长度，并朝向前进方向
    const distance = vec3.distance(this._p1, this._p3);
    this._time = distance / this.srcInfo.speed;

    vec3.subtract(this._dir,this._p3,this._p1);
    this._dir[1] = 0;
    vec3.normalize(this._dir,this._dir);
    this.faceToDir();

    ConsoleLog.log('state', this.info, 'enter');

  }

}
