import { InjectState } from "../../../../GameLogic/StatesFrame";
import { VGCheck } from "./VGCheck";

@InjectState('VGSoundCheck')
export class VGSoundCheck extends VGCheck {
  public test(): boolean {
    return this.srcInfo.isLastSpotAndSoundIsPlaying();
  }

  onEnter() {
    if (this.srcInfo.isFirstSpotInGroup()) {
      this.srcInfo.playCurrentGroupSound();
      if (this.srcInfo.isLastSpotInGroup()) {
        this.onPassState?.changeTo();
        return;
      }
    }
    super.onEnter();
  }
}
