import { Component, IPreRenderable } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import { BaseBehaviorVR } from "../Behaviors/lib";


@Component("RobotDialog")
export class RobotDialog extends BaseBehaviorVR implements IPreRenderable {
  public position: vec3 = [0,0,0];
  public container: HTMLElement = document.createElement("div");
  public elementNode: HTMLElement = document.createElement("div");
  public getPosition = () => {};

  private isAwake = false;
  private isDisplay = true;
  private halfWidth: number = window.innerWidth / 2;
  private halfHeight: number = window.innerHeight / 2;
  private offsetX = window.innerWidth > 1024 ? 65 : 50;
  private offsetY = 50;

  private setNodeStyle(elementNode: HTMLElement){
    elementNode.style.position = "absolute";
    elementNode.style.display = "flex";
    elementNode.style.justifyContent = "center";
    elementNode.style.alignItems = "center";
    elementNode.style.pointerEvents = "visible";
    elementNode.style.cursor = "pointer";
    const children = elementNode.children;
    if(!children){ return }
    for(const element of children){
      (element as any).style.userSelect = "none";
    }
  }

  private resize(){
    let container = this.container;
    if(this.Css2DContainer && this.Css2DContainer.css2dContainer){
      container = this.Css2DContainer.css2dContainer;
    }
    const rect = container!.getBoundingClientRect();
    // for ios 10
    if(!rect || !rect.width  || !rect.height){
      this.halfWidth = container.clientWidth / 2;
      this.halfHeight = container.clientHeight / 2;
    } else {
      this.halfWidth = rect.width / 2;
      this.halfHeight = rect.height / 2;
    }
    this.offsetX = window.innerWidth > 1024 ? 65 : 50;
  }

  public hide(){
    this.isDisplay = false;
    this.elementNode!.style.display = "none";
  }

  public show(){
    this.isDisplay = true;
    this.elementNode!.style.display = "flex";
  }

  public dispose(){
    this.isAwake = false;
    this.Css2DContainer?.removeChild(this.elementNode);
    this.MessageCenter.GlobalEvent.removeListener("resize", this.resize)
  }

  public isAwaked() {
    return this.isAwake;
  }

  public isDisplayed() {
    return this.isDisplay;
  }

  public preAwake(): void {
    if (this.isAwake) {return}
    if(!this.container){ return }
    this.Css2DContainer?.appendToCss2dContainer(this.elementNode);
    this.setNodeStyle(this.elementNode);
    this.resize();
    this.isAwake = true;
    this.preRender();
    this.MessageCenter.GlobalEvent.on("resize", () => {
      this.resize()
    })
  }

  public preRender(): void {
    const curPosition = this.getPosition() as any || this.position;
    const x = curPosition[0];
    const y = curPosition[1];
    const z = curPosition[2];
    const isNotInView = (x < -1 || y < -1) || (x > 1 || y > 1) || (z > 1 || z < -1);
    if (isNotInView) {
      this.hide()
    }
    const halfWidth = this.halfWidth;
    const halfHeight = this.halfHeight;
    let offsetX = this.elementNode.offsetWidth + this.offsetX;
    if (x < 0) {
      // 飞行器在屏幕左侧的时候，对话框展示在右侧
      offsetX = -this.offsetX
    }
    const offsetY = this.offsetY;
    this.elementNode!.style.transform = `translate(${x * halfWidth + halfWidth - offsetX }px, ${-y * halfHeight + halfHeight - offsetY }px)`;
  }

}
