import { ShaderMaterial, Texture2D, TextureCube } from "@ali/tidejs";
import { mat3, vec3 } from "gl-matrix";
import { ConfigPrivate } from "../api/config/lib/private";
import { Application } from "../Application";
import { ModelType } from "../Behaviors/GameEnter";
import SpotToSpotTransitionShader from "./Shader/SpotToSpotTransitionShader";
import SpotToSpotTransitionWithPanoVideoShader from "./Shader/SpotToSpotTransitionWithPanoVideoShader";

export class SpotToSpotTransitionMaterial extends ShaderMaterial {
  public get sourceCenter(): vec3 {
    return this._tmpUniforms.u_sourceCenter.value;
  }
  public set sourceCenter(value: vec3) {
    this._tmpUniforms.u_sourceCenter.value = value;
  }

  public get targetCenter(): vec3 {
    return this._tmpUniforms.u_targetCenter.value;
  }
  public set targetCenter(value: vec3) {
    this._tmpUniforms.u_targetCenter.value = value;
  }

  public get targetWorldToCameraMatrix(): mat3 {
    return this._tmpUniforms.u_targetWorldToCameraMatrix.value;
  }
  public set targetWorldToCameraMatrix(value: mat3) {
    this._tmpUniforms.u_targetWorldToCameraMatrix.value = value;
  }

  public get sourceWorldToCameraMatrix(): mat3 {
    return this._tmpUniforms.u_sourceWorldToCameraMatrix.value;
  }
  public set sourceWorldToCameraMatrix(value: mat3) {
    this._tmpUniforms.u_sourceWorldToCameraMatrix.value = value;
  }

  public get time(): number {
    return this._tmpUniforms.u_time.value;
  }
  public set time(time: number) {
    this._tmpUniforms.u_time.value = time;
  }

  public get cubeSourceLevel(): TextureCube {
    return this._tmpUniforms.u_cubeSourceLevel.value;
  }
  public set cubeSourceLevel(albedo: TextureCube) {
    this._tmpUniforms.u_cubeSourceLevel.value = albedo;
  }

  public get cubeTargetLevel(): TextureCube {
    return this._tmpUniforms.u_cubeTargetLevel.value;
  }
  public set cubeTargetLevel(albedo: TextureCube) {
    this._tmpUniforms.u_cubeTargetLevel.value = albedo;
  }

  public get cubeSourceMipFlag(): TextureCube {
    return this._tmpUniforms.u_cubeSourceMipFlag.value;
  }
  public set cubeSourceMipFlag(albedo: TextureCube) {
    this._tmpUniforms.u_cubeSourceMipFlag.value = albedo;
  }

  public get cubeTargetMipFlag(): TextureCube {
    return this._tmpUniforms.u_cubeTargetMipFlag.value;
  }
  public set cubeTargetMipFlag(albedo: TextureCube) {
    this._tmpUniforms.u_cubeTargetMipFlag.value = albedo;
  }

  public get cubeSource2K(): TextureCube {
    return this._tmpUniforms.u_cubeSource2K.value;
  }
  public set cubeSource2K(albedo: TextureCube) {
    this._tmpUniforms.u_cubeSource2K.value = albedo;
  }

  public get cubeTarget2K(): TextureCube {
    return this._tmpUniforms.u_cubeTarget2K.value;
  }
  public set cubeTarget2K(albedo: TextureCube) {
    this._tmpUniforms.u_cubeTarget2K.value = albedo;
  }

  public get cubeSource(): TextureCube {
    return this._tmpUniforms.u_cubeSource.value;
  }
  public set cubeSource(albedo: TextureCube) {
    this._tmpUniforms.u_cubeSource.value = albedo;
  }

  public get cubeTarget(): TextureCube {
    return this._tmpUniforms.u_cubeTarget.value;
  }
  public set cubeTarget(albedo: TextureCube) {
    this._tmpUniforms.u_cubeTarget.value = albedo;
  }

  public get panoVideoTexture(): Texture2D {
    return this._tmpUniforms.u_panoVideoTexture.value;
  }
  public set panoVideoTexture(albedo: Texture2D) {
    this._tmpUniforms.u_panoVideoTexture.value = albedo;
  }

  public get panoVideoBlendRatio(): number {
    return this._tmpUniforms.u_panoVideoBlendRatio.value;
  }
  public set panoVideoBlendRatio(albedo: number) {
    this._tmpUniforms.u_panoVideoBlendRatio.value = albedo;
  }
  public get panoVideoUOffset(): number {
    return this._tmpUniforms.u_panoVideoUOffset.value;
  }
  public set panoVideoUOffset(panoVideoUOffset: number) {
    this._tmpUniforms.u_panoVideoUOffset.value = panoVideoUOffset;
  }
  constructor() {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const configDefault = configAPI.getConfig();
    const modelType = configDefault.type;
    if (modelType === ModelType.lyj) {
      super(SpotToSpotTransitionShader); // 因为在ios15以下部分机型只支持一个shader中最多有8张纹理，所以临云镜场景中去掉不需要的全景视频的纹理，避免兼容性问题
    } else {
      super(SpotToSpotTransitionWithPanoVideoShader);
    }
  }
}
