import { IAnnotationConfigItem } from "../../api/elements/interface";
import { TransitionEventParams } from "../../api/transition/lib/interface";
import { vec3 } from "gl-matrix";
import { ConsoleLog, ErrorType } from "../../lib/log";
import { Entity } from "@ali/tidejs"
import  EventEmitter from "eventemitter3";
import { ProgressAPI } from "../../lib/progress/ProgressReporter";
import { cloneDeep } from 'lodash';
import { DataCenter } from "../../GameLogic/DataCenter";

export const APIInstanceContainer = new Map<string, any>();
export const instanceContainer = new Map<string, any>();
export function getThetaPhiByDir(direction:vec3):[number,number] {
  const transformedDirection = vec3.fromValues(direction[0], -direction[2], direction[1])
  const theta = Math.atan2(transformedDirection[0], transformedDirection[1]);
  const phi = Math.atan2(transformedDirection[2], Math.sqrt(transformedDirection[0] * transformedDirection[0] + transformedDirection[1] * transformedDirection[1]));
  return [theta, phi];
}
function ProxyState(state: any){
  return new Proxy(state, {
    set(target, key:string, value) {
      ConsoleLog.error('eca', 'ProxyState', ErrorType.InternalError, "set state 失败，请使用SetState");
      return true
    }
  });
}
function ProxyObj(tempObj: any){
  return new Proxy(tempObj,{
    get: function (target, key: string){
      const property = target[key]
      if(property instanceof Function){
        if(key === "constructor"){
          return property
        }
        return function(...arg: any){
          const className = Object.getPrototypeOf(target).constructor.name;
          const time1 = performance.now();
          const result = target[key](...arg);
          const time = (performance.now() - time1).toFixed(1);
          ConsoleLog.autoLog(className, key, `用时:[${time}ms] - |`, `调用：参数:`, arg);
          return result;
        };
      } else {
        if(key === "state"){
          return target["_state"]
        }
        return property;
      }
    },
    set(target, key:string, value) {

      const className = Object.getPrototypeOf(target).constructor.name;
      ConsoleLog.autoLog(className, key, `设置了属性：${key}，值为：`, value);
      target[key] = value
      if(key === "state"){
        // ConsoleLog.info('eca', 'set', className, key, `设置了属性：${key}，值为：`, value);
        target["_state"] = value;
        target[key] = ProxyState(target[key]);
      }
      return true
    }
  });
}
export function API() {
  return (constructor: any) => {
    constructor.getInstance = () => {
      return APIInstanceContainer.get(constructor.ModuleName);
    };
    // const obj = ProxyObj(new constructor ());
    APIInstanceContainer.set(constructor.ModuleName, new constructor ())
    // onApplicationInitObservable.subscribe(() => {
    //   const instance = constructor.getInstance();
    //   if (instance.init) {
    //     instance.init();
    //   }
    // });

    // todo : 自动初始化
    // todo : 每个函数调用之前都判断下是否active
  };
}

export const ElementMap = new Map();
interface IElement{
  type: string;
  build: any;
}
export function Element({
  type,
  build,
}: IElement){
  ElementMap.set(type, build)
  return (constructor: any) => {
  };
}

abstract class BaseClass<IState>{
  /**
     * @private
     * 内部私有state
     * State  of base class
     */
  public _state!: any;
  public state!: Readonly<IState>;
  abstract dispose(): void;

  /**
     * @ignore
     * Determines whether active is
     */
  public isActive = true;
  constructor(){
    if(ProgressAPI.progress >= 100){
      // 如果已经初始化完成，就调用SceneLoadEnd函数
      this.SceneLoadEnd();
      this.ResourceLoadEnd();
      this.AppInitEnd();
    } else if(ProgressAPI.progress >= 70){
      // 如果还在加载中，就调用资源加载完成，监听剩下一个
      this.ResourceLoadEnd();
      this.AppInitEnd();
      ProgressAPI.event.once("SceneLoadEnd",()=>{
        this.SceneLoadEnd()
      })
    }  else if(ProgressAPI.progress >= 20){
      // 如果刚刚初始化，调用初始化完成，监听剩下两个
      this.AppInitEnd();
      ProgressAPI.event.once("ResourceLoadEnd",()=>{
        this.ResourceLoadEnd()
      })
      ProgressAPI.event.once("SceneLoadEnd",()=>{
        this.SceneLoadEnd()
      })
    } else {
      // 如果还没初始化，监听三个
      ProgressAPI.event.once("AppInitEnd",()=>{
        this.AppInitEnd()
      })
      ProgressAPI.event.once("ResourceLoadEnd",()=>{
        this.ResourceLoadEnd()
      })
      ProgressAPI.event.once("SceneLoadEnd",()=>{
        this.SceneLoadEnd()
      })
    }
    const onTransition = ()=>{
      const transitionAPI = APIInstanceContainer.get("transitionControlAPI")
      transitionAPI.transitionProcessObserve.subscribe((event: TransitionEventParams)=>{
        this.onTransition(event)
      })
    }
    const onCameraRotate = ()=>{
      const cameraAPI = APIInstanceContainer.get("cameraAPI")
      cameraAPI.cameraControlSubject.subscribe(()=>{
        this.onCameraRotate()
      })
    }
    if(ProgressAPI.progress > 0){
      onTransition()
      onCameraRotate();
    } else {
      ProgressAPI.event.once("AppInitEnd",()=>{
        onTransition()
        onCameraRotate();
      })
    }
    if((this as any).state){
      this._state = (this as any).state
    }
    return ProxyObj(this);
  }
  protected setState(value: any){
    if(!this._state || !value || !value.hasOwnProperty){ return false }
    for(const key of Object.keys(this._state)){
      if(value.hasOwnProperty(key)){
        const className = Object.getPrototypeOf(this).constructor.name;
        ConsoleLog.autoLog(className, "setState", `设置了属性：${key}，值为：`, value[key]);
        ( this._state as any)[key] = cloneDeep( value[key] );
      }
    }
  }
  // 用于没有监听到修改的事件，例如map.set这种
  protected updateState(){

  }
  protected AppInitEnd(){

  }

  protected ResourceLoadEnd(){

  }
  protected SceneLoadEnd(){

  }

  protected onTransition(event: TransitionEventParams){

  }

  protected onCameraRotate(){

  }
  /**
     * @ignore
     * Deactivates base class
     */
  deactivate(){
    this.isActive = false;
  }
  /**
     * @ignore
     * Activates base class
     */
  activate(){

  }
  // prerender  render 函数？
}

export abstract class APIBaseClass<IState = any> extends BaseClass<IState> {
  static ModuleName: string;
  /**
     * @private
     * Class type of apibase class
     */
  public _class_type = "api";


  public static getInstance<T extends APIBaseClass<any>>(this: new () => T): T {
    const self = new this();
    return self;
  }

}
export abstract class ElementAPIBaseClass<Element ,IState = any> extends APIBaseClass<IState> {
  /**
     * 这个 type 必须和 build 里面的 type一样
     * Element type of element apibase class
     */
  abstract elementType: string;
  private _sdk_elementsMap = new Map<string, Element>();
  constructor(){
    super();
  }
  /**
     * 一般不调用这个，调用这个必须要传一个type，是SDKType
     * Builds element
     * @param item
     * @returns element
     */
  public async buildElement(item: IAnnotationConfigItem): Promise<Element| undefined>{
    if(!item || !item.type){
      ConsoleLog.error("ElementAPIBaseClass","buildElement", ErrorType.ParamsError, "id未传入");
      return undefined
    }
    const build = ElementMap.get(this.elementType);
    if(!build){
      ConsoleLog.error("ElementAPIBaseClass", "buildElement",ErrorType.InternalError, "标签类型不支持", item);
      return undefined
    }
    const element = await build(item);
    this.addElement(element);
    return element;
  }
  //
  /**
     * 添加元素Element
     * Adds element
     * @param element
     * @returns element
     */
  public addElement(element: any): Element| undefined{
    if(!element || !element.id){
      ConsoleLog.error("ElementAPIBaseClass","addElement", ErrorType.ParamsError, "element和id未传入");
      return
    }
    this._sdk_elementsMap.set(element.id, element);
    return element;
  }
  //
  /**
     * 根据ID删除Element
     * Removes element by id
     * @param id
     * @returns
     */
  public removeElementByID(id: string){
    if(!id){
      ConsoleLog.error("ElementAPIBaseClass","removeElementByID", ErrorType.ParamsError, "id未传入");
      return false
    }
    const element = this._sdk_elementsMap.get(id)
    if(element){
      (element as any).dispose();
    }
    this._sdk_elementsMap.delete(id);
    return true;
  }
  /**
     * 获取所有的 elements
     * Gets all elements
     * @returns all elements
     */
  public getAllElements(): Element[]{
    return Array.from( this._sdk_elementsMap.values() )
  }
  /**
     * Gets elements by id
     * @param id
     * @returns elements by id
     */
  public getElementById(id: string): Element | undefined{
    return this._sdk_elementsMap.get(id);
  }
  public dispose(){
    for(const element of this.getAllElements()){
      (element as any)?.dispose();
    }
  }

}

export abstract class BaseElement<IState = any> extends BaseClass<IState>{
  public id: string;
  public entity: Entity;
  public type: string;
  public elementConfig: IAnnotationConfigItem;
  //
  public isVisible = true;
  public _class_type = "element";

  public _normal: vec3 = vec3.create();
  // 为了兼容Z朝上的数据
  get old_normal(){
    return vec3.fromValues(this._normal[0], -this._normal[2], this._normal[1]);
  }
  get normal(){
    return this._normal;
  }
  get old_position(){
    const position =  this.position || vec3.create();
    return vec3.fromValues(position[0], -position[2], position[1]);
  }
  abstract get position(): vec3;

  /**
     * 设置可见性
     * Sets visible
     * @param bool
     */
  setVisible(bool: boolean){
    if(bool){
      ConsoleLog.log("BaseElement", "setVisible true", this.id);
      this.isVisible = true;
    } else {
      ConsoleLog.log("BaseElement", "setVisible false", this.id);
      this.isVisible = false;
    }
  }

  abstract highlight(color: number[]): void;
  abstract unhighlight(): void;
  public event = new EventEmitter<"dispose" | "deactivate"|"activate" | "show" | "hide" | "onShow" | "onHide" | "built", string>();

  constructor(entity: Entity, elementConfig: IAnnotationConfigItem){
    super();
    const { id, type } = elementConfig;
    this.elementConfig = elementConfig;
    this.id = id;
    this.entity = entity;
    this.type = type;
  }

  addComponent(comType: any){
    const com = this.entity.addComponent(comType) as any;
    com.element = this;
    com._init && com._init();
    return com;
  }
  show(){
    this.event.emit("show");
  }
  hide(){
    this.event.emit("hide");
  }
  /**
     * 隐藏标签
     * Deactivates base element
     */
  deactivate(){
    super.deactivate();
    this.setVisible(false);
    this.event.emit("deactivate",this.id);
    this.entity.deactivate();
  }
  /**
     * 显示标签
     * Activates base element
     */
  activate(){
    super.activate();
    this.setVisible(true);
    this.event.emit("activate",this.id);
    this.entity.activate();
  }
  dispose(){
    this.deactivate();
    this.event.emit("dispose",this.id);
    Entity.Destroy(this.entity)
  }
  /**
     * 跳转到附近并看向此物体，使用此函数必须确保标签有spotID这个属性
     * Looks at
     */
  async lookAt(){
    // 跳转到附近并看向此物体
    const entity = this.entity;
    const elementConfig = this.elementConfig;
    if(!entity) {
      ConsoleLog.error("BaseElement","lookAt", ErrorType.InternalError, `lookAt entity 不存在${this.id}`)
      return
    }
    if(!elementConfig){
      ConsoleLog.error("BaseElement","lookAt", ErrorType.InternalError, `elementConfig 不存在${this.id}`)
      return
    }
    const targetPosition = entity.transform.position;
    const hotSpotAPI = APIInstanceContainer.get("hotSpotAPI") as any;
    const transitionAPI = APIInstanceContainer.get("transitionControlAPI") as any;

    let spotID: string | undefined = undefined;
    if(elementConfig.includeSpots && elementConfig.includeSpots[0] && elementConfig.includeSpots[0].includes("p")){
      spotID = elementConfig.includeSpots[0];
    } else {
      spotID = hotSpotAPI.getNearestSpotByPosition(targetPosition)
    }
    if(!spotID){
      ConsoleLog.error("BaseElement","lookAt", ErrorType.InternalError, `toSpotID 未找到${this.id}`)
      return
    }
    const cameraSpot = hotSpotAPI.getHotSpotById(spotID);
    if(!cameraSpot){ return }
    const cameraPos = cameraSpot.opticalCenter;
    const vector = vec3.create();
    vec3.sub(vector, targetPosition, cameraPos);
    vec3.normalize(vector, vector);
    const [ theta, phi ] = getThetaPhiByDir(vector);

    const orbit = DataCenter.OrbitControlsInstance;
    if (orbit && orbit.isActive) {
      const ground = cameraSpot.groundCoord;
      const target = vec3.sub(vec3.create(),targetPosition,ground);
      transitionAPI.state.isInTransition = true;
      await orbit.rotateTo(target);
      transitionAPI.state.isInTransition = false;
    }
    await transitionAPI.transitionTo(spotID, {
      cameraAnimation: {
        theta,
        phi,
      }
    }, true, true);
  }

  /**
   * 更新element配置信息
   * @param key 属性名称
   * @param value 属性值
   */
  updateElementConfig(key: string, value: any) {
    if ((this.elementConfig as any)[key] === undefined) {
      ConsoleLog.warn("BaseElement","updateElementConfig", "未在当前elementConfig上找到对应的更新属性值");
      return
    }
    (this.elementConfig as any)[key] = value;
  }
}
export function ecaDispose(){
  for(const value of APIInstanceContainer.values()){
    value.deactivate && value.deactivate();
    value.dispose && value.dispose();
    // 这里只dispose，不会进行删除，因为还需要拿到构造函数reload，如果删除了就拿不到构造函数了
  }
}
export function ecaReload(){
  // eslint-disable-next-line prefer-const
  for(let [key, value] of APIInstanceContainer.entries()){
    APIInstanceContainer.set(key, new value.constructor());
    value = null;
  }
}
