import { RenderQueue, IShader, UniformType, BuiltInTexture2D} from "@ali/tidejs";
import { mat3, vec3 } from "gl-matrix";

const SpotToSpotTransitionShader: IShader = {
  name: "SpotToSpotTransitionShader",
  renderQueue: RenderQueue.Geometry,

  uniform: {
    u_cubeSource: { type: UniformType.TextureCube, value: undefined},
    u_cubeTarget: { type: UniformType.TextureCube, value: undefined},
    u_cubeSource2K: { type: UniformType.TextureCube, value: undefined},
    u_cubeTarget2K: { type: UniformType.TextureCube, value: undefined},
    u_cubeSourceLevel: { type: UniformType.TextureCube, value: undefined},
    u_cubeTargetLevel: { type: UniformType.TextureCube, value: undefined},
    u_cubeSourceMipFlag: { type: UniformType.TextureCube, value: undefined},
    u_cubeTargetMipFlag: { type: UniformType.TextureCube, value: undefined},

    u_panoVideoTexture: { type:UniformType.Texture2D, value: BuiltInTexture2D.Black},
    u_panoVideoBlendRatio: { type: UniformType.float, value: 0 },
    u_panoVideoUOffset: { type: UniformType.float, value: 0 },

    u_sourceCenter: { type: UniformType.vec3, value: vec3.create()},
    u_targetCenter: { type: UniformType.vec3, value: vec3.create()},

    u_sourceWorldToCameraMatrix: { type: UniformType.mat3, value: mat3.create()},
    u_targetWorldToCameraMatrix: { type: UniformType.mat3, value: mat3.create()},


    u_time: {type: UniformType.float, value: 0},
  },

  vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;

        varying vec4 v_worldPos;

        void main() {
            v_worldPos = u_model * vec4(position, 1.0);

            gl_Position = u_projection * u_view * v_worldPos;
        }
    `,

  fragmentShader: `
        precision highp float;
        uniform samplerCube u_cubeSource;
        uniform samplerCube u_cubeTarget;
        uniform samplerCube u_cubeSource2K;
        uniform samplerCube u_cubeTarget2K;

        uniform samplerCube u_cubeSourceLevel;
        uniform samplerCube u_cubeTargetLevel;

        uniform samplerCube u_cubeSourceMipFlag;
        uniform samplerCube u_cubeTargetMipFlag;

        // uniform sampler2D u_panoVideoTexture;
        // uniform float u_panoVideoBlendRatio;

        // uniform float u_panoVideoUOffset;

        uniform float u_time;
        uniform mat3 u_sourceWorldToCameraMatrix;
        uniform mat3 u_targetWorldToCameraMatrix;

        uniform vec3 u_sourceCenter;
        uniform vec3 u_targetCenter;

        varying vec4 v_worldPos;

        const float PI = 3.1415926;

        void main() {
            vec3 u_sourceTextureDir = (u_sourceWorldToCameraMatrix * normalize(v_worldPos.xyz - u_sourceCenter));
            u_sourceTextureDir.z =  -u_sourceTextureDir.z;

            vec3 u_targetTextureDir = (u_targetWorldToCameraMatrix * normalize(v_worldPos.xyz - u_targetCenter));
            u_targetTextureDir.z =  -u_targetTextureDir.z;

            vec3 res;
            vec3 colorSrc;
            vec3 colorTgt;

            vec3 srcLevel = textureCube(u_cubeSourceLevel, u_sourceTextureDir).rgb;
            vec3 tgtLevel = textureCube(u_cubeTargetLevel, u_targetTextureDir).rgb;

            float srcMipFlag = textureCube(u_cubeSourceMipFlag, u_sourceTextureDir).r;
            float tgtMipFlag = textureCube(u_cubeTargetMipFlag, u_targetTextureDir).r;

            colorSrc = srcLevel.r > 0.0 ? textureCube(u_cubeSource, u_sourceTextureDir).rgb : (srcMipFlag == 1.0 ? textureCube(u_cubeSource2K, u_sourceTextureDir).rgb : textureCubeLodEXT(u_cubeSource2K, u_sourceTextureDir, 0.0).rgb);
            colorTgt = tgtLevel.r > 0.0 ? textureCube(u_cubeTarget, u_targetTextureDir).rgb : (tgtMipFlag == 1.0 ? textureCube(u_cubeTarget2K, u_targetTextureDir).rgb : textureCubeLodEXT(u_cubeTarget2K, u_targetTextureDir, 0.0).rgb);

            // float theta = acos(u_targetTextureDir.y);
            // float phi = atan(u_targetTextureDir.x, u_targetTextureDir.z);
            // float u = (phi + PI)/ (2.0 * PI);
            // float v = theta / PI;
            // vec2 uv = vec2(u + u_panoVideoUOffset, v);
            // vec3 colorPanoVideo = texture2D(u_panoVideoTexture, uv).rgb;

            // colorTgt = mix(colorTgt, colorPanoVideo, u_panoVideoBlendRatio);

            res = mix(colorSrc, colorTgt, pow(u_time, 2.0));

            gl_FragColor = vec4(res, 1.0);
        }
    `,
};

export default SpotToSpotTransitionShader;
