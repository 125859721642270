import { BaseBehavior, Collider, Component, Constants, Entity, InputContext, IOnStart, IUpdatable, MeshCollider, PrimitiveType, Renderer, Texture2D, Transform, Loader } from '@ali/tidejs';
import { MessageCenter } from '../../GameLogic/MessageCenter';
import { vec3, vec4 } from 'gl-matrix';
import { StandarMaterial } from '../../Material/StandarMaterial';
import { SpotPrivate } from '../../api/spot/lib/private';
import EventEmitter from 'eventemitter3';
import { ConsoleLog } from '../../lib/log';
import { getImage } from '../../GameLogic/WanHuaTongUtility';
import { videoSpot, defaultSpot } from '../../assets/icon'

let panorama_texture: any;
async function initVideoSpotTexture() {
  const panorama_image = await getImage(videoSpot);
  panorama_texture = new Texture2D(panorama_image.width, panorama_image.height);
  panorama_texture.setTextureImageSource(panorama_image);
  panorama_texture.magFilter = Constants.LINEAR;
  panorama_texture.minFilter = Constants.LINEAR_MIPMAP_LINEAR;
  panorama_texture.anisotropic = 16;
  panorama_texture.generateMipmaps();
}

@Component('Spot')
export class Spot extends BaseBehavior implements IOnStart, IUpdatable {
  public type: "video-spot" | "normal-spot" = "normal-spot";
  private videoSpotEntity!: Entity;
  private rotate = 0;
  private _collider: Collider | undefined;
  private _clickHandler: any;
  private _neighbors: Array<Spot> | undefined;
  private _inProgress = false;
  private _upwardDirection!: vec3;
  private _forwardDirection!: vec3;
  private _opticalCenter!: vec3;

  private _finishHandler: any;
  private _spotMgr!: SpotPrivate;
  private _time = 0;
  private _mag = Math.PI * 0.5 + 1.0;
  private _trans!: Transform;
  private _normalSpotTexture!: Texture2D;
  private _ActiveSpotTexture!: Texture2D;
  private _tempNoramlUrl = '';
  private _tempActiveUrl = '';

  public enterScale = vec3.fromValues(1.2, 1.2, 1.2);
  public enterColor = vec4.fromValues(0.1, 0.93, 0.93, 1.0);
  public scale = vec3.fromValues(0.8, 0.8, 0.8);
  public color = vec4.fromValues(0.9, 0.9, 0.9, 0.6);

  public disableUpdate = false;

  public event = new EventEmitter<"click">();
  //该参数由spotMgr组装
  public material!: StandarMaterial;

  public name!: string;
  public floorId?: string

  set spotMgr(spotMgr: SpotPrivate) {
    this._spotMgr = spotMgr;
  }

  get neighbors() {
    return this._neighbors;
  }

  get upwardDirection(): vec3 {
    return this._upwardDirection;
  }

  set upwardDirection(dir: vec3) {
    this._upwardDirection = dir;
  }

  get forwardDirection(): vec3 {
    return this._forwardDirection;
  }

  set forwardDirection(dir: vec3) {
    this._forwardDirection = dir;
  }

  get opticalCenter(): vec3 {
    return this._opticalCenter;
  }

  set opticalCenter(pos: vec3) {
    this._opticalCenter = pos;
  }
  get groundCoord() {
    return this.entity.transform.position;
  }
  addNeighbor(neighbor: Spot) {
    if (!this._neighbors) {
      this._neighbors = new Array<Spot>();
    }
    this._neighbors.push(neighbor);
  }

  async onStart() {
    this._trans = this.entity.transform;
    this._trans.localScale = this.scale;
    this.material.color = this.color;
    this._collider = this.entity.getComponentByType(MeshCollider);
    if (this._collider) {
      this._clickHandler = this._collider.triggerEvent.on('enter', this.onEnter.bind(this));
      this._clickHandler = this._collider.triggerEvent.on('exit', this.onExit.bind(this));
    }
    if(SpotPrivate.Config.spot.normalSpotTexture){
      this.creatNormalSpotTexture()
    }
    if(SpotPrivate.Config.spot.activeSpotTexture){
      this.creatActiveSpotTexture();
    }
  }

  async changeSpotType(type: "video-spot" | "normal-spot") {
    if (this.type === type) {
      return
    }
    this.type = type
    if (type === 'normal-spot' && this.videoSpotEntity) {
      this.videoSpotEntity.deactivate()
      return
    }
    // 存在videoSpot实体，直接激活即可
    if (type === 'video-spot' && this.videoSpotEntity) {
      this.videoSpotEntity.activate()
      return
    }
    // 如果没有存在过videoSpot示例，就初始化实体
    if (type === 'video-spot') {
      if (!panorama_texture) {
        await initVideoSpotTexture();
      }
      const entity = Entity.CreatePrimitive(PrimitiveType.DoublePlane)!;
      const renderer = entity.getComponentByType<Renderer>(Renderer);
      if (renderer) {
        const m = new StandarMaterial();
        m.name = entity.name;
        m.albedo = panorama_texture!;
        m.transparent = true;
        m.cullface = Constants.BACK;
        renderer!.material = m;
      }
      const position = this.entity.transform.position;
      entity.transform.position = [position[0], position[1] + 0.2, position[2]];
      entity.transform.localScale = [0.3, 0.3, 0.3];
      this.videoSpotEntity = entity;
    }
  }

  onEnter() {
    this._inProgress = true;
    this._time = 0;
  }


  /**
    * 创建常态时点位纹理
    */
  async creatNormalSpotTexture(){
    const texture = await Loader.LoadTexture2D(SpotPrivate.Config.spot.normalSpotTexture, true) as Texture2D;
    this._normalSpotTexture = texture
    this._tempNoramlUrl = SpotPrivate.Config.spot.normalSpotTexture;
  }

  /**
    * 创建激活时点位纹理
    */
  async creatActiveSpotTexture(){
    const texture = await Loader.LoadTexture2D(SpotPrivate.Config.spot.activeSpotTexture, true) as Texture2D;
    this._ActiveSpotTexture = texture
    this._tempActiveUrl = SpotPrivate.Config.spot.activeSpotTexture;
  }

  async onExit() {
    this._inProgress = false;
    this._trans.localScale = this.scale;
    this.material.color = this.color;
    if(SpotPrivate.Config.spot.normalSpotTexture){
      this.material.albedo = this._normalSpotTexture
    }
  }
  /**
     * 可选的参数包，如果指定，则按指定的参数跳转
     * @param param
     * param 应该是一个数组，按如下格式存放
     * ~~~
     * [
     *   forwardDirection:vec3,
     *   fov:number,
     *   duration:number
     * ]
     * ~~~
     */
  async onClick() {
    //跳转到该点
    MessageCenter.JumpEvent.emit('toSpot', this.entity.name, this.upwardDirection, this.forwardDirection, this.opticalCenter);
    ConsoleLog.log("spot onClick JumpEvent emit", this.entity.name);
  }

  public showNeighbor() {
    if (this._neighbors) {
      for (const neighbour of this._neighbors) {
        const render = neighbour.entity.getComponentByType<Renderer>(Renderer);
        if (!render) { return }
        render.isActive = true;
      }
    }
  }

  public hideNeighbor() {
    if (this._neighbors) {
      for (const neighbour of this._neighbors) {
        const render = neighbour.entity.getComponentByType<Renderer>(Renderer);
        if (!render) { return }
        render.isActive = false;
      }
    }
  }

  /**
     * 组件销毁时，一并销毁监听事件handler
     */
  dispose() {
    if (this._collider) {
      this._collider.triggerEvent.off('click', this._clickHandler);
    }
    MessageCenter.JumpEvent.off('finish', this._finishHandler);
  }

  async update(deltaTime: number, inputContext: InputContext) {
    if (this.disableUpdate) {
      return
    }
    if(this._tempNoramlUrl !== SpotPrivate.Config.spot.normalSpotTexture){
      this.creatNormalSpotTexture()
    }
    if(this._tempActiveUrl !== SpotPrivate.Config.spot.activeSpotTexture){
      this.creatActiveSpotTexture()
    }
    if (!this._inProgress && !this.videoSpotEntity) return;
    this._time += deltaTime * 10;
    this.rotate += deltaTime * 100;

    if (this._time >= 1) {
      this._time = 1;
      this._inProgress = false;
    }
    if (this.videoSpotEntity) { // 视频spot点旋转
      this.videoSpotEntity.transform.setEulerAngles([0, this.rotate, 0]);
    } else {
      // 全景spot点闪烁,不设置或者只设置normalSpotTexture的时候才闪烁
      const ifFlashSpot = (!SpotPrivate.Config.spot.normalSpotTexture && !SpotPrivate.Config.spot.activeSpotTexture) || (SpotPrivate.Config.spot.normalSpotTexture && !SpotPrivate.Config.spot.activeSpotTexture) || (SpotPrivate.Config.spot.normalSpotTexture === SpotPrivate.Config.spot.activeSpotTexture)
      if(ifFlashSpot){
        this.material.color = vec4.lerp(vec4.create(), this.color, this.enterColor, this._time);
      }
      if(SpotPrivate.Config.spot.normalSpotTexture && SpotPrivate.Config.spot.activeSpotTexture){
        this.material.albedo = this._ActiveSpotTexture
      }
      if(!SpotPrivate.Config.spot.activeSpotTexture && SpotPrivate.Config.spot.normalSpotTexture){
        this.material.albedo = this._normalSpotTexture
      }
      const ratio = Math.sin(this._time * this._mag);
      this._trans.localScale = vec3.lerp(vec3.create(), this.scale, this.enterScale, ratio);
    }

  }
}
