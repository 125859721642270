import { MessageCenter } from "@sdkCore/GameLogic/MessageCenter";
import { PanoramState } from '@sdkCore/Behaviors/jumpAnimation/InterpolatorStates/PanoramaState'
import uuid from 'uuid-browser/v4.js'
import * as PIXI from 'pixi.js'
import { BehaviorSubject } from 'rxjs'
import { vec3 } from "gl-matrix"
import { Viewer } from "@ali/tidejs";

export enum SurveyDrawAction {
  start = 'SurveyDrawPluginStart',
  end = 'SurveyDrawPluginEnd'
}

interface LinePointData {
  position: vec3,
  cancel: boolean
}

enum Mode {
  'edit' = 'edit',
  'new' = 'new'
}
export default class SurveyDraw {
  private _addListener: any = null
  private _moveListener: any = null
  public currentLine: BehaviorSubject<any> = new BehaviorSubject([])
  public movePosition: BehaviorSubject<any> = new BehaviorSubject([])
  // 鼠标监听事件: click
  public action = new BehaviorSubject(SurveyDrawAction.end)
  public lines = new Map()


  static containerId: string = 'survey-draw-canvas'
  app: any | null = null
  pluginApp: PIXI.Application | null = null
  private _tempKey: any
  private _tempStartPoint: any
  private _tempEndPoint: any
  private _tempLine: any
  private _tempRatio: number | null = null
  private _lineContainers: Map<string, PIXI.Container> = new Map()
  private _currentLineContainer: PIXI.Container | null = null
  private _currentStartPoint: PIXI.Container | null = null
  public _currentEndPoint: PIXI.Container | null = null
  private _animationFrameId: number | null = null
  private _currentLine: PIXI.Container | null = null
  private _selectedContainer: PIXI.Container | null = null
  private _editPointType: null | string = null
  mode: Mode.new | Mode.edit = Mode.new

  constructor() {
    this._initPixi()
    this._createReaction()
  }

  private _initPixi() {
    const container = document.querySelector(`#${SurveyDraw.containerId}`)
    if (container) {
      container.parentNode?.removeChild(container)
    }
    this.pluginApp = new PIXI.Application({
      transparent: true,
      antialias: true,
      resolution: 1,
      resizeTo: window
    })
    this.pluginApp.view.id = SurveyDraw.containerId
    this.pluginApp.view.style.position = 'fixed'
    this.pluginApp.view.style.top = '0px'
    this.pluginApp.view.style.bottom = '0px'
    this.pluginApp.view.style.left = '0px'
    this.pluginApp.view.style.right = '0px'
    this.pluginApp.view.style.pointerEvents = 'none'
    document.body.appendChild(this.pluginApp.view)
  }

  updateMode(mode: Mode.new | Mode.edit) {
    this.mode = mode
    if (mode === Mode.new) {
      this._editPointType = null
      this.pluginApp!.view.style.pointerEvents = 'none';
      [...this._lineContainers.values()].forEach(container => {
        container.off('mouseup')
        container.children.forEach(item => {
          item.interactive = false
          item.buttonMode = false
          item.off('mousedown')
          item.off('mouseup')
          item.off('mousemove')
          item.off('click')
        })
        this.resetLineContainers()
      })
      this._animationFrameId = requestAnimationFrame(this._computeLinePosition.bind(this));
    } else {
      this.pluginApp?.view.addEventListener('mouseup', () => {
        if (!this._tempRatio) {
          this._editPointType = null
          return
        }
        this._updateWorldPosition(this._tempKey, this._editPointType!, this._tempStartPoint, this._tempEndPoint, this._tempLine, this._tempLine.children[0])
        this._tempRatio = null
        this._editPointType = null
        this._tempKey = null
        this._tempEndPoint = null
        this._tempStartPoint = null
        this._tempLine = null
      })
        ;[...this._lineContainers.entries()].forEach(entry => {
          const key = entry[0]
          const container = entry[1]
          container.interactive = true;
          let startPoint: PIXI.Graphics, endPoint: PIXI.Graphics, line: PIXI.Graphics
          container.children.forEach((item: any) => {
            if (item.name === 'startPoint') {
              startPoint = item
            }
            if (item.name === 'endPoint') {
              endPoint = item
            }
            if (item.name === 'line') {
              line = item
            }
          })
          startPoint!.interactive = true
          endPoint!.interactive = true
          startPoint!.buttonMode = true
          endPoint!.buttonMode = true

          startPoint!.on('click', (event: PIXI.InteractionEvent) => {
            this._clickHandler(container)
          })
          endPoint!.on('click', (event: PIXI.InteractionEvent) => {
            this._clickHandler(container)
          })
          startPoint!.on('mousedown', (event: PIXI.InteractionEvent) => {
            this._editPointType = 'startPoint'
            this._tempKey = key
            this._tempStartPoint = startPoint
            this._tempEndPoint = endPoint
            this._tempLine = line
          })
          startPoint!.on('mousemove', (event: PIXI.InteractionEvent) => {
            if (this._selectedContainer !== container) {
              return
            }
            if (this._editPointType === 'startPoint') {
              if (Math.abs(event.data.global.x - startPoint.x) < 40 && Math.abs(event.data.global.y - startPoint.y) < 40) {
                const newPosition = this._getNewPosition(key, startPoint, endPoint, event)
                startPoint.x = newPosition.x
                startPoint.y = newPosition.y
                const ratio = this._createVirtualPosition(startPoint, endPoint, line, line.children[0])
                this._tempRatio = ratio
                this._clickHandler(container)
              } else {
                this._editPointType = null
              }
            }
          })
          startPoint!.on('mouseup', (event: PIXI.InteractionEvent) => {
            if (!this._tempRatio) {
              return
            }
            this._updateWorldPosition(key, 'startPoint', startPoint, endPoint, line, line.children[0])
            this._tempRatio = null
          })
          endPoint!.on('mousedown', (event: PIXI.InteractionEvent) => {
            this._editPointType = 'endPoint'
            this._tempKey = key
            this._tempStartPoint = startPoint
            this._tempEndPoint = endPoint
            this._tempLine = line
          })
          endPoint!.on('mouseup', (event: PIXI.InteractionEvent) => {
            if (!this._tempRatio) {
              return
            }
            this._updateWorldPosition(key, 'endPoint', startPoint, endPoint, line, line.children[0])
            this._tempRatio = null
          })
          endPoint!.on('mousemove', (event: PIXI.InteractionEvent) => {
            if (this._selectedContainer !== container) {
              return
            }
            if (this._editPointType === 'endPoint') {
              if (Math.abs(event.data.global.x - endPoint.x) < 40 && Math.abs(event.data.global.y - endPoint.y) < 40) {
                const newPosition = this._getNewPosition(key, startPoint, endPoint, event)
                endPoint.x = newPosition.x
                endPoint.y = newPosition.y
                const ratio = this._createVirtualPosition(startPoint, endPoint, line, line.children[0])
                this._tempRatio = ratio
                this._clickHandler(container)
              } else {
                this._editPointType = null
              }
            }
          })
        })
      this.pluginApp!.view.style.pointerEvents = 'auto'
    }
  }
  private _createVirtualPosition(startPoint: any, endPoint: any, line: any, text: any) {
    const originStartPosition = startPoint.originPosition
    const originEndPosition = endPoint.originPosition
    const oldLength = Math.pow(Math.pow(originEndPosition.x - originStartPosition.x, 2) + Math.pow(originEndPosition.y - originStartPosition.y, 2), 0.5)
    const newStartPosition = startPoint.position
    const newEndPosition = endPoint.position
    const newLength = Math.pow(Math.pow(newEndPosition.x - newStartPosition.x, 2) + Math.pow(newEndPosition.y - newStartPosition.y, 2), 0.5)
    const ratio = newLength / oldLength
    text.text = `${(line.originWorldLength * ratio).toFixed(2)}`
    text.zIndex = 100
    text.x = (newStartPosition.x + newEndPosition.x) / 2 - 11
    text.y = (newStartPosition.y + newEndPosition.y) / 2 - 10
    if (Math.abs(newStartPosition.x - newEndPosition.x) < 40 && Math.abs(newStartPosition.y - newEndPosition.y) < 40) {
      text.visible = false
    } else {
      text.visible = true
    }
    return ratio
  }

  private _updateWorldPosition(key: string, type: string, startPoint: any, endPoint: any, line: any, text: any) {
    const originline = this.lines.get(key)
    const { width, height } = this.pluginApp!.view!.getBoundingClientRect()
    const { points } = originline
    const worldStartPoint = points[0]
    const worldEndPoint = points[1]
    let newWorldEndPoint = worldEndPoint
    let newWorldStartPoint = worldStartPoint
    const viewer = Viewer
    // 每次都需要重新计算screenZ
    const startPointScreenZ = viewer.GetNDCPositionFromWorld(worldStartPoint)[2]
    const endPointScreenZ = viewer.GetNDCPositionFromWorld(worldEndPoint)[2]
    if (type === 'endPoint') {
      const newEndPointScreenZ = (endPointScreenZ - startPointScreenZ) * this._tempRatio! + startPointScreenZ
      newWorldEndPoint = viewer.GetWorldPositionFromNDC(vec3.fromValues(2 * endPoint.x / width - 1, 1 - 2 * endPoint.y / height, newEndPointScreenZ))
      originline.points = [worldStartPoint, newWorldEndPoint as any]
      Object.assign(endPoint, {
        originPosition: {
          x: endPoint.x,
          y: endPoint.y
        }
      })
    } else {
      const newStartPointScreenZ = (startPointScreenZ - endPointScreenZ) * this._tempRatio! + endPointScreenZ
      newWorldStartPoint = viewer.GetWorldPositionFromNDC(vec3.fromValues(2 * startPoint.x / width - 1, 1 - 2 * startPoint.y / height, newStartPointScreenZ))
      originline.points = [newWorldStartPoint, worldEndPoint]
      Object.assign(startPoint, {
        originPosition: {
          x: startPoint.x,
          y: startPoint.y
        }
      })
    }
    originline.length = this.computeDistance(key)
    text.text = `${(originline.length).toFixed(2)}`
    // 更新存储的值
    Object.assign(line, {
      originWorldLength: originline.length
    })
  }
  private _getNewPosition(key: string, startPoint: PIXI.Graphics, endPoint: PIXI.Graphics, event: PIXI.InteractionEvent) {
    const mousePosition = {
      x: event.data.global.x,
      y: event.data.global.y
    }
    const dx = startPoint.x - endPoint.x
    const dy = startPoint.y - endPoint.y
    let u = (mousePosition.x - startPoint.x) * (startPoint.x - endPoint.x) + (mousePosition.y - startPoint.y) * (startPoint.y - endPoint.y)
    u = u / (dx * dx + dy * dy)
    return {
      x: startPoint.x + u * dx,
      y: startPoint.y + u * dy,
    }
  }

  private resetLineContainers(container?: PIXI.Container) {
    // 重置所有画面上的颜色
    this._selectedContainer = null;
    [...this._lineContainers.values()].forEach(itemContainer => {
      let startPoint, endPoint, line
      if (container && itemContainer === container) {
        return
      }
      itemContainer.children.forEach(item => {
        if (item.name === 'startPoint') {
          startPoint = item
        }
        if (item.name === 'endPoint') {
          endPoint = item
        }
        if (item.name === 'line') {
          line = item
        }
      });
      ; (line as any).lineStyle(2, 0xff6a00).moveTo((startPoint as any).x, (startPoint as any).y).lineTo((endPoint as any).x, (endPoint as any).y)
        ; (startPoint as any).beginFill(0xff6a00);
      ; (startPoint as any).drawCircle(0, 0, 4);
      ; (startPoint as any).endFill();
      ; (endPoint as any).beginFill(0xff6a00);
      ; (endPoint as any).drawCircle(0, 0, 4);
      ; (endPoint as any).endFill();
    })
  }

  private _clickHandler(container: PIXI.Container) {
    // 重置所有画面上的颜色
    this.resetLineContainers(container)
    this._selectedContainer = container
    let startPoint, endPoint, line
    container.children.forEach(item => {
      if (item.name === 'startPoint') {
        startPoint = item
      }
      if (item.name === 'endPoint') {
        endPoint = item
      }
      if (item.name === 'line') {
        line = item
      }
    });
    ; (startPoint as any).beginFill(0xFFFFFF);
    ; (startPoint as any).drawCircle(0, 0, 4);
    ; (startPoint as any).endFill();
    ; (endPoint as any).beginFill(0xFFFFFF);
    ; (endPoint as any).drawCircle(0, 0, 4);
    ; (endPoint as any).endFill();
    ; (line as any).clear()
      ; (line as any).lineStyle(2, 0xFFFFFF).moveTo((startPoint as any).x, (startPoint as any).y).lineTo((endPoint as any).x, (endPoint as any).y)
  }

  private _createReaction() {
    const viewer = Viewer;
    const { width, height } = this.pluginApp!.view!.getBoundingClientRect()
    this.action.subscribe(value => {
      if (value === SurveyDrawAction.start) {
        this.pluginApp!.view.style.display = 'block'
      } else {
        this.pluginApp!.view.style.display = 'none'
      }
    })
    this.currentLine.subscribe(value => {
      if (value.length === 0) {
        if (this._currentLineContainer) {
          this.pluginApp!.stage.removeChild(this._currentLineContainer)
        }
        const keys = [...this.lines.keys()]
        keys.forEach(key => {
          if (this._lineContainers.has(key)) {
            return
          } else {
            this._lineContainers.set(key, this._currentLineContainer!)
            this.pluginApp!.stage.addChild(this._currentLineContainer!)
            this._currentLineContainer = new PIXI.Container()
            this._currentStartPoint = null
            this._currentEndPoint = null
            this.pluginApp!.stage.addChild(this._currentLineContainer)
          }
        })
      } else if (value.length === 1) {
        this._currentLineContainer = new PIXI.Container()
        this.pluginApp!.stage.addChild(this._currentLineContainer)
        const startScreenPosition = viewer.GetNDCPositionFromWorld(value[0])
        const point = new PIXI.Graphics()
        point.beginFill(0xff6a00);
        point.drawCircle(0, 0, 4);
        point.endFill();
        point.name = 'startPoint'
        point.x = (startScreenPosition[0] + 1) * width / 2
        point.y = (1 - startScreenPosition[1]) * height / 2
        this._currentStartPoint = point
        Object.assign(point, {
          originPosition: {
            x: point.x,
            y: point.y
          }
        })
        this._currentLineContainer?.addChild(point)
      } else if (value.length === 2) {
        const endScreenPosition = viewer.GetNDCPositionFromWorld(value[1])
        const point = new PIXI.Graphics()
        point.beginFill(0xff6a00);
        point.drawCircle(0, 0, 4);
        point.endFill();
        point.name = 'endPoint'
        point.x = (endScreenPosition[0] + 1) * width / 2
        point.y = (1 - endScreenPosition[1]) * height / 2
        Object.assign(point, {
          originPosition: {
            x: point.x,
            y: point.y
          }
        })
        this._currentEndPoint = point
        this._currentLineContainer?.addChild(point)
      }
    })
    this.movePosition.subscribe(value => {
      if (value.length > 0 && this._currentStartPoint) {
        if (this._currentLine) {
          this._currentLineContainer?.removeChild(this._currentLine)
          this._currentLine = null
        }
        const startPosition = this._currentStartPoint.position
        const moveScreenPosition = viewer.GetNDCPositionFromWorld(value)
        const line = new PIXI.Graphics()
        line.name = 'line'
        const movePosition = {
          x: (moveScreenPosition[0] + 1) * width / 2,
          y: (1 - moveScreenPosition[1]) * height / 2
        }
        line.lineStyle(2, 0xff6a00).moveTo(startPosition.x, startPosition.y).lineTo(movePosition.x, movePosition.y)
        if (Math.abs(movePosition.x - startPosition.x) > 40 || Math.abs(movePosition.y - startPosition.y) > 40) {
          const length = this.computeDistance()
          const text = new PIXI.Text(`${this.computeDistance().toFixed(2)}`)
          Object.assign(line, {
            originWorldLength: length,
          })
          text.x = (movePosition.x + startPosition.x) / 2 - 11
          text.y = (movePosition.y + startPosition.y) / 2 - 10
          text.zIndex = 100
          text.width = 22
          text.height = 20
          line.addChild(text)
        }
        this._currentLineContainer?.addChild(line)
        this._currentLine = line
      }
    })
  }

  private _computeLinePosition() {
    if (this.action.getValue() === SurveyDrawAction.end || this.mode === Mode.edit) {
      return
    }
    const viewer = Viewer
    const { width, height } = this.pluginApp!.view!.getBoundingClientRect();
    [...this.lines.keys()].forEach((key: string) => {
      const container = this._lineContainers.get(key)
      const points = this.lines.get(key).points
      const startPosition = points[0]
      const endPosition = points[1]
      const startScreenPosition = viewer.GetNDCPositionFromWorld(startPosition)
      const endScreenPosition = viewer.GetNDCPositionFromWorld(endPosition)
      if (Math.abs(startScreenPosition[2]) > 1 || Math.abs(endScreenPosition[2]) > 1) {
        container!.visible = false
      } else {
        container!.visible = true
      }
      let startPoint, endPoint, line
      container!.children.forEach(item => {
        if (item.name === 'startPoint') {
          startPoint = item
          item.x = (startScreenPosition[0] + 1) * width / 2
          item.y = (1 - startScreenPosition[1]) * height / 2
          Object.assign(item, {
            originPosition: {
              x: item.x,
              y: item.y
            }
          })
        } else if (item.name === 'endPoint') {
          endPoint = item
          item.x = (endScreenPosition[0] + 1) * width / 2
          item.y = (1 - endScreenPosition[1]) * height / 2
          Object.assign(item, {
            originPosition: {
              x: item.x,
              y: item.y
            }
          })
        } else if (item.name === 'line') {
          line = item;
          ; (item as any).clear();
          ; (item as any).lineStyle(2, 0xff6a00).moveTo((startScreenPosition[0] + 1) * width / 2, (1 - startScreenPosition[1]) * height / 2).lineTo((endScreenPosition[0] + 1) * width / 2, (1 - endScreenPosition[1]) * height / 2)
        }
      });
      if ((line as any).children.length) {
        const text = (line as any).children[0]
        text.x = ((startPoint as any)!.x + (endPoint as any)!.x) / 2 - 11
        text.y = ((startPoint as any)!.y + (endPoint as any)!.y) / 2 - 10
      }
    })
    requestAnimationFrame(this._computeLinePosition.bind(this))
  }
  start() {
    // 确定当前实在全景模式下，才可以开启start
    if (this._addListener) {
      MessageCenter.GlobalEvent.off('click', this._addListener)
    }
    if (this._moveListener) {
      MessageCenter.GlobalEvent.off('move', this._moveListener)
    }
    this.action.next(SurveyDrawAction.start)
    PanoramState._surveyDrawAction = SurveyDrawAction.start
    this._addListener = this.addPoint.bind(this)
    this._moveListener = ((data: LinePointData) => {
      if (this.action.getValue() === SurveyDrawAction.end) {
        return
      }
      if (this.currentLine.getValue().length === 1) {
        const { position } = data
        this.movePosition.next(position)
      }
    }).bind(this)
    MessageCenter.GlobalEvent.on('click', this._addListener)
    MessageCenter.GlobalEvent.on('move', this._moveListener)
    this.pluginApp!.view.style.display = 'block'
    this._animationFrameId = requestAnimationFrame(this._computeLinePosition.bind(this))
  }

  end() {
    this.currentLine.next([])
    this.movePosition.next([])
    this.action.next(SurveyDrawAction.end)
    PanoramState._surveyDrawAction = SurveyDrawAction.end
    if (this._addListener) {
      MessageCenter.GlobalEvent.off('click', this._addListener)
    }
    if (this._moveListener) {
      MessageCenter.GlobalEvent.off('move', this._moveListener)
    }
    this.pluginApp!.view.style.display = 'none'
    this._animationFrameId && cancelAnimationFrame(this._animationFrameId)
  }

  public async addPoint(data: LinePointData) {
    if (this.action.getValue() === SurveyDrawAction.end) {
      return
    }
    const { position, cancel } = data
    // 鼠标右键取消
    if (cancel) {
      this.currentLine.next([])
      return
    }
    // 鼠标左键点击
    if (this.currentLine.getValue().length < 2) {
      this.currentLine.next([...this.currentLine.getValue(), position])
    }
    if (this.currentLine.getValue().length >= 2) {
      // 计算每条边的长度
      this.lines.set(uuid(), {
        points: this.currentLine.getValue(),
        length: this.computeDistance()
      })
      this.currentLine.next([])
      this.movePosition.next([])
    }
  }

  public computeDistance(targetKey?: string): number {
    if (targetKey && !this.lines.has(targetKey)) {
      return 0
    }
    const currentLine = targetKey ? this.lines.get(targetKey).points : this.currentLine.getValue()
    let result = null
    const startPoint = currentLine[0]
    const endPoint = targetKey ? currentLine[currentLine.length - 1] : this.movePosition.getValue()
    if (!endPoint || !endPoint.length) {
      return 0
    }
    let baseNum = 0
    new Array(3).fill(0).forEach((item, index) => {
      const temp = Number((endPoint[index] - startPoint[index]).toFixed(3))
      baseNum += Math.pow(temp, 2)
    })
    result = Math.pow(baseNum, 0.5)
    if (targetKey) {
      this.lines.get(targetKey).length = result
    }
    return result
  }

  public updateLine(targetKey: string, points: vec3[]) {
    if (!targetKey) {
      return
    }
    const currentLine = this.lines.get(targetKey)
    currentLine.points = points
    this.computeDistance(targetKey)
  }

  install(app: any, options: any) {
    this.app = app;
    const sceneLoadEnd = app.lifeHooks.sceneLoadEnd;
    const update = app.lifeHooks.update

    app.lifeHooks.sceneLoadEnd = async () => {
      sceneLoadEnd(); // 必须调用
    }
    app.lifeHooks.update = (deltaTime: any, inputContext: any) => {
      update(deltaTime, inputContext); // 必须调用
    }
  }
}
