import { CSS2DElement } from "../../../../Components/Css2DElement";
import { Entity, SceneManager } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import { IAnnotationConfigItem, SDKElementType } from "../../../../api/elements/interface";
import { Element } from "../../../../lib/utils/eca";
import { BaseElement } from "../../../../lib/utils/eca";
import { TransitionEventParams } from "../../../../api/transition/lib/interface";
import { ConsoleLog } from "../../../../lib/log";
import { Application } from "../../../../Application";
import { SpotPrivate } from "../../../../api/spot/lib/private";
import { SkyElementPrivate } from "./private";
import { Utils } from "../../../../lib/utils";
import { ElementsManager } from "../../../../api/elements/ElementsManager";

export const ImageHTMLPrefix = "css2d-skyElement-";
function createElement(id: string, url: string, width: number, height: number){
  const dom = document.createElement("div");
  dom.style.pointerEvents = "auto";
  dom.style.cursor = "pointer";

  const image = new Image(width,height,);
  image.src = Utils.autoAddProtocol(url);
  image.id = ImageHTMLPrefix + id;
  image.draggable = false;
  image.style.userSelect = "none";
  dom.style.display = "flex";
  dom.appendChild(image);
  return dom;
}
const isSpotName = (spotId: string) => {
  return spotId !== 'normal' && spotId !== 'top';
};
function build(item: IAnnotationConfigItem){
  const skyAnnotation = Application.getInstance<SkyElementPrivate>(SkyElementPrivate.ModuleName);
  const { id ,anchor, info } = item;
  const { screenHeight = 30, height = 50, width = 100  } = info;
  const plane: Entity = new Entity(id);

  const scene = SceneManager.GetInstance().currentScene;

  const container = createElement(id, info.src, width/height * screenHeight, screenHeight);

  container.addEventListener("mouseenter",()=>{
    skyAnnotation.event.emit("enter", id);
  })
  container.addEventListener("mouseleave",()=>{
    skyAnnotation.event.emit("exit", id);
  })
  container.addEventListener("click",()=>{
    skyAnnotation.event.emit("click", id);
    (ElementsManager as any).getInstance().event.emit("click", id);
  })

  plane.transform.position = vec3.fromValues(anchor.x,  anchor.z, -anchor.y);

  scene.addEntity(plane);

  const element = new SkyElement(plane, item);
  const com: CSS2DElement = element.addComponent(CSS2DElement);
  com.elementNode = container;
  com.elementNode.id = id;
  com.preAwake();
  const skyElementAPI = Application.getInstance<SkyElementPrivate>(SkyElementPrivate.ModuleName);
  skyElementAPI.addElement(element);
  return element;
}
@Element({
  type: SDKElementType.sky,
  build,
})
export class SkyElement extends BaseElement {

  public isDetailShow = false;
  public isShow = true;
  public type: string;
  public sdkType: SDKElementType;
  public elementConfig: IAnnotationConfigItem;
  public info: any;

  get position(){
    return this.entity.transform.position;
  }

  get imageHTML(){
    return document.getElementById(ImageHTMLPrefix + this.id) as HTMLImageElement;
  }

  get label() {
    const image = this.imageHTML;
    return image.src;
  }

  constructor( entity: Entity, item: IAnnotationConfigItem ){
    super(entity, item);
    const { info, type } = item;
    this.type = type;
    this.sdkType = SDKElementType.sky;
    this.info = info;
    this.elementConfig = item;
    const SpotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);

    if(isSpotName(SpotAPI.getFirstSpotId())){
      ConsoleLog.log("SkyElement","constructor","sky not isSpotName hide", this.id);
      this.deactivate();
    }
  }
  onTransition(event: TransitionEventParams){
    const { state, to } = event;
    if(!state || !to){ return }
    if(!event.to){ return }
    ConsoleLog.log("SkyElement","onTransition", event);

    if (event.state === 'AfterTransition') {
      if ((event.to.spotId === 'normal')) {
        ConsoleLog.log("SkyElement","onTransition"," 跳转到 normal 视图，设置可见性",this.id, true);
        this.activate();
      }
    }
    if (event.state === 'BeforeTransition') {
      if (isSpotName(event.to.spotId)) {
        ConsoleLog.log("SkyElement","onTransition","跳转到 全景 视图，设置可见性",this.id, false);
        this.deactivate();
      }
      if (event.to.spotId === 'top') {
        ConsoleLog.log("SkyElement","onTransition"," 跳转到 top 视图，设置可见性",this.id, false);
        this.activate();
      }
    }
  }

  dispose(){
    super.dispose();
    ConsoleLog.log("SkyElement","dispose",this.id);
  }
  highlight(color: number[]){
    const image = document.getElementById(ImageHTMLPrefix + this.id);
    if(!image){ return }
    image.style.backgroundColor = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }
  unhighlight(){
    const image = document.getElementById(ImageHTMLPrefix + this.id);
    if(!image){ return }
    image.style.backgroundColor = "";
  }

  /**
     * 移动标签
     * @param position
     */
  moveTo(position: vec3) {
    this.entity.transform.position = position;
  }

  /**
     * 设置标签的位置
     * @param anchor
     */
  public setPositon(anchor: any) {
    this.entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
  }

  /**
     * 设置标签的图案
     * @param url
     * @returns
     */
  public setLabel(url: string) {
    const image = this.imageHTML;
    if(!image){ return }
    image.src = Utils.autoAddProtocol(url);
  }

  /**
     * 改变标签dom的pointerEvents属性
     * @param value
     * @returns
     */
  public changePointerEvents(value: string) {
    const container = document.getElementById(this.id);
    if(!container){ return }
    container.style.pointerEvents = value;
  }
}
