import { Application } from "../../../Application";
import { vec3 } from "gl-matrix";
import { ConsoleLog, ErrorType } from "../../../lib/log";
import { APIBaseClass } from "../../../lib/utils/eca";
import { API } from "../../../lib/utils/eca";
import { defaultMouseNormal } from "../../../assets/icon";

export interface IConfig{
  default: {
    firstScene: string,
    directionIn: number[],
  },
  scenes : {
    [name: string]: {
      hotSpots: string[],
      spotPose: {
        visible: boolean,
        opticalCenter: [number , number ,number],
        upwardDirection: [number , number ,number],
        groundCoord: [number , number ,number],
        forwardDirection: [number , number ,number],
      }
    }
  }
}
// 负责sdk 整个的config

function checkConfigJson(config: IConfig){
  let isSuccess = true;
  if(!config || !config.default || !config.scenes){
    isSuccess = false;
  } else if(!config.default.firstScene){
    isSuccess = false;
  } else {
    const firstScene = config.default.firstScene;
    if(firstScene!== 'normal' && !config.scenes[firstScene]){
      isSuccess = false;
    }
  }
  if(!isSuccess){
    ConsoleLog.error("configAPI","checkConfigJson", ErrorType.DataError, "config.json 加载失败");
    throw new Error("Config.json 文件错误，错误类型：数据源错误");
  }
}
function checkConfigOrtho(config: any){
  let isSuccess = false;
  if(config && config.images){
    const images = config.images;
    if(images[0] && images[0].actual_bbox && images[0].bbox){
      const actual_bbox = images[0].actual_bbox;
      const bbox = images[0].bbox;
      if(actual_bbox.length === 6 && bbox.length === 4){
        isSuccess = true;
      }
    }
  }
  if(!isSuccess){
    ConsoleLog.error("configAPI","checkConfigOrtho", ErrorType.DataError, "orthomap.json 加载失败");
    throw new Error("orthomap.json 文件错误，错误类型：数据源错误");
  }
}
/**
 * [[include: ./src/api/config/lib/Config.md]]
 */
@API()
export class ConfigPrivate extends APIBaseClass{

  static ModuleName = "configAPI";

  static Config = {
    mouseImage: defaultMouseNormal,
    enablePierce: false, // 是否允许穿墙
    path: "config.json",
    orthomapPath: "orthomap/orthomap.json",
    modelPath: "model/dense_trim_tex.xyz",
    multiFloor: false // 多楼层标识🎀
  }
  private config: any = undefined;
  private orthomapConfig: any = undefined;

  /**
     * @ignore
     * 拉取 config.json
     * @returns
     */
  public async loadConfig(){
    try {
      ConsoleLog.info("configAPI","loadConfig","config.json 开始加载");
      const path = ConfigPrivate.Config.path;
      const config = await Application.Config.configResolver.resolve(path);
      ConsoleLog.info("configAPI","loadConfig","config.json 加载完毕");
      checkConfigJson(config);
      if(config.multiFloor && config.multiFloor.floorNumber > 1){ // 多楼层判断
        ConfigPrivate.Config.multiFloor = true;
      }
      this.config = config;
      return config;
    } catch (error) {
      ConsoleLog.error("configAPI","loadConfig", ErrorType.DataError, "config.json 加载失败", error);
      throw new Error("config.json 加载失败" + error);
    }
  }
  /**
     * 获取 config.json 内容
     * @returns
     */
  public getConfig(){
    return this.config!;
  }
  /**
     * @ignore
     * 拉取 orthomap.json 户型图数据
     * @returns
     */
  public async fetchOrthomapConfig(){
    try {
      ConsoleLog.info("configAPI","fetchOrthomapConfig","orthomap.json 加载完毕");
      const orthomapPath = ConfigPrivate.Config.orthomapPath;
      const config = await Application.Config.configResolver.resolve(orthomapPath);
      ConsoleLog.info("configAPI","fetchOrthomapConfig","orthomap.json 加载完毕");
      checkConfigOrtho(config);
      this.orthomapConfig = config;
      return config;
    } catch (error) {
      ConsoleLog.error("configAPI","checkConfigJson", ErrorType.DataError, "orthomap.json 加载失败", error);
      throw new Error("orthomap.json 加载失败" + error);
    }
  }
  /**
     * 获取 orthomap.json 户型图数据内容
     * @returns
     */
  public getOrthomapConfig(){
    return this.orthomapConfig;
  }
  /**
     * 从户型图数据中获取 bbox
     * @param floorId 楼层id
     * @returns
     */
  public getBoundingBox(floorId?: string | undefined){
    const curFloorData = this.orthomapConfig.images.find((floorData: any) => floorData.floor === floorId);
    let bbox, bboxMax, bboxMin;
    if(ConfigPrivate.Config.multiFloor && curFloorData) { // 默认获取一楼的bbox
      bbox = curFloorData.actual_bbox
      bboxMax = vec3.fromValues(bbox[3], bbox[5], bbox[4]);
      bboxMin = vec3.fromValues(bbox[0], bbox[2], bbox[1]);
    } else {
      bbox = this.orthomapConfig.images[0].actual_bbox;
      bboxMax = vec3.fromValues(bbox[3], bbox[5], bbox[4]);
      bboxMin = vec3.fromValues(bbox[0], bbox[2], bbox[1]);
    }
    return {
      max: bboxMax,
      min: bboxMin,
    }
  }

  /**
     * 获取层高
     * @param floorId 楼层id
     */
  public getCeilZ(floorId?: string){
    const box = this.getBoundingBox(floorId);
    return box.max[1];
  }

  /**
     * 获取地面高度
     * @param floorId 楼层id
     */
  public getFloorZ(floorId?: string){
    const box = this.getBoundingBox(floorId);
    return box.min[1];
  }

  /**
   *  获取建筑高度
   * @returns
   */
  public getbuildingHeight() {
    if (ConfigPrivate.Config.multiFloor) {
      const multiFloorScene = this.config.multiFloor.floorScene;
      let buildingZ = 0;
      for (const floorId in multiFloorScene) {
        const floorInfo = multiFloorScene[floorId];
        buildingZ = buildingZ + floorInfo.height;
      }
      return buildingZ;
    } else {
      return this.getCeilZ();
    }
  }
  /**
   * 获取建筑基底
   */
  public getBuildingBase() {
    if (ConfigPrivate.Config.multiFloor) {
      const multiFloorScene = this.config.multiFloor.floorScene;
      let baseMin = 0;
      for (const floorId in multiFloorScene) {
        const floorInfo = multiFloorScene[floorId];
        if (floorInfo.base < baseMin) {
          baseMin = floorInfo.base;
        }
      }
      return baseMin;
    } else {
      return this.getFloorZ();
    }
  }

  /**
     * Disposes config private
     */
  dispose(){

  }
}
