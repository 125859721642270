import { IShader, RenderQueue, UniformType } from '@ali/tidejs';

const AnimateUVShader: IShader = {
  name: 'AnimateUVShader',
  renderQueue: RenderQueue.Transparent,

  uniform: {
    u_diffuse: { type: UniformType.Texture2D, value: undefined },
    u_color: { type: UniformType.vec4, value: [1, 1, 1, 1] },
    u_uvOffset: { type: UniformType.vec4, value: [1, 1, 0, 0] }
  },

  vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        //attribute vec3 color;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;

        varying vec2 v_texCoord;

        void main() {
            v_texCoord = texcoord;
            gl_Position = u_projection * u_view * u_model * vec4(position, 1.0);
        }
    `,

  fragmentShader: `
        precision highp float;

        uniform sampler2D u_diffuse;
        varying vec2 v_texCoord;

        uniform vec4 u_color;
        uniform vec4 u_uvOffset;
        void main() {
            gl_FragColor = texture2D(u_diffuse, v_texCoord * u_uvOffset.xy + u_uvOffset.zw) * u_color;
        }
    `
};

export default AnimateUVShader;
