import { Camera, Entity } from "@ali/tidejs"
import { CameraPrivate } from "../../../api/camera/lib/private";
import { TransitionPrivate } from "../../../api/transition/lib/private";
import { PersonGuider } from '../../../api/personGuider/lib/private'
import { Application } from "../../../Application";
import { vec3 } from "gl-matrix";
import { VGInfo } from "../VGInfo";
import { ICS, ICSStates } from "./CSInterface";
import { CSMgr } from "./CSMgr";

export class BasicCS implements ICS {
  public stateName = ''
  private transitionAPI!: TransitionPrivate;
  private cameraAPI!: CameraPrivate;
  private personGuiderAPI!: PersonGuider
  private _dir: vec3 = vec3.create();
  private _mgr: CSMgr;
  private _srcInfo: VGInfo

  protected isJump = false;

  public onFinished: BasicCS | undefined;

  constructor(_mgr: CSMgr, public camera: Camera, public entity: Entity, public srcinfo: VGInfo) {
    this._mgr = _mgr;
    this._srcInfo = srcinfo;
    this.transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    this.cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    this.personGuiderAPI = Application.getInstance<PersonGuider>(PersonGuider.ModuleName);
  }

  protected enableCameraRot(value: boolean) {
    this.cameraAPI.setCameraControlEnable(value);
  }
  protected enablePickAndJump(value: boolean) {
    this.transitionAPI.state.disableClickTransition = !value;
  }

  finish() {
    if (this.onFinished) {
      this.onFinished.changeTo();
      this.onFinished = undefined;
    }
  }
  update(): void {
  }
  exit(): void {
  }
  enter(): void {
  }
  changeTo() {
    this._mgr.changeTo(this);
  }

  lookAt() {
    if (this.stateName === ICSStates.CSLookAt) {
      const currentSpotIndex = this._srcInfo.currentPath?.currentSpotIndex
      const cameraParams = this.personGuiderAPI.roamingGroupJson.cameraParams || {}
      if (cameraParams[`index-${currentSpotIndex}`]) {
        return
      }
    }
    const p1 = this.srcinfo.entityTransform.position;
    const p2 = this.entity.transform.position;
    if (!p1 || !p2) {
      return
    }
    //摄像机是-z，因此p2 - p1
    vec3.sub(this._dir, p2, p1);
    this._dir[1] = 0;
    vec3.normalize(this._dir, this._dir);
    return this.entity.transform.setForward(this._dir, [0, 1, 0]);
  }
  // customCamera是否启动自定义相机配置，由外面配置传入
  async jumpCheck(customCamera: boolean = false) {
    const currentSpotIndex = this._srcInfo.currentPath?.currentSpotIndex
    const cameraParams = this.personGuiderAPI.roamingGroupJson.cameraParams || {}
    const transAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    if (this.isJump) return;
    if (customCamera && cameraParams[`index-${currentSpotIndex}`]) {
      const param = cameraParams[`index-${currentSpotIndex}`]
      await transAPI.transitionTo(param.locationSpotId);
      this.cameraAPI.setCameraFov(param.fov)
      const direction = param.directionIn
      const currentDir = this.cameraAPI.getCameraThetaPhiForAnnotation(param.locationSpotId)
      await this.cameraAPI.rotateCamera({
        theta: currentDir[0],
        phi: currentDir[1],
      }, {
        theta: direction[0],
        phi: direction[1],
      })
      return
    }
    const originP1 = this.srcinfo.entityTransform.position;
    const p1 = vec3.fromValues(originP1[0], 0, originP1[2])
    // 虚拟人的位置取的是地面脚的位置，为了正确计算距离，将虚拟机和摄像机的y轴都置为0
    const originP2 = this.entity.transform.position;
    const p2 = vec3.fromValues(originP2[0], 0, originP2[2])
    const dist = vec3.distance(p1, p2);
    if (dist > 4.5 || dist < 1.0) { // 1.0已经是调试后的临界值，再小的话会有虚拟人行走过程中穿相机的现象
      // 先从背面找，找不到再从正面找
      let spot = this.CalculateSpot(1);
      if (!spot) {
        spot = this.CalculateSpot(-1);
      }
      if (spot) {
        this.isJump = true;
        await transAPI.transitionTo(spot);
        this.isJump = false;
      }
    }
  }

  CalculateSpot(negative = 1): string | undefined {
    let nextCharacterMoveSpotId = this.srcinfo.getNextMoveSpotId();
    if(!nextCharacterMoveSpotId) {
      nextCharacterMoveSpotId = this.srcinfo.currentPath?.currentSpot
    }
    const dir = vec3.clone(this.srcinfo.entityTransform.forward());
    const v1 = vec3.create();
    const origtinCharacterPos = this.srcinfo.entityTransform.position;
    const characterPos = vec3.fromValues(origtinCharacterPos[0], 0, origtinCharacterPos[2] )
    const list = this.srcinfo.getAllSpot();
    const filterList = list.filter((spotItem) => { // 筛选点位必须是可见点位
      return spotItem.neighbors?.some((neighborItem) => {
        return neighborItem.name === nextCharacterMoveSpotId;
      })
    })

    if (!filterList) return undefined;
    for (const ss of filterList) {
      const originSpotPos = ss.opticalCenter;
      const spotPos = vec3.fromValues(originSpotPos[0], 0, originSpotPos[2])
      vec3.sub(v1, spotPos, characterPos);
      vec3.normalize(v1, v1);
      const d = vec3.dot(v1, dir);
      if (d > 0.9) continue; // 计算方向, 当方向重合时值为1, 这里是为了排除虚拟人行走路线上的spot点
      if (d * negative > 0) continue;
      const dis = vec3.distance(spotPos, characterPos)
      if (dis < 4.4 && dis > 1.1) {
        return ss.name;
      }
    }
    return undefined;
  }


}
