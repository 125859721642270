import { AnimatedUV } from './AnimatedUV';
/**
 * 表情管理器、维持多个AnimatedUV组件，并提供AnimatedUV切换接口
 */
export class EmotionMgr {
  public current: AnimatedUV | undefined;
  public emotionMap: Map<string,AnimatedUV> = new Map<string, AnimatedUV>();

  constructor() {
    //
  }

  public add(cname: string, uv: AnimatedUV) {
    this.emotionMap.set(cname,uv);
  }

  public changeTo(cname: string) {
    const emoj = this.emotionMap.get(cname)
    if (emoj) {
      this.stopCurrent();
      emoj.play();
      this.current = emoj;
    }
  }

  public getCnameList() {
    return Array.from(this.emotionMap.keys());
  }

  private stopCurrent () {
    if (this.current) {
      this.current.stop();
    }
  }


}
