import { Observable } from 'rxjs';

export class OSSClient {

  get endpoint() {
    return `${this.oss.options.endpoint.protocol}//${this.oss.options.bucket}.${this.oss.options.endpoint.host}/`;
  }

  accessPath = '';

  id: any;

  expiration = 0;

  oss: any;

  private _config: {
    endpoint?: Endpoint | string;
    bucket?: string;
    aliyunCredential?: AliyunCredential;
    stsToken?: StsCredential;
    id?: any;
  } = {};

  constructor(config: {
    endpoint: any;
    bucket?: string;
    id?: any;
    aliyunCredential?: AliyunCredential;
    stsToken?: StsCredential;
  }) {
    if (!config) {
      throw new Error('need config');
    }

    this._config = {};
    Object.assign(this._config, config);

    if (!this._config.aliyunCredential && !this._config.stsToken) {
      throw new Error('need either aliyunCredential or stsToken');
    }

    if (!this._config.endpoint) {
      throw new Error('need endpoint in config');
    }

    this.id = this._config.id ? this._config.id : `upload-${new Date().getTime()}-${('' + Math.random()).substring(2)}`;

    if (this._config.stsToken) {
      if(!this._config.stsToken.oss_path){ 
        throw new Error('oss path 未传入');
      }
      const bucket = this._config.stsToken.oss_path.split('/')[2];
      if (this._config.stsToken.access_key_id && this._config.stsToken.access_key_secret && this._config.stsToken.security_token) {
      } else {
        this.oss = {
          options: {
            bucket,
            endpoint: {
              protocol: 'https:',
              host: this._config.endpoint
            }
          }
        };
      }
      if (this._config.stsToken.expiration) {
        this.expiration = new Date(this._config.stsToken.expiration).getTime();
      }
      if (this._config.stsToken.oss_path) {
        this.accessPath = this._config.stsToken.oss_path.split('/').slice(3).join('/') + '/';
      }
    } else if (this._config.aliyunCredential) {
          
    } else {
      throw new Error('need aliyunCredentials or stsToken');
    }

    this.oss.options.endpoint.protocol = 'https:';
  }

  signatureUrl(object_key: string) {
    while (object_key.includes(this.endpoint)) {
      object_key = object_key.replace(this.endpoint, '');
    }
    while (object_key.includes(this.accessPath)) {
      object_key = object_key.replace(this.accessPath, '');
    }
    return this.oss.signatureUrl ? this.oss.signatureUrl(this.accessPath + object_key) : `${this.endpoint}${this.accessPath}${object_key}`;
  }

}

export interface AliyunCredential {
  accessKeyId: string;
  securetAccessKey: string;
}

export interface StsCredential {
  access_key_id?: string;
  access_key_secret?: string;
  security_token?: string;
  expiration?: Date;
  oss_path: string;
}

export interface Endpoint {
  protocol: string;
  host: string;
}


export interface IResolver<T> {
  resolve(resourcePath?: string): T | Promise<T> | Observable<T>;
}

export interface IOssParams {
  oss_path: string;
  endpoint?: string;
  access_key_id?: string;
  access_key_secret?: string;
  security_token?: string;
}

export class OssResolver implements IResolver<string> {
  get ossParams() {
    return this._ossParams;
  }

  set ossParams(value: IOssParams) {
    if (this._ossParams !== value) {
      this._ossParams = value;
      this.ossClient = new OSSClient({
        endpoint: value.endpoint || 'alibaba.com',
        stsToken: value
      });
    }
  }

  protected ossClient?: OSSClient;

  private _ossParams!: IOssParams;

  constructor(
    ossParams: IOssParams
  ) {
    this.ossParams = ossParams;
  }

  resolve(resourcePath: string): string {
    if (!this.ossClient) {
      throw new Error('please set ossParams first');
    }
    return this.ossClient.signatureUrl(resourcePath);
  }

}
