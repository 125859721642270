import { BaseState, CameraStateType } from "./BaseState";
import { SpotPrivate } from "../../../api/spot/lib/private";
import { Camera, InputContext, RayCasterMgr } from "@ali/tidejs";
import { CubeInterpolator } from "../CubeInterpolator";
import { Normal2SpotInterpolator, Normal2TopInterpolator } from "./TransformInterpolator";
import { DataCenter } from "../../../GameLogic/DataCenter";
import { Spot } from "../../spot/Spot";
import { OrbitControls } from "../../../Behaviors/OrbitControls";
import { PanoramaOpt } from "../../../Behaviors/PanoramaOpt";
import { Application } from "../../../Application";
import { CameraPrivate } from "../../../api/camera/lib/private";
import { ConfigPrivate } from '../../../api/config/lib/private';
import { TransitionPrivate, defaultActiveFloor } from "../../../api/transition/lib/private";
import { vec3 } from "gl-matrix";
import { WanHuaTongUtility } from "../../../GameLogic/WanHuaTongUtility";


export class NormalState extends BaseState{
  private _orbitControl!: OrbitControls;
  private _normal2SpotInterpolator !: Normal2SpotInterpolator;
  private _normal2Top!: Normal2TopInterpolator;
  private _spot: Spot | undefined;

  constructor(spotMgr: SpotPrivate) {
    super(spotMgr);
    this._orbitControl = Camera.MainCamera!.entity.addComponent<OrbitControls>(OrbitControls);
    this._orbitControl.isActive = false;

    this._normal2SpotInterpolator = new Normal2SpotInterpolator();
    this._normal2Top = new Normal2TopInterpolator();
  }

  //normal 进入时，激活 _orbitControl
  enter() {
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    this._orbitControl.isActive = true;
    this._orbitControl.init(cameraAPI.center, cameraAPI.size, cameraAPI.normalCameraPostion);
  }

  //离开时，注销 _orbitControl
  exit() {
    this._orbitControl.isActive = false;
  }


  async setDefault(data: any) {
    this.spotMgr.setCurrentSpotId('normal');
    const pitchAndYaw: Array<number> = data.directionIn;
    const theta = pitchAndYaw[0]; // theta
    const phi = pitchAndYaw[1]; // phi
    const panoramaOpt = Camera.MainCamera?.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (panoramaOpt) {
      panoramaOpt!.isActive = false;
    }
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    const posZ = Math.cos( this._orbitControl.theta) * Math.cos( this._orbitControl.phi);
    const posX = Math.sin( this._orbitControl.theta) * Math.cos( this._orbitControl.phi);
    const posY = Math.sin( this._orbitControl.phi);
    const tmpDirection = vec3.fromValues(-posX, -posY, -posZ); // normalCameraForward
    const distFromInitialNormalCameraPosToSceneCenter = vec3.dist(cameraAPI.center, cameraAPI.normalCameraPostion);
    Camera.MainCamera!.entity.transform.position = vec3.sub(Camera.MainCamera!.entity.transform.position, cameraAPI.center, vec3.scale(tmpDirection, tmpDirection, distFromInitialNormalCameraPosToSceneCenter));
    this._orbitControl.init(cameraAPI.center, cameraAPI.size, cameraAPI.normalCameraPostion);
    this._orbitControl.isActive = true;
    this._orbitControl.theta = theta;
    this._orbitControl.phi = phi;

    this._orbitControl.updateCameraMatrix();

    await CubeInterpolator.getInstance().setNormalDest();
  }

  /**
     * 鼠标点击事件，特定状态下，需要做不同的事情；比如，normal2top的状态下，该事件什么也不做
     */
  click(inputCtx: InputContext): void {
    if(!DataCenter.RootModelMF){ return }
    const info = RayCasterMgr.GetInstance().intersectioinEntityMesh(inputCtx,DataCenter.RootModelMF);
    if (info) {
      const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
      const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
      const activeFloor = transitionAPI.activeFloor;
      if (ConfigPrivate.Config.multiFloor && activeFloor !== defaultActiveFloor) { // 多楼层模型的单层激活状态下，只允许跳转激活的楼层
        const clickY = info.position[1];
        const activeFloorBase = configAPI.getFloorZ(activeFloor);
        const activeFloorHeight = configAPI.getCeilZ(activeFloor);
        if (clickY < activeFloorBase || clickY > activeFloorHeight) { // 点击的区域在激活楼层外则不响应
          return
        }
        this._spot = this.spotMgr.getNearestSpotByWorldPosFloor(info.position, activeFloor);
      } else {
        this._spot = this.spotMgr.getNearestSpotByWorldPosAll(info.position);
      }
      if (!this._spot) {
        return
      }
      this._spot.onClick();
    }
  }

  move(inputCtx: InputContext): void {
    return;
  }

  getState(): CameraStateType {
    return CameraStateType.normal;
  }
  /**
     * 设置目标参数
     * @param parameter Array
     * ~~~
     * 0 = type,
     * 1 = pos,
     * 2 = up,
     * 3 = forward
     * 4 = spotId
     * 5 = fov
     * 6 = rotate 是否旋转
     * ~~~
     */
  async setDest(parameter: any){
    this.targetType = parameter[0];
    // 跳转到全景图
    if (this.targetType === CameraStateType.panorama) {
      //关闭orbit控制器
      this._orbitControl.isActive = false;
      //获取跳转目标点信息
      const spotId = parameter[4];
      const hotSpotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
      const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
      const cameraSpot = hotSpotAPI.getHotSpotById(spotId);
      if(!cameraSpot){ return }

      const cameraDir = parameter[3];
      const pos = parameter[1];
      const rotate = parameter[6];

      //如果需要对齐到商品标，则rotate为true，此时，指定cameraDir
      if(rotate) {
        await this._normal2SpotInterpolator.setDest(pos,false, cameraDir);
      } else {
        await this._normal2SpotInterpolator.setDest(pos,false);
      }

      await CubeInterpolator.getInstance().setNormalAtStart(parameter[4],parameter[1]);
      const buildingBase = configAPI.getBuildingBase();
      CubeInterpolator.getInstance().setMultiFloorMaterial(buildingBase, -1);
    }
    if (this.targetType === CameraStateType.top) {
      this._orbitControl.isActive = false;
      await this._normal2Top.setdest();
    }
  }
  update(ratio: number): void {
    if (this.targetType === CameraStateType.panorama) {
      this._normal2SpotInterpolator.update(this.curveFunc(ratio));
      CubeInterpolator.getInstance().update(Math.pow(ratio, 3));
      WanHuaTongUtility.changeTransparencyOfTheWall(0.67 *(1.0 - ratio));
    }
    if (this.targetType === CameraStateType.top) {
      this._normal2Top.update(ratio);
    }
  }
}
