/** @format */

import { BaseBehavior, Camera, Component, Entity, InputContext, IOnStart, IUpdatable, SceneManager } from "@ali/tidejs";
import { mat4, vec2, vec3, vec4 } from "gl-matrix";
import EventEmitter from "eventemitter3";
import { DataCenter } from "../GameLogic/DataCenter";


/** 轨道控制，对三维场景进行缩放、平移、旋转操作,本质上改变的并不是场景,而是相机的参数 */
@Component("OrbitControls")
export class OrbitControls extends BaseBehavior implements IUpdatable, IOnStart {
  /** 是否是透视投影，目前只支持透视投影 */
  private _isPerspective = true;

  private _dampingFactor = 0.85;

  public fixPhi = false;
  public enableRotation = true;
  public enableMovement = false;
  public enableZoom = true;

  /** 旋转场景时，实际上是相机绕着target在旋转，这个参数指的是球坐标的thetea角 */
  private _theta = 0;
  //手动控制
  private enableManual = true;
  public get theta() {
    return this._theta;
  }
  public set theta(value) {
    this._theta = value;
  }
  private _deltaTheta = 0;

  /** 旋转场景时，实际上是相机绕着target在旋转，这个参数指的是球坐标的phi角 */
  private _phi = 0;
  public get phi() {
    return this._phi;
  }
  public set phi(value) {
    this._phi = value;
  }
  private _deltaPhi = 0;

  /**
     * 控制旋转的速度
     */
  private _rotateSpeed = 2.0;
  public get rotateSpeed() {
    return this._rotateSpeed;
  }
  public set rotateSpeed(value) {
    this._rotateSpeed = value;
  }
  /**
     * 控制位移的速度
     */
  private _moveSpeed = 0.2;
  public get moveSpeed() {
    return this._moveSpeed;
  }
  public set moveSpeed(value) {
    this._moveSpeed = value;
  }

  /** 相机的fov */
  private _fov = 0;

  /** 当前渲染画布canvas的高 */
  private _screenHeight: number;

  /** 当前渲染画布canvas的宽 */
  private _screenWidth: number;

  /** 场景的中心点 */
  private _target: vec3 = vec3.fromValues(0, 0, 0);
  private _deltaTarget: vec3 = vec3.fromValues(0, 0, 0);

  /** 相机中心距离场景中心点的距离 */
  private _radius = 10;

  public minRadius = 0;

  private _originMinRadius: number | null = null

  public maxRadius = 0;

  public maxPhi = Math.PI * 80.0 / 180;

  public minPhi = Math.PI * 10.0 / 180;

  private _previousInputContext = new InputContext(undefined, undefined, vec2.fromValues(-1, -1),
    [], undefined, 0, [], false);

  /**
     * 对外暴露的事件
     */
  public event = new EventEmitter<"theta_phi", string>();

  /**
     * 构造函数
     * @param entity 用于指定当前的entity
     * @param uuid 用于指定该behavior的uuid
     */
  constructor(entity: Entity, uuid?: string) {
    super(entity, uuid);
    this._screenWidth = SceneManager.GetInstance().context.viewer.getWidth();
    this._screenHeight = SceneManager.GetInstance().context.viewer.getHeight();
    OrbitControls.instance = this;
    DataCenter.OrbitControlsInstance = this;
  }

  private static instance: undefined | OrbitControls = undefined;

  public static GetInstance() {
    return this.instance;
  }

  public get target(): vec3 {
    return this._target;
  }

  public set target(value: vec3) {
    this._target = value;
  }

  public get radius(): number {
    return this._radius;
  }

  public set radius(value: number) {
    this._radius = value;
  }

  public get dampingFactor() {
    return this._dampingFactor;
  }

  public set dampingFactor(value) {
    this._dampingFactor = value;
  }
  /**
     * 向右移动target
     * @param distance 移动target的距离
     * @param left 相机向右的向量
     */
  panLeft(distance: number, left: vec3): vec3 {
    const panLeft = vec3.create();
    panLeft[0] = left[0] * distance;
    panLeft[1] = left[1] * distance;
    panLeft[2] = left[2] * distance;
    return panLeft;
  }

  /**
     * 向上移动target
     * @param distance 移动target的距离
     * @param up 相机向上的向量
     */
  panUp(distance: number, up: vec3): vec3 {
    const panUp = vec3.create();
    panUp[0] = up[0] * distance;
    panUp[1] = up[1] * distance;
    panUp[2] = up[2] * distance;
    return panUp;
  }

  /**
     * 移动target，右和下为正，左上角为原点
     * @param deltaX 屏幕空间在X方向的变化量，以像素为单位
     * @param deltaY 屏幕空间在Y方向的变化量，以像素为单位
     */
  pan(deltaX: number, deltaY: number): vec3 {
    if (this._isPerspective) {
      // perspective
      const cameraPos = this.entity.transform.position;
      let targetDistance = vec3.distance(this.target, cameraPos);

      // half of the fov is center to top of screen
      targetDistance *= Math.tan((this._fov / 2) * Math.PI / 180.0);
      const forwardDir = vec3.subtract(vec3.create(), this.target, cameraPos);
      const yDir = vec3.fromValues(0, 1, 0);
      // const yDir = vec3.fromValues(0, 0, 1);
      const leftDir = vec3.cross(vec3.create(), yDir, forwardDir);
      const upDir = vec3.cross(vec3.create(), forwardDir, leftDir);
      vec3.normalize(leftDir, leftDir);
      vec3.normalize(upDir, upDir);

      const deltaRight = this.panLeft(this.moveSpeed * 2 * deltaX * targetDistance / this._screenWidth, leftDir);
      const deltaUp = this.panUp(this.moveSpeed * 2 * deltaY * targetDistance / this._screenHeight, upDir);
      const panOffset = vec3.create();
      vec3.add(panOffset, deltaRight, deltaUp);
      return panOffset;
    } else {
      return vec3.fromValues(0, 0, 0);
    }
  }

  onStart(): void {
    const camera = this.entity.getComponentByType<Camera>(Camera);
    if (camera) {
      this._fov = camera.fov;

      // this.updateCameraMatrix();
    }
  }

  updateCameraMatrix(): void {
    // get camera position
    const posZ = this._radius * Math.cos(this._theta) * Math.cos(this._phi);
    const posX = this._radius * Math.sin(this._theta) * Math.cos(this._phi);
    const posY = this._radius * Math.sin(this._phi);

    const cameraPos = vec3.add(vec3.create(), this._target, vec3.fromValues(posX, posY, posZ));
    // const cameraPos = vec3.add(vec3.create(), this.target, vec3.fromValues(posZ, posX, posY));
    const newCameraMatrix = mat4.targetTo(mat4.create(), cameraPos, this._target, vec3.fromValues(0, 1, 0));
    // const newCameraMatrix = mat4.targetTo(mat4.create(), cameraPos, this.target, vec3.fromValues(0, 0, 1));
    this.entity.transform.setLocalToWorldMatrix(newCameraMatrix);
  }

  private _sceneSize = 0;
  /**
     * 获取当前相机姿态对应的theta和phi值
     */
  public init(center: vec3, size: vec3, normalCameraPostion: vec3): void {
    const worldMatrix = Camera.MainCamera!.entity.transform.localToWorldMatrix();
    const forwardHomo = vec4.transformMat4(vec4.create(), vec4.fromValues(0, 0, -1, 0), worldMatrix);
    const forward = vec3.fromValues(forwardHomo[0], forwardHomo[1], forwardHomo[2]);

    this._theta = Math.atan2(-forward[0], -forward[2]);
    this._phi = Math.atan2(-forward[1], Math.sqrt(forward[0] * forward[0] + forward[2] * forward[2]));

    this._target = vec3.clone(center);
    this._sceneSize = vec3.len(size);

    this.minRadius = 0.5 * vec3.dist(normalCameraPostion, center);
    this.maxRadius = 4 * this.minRadius;

    this._radius = vec3.dist(this.entity.transform.position, this._target);
    this.updateCameraMatrix();

  }

  private centerMatrix: mat4 | undefined;
  private _srcTheta = 0;
  private _endTheta = 0;
  private _time = 0;
  private _interval = 0;

  // 支持外部传入最近的相机距离比例
  setMinRadius(ratio: number) {
    if(ratio > 1) {
      return
    }
    if(ratio < 0.4) {
      return
    }
    if(!this._originMinRadius) {
      this._originMinRadius = this.minRadius
    }
    this.minRadius = ratio * this._originMinRadius
  }

  /**
     * 移动到
     * @param duration 持续时间
     * @param target
     */
  async rotateTo(target: vec3, duration = 0.5) {
    return new Promise(resolve => {
      this.enableManual = false;

      this._interval = 1 / duration;
      this._time = 0;
      this._srcTheta = this._theta;
      if (this._srcTheta < 0) {
        this._srcTheta += 2 * Math.PI;
      }

      this._endTheta = Math.atan2(-target[0], -target[2])
      if (this._endTheta < 0) {
        this._endTheta += 2 * Math.PI;
      }

      const offset = Math.abs(this._endTheta - this._srcTheta);
      if (offset > Math.PI) {
        if (this._srcTheta < this._endTheta) {
          this._endTheta -= 2 * Math.PI;
        } else {
          this._srcTheta -= 2 * Math.PI;
        }
      }
      this.rotateToCallbalk = () => {
        this.enableManual = true;
        resolve(true);
      }
    });
  }
  private rotateToCallbalk!: () => void;




  update(deltaTime: number, inputContext: InputContext): void {
    if (!this.enableManual) {
      this._time += deltaTime * this._interval;
      let ratio = 1 - this._time * this._time;
      ratio = 1 - ratio * ratio * ratio;
      if (ratio >= 1) {
        ratio = 1;
      }
      this._theta = this._srcTheta * (1 - ratio) + this._endTheta * (ratio);
      this.updateCameraMatrix();
      if (ratio === 1) {
        this.rotateToCallbalk();
      }
      return
    }

    if (this.entity && this.isActive) {
      if (inputContext.isResize) {
        this._screenWidth = SceneManager.GetInstance().context.viewer.getWidth();
        this._screenHeight = SceneManager.GetInstance().context.viewer.getHeight();
      }

      let ratio = 1.0;
      if (this.enableZoom && inputContext.touchedNum === 2 && this._previousInputContext.touchedNum === 2) {
        const touchDistance = vec2.dist(inputContext.touchedPosition[0], inputContext.touchedPosition[1]);
        const previousTouchDistance = vec2.dist(this._previousInputContext.touchedPosition[0], this._previousInputContext.touchedPosition[1]);
        ratio = previousTouchDistance / touchDistance;
        if (touchDistance > previousTouchDistance) {
          inputContext.zoom = -1.0;
        } else if (touchDistance < previousTouchDistance) {
          inputContext.zoom = 1.0;
        } else {
          inputContext.zoom = 0;
        }
      }
      if (ratio === 1.0 && inputContext.zoom !== 0.0) {
        if (inputContext.zoom > 0) {
          ratio = 1.1;
        } else {
          ratio = 0.9;
        }
      }
      let isZoomActive = false;
      if (this.enableZoom && inputContext.zoom) {
        this._radius = ratio * this._radius;
        this._radius = Math.max(this._radius, this.minRadius);
        this._radius = Math.min(this._radius, this.maxRadius);
        isZoomActive = true;
      }
      // rotate
      if (this.enableRotation) {
        if ((inputContext.leftDown === true && this._previousInputContext.leftDown === true) ||
                    (inputContext.rightDown === true && this._previousInputContext.rightDown === true)) {
          const deltaPosition = vec2.subtract(vec2.create(), inputContext.position, this._previousInputContext.position);

          const screenSize = Math.min(this._screenWidth, this._screenHeight);
          const angleHeight = 180 - 0.5 * this._fov;
          const angleWidth = 180 - 0.5 * this._fov * this._screenWidth / this._screenHeight;
          this._deltaTheta = -1.0 / this._dampingFactor * deltaPosition[0] / screenSize * (angleWidth / 180.0) * Math.PI;
          this._deltaPhi = 1.0 / this._dampingFactor * deltaPosition[1] / screenSize * (angleHeight / 180.0) * Math.PI;

          // this._deltaTheta = -1.0 / this._dampingFactor * deltaPosition[0] / this._screenWidth * (angleWidth / 180.0) * Math.PI;
          // this._deltaPhi = 1.0 / this._dampingFactor * deltaPosition[1] / this._screenHeight * (angleHeight / 180.0) * Math.PI;
        }
      }
      // move: change both the camera position and target position
      if (this.enableMovement && inputContext.rightDown === true && this._previousInputContext.rightDown === true && inputContext.leftDown === false) {
        const deltaPosition = vec2.subtract(vec2.create(), inputContext.position, this._previousInputContext.position);
        const offset = this.pan(deltaPosition[0], deltaPosition[1]);
        this._deltaTarget = offset;
      }

      const isZoomDampingOngoing = isZoomActive;
      const isRotateDampingOngoing = Math.abs(this._deltaTheta) > 0.001 || Math.abs(this._deltaPhi) > 0.001
      const isPaningDampingOngoing = vec3.squaredLength(this._deltaTarget) > 0.00001;

      if (isRotateDampingOngoing) {
        this._deltaTheta *= this._dampingFactor;
        this._theta += this._deltaTheta;
        if (this._theta > Math.PI * 2) {
          this._theta -= Math.PI * 2;
        } else if (this._theta < -Math.PI * 2) {
          this._theta += Math.PI * 2;
        }
        if (!this.fixPhi) {
          this._deltaPhi *= this._dampingFactor;
          this._phi += this._deltaPhi;
          this._phi = Math.min(this._phi, this.maxPhi);
          this._phi = Math.max(this._phi, this.minPhi);
        }
      }

      if (isPaningDampingOngoing) {
        vec3.scale(this._deltaTarget, this._deltaTarget, this._dampingFactor);
        vec3.add(this.target, this.target, this._deltaTarget);
      }

      if (isPaningDampingOngoing || isRotateDampingOngoing || isZoomDampingOngoing) {
        this.updateCameraMatrix();
        // todo : 这里也改为四个，实现在normal视图下的相机同步
        this.event.emit("theta_phi", [this._theta, this._phi]);
      }


    }
    this._previousInputContext = inputContext;
  }

  public dispose(): void {
  }
}
