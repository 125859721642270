import { mat3, vec3, vec4 } from "gl-matrix";
import { Subject, Observable } from "rxjs"
import { throttleTime } from 'rxjs/operators';
import { ConfigPrivate } from "../../../api/config/lib/private";
import { Camera } from "@ali/tidejs";
import { PanoramaOpt } from "../../../Behaviors/PanoramaOpt";
import { OrbitControls } from "../../../Behaviors/OrbitControls";
import EventEmitter from "eventemitter3";
import { WanHuaTongUtility } from "../../../GameLogic/WanHuaTongUtility";
import { ConsoleLog } from "../../../lib/log";
import { Application } from "../../../Application";
import { APIBaseClass } from "../../../lib/utils/eca";
import { SpotPrivate } from '../../../api/spot/lib/private';
import { get } from 'lodash'
import { CameraAutoRotate } from "../../../Behaviors/CameraAutoRotate";
/**
 *
 * [[include: ./src/api/camera/lib/Camera.md]]
 *
 */
export class CameraPrivate extends APIBaseClass {
  static ModuleName = "cameraAPI";

  static Config = {
    panoramaViewFov: 60,
    normalViewFov: 60,
  }
  /**
     * @private
     * Normal camera postion of camera private
     */
  public normalCameraPostion = vec3.create();
  private normalCameraForward = vec3.create();

  private topRotationMatrix = mat3.create();
  /**
     * @private
     * Top angle of camera private
     */
  public topAngle = 0;
  /**
     * @private
     * Normal angle of camera private
     */
  public normalAngle = 0;

  private bboxMin = vec3.create();
  private bboxMax = vec3.create();
  /**
     * @private
     * Center  of camera private
     * 不能是000
     */
  public center = vec3.fromValues(1, 1, 1);
  /**
     * @private
     * Size  of camera private
     */
  public size = vec3.create();

  // top view parameters
  private topViewFov = 30;
  /**
     * @private
     * Top forward of camera private
     */
  public topForward = vec3.create();

  /**
     * @private
     * Y ratio of camera private
     */
  public yRatio = 0.5;

  /**
     * @private
     * Top view height of camera private
     */
  public topViewHeight = 0;

  /**
     * 和 setCameraParams 搭配使用，实现相机多端同步，
     * 参数是新SDK的数据，不兼容老数据
     */
  public cameraParamsChange = new EventEmitter<"theta_phi" | "fov">()

  /**
     * @private
     * SDK 内部相机旋转事件
     */
  cameraControlSubject: Subject<[number, number]> = new Subject();
  /**
   * 相机旋转事件
   *
   * @type {Observable<[number, number]>}
   * @memberof CameraAPI
   */
  // readonly cameraRotationObserve: Subject<[number, number]> = new Subject();
  cameraRotationObserve: Observable<[number, number]> = this.cameraControlSubject.pipe(throttleTime(100));

  AppInitEnd(){
    this.addEvent();
  }

  get position() {
    return Camera.MainCamera!.entity.transform.position;
  }
  set position(pos: vec3) {
    Camera.MainCamera!.entity.transform.position = pos;
  }

  /**
     * 添加事件
     */
  private addEvent() {
    // 全景视角变化
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    panoramaOpt?.event.on("theta_phi", (params) => {
      const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
      const currentSpotId = spotAPI.getCurrentSpotId();
      let [resTheta, resPhi] = this.getCameraThetaPhiForAnnotation(currentSpotId);
      resTheta = resTheta > 0 ? resTheta : resTheta + 2 * Math.PI; // 为了兼容老中台的theta取值 [0, 2 * Math.PI]
      this.cameraControlSubject.next([resTheta, resPhi]);
      this.cameraParamsChange.emit("theta_phi", params)
    })
    panoramaOpt?.event.on("fov", (params) => {
      this.cameraParamsChange.emit("fov", params)
    })

    // 轨道视角变化
    const OrbitOpt = Camera.MainCamera!.entity.getComponentByType<OrbitControls>(OrbitControls);
    OrbitOpt?.event.on("theta_phi", (params) => {
      // 为了保证normal视图下的theta、phi和全景图一致，还是使用同样的相机方法
      const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
      const defaultConfig = configAPI.getConfig();
      const defaultSpot = get(defaultConfig, 'default.firstScene');
      let [resTheta, resPhi] = this.getCameraThetaPhiForAnnotation(defaultSpot);
      resTheta = resTheta > 0 ? resTheta : resTheta + 2 * Math.PI; // 为了兼容老中台的theta取值 [0, 2 * Math.PI]
      this.cameraControlSubject.next([resTheta, resPhi]);
      // todo : 这里也改为四个，实现在normal视图下的相机同步
      // this.cameraParamsChange.emit("theta_phi", params)
    })
  }

  /**
   * 获取朝向设置方向，兼容老数据
   * @param spotId
   * @returns [theta,phi]
   */
  public getCameraThetaPhiForConfig(spotId: string) {
    const worldMatrix = Camera.MainCamera!.entity.transform.localToWorldMatrix();
    const forwardHomo = vec4.transformMat4(vec4.create(), vec4.fromValues(0, 0, -1, 0), worldMatrix);

    const forward = vec3.fromValues(forwardHomo[0], forwardHomo[1], forwardHomo[2]);
    const [theta, phi] = WanHuaTongUtility.getOldSDKConfigThetaPhiFromOurDirection(forward, spotId);
    return [phi, theta];
  }

  /**
   * 获取全景图中通用相机朝向，兼容老数据
   * @param spotId
   * @returns [theta,phi]
   */
  public getCameraThetaPhiForAnnotation(spotId: string) {
    const worldMatrix = Camera.MainCamera!.entity.transform.localToWorldMatrix();
    const forwardHomo = vec4.transformMat4(vec4.create(), vec4.fromValues(0, 0, -1, 0), worldMatrix);
    const forward = vec3.fromValues(forwardHomo[0], forwardHomo[1], forwardHomo[2]);
    const [theta, phi] = WanHuaTongUtility.getOldSDKConfigThetaPhiFromOurDirection(forward, spotId);
    const [resTheta, resPhi] = WanHuaTongUtility.thetaPhiToPitchYaw(theta, phi, spotId);
    // resPhi的值在 [-0.5 * Math.PI, 1.5 * Math.PI]
    return [resPhi, resTheta];
  }
  /**
     * 获取当前与目标处的theta phi
     * @param target vec3
     * @param spotId string
     * @returns
     */
  public getCameraThetaPhiFromTarget(pos: vec3, spotId: string){
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    if(!spotId){
      spotId = spotAPI.getCurrentSpotId()
    }
    const currentSpot = spotAPI.getHotSpotById(spotId);
    if(!currentSpot){ return }
    const currentPos = currentSpot.opticalCenter;
    const forward = vec3.sub(vec3.create(),pos, currentPos)
    const [theta, phi] = WanHuaTongUtility.getOldSDKConfigThetaPhiFromOurDirection(forward, spotId);
    const [resTheta, resPhi] = WanHuaTongUtility.thetaPhiToPitchYaw(theta, phi, spotId);
    // resPhi的值在 [-0.5 * Math.PI, 1.5 * Math.PI]
    return [resPhi, resTheta];
  }

  /**
     * @private
     * 根据户型图初始化相机normal位置
     * @returns
     */
  public initFromOrthomapConfig() {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName)
    const orthomapConfig = configAPI.getOrthomapConfig();
    if (!orthomapConfig) { return }
    const bbox = orthomapConfig.images[0].actual_bbox;
    const orthoMapBbox = orthomapConfig.images[0].bbox;
    this.yRatio = 0.6 * (orthoMapBbox[3] - orthoMapBbox[1]) / 550.0;
    // this.bboxMax = vec3.fromValues(bbox[3], bbox[5], bbox[4]);
    // this.bboxMin = vec3.fromValues(bbox[0], bbox[2], bbox[1]);
    // this.center = vec3.scale(vec3.create(), vec3.add(vec3.create(), this.bboxMin, this.bboxMax), 0.5);
    this.bboxMax = vec3.fromValues(bbox[3], bbox[4], bbox[5]);
    this.bboxMin = vec3.fromValues(bbox[0], bbox[1], bbox[2]);
    this.center = vec3.fromValues(0.5 * (this.bboxMax[0] + this.bboxMin[0]), 0.5 * (this.bboxMax[2] + this.bboxMin[2]), -0.5 * (this.bboxMax[1] + this.bboxMin[1]));
    this.size = vec3.sub(vec3.create(), this.bboxMax, this.bboxMin);
    this.topViewHeight = this.size[1];

    this.setNormalEndPositionWithGivenBoundingBox();
    this.setTopViewParameters();
  }

  private setTopViewParameters(): void {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName)
    const orthomapConfig = configAPI.getOrthomapConfig();
    if (!orthomapConfig) { return }
    this.topForward = vec3.transformMat3(vec3.create(), vec3.fromValues(0, 0, -1), this.topRotationMatrix);

    this.topAngle = Math.atan2(-this.topForward[0], -this.topForward[2]);
    this.topAngle = this.topAngle + orthomapConfig.rotationAngle / 180.0 * Math.PI;

    if (this.topAngle < 0) {
      this.topAngle = this.topAngle + 2 * Math.PI;
    }
    if (this.topAngle > 2 * Math.PI) {
      this.topAngle = this.topAngle - 2 * Math.PI;
    }
  }
  private setNormalEndPositionWithGivenBoundingBox() {
    this.normalCameraPostion = vec3.fromValues(this.bboxMin[0] - (this.bboxMax[0] - this.bboxMin[0]) * 0.6, this.bboxMin[1] + (this.bboxMax[1] - this.bboxMin[1]) * 1.6, -this.bboxMin[2] - (this.bboxMax[2] - this.bboxMin[2]) * 1.6);

    const initalForward = vec3.normalize(vec3.create(), vec3.sub(vec3.create(), this.center, this.normalCameraPostion));
    const theta = Math.atan2(initalForward[0], initalForward[2]);
    const phi = Math.atan2(initalForward[1], Math.sqrt(initalForward[0] * initalForward[0] + initalForward[2] * initalForward[2]));

    const posZ = Math.cos(theta) * Math.cos(phi);
    const posX = Math.sin(theta) * Math.cos(phi);
    const posY = Math.sin(phi);
    this.normalCameraForward = vec3.fromValues(-posX, -posY, -posZ);
    this.normalAngle = Math.atan2(this.normalCameraForward[0], this.normalCameraForward[2]);
    if (this.normalAngle < 0) {
      this.normalAngle = this.normalAngle + 2 * Math.PI;
    }
  }
  /**
     * 设置是否允许用户操作全景图中的相机
     * @param bool
     * @returns
     */
  public setCameraControlEnable(bool: boolean) {
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) { return }
    ConsoleLog.log("cameraAPI", "setCameraControlEnable", bool);
    panoramaOpt.isActive = bool
  }
  /**
     * 设置相机是否可以缩放
     * @param bool
     * @returns
     */
  public setCameraZoomEnable(bool: boolean){
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) { return }
    panoramaOpt.enableZoom = bool;
  }
  /**
     * 设置相机是否可以旋转
     * @param bool
     * @returns
     */
  public setCameraRotateEnable(bool: boolean){
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) { return }
    panoramaOpt.enableRotation = bool;
  }
  /**
   * 相机同步功能，返回当前相机的参数，内部theta phi，fov 参数，不兼容之前的数据
   * @returns [theta, phi, fov]
   */
  public getCameraParams() {
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) { return [0, 0, 0] }
    return panoramaOpt.getCameraParams();
  }
  /**
     * 相机同步功能，设置当前Fov
     * @param fov
     * @returns
     */
  public setCameraFov(fov: number) {
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) { return }
    return panoramaOpt.setCameraFov(fov);
  }
  /**
     * 相机同步功能，设置当前theta phi。这是新数据，不兼容老数据
     * @param theta
     * @param phi
     * @returns
     */
  public setCameraThetaPhi(theta: number, phi: number) {
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) { return }
    return panoramaOpt.setCameraThetaPhi(theta, phi);
  }
  /**
     * 和 cameraParamsChange 搭配使用，实现相机多端同步
     * 与 setCameraThetaPhi 的区别是，可以实现拖尾的效果
     * @param theta
     * @param phi
     * @param fov
     * @param c
     * @returns
     */
  public setCameraParams(theta: number, phi: number, thetaDelta: number, phiDelta: number) {
    const panoramaOpt = Camera.MainCamera!.entity.getComponentByType<PanoramaOpt>(PanoramaOpt);
    if (!panoramaOpt) { return }
    return panoramaOpt.setCameraParams(theta, phi, thetaDelta, phiDelta);
  }

  /**
     * 旋转摄像机，
     * @param from {theta: number, phi: number, fov?: number}
     * @param to {theta: number, phi: number, fov?: number}
     * @param [duration]
     * @returns
     */
  public rotateCamera(from: any, to: any, duration = 1){
    if(!from || !to){ return }
    const com: CameraAutoRotate = Camera.MainCamera!.entity.addComponent(CameraAutoRotate);
    com.duration = duration;
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const getOurThetaPhi = (theta: number, phi: number) => {
      const currentSpotID = spotAPI.getCurrentSpotId();
      return WanHuaTongUtility.getOurThetaPhiFromAreaAnnotation(phi, theta, currentSpotID);
    }
    const currentFov = this.getCameraParams()[2];
    {
      const {theta, phi, fov = currentFov} = from;
      const [theta1, phi1] = getOurThetaPhi(theta, phi);
      com.from.theta = theta1;
      com.from.phi = phi1;
      com.from.fov = fov;
    }
    {
      const {theta, phi, fov = currentFov } = to;
      const [theta1, phi1] = getOurThetaPhi(theta, phi);
      com.to.theta = theta1;
      com.to.phi = phi1;
      com.to.fov = fov;
    }
    com.start();
    return new Promise(resolve => com.event.once("end", resolve));
  }

  /**
     * 获取normal视图下相机的theta、phi
     */
  getNormalCameraThetaPhi() {
    const orbitOpt = Camera.MainCamera!.entity.getComponentByType<OrbitControls>(OrbitControls);
    return [orbitOpt?.theta, orbitOpt?.phi]
  }
  setOrbitMinRadius(minRatio = 1) {
    const OrbitOpt = Camera.MainCamera!.entity.getComponentByType<OrbitControls>(OrbitControls);
    OrbitOpt?.setMinRadius(minRatio)
  }
  /**
     * 设置normal视图下相机的theta、phi
     */
  setNormalCameraThetaPhi(theta: number, phi: number) {
    const orbitOpt = Camera.MainCamera!.entity.getComponentByType<OrbitControls>(OrbitControls);
    if (!orbitOpt) {
      return
    }
    orbitOpt.theta = theta;
    orbitOpt.phi = phi
    orbitOpt.updateCameraMatrix();
  }
  dispose() {

  }
}
