export enum TransitionState {
  beforeTransition = 'BeforeTransition',
  afterTransition = 'AfterTransition' }

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface TransitionProcessParams {
  spotId: string;
}

export class TransitionEventParams {

  constructor(
    public state: TransitionState,
    public from?: TransitionProcessParams,
    public to?: TransitionProcessParams,
  ) { }

  /**
   * 表示是否正在跳进全景
   */
  get isFlyIn() {
    return !this.from && Boolean(this.to);
  }

  /**
   * 表示是否正在跳出全景
   */
  get isFlyOut() {
    return !this.to && Boolean(this.from);
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface TransitionParams {
  /**
   * 单位：秒
   */
  duration?: number;
  easeFunc?: any;
  cameraAnimation?: CameraAnimationProps;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface CameraAnimationProps {
  /**
   * 拉近拉远相机动画参数
   */
  zoom?: number;

  fov?: number;

  /**
   * 垂直方向旋转相机动画参数
   */
  phi?: number;

  /**
   * 水平方向旋转相机动画参数
   */
  theta?: number;

  positionX?: number;
  positionY?: number;
  positionZ?: number;
}
