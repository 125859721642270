import { ElementsManager } from "../../../../api/elements/ElementsManager";
import EventEmitter from "eventemitter3";
import { LineElement } from "./LineElement";
import { getVisibleSpotsByPosition, hasIncludeSpots } from "../../../../api/elements/lib/util";
import { ElementAPIBaseClass } from "../../../../lib/utils/eca";
import { API } from "../../../../lib/utils/eca";
import { SDKElementType } from "../../../../api/elements/interface";
import { Texture2D, Loader } from "@ali/tidejs";
import { defaultLineTagEditorNormal, defaultLineTagEditorHighlight } from "../../../../assets/icon";

interface IState {
  allLineElementVisible: boolean
}
/**
 * 长线标签的 Api
 */
@API()
export class LineElementPrivate extends ElementAPIBaseClass<LineElement,IState> {
  static ModuleName = "lineElementAPI";
  static Config = {
    maxDistance: 50,
    defaultLineLength : 0.5,
    maxDepth: 0.2,
    imageSize: 32,
    unHightLightEditorImage: defaultLineTagEditorNormal,
    hightLightEditorImage: defaultLineTagEditorHighlight,
  }
  public hightLightEditorTex: undefined | Texture2D = undefined
  public unHightLightEditorTex: undefined | Texture2D = undefined
  /**
     * @private
     */
  public elementType = SDKElementType.line;

  /**
     * @event
     * enter: 编辑的时候鼠标进入了底座
     * exit: 编辑的时候鼠标离开了底座
     * Event  of line element private
     */
  public event = new EventEmitter<"enter" | "exit", string>();
  /**
     * @private
     * Hight light map of line element private
     */
  public hightLightMap = new Map<string, LineElement>();
  public state = {
    allLineElementVisible: false
  }

  async AppInitEnd(){
    this.initVisibleSpots();
    this.hightLightEditorTex = await Loader.LoadTexture2D(LineElementPrivate.Config.hightLightEditorImage);
    this.unHightLightEditorTex = await Loader.LoadTexture2D(LineElementPrivate.Config.unHightLightEditorImage);
  }
  async initVisibleSpots(){
    const elements = this.getAllElements();
    for(const element of elements){
      if(!hasIncludeSpots(element.elementConfig.includeSpots)){
        element.elementConfig.includeSpots = getVisibleSpotsByPosition(element.entity.transform.position);
      }
    }
  }
  /**
     * 设置所有LineElement标签可见性
     * Sets line element visible
     * @param bool
     */
  setLineElementVisible(bool: boolean){
    const elements = ElementsManager.getInstance().getElementsByType(SDKElementType.line);
    elements.forEach( value => bool?  value.activate() : value.deactivate());
    this.setState({
      allLineElementVisible: bool
    })
  }
  public dispose(){
  }
}
