import { Transform, Camera } from "@ali/tidejs";
import { mat4, quat, vec3 } from "gl-matrix";
export class BaseCameraInterpolator {
  private _camera!: Camera;
  private _trans!: Transform | undefined;

  //起始姿态
  private _srcPos!: vec3;
  // private static up: vec3;
  // private static forward: vec3;
  private _srcMatrix!: mat4;
  private _srcFov!: number;

  //末尾姿态
  private _destPos!: vec3;
  // private static endforward: vec3;
  private _destFov: number | undefined;
  private _destMatrix!: mat4;

  private _tempVec1: vec3 = vec3.fromValues(0, 0, 0);
  private _tempMat1: mat4 = mat4.create();
  private _tempQuat1: quat = quat.create();

  private srcQuat: quat = quat.create();
  private destQuat: quat = quat.create();

  private _rotate = true;
  constructor() {
    this._camera = Camera.MainCamera!;
  }

  public setdest(pos: vec3, up: vec3, forward: vec3, fov?: number) {
    if (!this._trans) {
      this._camera = Camera.MainCamera!;
      this._trans = Camera.MainCamera!.entity.transform;
    }
    //设置元数据
    this._srcMatrix = mat4.clone(Camera.MainCamera!.entity.transform.localToWorldMatrix());
    mat4.getRotation(this.srcQuat, this._srcMatrix);
    this._srcPos = vec3.clone(this._trans!.position);
    this._srcFov = this._camera.fov;
    //设置目标数据
    vec3.cross(this._tempVec1, up, forward);
    this._destMatrix = mat4.targetTo(mat4.create(), pos, vec3.add(vec3.create(), pos, forward), up);
    mat4.getRotation(this.destQuat, this._destMatrix);
    this._destFov = fov ? fov : this._srcFov;
    this._destPos = vec3.clone(pos);
  }

  private lerp(ratio: number) {
    vec3.lerp(this._tempVec1, this._srcPos, this._destPos, ratio);

    if (this._rotate) {
      //取出四元数进行插值
      quat.slerp(this._tempQuat1, this.srcQuat, this.destQuat, ratio);

      //将四元数塞回矩阵
      this._trans!.localRotation = quat.clone(this._tempQuat1);
      this._trans!.position = vec3.clone(this._tempVec1);
    } else {
      this._trans!.position = vec3.clone(this._tempVec1);
    }
  }

  public update(ratio: number, rotate = true) {
    this._rotate = rotate;
    this.lerp(ratio);
    if (this._destFov) {
      this._camera.fov = ratio * this._destFov + (1 - ratio) * this._srcFov;
    }
  }
}