import { BaseBehavior, Loader, MeshFilter, Renderer, Entity, PrimitiveType, RayCasterMgr, SceneManager, LLEFUtilities, RenderQueue, UnlitMaterial, PlantForm, Constants, Texture2D, InputContext } from "@ali/tidejs";
import { Camera, Component, IOnStart } from "@ali/tidejs";
import { DataCenter } from "../GameLogic/DataCenter";
import { JumpController } from "./jumpAnimation/JumpController";
import { CameraShake } from "./jumpAnimation/CameraShake";
import { MessageCenter } from "../GameLogic/MessageCenter";

import { SpotPrivate } from "../api/spot/lib/private";
import { Application } from "../Application";
import { ProgressReporter, ProgressAPI } from "../lib/progress/ProgressReporter";
import { ConfigPrivate } from "../api/config/lib/private";
import { TransitionPrivate } from "../api/transition/lib/private";

import { mat4, vec3 } from "gl-matrix";
import { CubeInterpolator } from "./jumpAnimation/CubeInterpolator";
import { UpdatePanorama } from "./UpdatePanorama";
import { WanHuaTongUtility, getImage } from "../GameLogic/WanHuaTongUtility";
import { CameraPrivate } from "../api/camera/lib/private";
import { performanceMark } from "../lib/utils";
import { ElementsManager } from "../api/elements/ElementsManager";
import { ConsoleLog, ErrorType } from "../lib/log";
import { TowardsNormal } from "./TowardsNormal";

(window as any).mat4 = mat4;
(window as any).vec3 = vec3;

export enum ModelType {
  wht = 'wht',
  lyj = 'lyj'
}

function createCube() {
  const cube = Entity.CreatePrimitive(PrimitiveType.Cube);
  if (!cube) { return }
  cube.transform.localScale = [50, 50, 50];
  const renderer = cube.getComponentByType<Renderer>(Renderer);
  if (!renderer) { return }
  const meshFilter = cube.getComponentByType<MeshFilter>(MeshFilter);
  if (meshFilter && meshFilter.mesh) {
    meshFilter.mesh.upLoad();
  }
  SceneManager.GetInstance().currentScene.addEntity(cube);
  return cube;
}


export function createCubeFromOrthConfig(config: any, cube: Entity, spotId?: string) {
  const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
  const configDefault = configAPI.getConfig();
  const floorNum = config?.images && config.images.length
  // 改变全景盒子的大小和位置
  const changeTransform = function(floorData: any, _cube: Entity){
    let bbox = floorData.actual_bbox;
    if (floorData.original_actual_bbox) {
      bbox = floorData.original_actual_bbox;
    }
    let x = 0;
    let y = 0;
    let z = 0;
    let sx = 50;
    let sy = 50;
    let sz = 50;
    if (bbox) {
      const min: vec3 = [bbox[0], bbox[2], -bbox[1]];
      const max: vec3 = [bbox[3], bbox[5], -bbox[4]];

      x = 0.5 * (min[0] + max[0]);
      y = 0.5 * (min[1] + max[1]);
      z = 0.5 * (min[2] + max[2]);

      sx = max[0] - min[0] + 5; // +5是为了容错，额外多放大5倍
      sy = max[1] - min[1] + 5;
      sz = bbox[4] - bbox[1] + 5; // 这里的计算取原值，否则取反之后，可能会出现负数
    }
    _cube.transform.localScale = [sx, sy, sz];
    _cube.transform.position = [x, y, z];
  }
  // 根据当前点位获得楼层
  const firstScene = spotId || configDefault.default.firstScene
  const currentFloor = configDefault.scenes[firstScene] ? configDefault.scenes[firstScene] : configDefault.scenes['p0'];
  const currentFloorData = config.images.find((data: any) => data.floor === currentFloor.floor )
  if(floorNum > 1 && currentFloorData){
    changeTransform(currentFloorData, cube);
  }else {
    changeTransform(config?.images[0], cube)
  }
}
export async function createMouseStyle(model: any, octree: any) {
  const plane = Entity.CreatePrimitive(PrimitiveType.Plane)!;
  plane.transform.localScale = [0.2, 0.2, 0.2];
  const renderer = plane.getComponentByType<Renderer>(Renderer);
  if (!renderer) { return }
  renderer.isActive = false;
  const m = new UnlitMaterial();
  m.name = 'mouseStyle';
  renderer!.material = m;
  renderer!.material!.cullface = -1;
  renderer!.material!.depthTest = false;
  renderer!.material!.depthWrite = false;
  renderer!.material!.renderQueue = RenderQueue.Transparent;
  renderer!.material!.transparent = true;

  const image = await getImage(ConfigPrivate.Config.mouseImage);
  const texture = new Texture2D(image.width, image.height);
  texture.setTextureImageSource(image, 0, true);
  texture.generateMipmaps();

  if (!texture) { return }
  texture.magFilter = Constants.LINEAR;
  texture.minFilter = Constants.LINEAR_MIPMAP_LINEAR;
  texture.anisotropic = 16;
  texture.generateMipmaps()
  renderer.isActive = true;
  const comp = plane.addComponent<TowardsNormal>(TowardsNormal);
  comp.baseMeshOctree = octree;
  comp.baseMeshEntity = model;
  (renderer!.material! as any).albedo = texture;
  SceneManager.GetInstance().currentScene.addEntity(plane);
  return comp;
}

/**
 * 这是整个游戏的入口
 * ~~~
 * 只做3件事：
 * 1，拉取 配置数据
 * 2，加载根模型数据
 * 3，添加其他基础脚本
 * ~~~
 */
@Component("GameEnter")
export class GameEnter extends BaseBehavior implements IOnStart {
  private cube: Entity | undefined;

  public dispose() {
    CubeInterpolator.getInstance().dispose();
  }
  public async onStart(): Promise<void> {
    ConsoleLog.info("GameEnter", "onStart", "iswebgl2" + LLEFUtilities.IsWebGL2())
    if (Camera.MainCamera) {
      DataCenter.RootCameraEntity = Camera.MainCamera.entity;
    }
    if (!DataCenter.RootCameraEntity) { return }
    ConsoleLog.time('load');

    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);

    // step1: 加载场景基础配置
    await configAPI.loadConfig();
    const configDefault = configAPI.getConfig();

    // step2: 初始化全景视图cube
    await this._initModelCube();

    // step3: 加载户型配置
    try {
      const config = await configAPI.fetchOrthomapConfig();
      createCubeFromOrthConfig(config, this.cube as Entity);
      await cameraAPI.initFromOrthomapConfig();
    } catch (error) {
      ConsoleLog.error("GameEnter", "onStart", ErrorType.DataError, "Orthomap.json文件不存在");
    }

    // step4: 初始化地面spot点位
    await spotAPI.init();

    // step5: 进入全景视图 or 3D视图
    if (configDefault.default.firstScene === 'normal') {
      await this._enterNormal();
    } else {
      await this._enterPanorama();
    }

  }

  /**
     * 初始进入全景视图
     */
  private async _enterPanorama() {
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    //添加，跳转控制
    ConsoleLog.info("GameEnter", "onStart", "跳转到第一个点开始");
    await transitionAPI.jumpController.gotoDefault('panorama');
    ConsoleLog.info("GameEnter", "onStart", "跳转到第一个点结束");
    ProgressReporter.onProgress.next({ progress: 50 });
    performanceMark('show-scene-end');
    ConsoleLog.timeEnd("load")
    await this._loadModelAndTexture();
  }

  /**
     * 初始进入normal视图
     */
  private async _enterNormal() {
    ProgressReporter.onProgress.next({ progress: 50 });
    await this._loadModelAndTexture();
    WanHuaTongUtility.changeTransparencyOfTheWall(0.67);
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    await transitionAPI.jumpController.gotoDefault('normal');
    transitionAPI.model.deactivate();

  }

  /**
     * 初始化全景视图cube
     * @returns
     */
  private async _initModelCube() {
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    // 获取yRatio等什么的是在这个组件添加后才执行的，在这之前设置好就行
    // DataCenter.RootCameraEntity.addComponent(PauseRender);
    const jumpController = DataCenter.RootCameraEntity!.addComponent<JumpController>(JumpController);
    transitionAPI.jumpController = jumpController;
    await jumpController.init();
    const cameraShake = DataCenter.RootCameraEntity!.addComponent<CameraShake>(CameraShake);
    transitionAPI.cameraShake = cameraShake;
    const cube = createCube();
    this.cube = cube;
    if (!cube) {
      ConsoleLog.error("GameEnter", "onStart", ErrorType.InternalError, "cube 初始化错误")
      return
    }
    transitionAPI.model = cube;
    CubeInterpolator.getInstance().setModel(cube, false);
    DataCenter.RootModelMF = cube.getComponentByType(MeshFilter)! as MeshFilter;
    if (PlantForm.IsPc()) {
      RayCasterMgr.GetInstance().asyncMode = true;
      RayCasterMgr.GetInstance().activate();
    } else {
      RayCasterMgr.GetInstance().deactivate();
    }
    ProgressReporter.onLoaded.next();
    ProgressReporter.onProgress.next({ progress: 20 });
    Application.instance.lifeHooks.appInitEnd();
  }

  /**
     * 加载模型和模型纹理
     */
  private async _loadModelAndTexture() {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const configDefault = configAPI.getConfig();
    const modelType = configDefault.type;
    // 场景初始化完毕，等待模型下载
    performanceMark('can-move-begin');
    ConsoleLog.time("canmove")
    //加载模型
    performanceMark('model-download-begin');
    const LoadXYZFunc = modelType === ModelType.lyj ? WanHuaTongUtility.LoadXYZ_v1_LYJ : WanHuaTongUtility.LoadXYZ_v1;
    const model = await LoadXYZFunc(await Application.Config.privateResourceResolver.resolve(ConfigPrivate.Config.modelPath));
    DataCenter.RootModel = model;
    ConsoleLog.info("GameEnter", "onStart", "模型下载完毕");

    const filter: MeshFilter = model.getComponentByType<MeshFilter>(MeshFilter)!;

    DataCenter.RootModelMF = model.getComponentByType(MeshFilter)! as MeshFilter;
    DataCenter.PanoramaMeshFilters.push(DataCenter.RootModelMF);
    CubeInterpolator.getInstance().setModel(model, true);
    model.transform.setLocalEulerAngles(vec3.fromValues(-90, 0, 0));
    const modelRender = model.getComponentByType<Renderer>(Renderer);
    modelRender!.isActive = false;
    performanceMark('model-download-end');
    ProgressReporter.modelLoaded.next();
    ProgressReporter.onProgress.next({ progress: 70 });
    Application.instance.lifeHooks.resourceLoadEnd();
    MessageCenter.GlobalEvent.emit('modelLoaded', 'dense_trim_tex');

    if (PlantForm.IsPc()) {
      setTimeout(() => {
        ConsoleLog.time("octree")
        filter.mesh!.buildOctree(6, 32);
        ConsoleLog.timeEnd("octree")
        DataCenter.octree = filter.mesh!.octree;
        DataCenter.trans = model.transform;
        ConsoleLog.info("GameEnter", "onStart", "Octree 构建完毕");

        const ele = ElementsManager.getInstance();
        createMouseStyle(model, filter.mesh!.octree).then((com: any) => {
          (ele as any).mouseTowardsCom = com;
        });
        if (Application.mode == "editor") {
          (ele as any).enableEditorMode(filter.mesh!.octree, model.transform);
        }
      }, 16)
    }
    ConsoleLog.info("GameEnter", "onStart", "模型纹理下载开始");
    const url = await Application.Config.publicResourceResolver.resolve("model/dense_trim_tex0.jpg");
    // wanhuatong: true, LYJ: false
    const LoadTexture2DFlipY = modelType === ModelType.lyj ? false : true;
    const modelTexture = await Loader.LoadTexture2D(url, LoadTexture2DFlipY);
    if (modelTexture) {
      modelTexture.wrapS = Constants.REPEAT; //为了消除裂缝
      modelTexture.wrapT = Constants.REPEAT;
      modelTexture.minFilter = Constants.LINEAR_MIPMAP_LINEAR;
      modelTexture.magFilter = Constants.LINEAR;
      modelTexture.generateMipmaps();
    }
    CubeInterpolator.getInstance().setModelTexture(modelTexture);
    modelRender!.isActive = true;
    ConsoleLog.info("GameEnter", "onStart", "模型纹理下载完毕");
    // 可以进行top视图等的跳转了
    performanceMark('can-move-end');
    ConsoleLog.timeEnd("canmove")
    ProgressReporter.canMove.next();
    ProgressReporter.onProgress.next({ progress: 100 });
    Application.instance.lifeHooks.sceneLoadEnd();
    UpdatePanorama.getInstance().addEventListener();
  }

  update(deltaTime: number, inputContext: InputContext) {
    if (ProgressAPI.progress >= 100) { // 在场景完全加载完成之后，再执行update钩子函数
      Application.instance.lifeHooks.update(deltaTime, inputContext);
    }
  }


}
