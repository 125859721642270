
import { Transform, Camera } from "@ali/tidejs";
import { SpotPrivate } from "../../../../api/spot/lib/private";
import { mat3, mat4, quat, vec3, vec4 } from "gl-matrix";
import { Application } from "../../../../Application";
import { CameraPrivate } from "../../../../api/camera/lib/private";


//TODO 性能可以提升，去除内存申请
export class Panorama2TopInterpolator {

  private _endCameraPosition = vec3.create();

  private _srcCenter = vec3.create();

  private _startAngle = 0;
  private _offsetAngle = 0;

  private _startTarget = vec3.create();

  private _size = 0;
  private _center = vec3.create();
  private _camera: Camera;
  private _trans: Transform;

  private _startForward = vec3.create();

  constructor() {
    this._camera = Camera.MainCamera!;
    this._trans = this._camera.entity.transform;
  }
  public setdest() {
    const worldMatrix = this._trans.localToWorldMatrix();
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    this._size = cameraAPI.topViewHeight;

    this._tmpDirectionHomo = vec4.create();
    vec4.transformMat4(this._tmpDirectionHomo, vec4.fromValues(0, 0, -1, 0), worldMatrix); // forwardHomo
    const radius = 3;
    this._startForward = vec3.fromValues(radius * this._tmpDirectionHomo[0], radius * this._tmpDirectionHomo[1], radius * this._tmpDirectionHomo[2]); // forward

    vec3.add(this._startTarget, this._trans.position, this._startForward);

    this._startAngle = Math.atan2(-this._startForward[0], -this._startForward[2]);


    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    this._srcCenter = spotAPI.currentSpot.opticalCenter;
    const nearPlaneHeight = Math.tan(CameraPrivate.Config.normalViewFov * 0.5 / 180.0 * Math.PI) * this._camera.near;
    const height = ((this._size * 0.5 / cameraAPI.yRatio) / nearPlaneHeight) * this._camera.near;

    this._endCameraPosition = vec3.fromValues(cameraAPI.center[0], cameraAPI.center[1] + height, cameraAPI.center[2]);
    this._center = cameraAPI.center;

    if (this._startAngle < 0) {
      this._startAngle = this._startAngle + 2 * Math.PI;
    }

    this._offsetAngle = cameraAPI.topAngle - this._startAngle;

    if (this._offsetAngle < -Math.PI) {
      this._offsetAngle = this._offsetAngle + 2 * Math.PI;
    }
    if (this._offsetAngle > Math.PI) {
      this._offsetAngle = this._offsetAngle - 2 * Math.PI;
    }
  }

  private _tempQuat: quat = quat.create();
  private _offsetQuat: quat = quat.create();
  private _tmpPos = vec3.create();
  private _tmpDirectionHomo = vec4.create();
  private _tmpDirection = vec3.create();
  private _tmpRotationMatrix = mat3.create();
  private _tmpTransformMatrix = mat4.create();

  public update(ratio: number): void {
    vec3.lerp(this._tmpPos, this._srcCenter, this._endCameraPosition, ratio); //currentPosition
    this._trans.position = vec3.clone(this._tmpPos);

    this._tmpDirection = vec3.fromValues(0, 1, 0); // up
    if (ratio === 1.0) {
      this._tmpDirection = vec3.clone(this._startForward);
    }

    vec3.lerp(this._tmpPos, this._startTarget, this._center, ratio); // targetPosition
    mat4.targetTo(this._tmpTransformMatrix, this._trans.position, this._tmpPos, this._tmpDirection);

    const currentAngle = this._offsetAngle * ratio;

    quat.setAxisAngle(this._offsetQuat, vec3.fromValues(0, 0, 1), currentAngle);

    quat.fromMat3(this._tempQuat, mat3.fromMat4(this._tmpRotationMatrix, this._tmpTransformMatrix)); // originQ
    quat.mul(this._tempQuat, this._tempQuat, this._offsetQuat); // fixedQ
    this._trans.localRotation = quat.clone(this._tempQuat);

    this._camera.fov = this._camera.fov * (1.0 - ratio) + CameraPrivate.Config.normalViewFov * ratio
  }
}
