import * as ECA from "./lib/utils/eca";
export { ECA };
import { ProgressReporter } from "./lib/progress/ProgressReporter";
export { ProgressAPI } from "./lib/progress/ProgressReporter";
import { Application } from "./Application";
export { Application }
export * from './Material'
export * from './GameLogic'
import { PanoVideoApp } from "./PanoVideoApp";
import { PanoImageApp } from "./PanoImageApp";
Application.mode = "runtime";

export { TransitionRuntime as TransitionAPI } from "./api/transition/runtime";
export { SpotRuntime as HotSpotAPI } from "./api/spot/runtime";

// 所有的标签---
export { Annotation as Annotation2D }  from "./api/elements/annotation2d/lib/annotation";
export { Annotation2DRuntime as Annotation2DAPI }  from "./api/elements/annotation2d/runtime";

export { MediaElement } from "./api/elements/media-element/lib/MediaElement";
export { MediaElementPrivate as MediaElementAPI } from "./api/elements/media-element/lib/private";

export { AudioElement } from "./api/elements/audio-element/lib/AudioElement";
export { AudioElementPrivate as AudioElementAPI } from "./api/elements/audio-element/lib/private";

export { SkyElement } from "./api/elements/sky-element/lib/SkyElement";
export { SkyElementPrivate as SkyElementAPI } from "./api/elements/sky-element/lib/private";

export { LineElement } from "./api/elements/line-element/lib/LineElement";
export { LineElementPrivate as LineElementAPI } from "./api/elements/line-element/lib/private";

export { HotElement } from "./api/elements/hot-element/lib/hotElement";
export { HotElementPrivate as HotElementAPI } from "./api/elements/hot-element/lib/private";

export { ElementsManager as ElementsAPI } from "./api/elements/ElementsManager";

// 功能模块---
export { CameraRuntime as CameraAPI }  from "./api/camera/runtime";

export { ConfigPrivate as ConfigAPI } from "./api/config/lib/private";

export { RoamingRuntime as RoamingAPI } from "./api/roaming/runtime";

export { PersonGuiderRuntime as PersonGuiderAPI} from "./api/personGuider/runtime";

export { PerformanceRecorderAPI } from "./api/recorder/PerformanceRecorder";
export { ActionRecorderAPI } from "./api/recorder/ActionRecorder";

export { FlyGuiderRuntime as FlyGuiderAPI } from './api/flyGuider/runtime';
export { ChassisAPI } from './api/chassis/private';
export { StrategyVisibleAPI } from "./api/elements/lib/StrategyVisCom";
export { AudioPlayManager as AudioPlayAPI } from "./lib/audio/AudioPlayManager";

export { ConsoleLog } from "./lib/log";

export * from "./api/util";

export * from "./Behaviors/UpdatePanorama";

export { ApplicationModeNormal } from "./api/mode/ApplicationModeNormal";

export const Meshviewer = {
  getApp: ()=>{
    return Application.instance;
  },
  Application,
  PanoVideoApp,
  PanoImageApp,
  ProgressReporter,
}
import { CSS2DElement } from "./Components/Css2DElement";
import { SetVisibleBySpot } from "./Components/SetVisbleBySpot";
export { PanoramaVideoRuntime as PanoramaVideoAPI  } from "./api/panovideo/runtime";

import * as Tide from "@ali/tidejs";
export { Tide }
export const Components = {
  CSS2DElement,
  SetVisibleBySpot,
}
