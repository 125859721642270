import { ShaderMaterial } from "@ali/tidejs";
import { mat3, mat4, vec2, vec3 } from "gl-matrix";
import SpotToNormalShader from "./Shader/SpotToNormalShader";

export class SpotToNormalMaterial extends ShaderMaterial {
  constructor() {
    super(SpotToNormalShader);
  }

  public get modelTexture(): WebGLTexture {
    return this._tmpUniforms["u_diffuse"].value;
  }
  public set modelTexture(value: WebGLTexture) {
    this._tmpUniforms["u_diffuse"].value = value;
  }

  public get center(): vec3 {
    return this._tmpUniforms["u_center"].value;
  }
  public set center(value: vec3) {
    this._tmpUniforms["u_center"].value = value;
  }

  public get worldToCameraMatrix(): mat3 {
    return this._tmpUniforms["u_worldToCameraMatrix"].value;
  }
  public set worldToCameraMatrix(value: mat3) {
    this._tmpUniforms["u_worldToCameraMatrix"].value = value;
  }

  public get time(): number {
    return this._tmpUniforms["u_time"].value;
  }
  public set time(time: number) {
    this._tmpUniforms["u_time"].value = time;
  }

  public get displayRange(): vec2 {
    return this._tmpUniforms["u_displayRange"].value;
  }
  public set displayRange(value: vec2) {
    this._tmpUniforms["u_displayRange"].value = value;
  }

  public get orthographicProjection(): mat4 {
    return this._tmpUniforms["u_orthographicProjection"].value;
  }
  public set orthographicProjection(orthographicProjection: mat4) {
    this._tmpUniforms["u_orthographicProjection"].value = orthographicProjection;
  }

  public get perspectiveToOrthographicTime(): number {
    return this._tmpUniforms["u_p2otime"].value;
  }
  public set perspectiveToOrthographicTime(time: number) {
    this._tmpUniforms["u_p2otime"].value = time;
  }

  public get cube(): WebGLTexture {
    return this._tmpUniforms["u_cube"];
  }
  public set cube(albedo: WebGLTexture) {
    this._tmpUniforms["u_cube"].value = albedo;
  }

  public set cube2K(albedo: WebGLTexture) {
    this._tmpUniforms["u_cubeSource2K"].value = albedo;
  }
  public get cube2K() {
    return this._tmpUniforms["u_cubeSource2K"];
  }

  public set level2k(albedo: WebGLTexture) {
    this._tmpUniforms["u_cubeSourceLevel"].value = albedo;
  }
  public get level2k() {
    return this._tmpUniforms["u_cubeSourceLevel"];
  }
}
