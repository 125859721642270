import { TransitionPrivate } from "../../../api/transition/lib/private";
import { Application } from "../../../Application";
import { TransitionState } from "../../../api/transition/lib/interface";
import { CameraPrivate } from "../../../api/camera/lib/private";
import { ElementsManager } from "../../../api/elements/ElementsManager";
import { SpotPrivate } from "../../../api/spot/lib/private";
import { vec3 } from "gl-matrix";
import { ConsoleLog } from "../../../lib/log";
import { API, APIBaseClass } from "../../../lib/utils/eca";
import { getScreenXYFromVec3 } from "../../../lib/utils/tools";

export type ElementVisibleStrategy = {
  view: 'ALL_SPOTS' | 'SOME_SPOTS' | 'ONLY_NORMAL' | 'ONLY_TOP' | 'EXPECT_PANORAMA' | 'ALL_VIEW';
  condition: Array<'IN_VIEW' | 'NEAREST_SHOW'>;
  distance?: number;
  spots?: [];
};
const visibleStrategyMap = {
  ALL_SPOTS: (spotID: string) => spotID !== 'normal' && spotID !== 'top',
  SOME_SPOTS: (toSpotID: string) => toSpotID !== 'normal' && toSpotID !== 'top',
  ONLY_NORMAL: (spotID: string) => spotID === 'normal',
  ONLY_TOP: (spotID: string) => spotID === 'top',
  TOP_NORMAL: (spotID: string) => spotID === 'normal' || spotID === 'top',
  ALL_VIEW: () => true,
};
function getElementDistance(meshPosition: vec3){
  const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
  const currentSpotID = spotAPI.getCurrentSpotId()
  const currentPosition = spotAPI.getPoseBySpotId(currentSpotID)?.pos;
  if(!currentPosition){ return 9999}
  const distance = vec3.distance(meshPosition, currentPosition) ;
  return distance;
}

@API()
export class StrategyVisibleAPI extends APIBaseClass {
  static ModuleName = "strategyVisibleAPI";

  showElementsMap = new Map();
  strategy!: ElementVisibleStrategy;

  private _visibleElementsByDistance: any[] = [];
  private _visibleElementsByNearest: any[] = [];

  SceneLoadEnd(): void {
    ConsoleLog.log("StrategyVisCom","SceneLoadEnd");
    this.addTransitionListener();
    this.addElementsLoadedListener();
    this.addCameraEventListener();
  }
  addTransitionListener() {
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);

    transitionAPI.transitionProcessObserve.subscribe((params) => {
      if(!params || !params.to || !params.from){ return }
      const toSpotID = params.to.spotId;
      const fromSpotID = params.from.spotId;
      if (params.state === TransitionState.afterTransition) {
        this.setElementsVisibility(fromSpotID, toSpotID);
      }
    });
  }
  addElementsLoadedListener() {
    const elementAPI = ElementsManager.getInstance();
    elementAPI.event.on("loaded", ()=>{
      const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
      const currentSpotID = spotAPI.getCurrentSpotId();
      this.setElementsVisibility( currentSpotID, currentSpotID);
    })
  }
  addCameraEventListener() {
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    cameraAPI.cameraRotationObserve.subscribe(() => {
      const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
      const currentSpotID = spotAPI.getCurrentSpotId();
      if (currentSpotID) {
        // 只在全景图中判断
        this.setElementsVisByCamera();
      }
    });
  }

  addShowElement(element: any) {
    if (!this.isVisByView(element)) {
      ConsoleLog.log("StrategyVisCom","addShowElement","准备显示，但视图不可见");
      return;
    }
    ConsoleLog.log("StrategyVisCom","addShowElement","mediaElement 显示", element.id);
    element.show();

  }
  addHideElement(element: any) {
    ConsoleLog.log("StrategyVisCom","addHideElement","mediaElement 隐藏", element.id);
    element.hide();
  }
  setElementsVisibility(fromSpotID: string, toSpotID: string) {
    if (toSpotID !== 'normal' && toSpotID !== 'top' && fromSpotID !== 'normal' && fromSpotID !== 'top') {
      // 先按条件显示
      this.setElementsVisByCondition();
    } else {
      this.setElementsVisByView(toSpotID);
    }
    // 再根据视图显示
  }
  /**
   * 在全景图中根据条件显示或隐藏element
   */
  setElementsVisByCondition() {
    // 先通过距离筛选出合适距离内的标签
    this.setElementVisByDistance();
    // 最后显示距离最近的点
    this.setElementVisNearest();
    // 先通过视野筛选一波可见的
    this.setElementsVisByCamera();
  }
  /**
   * 需求： 十米内最近显示几个点，显示最近的一个点
   */
  setElementVisByDistance() {
    const elementAPI = ElementsManager.getInstance();
    const maxDistance = 99;
    const elements = elementAPI.getElements().filter((item) => {
      const condition = item.info?.strategy?.visible?.condition;
      if (!condition) { return false; }
      // 不管什么类型的，都按距离筛选一遍
      return true;
    });
    this._visibleElementsByDistance = [];
    for (const element of elements) {
      let targetDistance = element.info?.strategy?.visible?.distance;
      targetDistance = targetDistance || maxDistance;
      const distance = getElementDistance(element.position);
      // 如果当前的距离小于设定的距离
      if (distance < targetDistance) {
        ConsoleLog.log("StrategyVisCom","setElementVisByDistance","未超出距离 显示", element.id);
        this.addShowElement(element);
        this._visibleElementsByDistance.push(element);
      } else {
        ConsoleLog.log("StrategyVisCom","setElementVisByDistance","超出距离 隐藏", element.id);
        this.addHideElement(element);
      }
    }
  }
  /**
   * 显示最近的标签
   * @param elements
   */
  setElementVisNearest() {
    // 已经被距离筛选过了，第二次 选出一个距离最近的标签。
    const elements = this._visibleElementsByDistance.filter((item) => {
      const condition = item.info?.strategy?.visible?.condition;
      if (!condition) { return false; }

      if (condition instanceof Array) {
        return condition.includes('NEAREST_SHOW');
      }
      return condition === 'NEAREST_SHOW';
    });

    // 选出一个距离最近的标签。
    let nearestDistance = 99;
    let nearestId = '';
    for (const element of elements) {
      const distance = getElementDistance(element.position);
      if (distance <= nearestDistance) {
        nearestId = element.id;
        nearestDistance = distance;
      }
    }
    this._visibleElementsByNearest = [];
    for (const element of elements) {
      if (element.id === nearestId) {
        this._visibleElementsByNearest.push(element);
        ConsoleLog.log("StrategyVisCom","setElementVisNearest","显示最近的一个", element.id);
        this.addShowElement(element);
      } else {
        ConsoleLog.log("StrategyVisCom","setElementVisNearest","隐藏非最近的", element.id);
        this.addHideElement(element);
        // 从范围内的标签列表中删除， 这样就确保每次只留下一个标签，满足只显示最近的标签
        const index = this._visibleElementsByDistance.indexOf(element);
        this._visibleElementsByDistance.splice(index, 1);
      }
    }
  }
  setElementsVisByCamera(): void {
    // 剩下的都是可见的，视野内显示，视野外隐藏
    const elements = this._visibleElementsByDistance.filter((item) => {
      const condition = item.info?.strategy?.visible?.condition;
      if (!condition) { return false; }

      if (condition instanceof Array) {
        return condition.includes('IN_VIEW');
      }
      return condition === 'IN_VIEW';
    });

    for (const element of elements) {
      const position = element.position;
      const [x,y,z] = getScreenXYFromVec3(position);

      if (x > -1 && x < 1 && y > -1 && y < 1 && z > -1 && z < 1) {
        ConsoleLog.log("StrategyVisCom","setElementsVisByCamera","视野内可见", element.id);
        this.addShowElement(element);
      } else {
        ConsoleLog.log("StrategyVisCom","setElementsVisByCamera","视野外隐藏", element.id);
        this.addHideElement(element);
      }
    }
  }
  // 根据当前视图类型显示
  setElementsVisByView(toSpotID: string) {
    const elementAPI = ElementsManager.getInstance();
    const getElement = (type: string) => {
      return elementAPI.getElements().filter((item) => {
        return item.info?.strategy?.visible?.view === type;
      });
    };

    for (const key in visibleStrategyMap) {
      const f = (visibleStrategyMap as any)[key];
      if(!f){ return }
      const elements = getElement(key);
      if (f(toSpotID)) {
        elements.forEach((item) => {
          ConsoleLog.log("StrategyVisCom","setElementsVisByView","视图显示", toSpotID,"  ", item.id);
          this.addShowElement(item);
        });
      } else {
        elements.forEach((item) => {
          ConsoleLog.log("StrategyVisCom","setElementsVisByView","视图隐藏", toSpotID,"  ", item.id);
          this.addHideElement(item);
        });
      }
    }
  }
  isVisByView(element: any) {
    const view = element.info?.strategy?.visible?.view;
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const currentSpotID = spotAPI.getCurrentSpotId()
    const spots = element.info?.strategy?.visible?.spots;

    // 不指定视图，则全部可见
    if (!view) {
      return true;
    }
    // 不指定一些sopt ，则根据条件判断
    if (view !== 'SOME_SPOTS') {
      const fun = (visibleStrategyMap as any)[view];
      if (!fun) {
        return true;
      }
      return fun(currentSpotID);
    }

    // 判断当前点是否在指定的spots中
    if (!spots || !(spots instanceof Array)) {
      return true;
    }
    return spots.includes(currentSpotID);
  }
  dispose(){

  }
}
