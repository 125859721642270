import { BaseBehavior, Camera, Component, InputContext, IOnStart, IUpdatable } from '@ali/tidejs';
import EventEmitter from 'eventemitter3';
import { mat4, vec3 } from 'gl-matrix';
import { PanoramaOpt } from './PanoramaOpt';
function checkParams(item: any){
  if(item.theta ===0 || item.phi === 0 || item.fov === 0){
    return false;
  }
  return true;
}
function restParams(item: any){
  item.theta = 0;
  item.phi = 0;
  item.fov = 0;
}
const maxPhi = 0.5 * Math.PI - (60 / 2.0 + 5.0) / 180.0 * Math.PI;
const minPhi = -0.5 * Math.PI + (60 / 2.0 + 5.0) / 180.0 * Math.PI;
@Component("CameraAutoRotate")
export class CameraAutoRotate extends BaseBehavior implements IUpdatable, IOnStart{
  public event = new EventEmitter<"start" | "end">();
  public from = {
    theta: 0,
    phi: 0,
    fov: 0,
  };
  public to = {
    theta: 0,
    phi: 0,
    fov: 0,
  };
  public speed = {
    theta: 0,
    phi: 0,
    fov: 0,
  }

  public current = {

  }
  // 单位 秒
  public duration = 5;
  /**
   * 当前场景在xz平面，从z正方向绕着y轴逆时针旋转的角度,范围  -3.14 3.14
   */
  private _theta = 0;

  /**
   * 当前场景在垂直xz平面，从xz平面向正y方向旋转的角度，范围 -1 1
   */
  private _phi = 0;
  private _cameraComponent!: Camera;
  private totalTime = 0;

  //该方法只在脚本初始化时执行一次
  onStart(): void {
      
  }
  /**
   * 更新相机旋转矩阵
   */
  updateCameraMatrix(): void {
    const theta = this._theta - Math.PI;
    const phi = this._phi - (maxPhi - minPhi);

    const posY = Math.cos(phi) * Math.cos(theta);
    const posX = Math.cos(phi) * Math.sin(theta);
    const posZ = Math.sin(phi);
    let target = vec3.fromValues(posX, posZ, -posY)
    target = vec3.add(vec3.create(), target, this.entity.transform.position);
    const newCameraMatrix = mat4.targetTo(mat4.create(), this.entity.transform.position, target, vec3.fromValues(0, 1, 0));
    this.entity.transform.setLocalToWorldMatrix(newCameraMatrix);
  }
  start(){
    if(!checkParams(this.from) || !checkParams(this.to)){ return }
    this.event.emit("start");
    this.isActive = true;
    const com = this.entity.getComponentByType(PanoramaOpt);
    this._cameraComponent = this.entity.getComponentByType(Camera)!;
    if(!com){ return }
    com.isActive = false;

    // 加上PI，同一到 0 - 2PI的范围内
    this.from.theta += Math.PI;
    this.to.theta += Math.PI;

    let thetaLength = this.to.theta - this.from.theta;
    // todo: 如果两个方向相减大于 PI，相当于两个夹角超过了 180 度，需要选择另一个更小的夹角
    if( Math.abs( thetaLength ) > Math.PI ){
      // 例如 to: 6.14  from: 0.14 ， 必须是 0.14 -> 0(6.2) -> 6.14，不能是  0.14 -> 3.14 -> 6.14， 所以速度必须是负的，顺时针走
      if(thetaLength > 0){
        thetaLength = 2 * Math.PI - thetaLength;
        this.speed.theta = -( thetaLength ) / this.duration;
      } else {
        // 例如 to: 0.14  from: 6.14 ， 必须是 6.14 -> 6.2(0) -> 0.14，不能是  6.14 -> 3.14 -> 0.14， 所以速度必须是正的，逆时针走
        thetaLength =  -thetaLength - 2 * Math.PI;
        this.speed.theta = -( thetaLength ) / this.duration;
      }
    } else {
      this.speed.theta =  thetaLength / this.duration;
    }

    const MathPhi = maxPhi - minPhi;
    // 统一
    this.from.phi += MathPhi;
    this.to.phi += MathPhi;

    const phiLength = this.to.phi - this.from.phi;
    // todo: 如果两个方向相减大于 PI，相当于两个夹角超过了 180 度，需要选择另一个更小的夹角
 
   
    this.speed.phi = (phiLength) / this.duration;
    this.speed.fov = (this.to.fov - this.from.fov) / this.duration;

    this._theta = this.from.theta;
    this._phi = this.from.phi;
  }
  stop(){
    const com = this.entity.getComponentByType(PanoramaOpt);
    if(com){ 
      com.isActive = true;
    }
    this.event.emit("end");
    this.isActive = false;
    
    restParams(this.speed);
    restParams(this.from);
    restParams(this.to);
    this.totalTime = 0;
  }

  /**
   * update 每帧执行一次
   * @param deltaTime 当前帧与上一帧的插值，秒
   * @param inputContext 键盘输入
   */
  update(deltaTime: number, inputContext: InputContext): void {
    if(!this.isActive){ return }

    if( this.totalTime > this.duration){
      this.stop();
      return 
    }
    this.totalTime += deltaTime;
    if(this.speed.theta !== 0){
      this._theta = this._theta + this.speed.theta * deltaTime;
      if(this._theta > 2 * Math.PI){
        // 超过了最大值，重置 6.3 -> 0.1
        this._theta =  this._theta - 2 * Math.PI;
      }
      if(this._theta < 0){
        // 小于最小值 -0.3 -> 6
        this._theta =  this._theta + 2 * Math.PI;
      }
 
    }
    if(this.speed.phi !== 0){
      this._phi = this._phi + this.speed.phi * deltaTime ;
    }
    if(this.speed.fov !== 0){
      // this._cameraComponent.fov = this.speed.fov * deltaTime ;
    }
    this.updateCameraMatrix();


  
  }
}
