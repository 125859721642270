
import { ConsoleLog, ErrorType } from "../../lib/log";
import EventEmitter from "eventemitter3";
import { Utils } from "../../lib/utils";
export function checkAudioSupport(){
  const audioContext = window.AudioContext || (window as any).webkitAudioContext;
  if(audioContext){
    const context = new audioContext();
    if(!!context.createBufferSource && !!context.decodeAudioData){
      return true
    }
  }
  return false
}
let context: AudioContext | undefined = undefined;
if(!checkAudioSupport()){
  ConsoleLog.error("Audio","checkAudioSupport",ErrorType.ExternalError,"不支持 AudioContext");
} else {
  context = new ( window.AudioContext || (window as any).webkitAudioContext )();
  window.addEventListener("mousedown", ()=>{
    ConsoleLog.info("Audio","checkAudioSupport","鼠标交互，可以正常播放");
    context?.resume();
  });
  window.addEventListener("touchstart", ()=>{
    ConsoleLog.info("Audio","checkAudioSupport","鼠标交互，可以正常播放");
    context?.resume();
  });
}
export class ContextAudio{
  get readyState(){
    return this._readyState;
  }
  private _currentTime = 0;
  set currentTime(value: number){
    if(isNaN(value) || value < 0){
      return
    }
    this.pause();
    this.pausedAt = value;
    this.play();
  }
  // 这个值不准，todo
  get currentTime(){
    if(this.startedAt == 0){
      return 0;
    }
    if(!this.paused && !this.ended && !this.isError){
      this._currentTime = (Date.now() - this.startedAt) / 1000;
    }
    return this._currentTime;
  }
  public event = new EventEmitter<"play" | "pause" | "end" | "loading" | "loaded" | "error">();
  public src!: string;
  public duration = 0;
  public paused = true;
  public ended = false;
  public isError = false;
  public isLoading = false;
  public isLoaded = false;
  public disposed = false;

  private _readyState = 0;
  // pausedAt 就是上次暂停的时间点，下次播放的开始时间点
  private pausedAt = 0;
  // startedAt 只是记录时间
  private startedAt = 0;
  private audioBuffer!: AudioBuffer;
  private bufferSource!: AudioBufferSourceNode;
  constructor(src?: string){
    if(src){
      this.src = src;
    }
  }
  fetchAudioBuffer(){
    return new Promise((resolve, reject)=>{
      const url = Utils.autoAddProtocol(this.src);
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'arraybuffer';
      xhr.open('GET', url);
      xhr.send();
      this._readyState = 1;
      xhr.onload = () => {
        if (xhr.status === 200) {
          // todo: 完善 readyState
          this._readyState = 2;
          resolve(xhr.response as ArrayBuffer);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = (reason) => {
        reject(reason);
      };
    })
  }
  load(){
    if(!this.isLoading && this.isLoaded){
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject)=>{
      this.event.emit("loading");
      this.isLoading = true;
      this.fetchAudioBuffer().then((buffer: any)=>{
        if(this.disposed){
          return
        }
        // three.js 是这样做的防止bug
        const bufferCopy = buffer.slice( 0 );
        context?.decodeAudioData( bufferCopy,  ( audioBuffer )=> {
          this._readyState = 3;
          this.duration = audioBuffer.duration;
          this.audioBuffer = audioBuffer;
          this._readyState = 4;
          this.isLoading = false;
          this.isLoaded = true;
          this.event.emit("loaded");
          resolve();
        } )
      }).catch((error)=>{
        this.event.emit("error");
        this.onerror(error);
        reject(error);
        this.isError = true;
        this.isLoading = false;
        this.isLoaded = false;
      })
    })
  }
  async play(){
    if(this.isError){ return Promise.reject() }
    if(!this.paused){
      ConsoleLog.info("Audio","play","语音正在播放，不重复播放了");
      // 已经正在播放的过程中就不需要再次调用了
      return Promise.resolve();
    }
    if(!this.isLoading && !this.isLoaded){
      ConsoleLog.info("Audio","play","语音未加载，正在等待加载");
      await this.load();
    }
    if(this.disposed){ return }
    return new Promise<void>((resolve, reject)=>{
      ConsoleLog.info("Audio","play","audioBuffer 准备播放");
      if(this.audioBuffer && context){
        const source = context.createBufferSource();
        // 加了一个 stopped 的标志位，因为 调用 stop之后会自动触发 onended 事件，不能这样
        (source as any).stopped = false;
        source.buffer = this.audioBuffer;
        source.loopStart = 0;
        source.loopEnd = 0;
        source.onended = ()=>{
          ConsoleLog.info("source 是否暂停：", (source as any).stopped);
          if((source as any).stopped){ return }
          ConsoleLog.info("Audio","onended","音频播放完成");
          this.event.emit("end");
          (source as any).stopped = true;
          this.ended = true;
          this.onended();
        };
        source.connect(context.destination);
        this.bufferSource = source;

        if(this.paused){
          this.startedAt = Date.now() - this.pausedAt;
          source.start(0, this.pausedAt / 1000);
        } else {
          this.startedAt = Date.now();
          source.start(0);
        }
        this.ended = false;
        ConsoleLog.info("Audio","play","播放成功");
        this.paused = false;
        this.event.emit("play");
        resolve();
      } else {
        reject()
      }
    })
  }
  pause(){
    if(this.disposed){ return }
    if(this.isError){ return }
    this.paused = true;
    ConsoleLog.info("Audio","pause","audio 暂停：");
    if(this.bufferSource){
      if((this.bufferSource as any).stopped){ return }
      try {
        this.event.emit("pause");
        this.bufferSource.stop();
        (this.bufferSource as any).stopped = true;
        this.pausedAt = Date.now() - this.startedAt;
      } catch (error) {
        ConsoleLog.error("Audio","play",ErrorType.InternalError,error);
      }
    }
  }
  /**
     * resume 和 play 的区别是 播完之后就不重新播放
     * Resumes context audio
     */
  resume(){
    if(this.isError){ return }
    if(this.ended){
      return
    }
    this.play();
  }
  onended(){}

  onerror(msg: string){}

  dispose(){
    this.pause();
    (this as any).audioBuffer = undefined;
    (this as any).bufferSource = undefined;
    this.disposed = true;
    this.event.removeAllListeners();
  }
}
