import { RenderQueue, IShader, UniformType} from "@ali/tidejs";

const PanoramaShader: IShader = {
  name: "PanoramaShader",
  renderQueue: RenderQueue.Geometry,

  uniform: {
    u_panorama: { type: UniformType.TextureCube, value: undefined},
    u_cameraPos: { type: UniformType.vec3, value: [0, 0, 0]},
    u_alphaRatio: { type: UniformType.float, value: 0.0}
  },

  vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        //attribute vec3 color;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;

        varying vec4 v_worldPos;

        void main() {
            v_worldPos = u_model * vec4(position, 1.0);

            gl_Position = u_projection * u_view * v_worldPos;
        }
    `,

  fragmentShader: `
        precision highp float;
        uniform samplerCube u_panorama;
        uniform vec3 u_cameraPos;
        uniform float u_alphaRatio;

        varying vec4 v_worldPos;

        void main() {
            vec3 samplePanoDir = normalize(v_worldPos.xyz - u_cameraPos);
             samplePanoDir.x = -samplePanoDir.x;
            //samplePanoDir.z = -samplePanoDir.z;

            vec3 color = textureCube(u_panorama, samplePanoDir).rgb;
            gl_FragColor = vec4(color, 1.0);
        }
    `,
};

export default PanoramaShader;