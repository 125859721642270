import { Camera, Entity, Player, Scene, AssetManager } from '@ali/tidejs'
import { Application } from '../../Application';
import { PanoImageApp } from '../../PanoImageApp';
import { PanoVideoApp } from '../../PanoVideoApp';
import { GameEnter } from '../../Behaviors/GameEnter';
import { MessageCenter } from '../..//GameLogic/MessageCenter';
import { ConsoleLog } from '../../lib/log';
import { OssResolver } from '../../lib/oss';
import { ProgressReporter } from '../../lib/progress/ProgressReporter';
import { SpotPrivate } from '../../api/spot/lib/private';
import { ConfigPrivate } from '../../api/config/lib/private';


export class ApplicationModeBase {
  protected app!: Application | PanoImageApp | PanoVideoApp;
  _init(app: Application | PanoImageApp | PanoVideoApp) {
    //1.索引到canvas
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = app.container.clientWidth;
    canvas.height = app.container.clientHeight;
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    app.container.style.display = "flex";
    if ((window as any).ResizeObserver) {
      const ro = new (window as any).ResizeObserver(() => {
        ConsoleLog.info("Application", "init", "resize");
        window.dispatchEvent(new Event("resize"));
        MessageCenter.GlobalEvent.emit("resize");
      });
      // 观察一个或多个元素
      ro.observe(canvas);
    }
    window.addEventListener("resize", () => { MessageCenter.GlobalEvent.emit("resize"); })
    app.canvas = canvas;
    app.container.appendChild(canvas)
    //2.新建一个引擎 player对象
    const player = new Player();
    let preserveDrawingBuffer = false;
    let antialias = true;
    let dpr = Math.sqrt(window.devicePixelRatio)
    if (app.options && app.options.renderOption) {
      if (app.options.renderOption.preserveDrawingBuffer !== undefined) {
        preserveDrawingBuffer = app.options.renderOption.preserveDrawingBuffer;
      }
      if (app.options.renderOption.antialias !== undefined) {
        antialias = app.options.renderOption.antialias;
      }
      if (app.options.renderOption.dpr !== undefined) {
        dpr = Math.sqrt(app.options.renderOption.dpr);
      }
    }

    //3.初始化
    player.init(canvas!, undefined, {
      preserveDrawingBuffer,
      antialias,
    }, 1.0 / dpr);
    player.setFrameRate(60);
    app.player = player;

    //4.在场景中，添加一个默认 cube 对象
    const scene = player.sceneManager.currentScene;

    //5.新建一个摄像机entity，并为其添加摄像机组件
    const cam = new Entity("maincamera");
    const camCom: Camera = cam.addComponent(Camera);
    camCom.backGround = [0.3, 0.3, 0.3, 1.0];
    camCom.near = 0.1;
    camCom.far = 1000;
    cam.transform.position = [0, 0, 0];

    //6.添加camera到场景
    scene.addEntity(cam);
    Camera.MainCamera = camCom; //TODO:该句下个版本会去掉！！！
    camCom.fov = 80;
    player.play();

    //7.场景中用户参数设置
    if (app.options) {
      if ((app as Application).options.background) {
        const backGroundColor = (app as Application).options.background!;
        camCom.backGround = [backGroundColor[0]/255, backGroundColor[1]/255, backGroundColor[2]/255, 1.0];
      }
      if ((app as Application).options.spot?.normalSpotTexture) {
        SpotPrivate.Config.spot.normalSpotTexture = (app as Application).options.spot?.normalSpotTexture as string;
        if ((app as Application).options.spot?.activeSpotTexture) {
          SpotPrivate.Config.spot.activeSpotTexture = (app as Application).options.spot?.activeSpotTexture as string;
        }
      }
      if ((app as Application).options.mouseTexture) {
        ConfigPrivate.Config.mouseImage = (app as Application).options.mouseTexture as string;
      }
      if ((app as Application).options.enablePierce) {
        ConfigPrivate.Config.enablePierce = (app as Application).options.enablePierce as boolean;
      }
      if ((app as Application).options.hdrOption?.enableHdr) {
        this.loadHdr();
      }
    }

  }
  init(app: Application) {
    this.app = app;
    return new Promise<void>(async (resolve) => {
      await this._init(app);
      ProgressReporter.canMove.subscribe(() => {
        resolve();
      })
    })
  }
  initPanoImage(app: PanoImageApp) {
    this.app = app;
    this._init(app);
  }

  initPanoVideo(app: PanoVideoApp) {
    this.app = app;
    this._init(app);
  }

  setResolverByCDNId(sceneId: string) {
    const oss = {
      oss_path: `oss://virtualbuy-cdn/datastore/${sceneId}/latest`,
    }
    Application.Config.publicResourceResolver = new OssResolver(oss);
    Application.Config.privateResourceResolver = new OssResolver(oss);
    Application.sceneID = sceneId;
  }
  loadScene(sceneId: string) {
    this.setResolverByCDNId(sceneId);
    Camera.MainCamera!.entity.deactivate();
    this.app.reload();

    Scene.Destroy(this.app.player.sceneManager.currentScene)
    this.app.player.sceneManager.currentScene = new Scene(sceneId);
    const cam = new Entity("maincamera");
    const camCom: Camera = cam.addComponent(Camera);
    camCom.backGround = [0.3, 0.3, 0.3, 1.0];
    camCom.near = 0.1;
    camCom.far = 1000;
    cam.transform.position = [0, 0, 0];

    cam.addComponent(GameEnter);

    //7.添加camera到场景
    this.app.player.sceneManager.currentScene.addEntity(cam);
    Camera.MainCamera = camCom; //TODO:该句下个版本会去掉！！！
    ProgressReporter.onProgress.next({ progress: 30 });
  }

  async loadHdr() {
    const irradianceImages = ['https://virtualbuy-cdn.alibaba.com/hdrSource/output_iem_posx.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_iem_negx.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_iem_posy.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_iem_negy.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_iem_posz.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_iem_negz.hdr'];

    await AssetManager.GetInstance().loadAsset('irradianceCubemap', irradianceImages, { UUID: 'irradianceCubemap' });

    const radianceImages = ['https://virtualbuy-cdn.alibaba.com/hdrSource/output_skybox_posx.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_skybox_negx.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_skybox_posy.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_skybox_negy.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_skybox_posz.hdr',
      'https://virtualbuy-cdn.alibaba.com/hdrSource/output_skybox_negz.hdr'];

    await AssetManager.GetInstance().loadAsset('radianceCubemap', radianceImages, { UUID: 'radianceCubemap' });
  }
}
