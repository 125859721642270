import { Component, BaseBehavior, IOnStart, Camera } from "@ali/tidejs";

@Component("LookAtCameraCom")
export class LookAtCameraCom extends BaseBehavior implements IOnStart {



  onStart(): void {

  }
  update() {
    const camera = Camera.MainCamera;
    if (!camera) { return }

    this.entity.transform.localRotation = Camera.MainCamera!.entity.transform.localRotation;
  }
}
