import { ElementsManager } from "@sdkCore/api/elements/ElementsManager";
import { Constants, Entity, Loader, Mesh, MeshCollider, Transform, Texture2D, MeshFilter, PrimitiveType, PureColorMaterial, Renderer, RenderQueue, SceneManager, UnlitMaterial, UnpackedAttrs } from "@ali/tidejs";
import { IAnnotationConfigItem, SDKElementType } from "@sdkCore/api/elements/interface";
import { CSS2DElement } from "@sdkCore/Components/Css2DElement";
import { vec3, vec4 } from "gl-matrix";
import { LineElementPrivate } from "./private";
import { ConsoleLog, ErrorType } from "../../../../lib/log";
import { Application } from "../../../../Application";
import { Element } from "../../../../lib/utils/eca";
import { BaseElement } from "../../../../lib/utils/eca";
import { SetVisibleBySpot } from "../../../../Components/SetVisbleBySpot";
import { SpotPrivate } from "../../../../api/spot/lib/private";
import { Utils } from "../../../../lib/utils";
import { isInModelApp } from "../../../../api/elements/lib/util";


export const LineContainerPrefix = "line-element-container-";
export const LineImagePrefix = "line-element-image-";

function createAnnotationElement(id: string, url: string) {
  const size = LineElementPrivate.Config.imageSize;
  const dom = document.createElement("div");
  dom.style.pointerEvents = "auto";
  dom.style.cursor = "pointer";
  const image = new Image(size, size);
  image.src = Utils.autoAddProtocol(url);
  image.id = LineImagePrefix + id;
  image.draggable = false;
  image.style.userSelect = "none";

  dom.id = LineContainerPrefix + id;
  dom.style.display = "flex";
  dom.appendChild(image);
  return [dom, image]
}
export function createLine(id: string, startPosition: vec3, endPosition: vec3, length: number) {
  const attrs: UnpackedAttrs = {
    verts: [],
    uvs: [0, 0, 0, 0],
    norms: [1, 0, 0, 0, 1, 0, 0, 0],
    indices: [0, 1],
    min: vec3.fromValues(-10, -10, -10),
    max: vec3.fromValues(10, 10, 10),
    isBufferGeometry: false
  };
  attrs.verts = [...[0, 0, 0], ...[0, 0, 1]];
  const lineEntity = new Entity(id + '_line');
  const meshFilter = lineEntity.addComponent<MeshFilter>(MeshFilter);
  if (meshFilter) {
    meshFilter.mesh = new Mesh(lineEntity.name + '_mesh', attrs, lineEntity.name + '_mesh');
    meshFilter.mesh.upLoad();
  }
  const render = lineEntity.addComponent<Renderer>(Renderer);
  render.material = new PureColorMaterial();
  render.material!.drawType = Constants.LINES;
  render!.material!.renderQueue = RenderQueue.Transparent;
  render!.material!.depthTest = false;
  render!.material!.depthWrite = false;
  (render.material as PureColorMaterial).color = vec4.fromValues(1.0, 1.0, 1.0, 1);
  lineEntity.transform.position = startPosition;
  const normal = vec3.create();
  vec3.sub(normal, endPosition, startPosition);
  vec3.normalize(normal, normal);
  if (Math.abs(vec3.dot(normal, Transform.Up)) > 0.999) {
    lineEntity.transform.setForward(normal, [1, 0, 0]);
  } else {
    lineEntity.transform.setForward(normal, [0, 1, 0]);
  }
  lineEntity.transform.localScale = [length, length, length];
  const scene = SceneManager.GetInstance().currentScene;
  scene.addEntity(lineEntity);
  return lineEntity;
}
async function createLineEditor(id: string, position: vec3, normal: vec3) {
  const plane = Entity.CreatePrimitive(PrimitiveType.DoublePlane);
  if (!plane) { return }
  plane.transform.position = vec3.add(vec3.create(), position, vec3.scale(vec3.create(), normal, 0.0));
  // 朝向不能和up方向相同
  vec3.normalize(normal, normal);
  if (Math.abs(vec3.dot(normal, Transform.Up)) > 0.999) {
    plane.transform.setForward(normal, [1, 0, 0]);
  } else {
    plane.transform.setForward(normal, [0, 1, 0]);
  }
  plane.transform.localScale = vec3.fromValues(0.1, 0.1, 0.1);
  const render = plane.addComponent<Renderer>(Renderer);
  const m = new UnlitMaterial();
  m.name = 'line';
  render.isActive = false;
  render.material = m;
  const texture = await Loader.LoadTexture2D(LineElementPrivate.Config.unHightLightEditorImage);
  if (!texture) { return }
  render.material!.cullface = -1;
  render!.material!.renderQueue = RenderQueue.Transparent;
  render!.material!.transparent = true;
  render!.material!.depthTest = false;
  render!.material!.depthWrite = false;
  render.isActive = true;
  // render!.material!.depthTest = false;
  (render.material as UnlitMaterial).albedo = texture;

  const com = plane.addComponent<MeshCollider>(MeshCollider);
  com.triggerEvent.on("enter", () => {
    ConsoleLog.log("LineElement ", "triggerEvent", "editor enter", id);
    const lineElementAPI = Application.getInstance<LineElementPrivate>(LineElementPrivate.ModuleName)
    lineElementAPI.event.emit("enter", id);
  })
  com.triggerEvent.on("exit", () => {
    ConsoleLog.log("LineElement", "triggerEvent", "editor exit", id);
    const lineElementAPI = Application.getInstance<LineElementPrivate>(LineElementPrivate.ModuleName)
    lineElementAPI.event.emit("exit", id);
  })
  const scene = SceneManager.GetInstance().currentScene;
  scene.addEntity(plane);
  return plane;
}

/**
   * COMMODITY_LINE，ICBU样式，一根线，一个图片
   */
async function build(item: IAnnotationConfigItem) {
  const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName)
  const { id, anchor, info, normal } = item;
  const url = info.src;
  if (!url) { return }
  const length = info.line_length || LineElementPrivate.Config.defaultLineLength;
  const normalVe3 = vec3.fromValues(normal.x * length, normal.z * length, -normal.y * length);
  const startPosition = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
  const endPosition = vec3.create();
  vec3.add(endPosition, startPosition, normalVe3);//vec3.fromValues(anchor.x,  anchor.z , -anchor.y);

  const lineEntity = createLine(id, startPosition, endPosition, length);

  const plane: Entity = new Entity(id);
  plane.transform.position = endPosition;
  const scene = SceneManager.GetInstance().currentScene;
  scene.addEntity(plane);

  const [container, annotationImg] = createAnnotationElement(id, url);
  annotationImg.addEventListener("click", () => {
    ElementsManager.getInstance().event.emit("click", id);
  })

  let editorEntity = undefined;
  if (Application.mode === "editor") {
    editorEntity = await createLineEditor(id, startPosition, normalVe3);
  }
  const annotation = new LineElement(plane, item, lineEntity, editorEntity);
  // 这里有先后顺序，要先判断可见性，再设置CSS2DElement
  const isInModelPanorama = isInModelApp();
  if (isInModelPanorama) {
    const com2 = annotation.addComponent(SetVisibleBySpot);
    com2.preAwake(spotAPI.getFirstSpotId())
  }
  const com: CSS2DElement = annotation.addComponent(CSS2DElement);
  com.elementNode = container;
  com.preAwake();
  const lineElementAPI = Application.getInstance<LineElementPrivate>(LineElementPrivate.ModuleName);
  lineElementAPI.addElement(annotation);
  annotation._normal = vec3.fromValues(normal.x, normal.z, -normal.y);
  //   ElementsManager.getInstance()._addElement(annotation);
  return annotation;
}
/**
 * 长线标签，由一根连接到地面或者墙体的长线和一个图片标识组成，标签样式如下所示：
 * * 图片浮层：
 *
 * ![图片](https://intranetproxy.alipay.com/skylark/lark/0/2021/png/358286/1624441622727-1ded2e54-cca2-4d61-9817-c148f6f46f97.png?x-oss-process=image%2Fresize%2Cw_752)
 */
@Element({
  type: SDKElementType.line,
  build,
})
export class LineElement extends BaseElement {

  public state = {
    isHighLight: false
  }
  public sdkType: SDKElementType;
  private hightLightEditorTex: undefined | Texture2D = undefined
  private unHightLightEditorTex: undefined | Texture2D = undefined
  constructor(entity: Entity, item: IAnnotationConfigItem, public lineEntity: Entity, public editorEntity?: Entity) {
    super(entity, item);
    const { info } = item;
    this.sdkType = SDKElementType.line;
    this.lineLength = info.line_length || LineElementPrivate.Config.defaultLineLength;
  }

  get position() {
    return this.editorEntity!.transform.position; // 注意position取得是于场景接触的配置，这样移动时的坐标与其他标签保持了统一
  }
  set image(url: string) {
    const imageDom = document.getElementById(LineImagePrefix + this.id) as HTMLImageElement;
    if (!imageDom) { return }
    imageDom.src = url;
  }
  get image() {
    const imageDom = document.getElementById(LineImagePrefix + this.id) as HTMLImageElement;
    if (!imageDom) { return "" }
    return imageDom.src;
  }
  // 线的长度
  private _lineLength = 0;
  set lineLength(value: number) {
    this._lineLength = value;
  }
  get lineLength() {
    return this._lineLength;
  }
  /**
     * 隐藏，但高亮的时候不生效
     * Hides line element
     */
  hide() {
    if (this.state.isHighLight) {
      ConsoleLog.log("LineElement", "event-on-hide", `${this.id}隐藏失败，正在高亮`);
      return
    }
    super.hide();
    ConsoleLog.log("LineElement", "hide", this.id);
    this.lineEntity.deactivate();
    this.editorEntity?.deactivate();
  }
  /**
     * 显示
     * Shows line element
     */
  show() {
    super.show();
    ConsoleLog.log("LineElement", "show", this.id);
    this.lineEntity.activate();
    this.editorEntity?.activate();
  }
  onClick() {
    ElementsManager.getInstance().event.emit("click", this.id);
  }
  /**
     * 高亮底座
     * Highlights editor
     */
  highlightEditor() {
    if (!this.editorEntity) { return }
    const render = this.editorEntity.getComponentByType<Renderer>(Renderer);
    if (!render || !render.material) { return }
    const api = Application.getInstance<LineElementPrivate>(LineElementPrivate.ModuleName);
    if (!api.hightLightEditorTex) {
      return false
    }
    (render?.material as UnlitMaterial).albedo = api.hightLightEditorTex as any;
    return true
  }
  /**
     * 取消高亮底座
     * Unhighlights editor
     */
  unhighlightEditor() {
    if (!this.editorEntity) { return }
    const render = this.editorEntity.getComponentByType<Renderer>(Renderer);
    if (!render || !render.material) { return }
    const api = Application.getInstance<LineElementPrivate>(LineElementPrivate.ModuleName);
    if (!api.unHightLightEditorTex) {
      return false
    }
    (render?.material as UnlitMaterial).albedo = api.unHightLightEditorTex as any;
    return true
  }
  dispose() {
    ConsoleLog.log("LineElement", "dispose", this.id);
    super.dispose();
  }
  /**
     * 高亮标签
     * Highlights line element
     * @param color
     */
  highlight() {
    const image = document.getElementById(LineImagePrefix + this.id) as HTMLImageElement;
    const src = this.elementConfig.info.highlightSrc;
    if (!src) {
      ConsoleLog.error("LineElement", "highlight", ErrorType.UseError, "info.highlightSrc 未指定", this.id);
      return
    }
    ConsoleLog.log("LineElement", "highlight", this.id);
    image.src = Utils.autoAddProtocol(src);
    const lineElementAPI = Application.getInstance<LineElementPrivate>(LineElementPrivate.ModuleName)
    lineElementAPI.hightLightMap.forEach(item => item.unhighlight());
    lineElementAPI.hightLightMap.clear();
    lineElementAPI.hightLightMap.set(this.id, this);
    // this.isHighLight = true;
    this.setState({
      isHighLight: true,
    })
    this.setVisible(true);
  }
  /**
     * 取消高亮标签
     * Unhighlights line element
     */
  unhighlight() {
    ConsoleLog.log("LineElement", "unhighlight", this.id);
    const image = document.getElementById(LineImagePrefix + this.id) as HTMLImageElement;
    image.src = Utils.autoAddProtocol(this.elementConfig.info.src);
    const lineElementAPI = Application.getInstance<LineElementPrivate>(LineElementPrivate.ModuleName)
    lineElementAPI.hightLightMap.delete(this.id);
    // this.isHighLight = false;
    this.setState({
      isHighLight: false,
    })
  }
  /**
     * 移动到某个位置
     * Moves to
     * @param position
     * @param normal
     */
  moveTo(position: vec3, normal: vec3) {
    ConsoleLog.log("LineElement", "moveTo", this.id);
    const endPosition = vec3.create();
    const normalVe3 = vec3.create();
    vec3.scale(normalVe3, normal, this.lineLength);
    vec3.add(endPosition, position, normalVe3);
    this.entity.transform.position = endPosition;
    this.lineEntity.transform.position = position;
    vec3.normalize(normal, normal);
    if (Math.abs(vec3.dot(normal, Transform.Up)) > 0.999) {
      this.lineEntity.transform.setForward(normal, [1, 0, 0]);
    } else {
      this.lineEntity.transform.setForward(normal, [0, 1, 0]);
    }
    if (this.editorEntity) {
      this.editorEntity.transform.position = vec3.add(vec3.create(), position, vec3.scale(vec3.create(), normalVe3, 0.0));
      this.editorEntity.transform.setForward(normalVe3);
    }
    this._normal = normal;
  }

  /**
     * 设置标签的位置
     * @param anchor
     * @param normal
     */
  public setPosition(anchor: any, normal: any) {
    this.moveTo(vec3.fromValues(anchor.x, anchor.z, -anchor.y), vec3.fromValues(normal.x, normal.z, -normal.y))
  }


}
