import EventEmitter from "eventemitter3";
export class HTMLAudio extends Audio{
  public isError = false;
  public isLoading = false;
  public isLoaded = false;
  public event = new EventEmitter<"play" | "pause" | "end" | "loading" | "loaded" | "error">();

  constructor(src?: string){
    super(src);
    this.addEventListener("loadstart", ()=>{
      this.isLoading = true;
      this.event.emit("loading");
    })
    this.addEventListener("loadeddata", ()=>{
      this.isLoading = false;
      this.isLoaded = true;
      this.event.emit("loaded");
    })
    this.addEventListener("error",()=>{
      this.isError = true;
      this.event.emit("error");
    })
    this.addEventListener("ended", ()=>{
      this.event.emit("end");
    })
        
  }
  play(){
    this.event.emit("play");
    return super.play();
  }
  pause(){
    this.event.emit("pause");
    return super.pause();
  }
  resume(){
    return super.play();
  }
  dispose(){
    super.pause();
  }

}