

import { Transform, Camera } from "@ali/tidejs";
import { CameraPrivate } from "../../../../api/camera/lib/private";
import { Application } from "../../../../Application";
import { mat3, mat4, quat, vec3, vec4 } from "gl-matrix";

export class Normal2TopInterpolator {

  private _endCameraPosition = vec3.create();
  private _startCameraPosition = vec3.create();
  private _size = 0;
  private center = vec3.create();

  private _camera: Camera;
  private _trans: Transform;
  private _startForward = vec3.create();

  private _startAngle = 0;
  private _offsetAngle = 0;

  constructor() {
    this._camera = Camera.MainCamera!;
    this._trans = this._camera.entity.transform;
  }
  public setdest() {
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    const worldMatrix = this._trans.localToWorldMatrix();
    const nearPlaneHeight = Math.tan(CameraPrivate.Config.normalViewFov * 0.5 / 180.0 * Math.PI) * this._camera.near;
    this._size = cameraAPI.topViewHeight;
    const height = ((this._size * 0.5 / cameraAPI.yRatio) / nearPlaneHeight) * this._camera.near;
    this.center = cameraAPI.center;

    this._endCameraPosition = vec3.fromValues(cameraAPI.center[0], cameraAPI.center[1] + height, cameraAPI.center[2]);
    vec4.transformMat4(this._tmpDirectionHomo, vec4.fromValues(0, 0, -1, 0), worldMatrix); // forwardHomo

    this._startForward = vec3.fromValues(this._tmpDirectionHomo[0], this._tmpDirectionHomo[1], this._tmpDirectionHomo[2]);
    this._startAngle = Math.atan2(-this._tmpDirectionHomo[0], -this._tmpDirectionHomo[2]);

    this._startCameraPosition = vec3.clone(this._trans.position);

    if (this._startAngle < 0) {
      this._startAngle = this._startAngle + 2 * Math.PI;
    }
    this._offsetAngle = cameraAPI.topAngle - this._startAngle;

    if (this._offsetAngle < -Math.PI) {
      this._offsetAngle = this._offsetAngle + 2 * Math.PI;
    }
    if (this._offsetAngle > Math.PI) {
      this._offsetAngle = this._offsetAngle - 2 * Math.PI;
    }
  }
  private _tempQuat: quat = quat.create();
  private _offsetQuat: quat = quat.create();
  private _tmpPos = vec3.create();
  private _tmpDirectionHomo = vec4.create();
  private _tmpDirection = vec3.create();
  private _tmpRotationMatrix = mat3.create();
  private _tmpTransformMatrix = mat4.create();

  public update(ratio: number): void {
    this._tmpDirection = vec3.fromValues(0, 1, 0); // up
    if (ratio === 1.0) {
      this._tmpDirection = vec3.clone(this._startForward);
    }
    vec3.lerp(this._tmpPos, this._startCameraPosition, this._endCameraPosition, ratio);
    this._trans.position = vec3.clone(this._tmpPos);
    mat4.targetTo(this._tmpTransformMatrix, this._trans.position, this.center, this._tmpDirection);

    const currentAngle = this._offsetAngle * ratio;

    quat.setAxisAngle(this._offsetQuat, vec3.fromValues(0, 0, 1), currentAngle);
    quat.fromMat3(this._tempQuat, mat3.fromMat4(this._tmpRotationMatrix, this._tmpTransformMatrix)); //originQ
    quat.mul(this._tempQuat, this._tempQuat, this._offsetQuat);
    this._trans.localRotation = quat.clone(this._tempQuat);
    this._camera.fov = this._camera.fov * (1.0 - ratio) + CameraPrivate.Config.normalViewFov * ratio
  }

}
