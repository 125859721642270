import { RenderQueue, IShader, UniformType} from "@ali/tidejs";

const ElementHighLightShader: IShader = {
  name: "ElementHighLightShader",
  renderQueue: RenderQueue.Geometry,
  uniform: {
    u_diffuse: { type: UniformType.Texture2D, value: undefined },
    u_highLightColor: { type: UniformType.vec3, value: [0, 0, 0] },
    u_highLightStrength: { type: UniformType.float, value: 0 }
  },

  vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;

        varying vec2 v_texCoord;
        varying vec3 v_normal;

        void main() {
            gl_Position = u_projection * u_view * u_model * vec4(position, 1.0);
            v_texCoord = texcoord;
            v_normal = normal;
        }
    `,

  fragmentShader: `
        precision highp float;
        precision highp int;
        precision highp sampler2D;

        uniform sampler2D u_diffuse;
        uniform vec3 u_highLightColor;
        uniform float u_highLightStrength;
        varying vec2 v_texCoord;
        varying vec3 v_normal;

        void main() {
            vec4 color = texture2D(u_diffuse, vec2(v_texCoord.x, v_texCoord.y)).rgba;
            color.rgb = color.rgb + u_highLightStrength * u_highLightColor;
            gl_FragColor = color;
        }
    `,
};

export default ElementHighLightShader;
