import { Entity, MeshFilter, Renderer, MeshCollider, Transform, Texture2D, VideoPlayer, PrimitiveType, PureColorMaterial } from "@ali/tidejs";
import { ElementsManager } from "../../ElementsManager";
import { TransitionPrivate } from "@sdkCore/api/transition/lib/private";
import { TransitionState } from "@sdkCore/api/transition/lib/interface";
import { SpotPrivate } from "@sdkCore/api/spot/lib/private";
import { IAnnotationConfigItem, SDKElementType } from "../../interface";
import { Element } from "../../../../lib/utils/eca";
import { addImageElement, addVideoElement, getImage, judgeImageOrVideo } from "../../../../api/elements/lib/util";
import { BaseElement } from "../../../../lib/utils/eca";
import { MediaElementPrivate } from "./private";
import { Application } from "../../../../Application";
import { DataCenter } from "../../../../GameLogic/DataCenter";
import { MessageCenter } from "../../../../GameLogic/MessageCenter";
import { quat, vec3 } from "gl-matrix";
import { playIcon, pauseIcon } from "../../../../assets/icon";


async function build(item: IAnnotationConfigItem) {
  const api = Application.getInstance<MediaElementPrivate>(MediaElementPrivate.ModuleName);
  let buildEntity: Entity | undefined = undefined;
  const { id, info } = item;
  const mediaType = judgeImageOrVideo(info.src);
  if (mediaType === 'video' || item.mediaType === "VIDEO") { // item.mediaType兼容老数据
    buildEntity = await addVideoElement(item);
  } else {
    buildEntity = await addImageElement(item);
  }
  const childEntity = buildEntity?.getChildrenEntitiesRecursively();
  let controlEntity: Entity | undefined = undefined as any;
  childEntity?.forEach((item: any) => {
    if (item.name === 'videoControl') {
      controlEntity = item
    }
  })

  const com = buildEntity?.addComponent<MeshCollider>(MeshCollider);
  com?.triggerEvent.on("enter", () => {
    api.event.emit("enter", id);
  })
  com?.triggerEvent.on("exit", () => {
    api.event.emit("exit", id);
  })


  if (controlEntity) {
    const controlCom = (controlEntity as Entity)?.addComponent<MeshCollider>(MeshCollider);

    controlCom?.triggerEvent.on("enter", () => {
      const videoPlayer = buildEntity!.getComponentByType<VideoPlayer>(VideoPlayer);
      if (!videoPlayer) {
        return
      }
      const iconEntityRender = (controlEntity as Entity).getComponentByType<Renderer>(Renderer);
      iconEntityRender!.isActive = true;

    })

    controlCom?.triggerEvent.on("exit", () => {
      const videoPlayer = buildEntity!.getComponentByType<VideoPlayer>(VideoPlayer);
      if (!videoPlayer) {
        return
      }
      const iconEntityRender = (controlEntity as Entity).getComponentByType<Renderer>(Renderer);
      iconEntityRender!.isActive = false;
    })

    controlCom?.triggerEvent.on("click", async() => {
      const videoPlayer = (buildEntity as Entity).getComponentByType<VideoPlayer>(VideoPlayer);
      if (!videoPlayer) {
        return
      }
      const isPaused = videoPlayer!.videotexture!.data.paused;
      if (isPaused) {
        element.playVideo();
        element.setMuted(false);
      } else {
        element.pauseVideo();
      }

    })
  }

  const element = new MediaElement(item, buildEntity as Entity, SDKElementType.image);
  api.addElement(element);
  element.event.emit('built')
  return element
}

/**
 * 浮层标签，外形是一个平铺的浮层，可以自由贴在地面、墙体上。分为图片和视频两者类型，标签样式如下所示：
 * ![图片](https://intranetproxy.alipay.com/skylark/lark/0/2021/png/358286/1624439908270-69157f42-4d77-4792-9c0e-c915024934d8.png)
 * * 视频浮层：
 * ![图片](https://intranetproxy.alipay.com/skylark/lark/0/2021/png/358286/1624439923912-1baf68b7-1923-4620-b127-8ae56a73ec99.png)
 */
@Element({
  type: SDKElementType.image,
  build,
})
export class MediaElement extends BaseElement {
  config: IAnnotationConfigItem
  localEulerAngles: vec3
  private _transitionListener: any = null
  constructor(item: IAnnotationConfigItem, entity: Entity, public sdkType: SDKElementType) {
    super(entity, item);
    this.localEulerAngles = this.entity.transform.getlocalEulerAngles();
    this.config = item
    DataCenter.PanoramaMeshFilters.push(entity.getComponentByType(MeshFilter) as any);
    this.listener = (id: string) => {
      if (id === entity.uuid) {
        this.onClick();
      }
    }
    MessageCenter.GlobalEvent.on("click", this.listener)
    this._initBorderCube();
    this.event.on('built', () => this.updateVisibleSpots())
  }
  private listener: any;
  private _borderCube: Entity | undefined;

  get position() {
    return this.entity.transform.position;
  }

  setVisible(bool: boolean) {
    bool ? this.show() : this.hide();
  }
  hide() {
    this.entity.deactivate();
    (ElementsManager as any).getInstance().event.emit("hide", this.id);
  }
  show() {
    this.entity.activate();
    (ElementsManager as any).getInstance().event.emit("show", this.id);
  }
  onClick() {
    (ElementsManager as any).getInstance().event.emit("click", this.id);
  }

  deactivate(): void {
    super.deactivate()
    this.setMuted(true)
  }
  activate(): void {
    super.activate()
    const { info } = this.config
    this.setMuted(info ? !!info.muted : false)
  }
  dispose() {
    MessageCenter.GlobalEvent.off("click", this.listener);
    const videoPlayer = this.entity!.getComponentByType<VideoPlayer>(VideoPlayer);
    if (videoPlayer) {
      // 如果是视频浮层，删除时需要移除正在播放的视频
      videoPlayer.pause();
      videoPlayer.dispose();
      this.entity.removeComponent(videoPlayer);
    }
    super.dispose();
    this._transitionListener && this._transitionListener.unsubscribe()
  }

  public updateVisibleSpots(newVisibleSpots?: string[]) {
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    const elementsAPI = Application.getInstance<ElementsManager>(ElementsManager.ModuleName)
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const currentSpotId = spotAPI.getCurrentSpotId();
    if (!this.elementConfig.extra) {
      this.elementConfig.extra = {}
    }
    if (!this.elementConfig.extra.visibleSpots) {
      this.elementConfig.extra.visibleSpots = this.elementConfig.includeSpots
    }
    if (Array.isArray(newVisibleSpots)) {
      this.elementConfig.extra!.visibleSpots = newVisibleSpots
    }
    if (!this.elementConfig.extra!.visibleSpots) {
      return
    }
    if (this.elementConfig.extra!.visibleSpots!.includes(currentSpotId)) {
      if (elementsAPI.allVisible) {
        this.activate()
      }
    } else {
      this.deactivate()
    }
    if (this._transitionListener) {
      this._transitionListener.unsubscribe()
    }
    this._transitionListener = transitionAPI.transitionProcessObserve.subscribe((params) => {
      if (!params || !params.to || !params.from) { return }
      const toSpotID = params.to.spotId;
      if (params.state === TransitionState.afterTransition) {
        if (this.elementConfig.extra!.visibleSpots!.includes(toSpotID)) {
          if (elementsAPI.allVisible) {
            this.activate()
          }
        } else {
          this.deactivate()
        }
      }
    });
  }

  private _initBorderCube() {
    const cubeBox = Entity.CreatePrimitive(PrimitiveType.Cube);
    const mtl = new PureColorMaterial();
    mtl.color = [255, 0, 0, 0.1];
    mtl.transparent = true;
    mtl.depthWrite = false;
    cubeBox?.getComponentByType<Renderer>(Renderer)?.setMaterial(mtl);
    this._borderCube = cubeBox;
    this._borderCube?.deactivate();
  }

  highlight(color: number[]) {
    const material = this._borderCube?.getComponentByType<Renderer>(Renderer)?.material as PureColorMaterial;
    if (!material) {
      return;
    }
    material!.color = [color[0], color[1], color[2], 0.1]
    this._borderCube!.setParent(this.entity!);
    this._borderCube?.activate();
  }

  unhighlight() {
    this._borderCube?.deactivate();
  }

  /**¸¸
  * 移动标签
  * @param position
  */
  moveTo(position: vec3, normal: vec3) {
    if (Math.abs(vec3.dot(normal, Transform.Up)) > 0.999) {
      this.entity.transform.setForward(normal, [1, 0, 0]);
    } else {
      this.entity.transform.setForward(normal, [0, 1, 0]);
    }
    // 为了让浮层不被模型表面遮挡，浮层位置朝着法线方向稍微移出来一点
    let fixPosition = vec3.create();
    const scaleNormal = vec3.create();
    const tempPostion = vec3.create();
    fixPosition = vec3.add(tempPostion, position, vec3.scale(scaleNormal, normal, 0.05));
    this.entity.transform.position = fixPosition;
    this._normal = normal;
    this.updateElementConfig('anchor', {
      x: fixPosition[0],
      y: -fixPosition[2],
      z: fixPosition[1]
    });
    this.updateElementConfig('normal', {
      x: normal[0],
      y: -normal[2],
      z: normal[1]
    });
  }

  /**
   * 设置标签的位置
   * @param anchor
   */
  public setPosition(anchor: any) {
    this.entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
    this.updateElementConfig('anchor', anchor);
  }

  /**
   * 设置标签的缩放
   * @param scale
   */
  public setScale(scale: any) {
    this.entity.transform.localScale = vec3.fromValues(scale.x, scale.y, 1);
    this.updateElementConfig('scale', {
      x: scale.x,
      y: scale.y
    });
  }

  /**
   * 设置标签的旋转
   * @param rotation
   */
  public setRotation(rotation: any) {
    this.entity.transform.rotation = quat.fromEuler(quat.create() ,Transform.Rad2Deg * rotation.x, Transform.Rad2Deg * rotation.y, Transform.Rad2Deg * rotation.z);
    this.updateElementConfig('rotation', {
      x: rotation.x,
      y: rotation.y,
      z: rotation.z
    });
  }

  /**
   * 设置业务自定义附加信息
   * @param extraInfo 附加信息
   */
  public setExtra(extraInfo: any) {
    this.updateElementConfig('extra', extraInfo)
  }

  /**
   * 设置标签的整体配置信息，一次性修改所有需要的信息
   * @param config
   */
  public setTagConfig(config: any) {
    const onlyDataKey = ['spotId', 'includeSpots', 'extra'];
    onlyDataKey.forEach((item) => {
      if (config[item]) {
        this.updateElementConfig(item, config[item]);
      }
    })

    const url = config.info?.src;
    if (url) {
      this.setLabel(url);
    }
    if (config.anchor) {
      this.setPosition(config.anchor);
    }
    if (config.rotation) {
      this.setRotation(config.rotation);
    }
    if (config.scale) {
      this.setScale(config.scale);
    }
  }

  /**
   * 设置浮层视频是否静音
   * @param value
   */
  public setMuted(value: boolean) {
    const videoPlayer = this.entity!.getComponentByType<VideoPlayer>(VideoPlayer);
    if (videoPlayer && videoPlayer.videotexture) {
      videoPlayer.videotexture!.data.muted = value
    }
  }

  /**
   * 设置标签的视图, 兼容视频->视频，视频->图片，图片->图片，图片->视频
   * @param url
   */
  public async setLabel(url: string) {
    const { anchor, scale, rotation, } = this.elementConfig
    // 注意添加图片或视频的，需要再次更新entity的位置
    this.entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
    this.entity.transform.localScale = vec3.fromValues(scale.x || 0.2, scale.y || 0.2, 0.2);
    this.entity.transform.rotation = quat.fromEuler(quat.create(), Transform.Rad2Deg * rotation.x, Transform.Rad2Deg * rotation.y, Transform.Rad2Deg * rotation.z)
    const render = this.entity.getComponentByType<Renderer>(Renderer);
    if (!render) {
      return
    }
    const fileType = judgeImageOrVideo(url);
    if (!fileType) {
      throw new Error('传入的链接必须为图片或视频')
    }
    let videoPlayer = this.entity!.getComponentByType<VideoPlayer>(VideoPlayer);
    if (fileType === 'image') {
      if (videoPlayer) {
        videoPlayer.pause();
        videoPlayer.dispose();
        this.entity.removeComponent(videoPlayer);
      }
      const image = await getImage(url);
      const texture = new Texture2D(image.width, image.height);
      texture.setTextureImageSource(image, 0, true);
      texture.generateMipmaps();
      (render!.material as any).albedo?.value.dispose();
      (render.material as any).albedo = texture;
    } else {
      if (videoPlayer) {
        videoPlayer.pause();
        videoPlayer.videotexture = undefined;
      }
      else {
        videoPlayer = this.entity!.addComponent<VideoPlayer>(VideoPlayer);
      }
      const texture = await videoPlayer.loadVideo(url);
      if (!texture) { return }
      (render!.material as any).albedo?.value.dispose();
      (render!.material as any).albedo = texture;
      videoPlayer.play();
      videoPlayer.videotexture!.data.loop = true;
      this._changeVideoControlIcon('pause');
    }
    const info = this.elementConfig?.info;
    this.updateElementConfig('info', {...info, src: url})
  }

  /**
   * 播放浮层视频
   */
  public playVideo() {
    const videoPlayer = this.entity!.getComponentByType<VideoPlayer>(VideoPlayer);
    if (videoPlayer && videoPlayer.videotexture) {
      videoPlayer.play();
      this._changeVideoControlIcon('pause');
    }
  }

  /**
   * 暂停浮层视频
   */
  public pauseVideo() {
    const videoPlayer = this.entity!.getComponentByType<VideoPlayer>(VideoPlayer);
    if (videoPlayer && videoPlayer.videotexture) {
      videoPlayer.pause();
      this._changeVideoControlIcon('play');
    }
  }

  /**
   * 切换视频浮层的控制图标
   * @param type
   */
  private async _changeVideoControlIcon(type: 'play'|'pause') {
    const iconUrl = type === 'play'? playIcon : pauseIcon;
    const image = await getImage(iconUrl);
    const iconTexture = new Texture2D(image.width, image.height);
    iconTexture.setTextureImageSource(image, 0, true);
    iconTexture.generateMipmaps();
    const childEntity = this.entity?.getChildrenEntitiesRecursively();
    let controlEntity: Entity | undefined = undefined;
    childEntity?.forEach((item: any) => {
      if (item.name === 'videoControl') {
        controlEntity = item
      }
    })
    if (controlEntity) {
      const iconEntityRender = (controlEntity as Entity).getComponentByType<Renderer>(Renderer);
      (iconEntityRender!.material! as any).albedo = iconTexture;
    }
  }


}
