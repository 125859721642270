import EventEmitter from "eventemitter3";
export class StateEvent {

  public static onFlyToSpot: EventEmitter<string> = new EventEmitter<string>();

  public static onStateChange: EventEmitter<string> = new EventEmitter<string>();

  public static onArrivedSpot: EventEmitter<string> = new EventEmitter<string>();

  public static onTimeChange: EventEmitter<string> = new EventEmitter<string>();

  public static markEvent: EventEmitter<string> = new EventEmitter<string>();

  public static cameraStateEvent: EventEmitter<string> = new EventEmitter<string>();

  public static onInteract: EventEmitter<string> = new EventEmitter<string>()
}
