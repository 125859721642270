import { vgbJson, vgbJson2, VGModelConfig, VGModelConfigSkirt, VGModelConfigSuit, VGModelConfigJiashan, VGModelConfigAnt } from "@sdkCore/GameLogic/RobotStatesV2";
import { Entity, Loader, SceneManager, ComponentManager, AssetManager, TextureCube, Renderer, StandardMaterial, ShaderKeyword } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import { StateEvent } from "../../../GameLogic/StateEvent";
import { APIBaseClass } from "../../../lib/utils/eca";
import { Application } from "../../../Application";
import { SpotPrivate } from "../../spot/lib/private";
import { ConsoleLog, ErrorType } from "../../../lib/log";

export class PersonGuider extends APIBaseClass {
  static ModuleName = "personGuiderAPI";
  private _root: Entity | undefined;
  public ctr: any;
  public ctrReady = false;
  public roamingGroupJson: any;
  public descJson: any;

  /**
     * 暂停当前带看，行走人会隐藏
     */
  public pause() {
    this.ctr.pause();
  }

  /**
     * 恢复当前带看，行走人出现，并继续之前的状态
     */
  public resume() {
    this.ctr.resume();
  }

  /**
     * 切换到指定带看路径上
     * @param pathName 路径名称
     */
  public switchPath(pathName: string) {
    this.ctr.switchPath(pathName);
  }

  /**
     * 切换到下一条带看路径上，切换后会自动从当前路径下的第一个group开始播放
     */
  public switchNextPath() {
    this.ctr.switchNextPath();
  }

  /**
     * 切换到指定带看组上，切换后会自动开始播放
     * @param groupName 带看组名称
     */
  public switchGroup(groupName: string) {
    this.ctr.switchGroup(groupName);
  }

  /**
     * 切换到指定带看组上，切换后会自动开始播放
     * @param groupIndex  带看组序号
     */
  public switchGroupByIndex(groupIndex: number) {
    this.ctr.switchGroupByIndex(groupIndex);
  }

  /**
     * 切换到下一个带看组上，切换后会自动开始播放
     */
  public switchNextGroup() {
    this.ctr.switchNextGroup();
  }

  /**
     * 监听到达spot事件
     * @param callBack
     * @returns
     */
  public onArrivedSpotEvent(callBack: (pathID: number, spotID: string) => void) {
    StateEvent.onArrivedSpot.on('arrived', callBack);
    return callBack;
  }

  /**
     * 卸载对到达spot事件的监听
     * @param callBack
     */
  public offArrivedSpotEvent(callBack: (pathID: number, spotID: string) => void) {
    StateEvent.onArrivedSpot.off('arrived', callBack);
  }

  /**
     * 监听带看路径走完事件
     * @param callBack
     * @returns
     */
  public onPathEndEvent(callBack: (pathID: number, spotID: string) => void) {
    StateEvent.onArrivedSpot.on('finish', callBack);
    return callBack;
  }

  /**
     * 卸载对带看路径走完事件的监听
     * @param callBack
     */
  public offPathEndEvent(callBack: (pathID: number, spotID: string) => void) {
    StateEvent.onArrivedSpot.off('finish', callBack);
  }

  /**
     * 监听当前带看组切换事件
     * @param callBack
     * @returns
     */
  public onGroupChanged(callBack: (pathName: string, groupName: string, groupIndex: number) => void) {
    StateEvent.onArrivedSpot.on('groupChaned', callBack);
    return callBack;
  }

  /**
     * 卸载对当前带看组切换事件的监听
     * @param callBack
     */
  public offGroupChanged(callBack: (pathName: string, groupName: string, groupIndex: number) => void) {
    StateEvent.onArrivedSpot.off('groupChaned', callBack);
  }

  /**
     * 监听当前带看组语音播放时间变化
     * @param callBack
     */
  public onGroupTimeChanged(callBack: (pathName: string, groupName: string, currentTime: number, totalTime: number) => void) {
    StateEvent.onTimeChange.on('groupTimeChanged', callBack);
  }

  /**
     * 卸载对当前带看组语音播放时间变化的监听
     * @param callBack
     */
  public offGroupTimeChanged(callBack: (pathName: string, groupName: string, currentTime: number, totalTime: number) => void) {
    StateEvent.onTimeChange.off('groupTimeChanged', callBack);
  }

  /**
     * 监听带看语音播放状态变化（暂停/恢复）
     * @param callBack
     */
  public onSoundPlayChange(callBack: (type: string, currentTime: number, totalTime: number) => void) {
    StateEvent.onTimeChange.on('soundPlayChanged', callBack);
  }

  /**
     * 卸载对带看语音播放状态变化的监听
     * @param callBack
     */
  public offSoundPlayChange(callBack: (type: string, currentTime: number, totalTime: number) => void) {
    StateEvent.onTimeChange.off('soundPlayChanged', callBack);
  }

  /**
     * 监听到达标识点事件
     * @param callBack
     * @returns
     */
  public onArrivedMarkEvent(callBack: (mark: any) => void) {
    StateEvent.markEvent.on('arrivedMark', callBack);
    return callBack;
  }

  /**
     * 卸载对到达标识点事件的监听
     * @param callBack
     * @returns
     */
  public offArrivedMarkEvent(callBack: (mark: any) => void) {
    StateEvent.markEvent.off('arrivedMark', callBack);
  }

  /**
     * 监听标识点停留完成事件
     * @param callBack
     * @returns
     */
  public onEndMarkStopEvent(callBack: (mark: any) => void) {
    StateEvent.markEvent.on('endMarkStop', callBack);
    return callBack;
  }

  /**
     * 卸载对监听标识点停留完成事件的监听
     * @param callBack
     * @returns
     */
  public offEndMarkStopEvent(callBack: (mark: any) => void) {
    StateEvent.markEvent.off('endMarkStop', callBack);
  }

  /**
     * 展示隐藏虚拟导购
     * @param  isVisible 可见状态，true（显示） |  false（隐藏）
     */
  public setGuiderVisible(isVisible: boolean) {
    if (isVisible) {
      this._root?.activate();
    } else {
      this._root?.deactivate();
    }
  }

  /**
     * 获取走路人初始化是否完成状态
     * Gets guider ready state
     * @returns  boolean
     */
  public getGuiderReadyState() {
    return this.ctrReady;
  }

  /**
     * 初始化虚拟导购
     * @param style 导购风格，默认是用v2,将启用系统内置
     * @param behavior 导购行为模式，默认是v2，导购行为模式将采用自动内置
     * @param descJson 特定json配置
     */
  init(roamingGroupConfigJson: any, descJson: any) {
    this.roamingGroupJson = roamingGroupConfigJson
    this.descJson = descJson
    const virtualSkin = roamingGroupConfigJson.virtualSkin
    const virtualModelUrl = roamingGroupConfigJson.virtualModelUrl
    return new Promise((resolve, reject) => {
      let sJson: any;
      let bJson: any;
      switch (virtualSkin) {
        case 'v2':
          sJson = VGModelConfig;
          break;
        case 'v2_skirt':
          sJson = VGModelConfigSkirt;
          break;
        case 'v2_suit':
          sJson = VGModelConfigSuit;
          break;
        case 'jiashan_only':
          sJson = VGModelConfigJiashan;
          break;
        case 'ant':
          sJson = VGModelConfigAnt;
          break
        default:
          sJson = VGModelConfig;
          break;
      }
      if (virtualModelUrl) {
        Object.assign(sJson.modelConfig, {
          modelURL: virtualModelUrl
        })
      }
      switch (virtualSkin) {
        case 'v1':
          bJson = vgbJson;
          break;
        case 'v2':
          bJson = vgbJson2;
          break;
        default:
          bJson = vgbJson2;
          break;
      }

      this.makeInstance(sJson, bJson, descJson).then((res) => {
        resolve(true);
      }).catch(err => {
        reject(err);
      })

    });
  }

  /**
   * 实例化一个导购对象
   * @param json
   */
  private async makeInstance(style: any, behavior: any, descJson: any): Promise<Entity | undefined> {
    return new Promise<Entity | undefined>(async (resolve, reject) => {
      //虚拟角色会新增一个root
      this._root = new Entity('root');
      try {
        const model = await this._loadModel(style.modelConfig);
        if (model) {
          Object.assign(this._root, {
            _model: model
          })
          this._root.transform.position = model.transform.position;
          this._root.transform.rotation = model.transform.rotation;
          model.setParent(this._root);
          SceneManager.GetInstance().currentScene.addEntity(this._root);

          //给root添加控制组件
          const com = ComponentManager.GetDef(style.modelConfig.commponentName);
          this.ctr = this._root.addComponent(com) as any;
          this.ctr.behaviorJson = behavior;
          this.ctr.descJson = descJson;
          await this.ctr.init();
          this.ctrReady = true;
          resolve(this._root);
        }
      } catch (error) {
        reject(error)
        throw new Error('state 实例化异常');
      }
    })
  }

  /**
     * 改变人物模型
     * @param type
     */
  public async changeModel(type: string) {
    let sJson: any;
    switch (type) {
      case 'v2':
        sJson = VGModelConfig;
        break;
      case 'v2_skirt':
        sJson = VGModelConfigSkirt;
        break;
      case 'v2_suit':
        sJson = VGModelConfigSuit;
        break;

      default:
        sJson = VGModelConfig;
        break;
    }
    // 先移除原来的虚拟人物模型
    const childModels = this._root?.getChildrenEntitiesRecursively();
    childModels?.forEach((item: any) => {
      if (item.name === 'personGuider') {
        Entity.Destroy(item);
      }
    })
    // 重新加载新模型
    const model = await this._loadModel(sJson.modelConfig);
    if (model) {
      this._root!.transform.position = model.transform.position;
      this._root!.transform.rotation = model.transform.rotation;
      model.setParent(this._root!);
      // 重新更新动画模型
      this.ctr.updateAniCom();
    }

  }

  /**
     * 放置人物到指定spot点
     * @param placeSpotID  人物放置的spot点
     * @param faceSpotID   人物朝向的spot点
     */
  public placeToSpot(placeSpotID: string, faceSpotID?: string) {
    this.ctr.placeToSpot(placeSpotID);
    if (!faceSpotID) {
      const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
      faceSpotID = spotAPI.getCurrentSpotId();
    }
    this.ctr.faceToSpot(faceSpotID);
  }

  /**
     * 加载虚拟导购模型
     * @param modelConfig
     * @returns
     */
  private _loadModel(modelConfig: any) {
    return new Promise<Entity | undefined>(async(resolve: any, reject: any) => {
      Loader.LoadGLTF(modelConfig.modelURL).then((model: any) => {
        if (!model) { resolve(); return }
        const list: Entity[] = model.getChildrenEntitiesRecursively();
        list.forEach(entity => {
          if (!entity) { return }
          const render = entity.getComponentByType<Renderer>(Renderer);
          if(!render){ return }
          const material = render.material;
          if (material && material instanceof StandardMaterial) {
            material.enableKeywords(ShaderKeyword.TangentSpaceNormalMap);
            material.irradianceMap = AssetManager.GetInstance().getAsset<TextureCube>('irradianceCubemap')!;
            material.radianceMap = AssetManager.GetInstance().getAsset<TextureCube>('radianceCubemap')!;
            material.roughnessFactor = 0.95;
            const baseColorFactor =  0.8;
            material.baseColorFactor = [baseColorFactor, baseColorFactor, baseColorFactor];
          }
        });
        model.name = 'personGuider';
        const scale = modelConfig.scale;
        model.transform.localScale = vec3.fromValues(scale, scale, scale);
        resolve(model);
      }).catch((e: any) => {
        ConsoleLog.error('PersonGuider', '_loadModel', ErrorType.DataError, '加载虚拟人模型出错', e);
        reject(e)
      })
    })
  }

  dispose() {
    this.ctr = undefined;
    this.ctrReady = false;
    if (this._root) {
      Entity.Destroy(this._root);
    }
    this._root = undefined;
  }


}
