import { BasicState, InjectState, expJson } from '../States';

export class InfoSrc {
  public name!: string;
  public age!: number;
  constructor(){
    //
  }
}

@InjectState<InfoSrc>('IdleState')
export class IdleState extends BasicState<InfoSrc> {
  @expJson('info')
  public info = 'idle';

  @expJson('time')
  public time = 30;

  @expJson('distance')
  public distance = 1;

  @expJson('onTimeState')
  public onTimeState: BasicState<InfoSrc> | undefined;

  @expJson('onDistanceState')
  public onDistanceState: BasicState<InfoSrc> | undefined;

  private _currentTime = 0;

  update (deltaTime: number) {
    this._currentTime += deltaTime;
    if (this._currentTime >= this.time) {
      if (this.onTimeState) {
        this.onTimeState.changeTo();
      }
    }
    // 距离检测
  }

  onEnter () {
    this._currentTime = 0;
  }

  onExit () {
  }
}
