import { Camera, Constants, Entity, Renderer, Texture2D, TextureCube, Viewer, TextureFormat, TextureCubeFace, ShaderMaterial, RenderQueue } from '@ali/tidejs'
import { mat3, mat4, vec3 } from 'gl-matrix';
import { SpotToSpotTransitionMaterial } from '../../Material/SpotToSpotTransitionMaterial';

import { SpotToNormalMaterial } from '../../Material/SpotToNormalMaterial';
import { UpdatePanorama } from '../UpdatePanorama';
import { Application } from '../../Application';
import { CameraPrivate } from '../../api/camera/lib/private';
import { ConfigPrivate } from "../../api/config/lib/private";
import { SpotPrivate } from '../../api/spot/lib/private';
import { performanceMark } from '../../lib/utils';
import { MessageCenter } from '../../GameLogic/MessageCenter';
import { createCubeFromOrthConfig } from '../GameEnter';

export function isSupportCubeImageFilter(): boolean {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('Windows') > -1 || ua.indexOf('Android') > -1 || ua.indexOf('like Mac OS X') > -1)
    return true;
  return false;
}

export function isMobileDevice(): boolean {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('Android') > -1 || ua.indexOf('like Mac OS X') > -1)
    return true;
  return false;
}

/**
 * LOD 层次信息存储
 * @param name
 * @param x
 * @param y
 * @param initData
 */
export function createDataTextureCube(name: string, x: number, y: number, initData: number[]) {
  if (x === 2048 && y === 2048) {
    let tex: TextureCube;
    if (isSupportCubeImageFilter())
      tex = new TextureCube(x, y, TextureFormat.R8G8B8, true);
    else
      tex = new TextureCube(x, y, TextureFormat.R8G8B8, false);

    return tex;
  }
  if (x === 512 && y === 512) {
    const tex = new TextureCube(x, y, TextureFormat.R8G8B8, false);
    return tex;
  }
  const initLevelData: number[] = [];
  for (let i = 0; i < x; i++) {
    for (let j = 0; j < y; j++) {
      initLevelData.push(...initData);
    }
  }
  const initBuf: Uint8ClampedArray = new Uint8ClampedArray(initLevelData);
  const initRawData: ImageData = new ImageData(initBuf, x, y);
  const tex = new TextureCube(x, y, TextureFormat.R8G8B8A8, false);
  for (let i = 0; i < 6; i++) {
    tex.setTextureImageSource(TextureCubeFace.PositiveX + i, initRawData);
  }
  return tex;
}
export interface IInterpolator {
  setSrc(parameter: any): void;
  setDest(parameter: any): void;
  update(progress: number): void;
  changeTo(target: IInterpolator): void;
}
export class CubeInterpolator {

  private static _cubeInterpolator: CubeInterpolator;
  public static getInstance() {
    if (!CubeInterpolator._cubeInterpolator) {
      CubeInterpolator._cubeInterpolator = new CubeInterpolator();
    }
    return CubeInterpolator._cubeInterpolator;
  }
  public needInit = true;

  private _srcCenter = vec3.create();
  private _tgtCenter = vec3.create();
  private _srcMartix!: mat3;
  private _srcCube!: TextureCube;
  private _destMartix!: mat3;
  private _destCube!: TextureCube;

  // 模型是否加载完，没加载完就不允许跳转
  private hasModel = false;

  private _currentEntityRenderList: Renderer[] = [];
  private _currentMaterial: any;
  private modelTexture: Texture2D | undefined;
  /**
     * 为正时，从0-1渐变，false时，1-0渐变
     */
  private _flip = false;
  private _perspective2orthographicFlip = false;

  private _material!: SpotToSpotTransitionMaterial;
  private _materialNormal!: SpotToNormalMaterial;

  //2k cube纹理处理
  private _need2K = true;
  private _cubeSrcLevel!: TextureCube;
  private _cubeSrcMipFlag!: TextureCube;
  private _cubeTargetLevel!: TextureCube;
  private _cubeTargetMipFlag!: TextureCube;
  private _cubeSrc2K!: TextureCube;
  private _cubeTarget2K!: TextureCube;
  private _cubUpdateTool!: UpdatePanorama;
  private count = 0;

  // 全景盒子
  private cube!: Entity

  get modelMaterial() {
    return this._material;
  }

  constructor() {
    this._material = new SpotToSpotTransitionMaterial();
    this._materialNormal = new SpotToNormalMaterial();
    this._materialNormal.transparent = true;
    this._materialNormal.renderQueue = RenderQueue.Geometry;

    this.initCube();
    MessageCenter.DisposeEvent.once("dispose", () => {
      (CubeInterpolator as any)._cubeInterpolator = undefined;
    })
  }
  dispose() {
    this._cubeSrc2K.dispose();
    this._cubeSrcLevel.dispose();
    this._cubeSrcMipFlag.dispose();
    this._cubeTarget2K.dispose();
    this._cubeTargetLevel.dispose();
    this._cubeTargetMipFlag.dispose();
    this._material.release();
    this._materialNormal.release();
  }
  setModel(entity: Entity, isRoot: boolean) {
    const render = entity.getComponentByType(Renderer)! as Renderer;
    render.material = (this._material.clone() as ShaderMaterial);
    render.material.cullface = -1;
    this._currentEntityRenderList.push(render);
    if (!isRoot) this.cube = entity // 保存全景cube
    this._cubUpdateTool = entity.addComponent(UpdatePanorama);
    if (!isRoot) { return }

    if (!this._need2K) { return }
    this.init2K();

    this._material.cubeSource2K = this._cubeSrc2K;
    this._material.cubeTarget2K = this._cubeTarget2K;
    this._material.cubeSourceLevel = this._cubeSrcLevel;
    this._material.cubeTargetLevel = this._cubeTargetLevel;
    this._material.cubeSourceMipFlag = this._cubeSrcMipFlag;
    this._material.cubeTargetMipFlag = this._cubeTargetMipFlag;
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spotId = spotAPI.getCurrentSpotId();
    this.loadAndApplay2KCube(spotId, this._cubeTargetLevel, this._cubeTargetMipFlag, this._cubeTarget2K);
  }
  setModelTexture(modelTexture: any) {
    if (!this.modelTexture) {
      this.modelTexture = modelTexture
      if (this.modelTexture && this.modelTexture) {
        this._materialNormal.modelTexture = this.modelTexture;
      }
    }
  }
  init2K() {
    this._cubeSrcLevel = createDataTextureCube("_srcCubeLevel", 16, 16, [255, 0, 255, 255]);
    this._cubeTargetLevel = createDataTextureCube("_tgtCubeLevel", 16, 16, [255, 0, 255, 255]);

    this._cubeSrcLevel.minFilter = Constants.LINEAR;
    this._cubeSrcLevel.magFilter = Constants.LINEAR;
    this._cubeTargetLevel.minFilter = Constants.LINEAR;
    this._cubeTargetLevel.magFilter = Constants.LINEAR;

    this._cubeSrcMipFlag = createDataTextureCube("_srcCubeMipFlag", 4, 4, [0, 0, 0, 255]);
    this._cubeTargetMipFlag = createDataTextureCube("_tgtCubeMipFlag", 4, 4, [0, 0, 0, 255]);

    this._cubeSrcMipFlag.minFilter = Constants.LINEAR;
    this._cubeSrcMipFlag.magFilter = Constants.LINEAR;
    this._cubeTargetMipFlag.minFilter = Constants.LINEAR;
    this._cubeTargetMipFlag.magFilter = Constants.LINEAR;

    this._cubeSrc2K = createDataTextureCube("_srcCube2K", 2048, 2048, [0, 0, 0, 0]);
    this._cubeSrc2K.minFilter = Constants.LINEAR;
    this._cubeSrc2K.magFilter = Constants.LINEAR;
    this._cubeSrc2K.wrapS = Constants.REPEAT;
    this._cubeSrc2K.wrapT = Constants.REPEAT;

    this._cubeTarget2K = createDataTextureCube("_tgtCube2K", 2048, 2048, [0, 0, 0, 0]);
    this._cubeTarget2K.minFilter = Constants.LINEAR;
    this._cubeTarget2K.magFilter = Constants.LINEAR;
    this._cubeTarget2K.wrapS = Constants.REPEAT;
    this._cubeTarget2K.wrapT = Constants.REPEAT;
  }
  private setCurrentRenderMaterial() {
    this._currentEntityRenderList.forEach((item) => {
      item.material = this._currentMaterial.clone();
    })
  }

  private initCube() {
    this._srcCube = createDataTextureCube("_srcCube", 512, 512, [0, 0, 0, 0]);
    this._destCube = createDataTextureCube("_destCube", 512, 512, [0, 0, 0, 0]);
  }

  private setMaterialMatrix() {
    this._material.sourceCenter = this._srcCenter;
    this._material.targetCenter = this._tgtCenter;
    this._material.sourceWorldToCameraMatrix = this._srcMartix;
    this._material.targetWorldToCameraMatrix = this._destMartix;
  }

  // when the fun is execute,must run upLoad to update the texture
  private copyDest2Src() {
    //copy上一次的dest数据到src
    // this._srcCube = this._destCube;
    this._srcMartix = mat3.clone(this._destMartix);
    this._srcCenter = vec3.clone(this._tgtCenter);

    //2k
    this._cubeSrc2K = this._cubeTarget2K;
    this._cubeSrcLevel = this._cubeTargetLevel;
  }

  public get materialNormal(): SpotToNormalMaterial {
    return this._materialNormal;
  }

  public set materialNormal(value: SpotToNormalMaterial) {
    this._materialNormal = value;
  }


  public calSpotMatrixV2(srcForward: vec3, srcUp: vec3): mat3 {
    const srcMatrix = mat3.fromMat4(mat3.create(), mat4.lookAt(mat4.create(), [0, 0, 0], srcForward, srcUp));
    return srcMatrix;
  }

  public getSpotMatrix(spotId: string) {
    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    const toSpot = spotAPI.getHotSpotById(spotId)!;
    const { upwardDirection, forwardDirection } = toSpot;
    const srcMatrix = mat3.fromMat4(mat3.create(), mat4.lookAt(mat4.create(), [0, 0, 0], forwardDirection, upwardDirection));
    return srcMatrix;

  }

  /**
     * 在末尾设置normal 纹理，用于 spot->normal
     */
  public async setNormalAtEnd() {
    this._currentMaterial = this._materialNormal;
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    const aspect = Viewer.Current.getAspectRatio();
    const yRatio = cameraAPI.yRatio;
    const length = cameraAPI.topViewHeight / yRatio;
    this._currentMaterial.orthographicProjection = mat4.ortho(mat4.create(), -length * 0.5 * aspect, length * 0.5 * aspect, -length * 0.5, length * 0.5, Camera.MainCamera!.near, Camera.MainCamera!.far);
    this._flip = false;
    //指定normal纹理
    this._materialNormal.cube = this._destCube;
    this._materialNormal.center = this._tgtCenter;
    this._materialNormal.worldToCameraMatrix = this._destMartix;
    if (this._cubeTargetLevel) {
      this._materialNormal.level2k = this._cubeTargetLevel;
    }
    if (this._cubeTarget2K) {
      this._materialNormal.cube2K = this._cubeTarget2K;
    }
    //this.uploadCube2k()
    // this._currentEntityRender.material = this._materialNormal;
    this.setCurrentRenderMaterial()
    this._currentMaterial.cullface = Constants.BACK;

    this.finish = () => { };
    // 当视图框高变化时，及时更新
    MessageCenter.GlobalEvent.on("resize", () => {
      const aspect = Viewer.Current.getAspectRatio();
      const length = cameraAPI.topViewHeight / yRatio;
      this._currentMaterial.orthographicProjection = mat4.ortho(mat4.create(), -length * 0.5 * aspect, length * 0.5 * aspect, -length * 0.5, length * 0.5, Camera.MainCamera!.near, Camera.MainCamera!.far);
    });

  }
  /**
   * 根据楼层更新全景盒子的大小和位置🌹
   */
  updataCubeTransform(spotId: string) {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const config = configAPI.getOrthomapConfig();
    if(ConfigPrivate.Config.multiFloor){
      createCubeFromOrthConfig(config, this.cube as Entity, spotId);// 根据楼层修改全景盒子大小
    }
  }

  /**
     * 在开头设置normal 纹理，用于  normal-> spot
     * @param pos
     */
  public async setNormalAtStart(spotId: string, pos: vec3) {
    this.updataCubeTransform(spotId);
    this._currentMaterial = this._materialNormal;
    this._need2K = true;
    this._flip = true;
    // const cubeList = await getCubeTextureUrl(spotId);

    await this._cubUpdateTool.updateSubTextureCube512(spotId, this._destCube);
    this._tgtCenter = pos;
    this._destMartix = this.getSpotMatrix(spotId);
    // //下载全景图到dest
    // await WanHuaTongUtility.LoadTextureCube(cubeList, false).then(cubeSource => {
    //     if (!cubeSource || !cubeSource.texture) { return }
    //     this._destCube = cubeSource;
    //     this._tgtCenter = pos;
    //     this._destMartix = this.getSpotMatrix(spotId);
    // })
    this._materialNormal.cube = this._destCube;
    this._materialNormal.center = this._tgtCenter;
    this._materialNormal.worldToCameraMatrix = this._destMartix;
    // 必须为奇数
    this.count = 1;
    //从外部切回全景，512的标志位要更新
    this._cubUpdateTool.resetLevelCubeTo512(this._cubeSrcLevel, this._cubeSrcMipFlag);
    this._cubUpdateTool.resetLevelCubeTo512(this._cubeTargetLevel, this._cubeTargetMipFlag);

    this.setCurrentRenderMaterial();

    if (this._need2K) {
      this.finish = () => {
        this._currentMaterial.cullface = -1;
        this.loadAndApplay2KCube(spotId, this._cubeTargetLevel, this._cubeTargetMipFlag, this._cubeTarget2K);
        this.finish = () => { };
      }
    }
  }
  public async initDest(spotId: string, pos: vec3) {

    this.needInit = false;

    //下载默认第一个全景图 512版本
    // const cubeList = await getCubeTextureUrl(spotId);
    // await WanHuaTongUtility.LoadTextureCube(cubeList, false).then(cubeSource => {
    //     if (!cubeSource || !cubeSource.texture) { return }
    //     this._srcCube = cubeSource;
    //     this._destCube = cubeSource;
    //     //制定到材质 512
    //     this._material.cubeSource = this._srcCube;
    //     this._material.cubeTarget = this._destCube;
    //     // Camera!.MainCamera?.backGround = this._material.cubeSource;

    // })
    await this._cubUpdateTool.updateSubTextureCube512(spotId, this._srcCube, this._destCube);
    this._material.cubeSource = this._srcCube;
    this._material.cubeTarget = this._destCube;

    this._srcMartix = this.getSpotMatrix(spotId);
    this._destMartix = this._srcMartix;
    this._srcCenter = pos;
    this._tgtCenter = pos;
    this.setMaterialMatrix();
  }



  public async setDest(spotId: string, pos: vec3) {
    this._currentMaterial = this._material;
    this._flip = false;
    this._need2K = true;

    //vec3.normalize(forward,vec3.fromValues(forward[0],0,forward[1]));
    //初始化
    if (this.needInit) {
      await this.initDest(spotId, pos);
      return
    }
    this.updataCubeTransform(spotId)
    // const cubeList = await getCubeTextureUrl(spotId);
    this.copyDest2Src();

    performanceMark('getImage-begin');
    //下载全景图到dest
    if (this.count % 2 === 0) {
      await this._cubUpdateTool.updateSubTextureCube512(spotId, this._destCube);
      this._material.cubeSource = this._srcCube;
      this._material.cubeTarget = this._destCube;
    } else {
      await this._cubUpdateTool.updateSubTextureCube512(spotId, this._srcCube);
      this._material.cubeSource = this._destCube;
      this._material.cubeTarget = this._srcCube;
    }
    this.count++;

    // await WanHuaTongUtility.LoadTextureCube(cubeList, false).then(newCube => {
    //     if (!cubeSource || !cubeSource.texture) { return }
    //     this._destCube = newCube;
    //     this._material.cubeSource = this._srcCube;
    //     this._material.cubeTarget = this._destCube;
    //     performanceMark('getImage-end');
    // })

    //制定材质矩阵
    this._destMartix = this.getSpotMatrix(spotId);
    //this._destMartix = this.getSpotMatrix(spotId);

    this._tgtCenter = pos;
    this.setMaterialMatrix();

    //每次起跳前，将cubeTargetlevel 更新为512状态， [255,0,255,255]
    this._cubUpdateTool.resetLevelCubeTo512(this._cubeTargetLevel, this._cubeTargetMipFlag);

    //如果target还在更新2k，则跳转之前，将src的标志位也取到512,避免看到错误的2k src图像
    // if (this._cubUpdateTool.inDownloading()) {
    //     this._cubUpdateTool.resetLevelCubeTo512(this._cubeSrcLevel);
    // }

    // this._currentEntityRender.material = this._material;
    this.setCurrentRenderMaterial()

    if (this._need2K) {
      // todo: finish
      this.loadAndApplay2KCube(spotId, this._cubeTargetLevel, this._cubeTargetMipFlag, this._cubeTarget2K);
    }
  }

  public async setNormalDest() {
    this.needInit = false; // 初始如果直接进入normal视图，不需要去init全景视图的设置
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    const yRatio = cameraAPI.yRatio;
    this._currentMaterial = this._materialNormal;
    this._materialNormal.cube = this._destCube;
    this._materialNormal.center = this._tgtCenter;
    this._materialNormal.time = 1.0;
    if (this._cubeTargetLevel) {
      this._materialNormal.level2k = this._cubeTargetLevel;
    }
    if (this._cubeTarget2K) {
      this._materialNormal.cube2K = this._cubeTarget2K;
    }
    this.setCurrentRenderMaterial();
    this._currentMaterial.cullface = Constants.BACK;
    // 当视图框高变化时，及时更新
    MessageCenter.GlobalEvent.on("resize", () => {
      const aspect = Viewer.Current.getAspectRatio();
      const length = cameraAPI.topViewHeight / yRatio;
      this._currentMaterial.orthographicProjection = mat4.ortho(mat4.create(), -length * 0.5 * aspect, length * 0.5 * aspect, -length * 0.5, length * 0.5, Camera.MainCamera!.near, Camera.MainCamera!.far);
    });
  }

  //will be call on the end of update;
  private finish() {
    //
  }

  public update(ratio: number, p2oratio?: number) {
    const _temp = ratio;
    if (this._flip) {
      ratio = 1 - ratio;
    }
    this._currentMaterial.time = ratio;

    if (p2oratio !== undefined) {
      this._currentMaterial.time = 2 * Math.min(0.5, ratio);
      if (p2oratio) {
        this._currentMaterial.perspectiveToOrthographicTime = 2 * Math.min(1.0, Math.max(0, p2oratio - 0.5));
        if (this._perspective2orthographicFlip) {
          this._currentMaterial.perspectiveToOrthographicTime = 1.0 - this._currentMaterial.perspectiveToOrthographicTime;
        }
        if (p2oratio == 1.0) { // set to Orthographic
          Camera.MainCamera!.orthographic = true;
          const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
          const length = cameraAPI.topViewHeight;
          Camera.MainCamera!.orthographicSize = length * 0.5 / cameraAPI.yRatio;
        } else {
          Camera.MainCamera!.orthographic = false;
        }
      }
    }

    if (_temp === 1) {
      this.finish();
    }
  }


  async loadAndApplay2KCube(spotId: string, level: TextureCube, mipFlag: TextureCube, cube2K: TextureCube) {
    this.loading2K = true;
    this._cubUpdateTool.updateSubTextureCube(spotId, level, mipFlag, cube2K);
  }

  private loading2K = false;
  public cancle2KCube() {
    this.loading2K = false;
    //
  }

  public setMultiFloorMaterial(base: number, height: number) {
    this._materialNormal.displayRange = [base, height];
  }
}
