import { API } from "../../lib/utils/eca";
import { CameraPrivate } from "./lib/private";
/**
 * @ignore
 * 相机运行时
 */

@API()
export class CameraRuntime extends CameraPrivate {
}
