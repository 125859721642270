import uuid from 'uuid-browser/v4.js';
import { ConsoleLog } from "../../lib/log";

export function expJson (completeName: string): Function {
  return (target: Record<string, any> | Function, key: string): void => {
    // find the real key
    let explist: Map<string, string>;
    if (Reflect.hasMetadata('explist', target)) {
      explist = Reflect.getMetadata('explist', target);
    } else {
      explist = new Map<string, string>();
    }
    explist.set(completeName, key);
    Reflect.defineMetadata('explist', explist, target);
  };
}
export class BasicState<T> {
  public id: string;
  private _mgr: StateMgr<T>;
  /**
     * 状态机的共享数据
     */
  public srcInfo!: T;
  public isPausing = false;
  constructor (mgr: StateMgr<T>, t: T, id?: string) {
    this._mgr = mgr;
    this.srcInfo = t;
    if (id) {
      this.id = id;
    } else {
      this.id = uuid();
    }
  }

  update (deltaTime: number) {
    throw new Error('not implements');
  }

  onEnter () {
    throw new Error('not implements');
  }

  onExit () {
    throw new Error('not implements');
  }

  changeTo () {
    this._mgr.changeTo(this);
  }

  pause() {
    throw new Error('not implements');
  }

  resume() {
    throw new Error('not implements');
  }

  async Init (): Promise<boolean> {
    return true;
  }
}
export class StatesMapc<T> {
  private static _Instance: any;
  public static GetInstance<T>(): StatesMapc<T> {
    if (this._Instance == null) {
      this._Instance = new StatesMapc<T>();
    }
    return this._Instance;
  }
  public map: Map<string, new(mgr: StateMgr<T>, t: T, id?: string) => BasicState<T>> = new Map<string, new(mgr: StateMgr<T>, t: T, id?: string) => BasicState<T>>();
}

/**
 * 将类添加到全局状态map；T为共享数据类型
 * @param className 序列化时真实的类名
 * @returns
 */
export function InjectState<T> (className: string) {
  return function (target: any) {
    StatesMapc.GetInstance<T>().map.set(className, target);
    ConsoleLog.info('states', 'InjectState', 'className', className, StatesMapc.GetInstance<T>().map);
  };
}
/**
 * 状态机管理器
 *
 * T为共享数据类型
 */
export class StateMgr<T> {
  protected _states: Map<string, BasicState<T>> = new Map<string, BasicState<T>>();
  protected _current: BasicState<T> | undefined;
  protected _readly = false;
  protected _root!: BasicState<T>;
  protected _isPausing = false;
  private _autoPlay = false;

  async init (): Promise<void> {
    this._readly = true;
  }
  /**
     * 构建状态机
     * @param json 状态机逻辑json
     * @param t 同享数据实例
     */
  async fromJson (json: any, t: T) {
    // 1. build state
    const allPromises: Promise<boolean>[] = [];
    json.list.forEach(async (s: { parameters: any }) => {
      const state = this.buildStateFromJson(s,t);
      this.setParameters(s.parameters, state);
      allPromises.push(state.Init());
    });
    await Promise.all(allPromises);

    // 2. build Correlation
    json.list.forEach((s: { id: string; events: any[] }) => {
      const state = this._states.get(s.id);
      s.events.forEach((e: { target: string; name: string | number }) => {
        const target = this._states.get(e.target);
        if (target) {
          (state! as any)[e.name] = target;
        }
      });
    });

    this._root = this._states.get(json.root)!;
    this._autoPlay = json.autoPlay;
    if (this._autoPlay) {
      this.changeTo(this._root);
    }

    this.init();
  }

  buildStateFromJson (json: any, t: T): BasicState<T> {
    const id = json.id || undefined;
    const s = this.loadByClassName(json.className,t,id);
    this._states.set(s.id, s);
    return s;
  }


  changeTo (state: BasicState<T>) {
    if (this._current) this._current.onExit();
    this._current = state;
    this._current.onEnter();
  }

  changeToRoot() {
    if (this._isPausing) {
      this.resume();
    }
    this.changeTo(this._root);
  }

  update (deltaTime: number) {
    if (this._readly && !this._isPausing) {
      this._current?.update(deltaTime);
    }
  }

  setParameters (json: any, state: BasicState<T>) {
    const temp = state as any;
    if (Reflect.hasMetadata('explist', temp)) {
      const keyMap = Reflect.getMetadata('explist', temp);
      for (const completeKey in json) {
        const simpleKey = keyMap.get(completeKey);
        temp[simpleKey] = json[completeKey];
      }
    }
  }

  loadByClassName (className: string,t: T ,id?: string): BasicState<T> {
    const C = StatesMapc.GetInstance<T>().map.get(className);
    if (C) {
      return (new C(this, t, id)) as BasicState<T>;
    }
    throw new Error(`class name error ${className}`);
  }

  /**
     * 暂停状态流转
     */
  pause() {
    if (this._readly && this._current && !this._isPausing) {
      this._current.pause();
      this._current.isPausing = true;
      this._isPausing = true;
    }
  }

  /**
     * 恢复，从当前态恢复
     */
  resume() {
    if (this._readly && this._current && this._isPausing) {
      this._current.resume();
      this._current.isPausing = false;
      this._isPausing = false;
    }
  }
  /**
     * 重置，清空当前态
     */
  reset() {
    this.resume();
    this._current?.onExit();
    this._current = undefined;
  }
}
