import EventEmitter from "eventemitter3";

export class MessageCenter {
  public static JumpEvent = new EventEmitter<"cameraShake" | "toSpot" | "finish" | "start">();

  /**
     * 包含时间 modelLoaded
     *
     * resize 是窗口变化事件
     *
     * click 是物体点击事件
     *
     * @example
     * ~~~ typescript
     *  //modelLoaded 模型加载完毕消息
     *  MessageCenter.GlobalEvent.on('modelLoaded', (name:string)=>{...});
     *  MessageCenter.GlobalEvent.emit('modelLoaded',name);
     * ~~~
     */
  public static GlobalEvent = new EventEmitter<"resize" |"modelLoaded" | "click" | "move">();
  // 这里不能改为一次性的
  public static DisposeEvent = new EventEmitter<"dispose">();
  public static ReloadEvent = new EventEmitter<"reload">();
}
export function MessageDispose(){
  MessageCenter.DisposeEvent.emit("dispose");
  MessageCenter.JumpEvent.removeAllListeners();
  MessageCenter.GlobalEvent.removeAllListeners();
}
export function MessageReload(){
  MessageCenter.ReloadEvent.emit("reload");
  MessageCenter.JumpEvent = new EventEmitter<"cameraShake" | "toSpot" | "finish" | "start">();
  MessageCenter.GlobalEvent = new EventEmitter<"resize" |"modelLoaded" | "click" | "move">();
}
