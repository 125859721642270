import { IAnnotationConfigItem, SDKElementType } from "../../../../api/elements/interface";
import { addImageElement, addVideoElement } from "../../../../api/elements/lib/util";
import { API, ElementAPIBaseClass } from "../../../../lib/utils/eca";
import { MediaElement } from "./MediaElement";
import EventEmitter from "eventemitter3";
import { Entity } from "@ali/tidejs";

@API()
export class MediaElementPrivate extends ElementAPIBaseClass<MediaElement, any> {

  static ModuleName = "mediaElementAPI";
  /**
   * @ignore
   * Element type of annotation2 dprivate
   */
  public elementType = SDKElementType.image;

  public event = new EventEmitter<"enter" | "exit", string>();

  async addImageElement(item: IAnnotationConfigItem) {
    const imageEntity = await addImageElement(item);
    const element = new MediaElement(item, imageEntity as Entity, SDKElementType.image);
    return element;
  }
  async addVideoElement(item: IAnnotationConfigItem) {
    const videoEntity = await addVideoElement(item);
    const element = new MediaElement(item, videoEntity as Entity, SDKElementType.video);
    return element;
  }
}
