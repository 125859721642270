import worker from 'web-worker:./worker';

import PQueue from 'p-queue';

export async function decodeInWorker(decoder: any, blob: Blob): Promise<ImageBitmap> {
  // !! 这里用FileReader转一下，内存占用会小500M，效果拔群
  const reader = new FileReader();
  reader.readAsArrayBuffer(blob);
  const buffer = await new Promise((resolve) => {
    reader.onload = (event) => {
      resolve(event.target!.result);
    };
  });
  return new Promise((resolve, reject) => {
    decoder.postMessage({
      method: 'decode',
      buffer,
    },[buffer]);
    decoder.onmessage = (event: any) => {
      const { method, image } = event.data;
      if (method === 'decode') {
        if (image) {
          decoder = null;
          resolve(image);
        } else {
          reject('decode image failed');
        }
      }
    };
  });

  // const bitmap = await decoder.decode(buffer);
  // return bitmap;
}
export class DecodeWorkerManager {
  static instance: DecodeWorkerManager;
  static getInstance(): DecodeWorkerManager {
    if (!DecodeWorkerManager.instance) {
      DecodeWorkerManager.instance = new DecodeWorkerManager();
    }
    return DecodeWorkerManager.instance;
  }
  workerIdleNum = 0;
  workerNum = 0;
  private workerQueue: any[] = [];
  // 手机多线程超过8个就会卡
  private maxWorkerNum = 6;
  private queue = new PQueue({ concurrency: 1 });
  constructor(
  ) {
    this.initWorkers();
  }
  initWorkers() {
    const promises = [];
    for (let i = 0; i < this.maxWorkerNum; i++) {
      promises.push(this.createWorker());
    }
    return Promise.all(promises);
  }
  async createWorker() {
    try {
      const instance = worker();
      this.workerQueue.push(instance);
      this.workerIdleNum++;
      this.workerNum++;
      return instance;
    } catch (error) {
      throw new Error('createWorker error')
    }
  }
  getWorker() {
    if (this.workerIdleNum < 0) { return; }
    if (this.workerIdleNum === 0) {
      if (this.workerNum < this.maxWorkerNum) {
        this.createWorker();
      } else {
        return undefined;
      }
    }
    this.workerIdleNum--;
    return this.workerQueue.pop();
  }
  decodeInWorker(blob: Blob): Promise<ImageBitmap> {
    return new Promise((resolve) => {
      this.queue.add(async () => {
        const worker2 = this.getWorker();
        const result = await decodeInWorker(worker2, blob);
        this.workerIdleNum++;
        this.workerQueue.push(worker2!);
        resolve(result);
      });
    });
  }
}
