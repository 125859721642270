import { Subject } from "rxjs"
import EventEmitter from "eventemitter3";
import { MessageCenter } from "../../GameLogic/MessageCenter";

/**
 * 兼容旧接口的进度条事件
 * 使用示例
 *
 * ```typescript
 * import { Meshviewer } from "@ali/tide-wanhuatong-sdk"
const { ProgressReporter, Application } = Meshviewer;

// 1.显示进度条
loadingElement.show();
loadingElement.progress = "0%";

// 2.初始化程序
const app = new Application()

// 3.监听事件
ProgressReporter.modelLoaded.subscribe(()=>{
  // 模型加载完成事件， 进度在90%
  loadingElement.progress = "90%";
})

ProgressReporter.onLoaded.subscribe(()=>{
  // 程序初始化完成事件，进度100%。
  loadingElement.progress = "100%";
  // 4.隐藏进度条。
  loadingElement.hide()
})
 * ```
 */
export class ProgressReporter {
  /**
     * 场景初始化完成事件。可以隐藏进度条。
     */
  static onLoaded = new Subject();
  //
  /**
     * 模型加载完成事件，兼容旧工程
     */
  static modelLoaded = new Subject();
  /**
     * 在场景内可以移动的事件。可以进行漫游的跳转
     */
  static canMove = new Subject();

  // 新增一个插件加载完成事件
  static pluginsLoaded = new Subject();

  /**
     * Progress 进度条事件，使用示例
     * ```typescript
     * ProgressReporter.onProgress.subscribe((event) => event.progress });
     * ```
     */
  static onProgress = new Subject();
}


export enum IProgressNumber{
  default = 0,
  AppInitEnd = 20,
  ResourceLoadEnd = 70,
  SceneLoadEnd = 100,
}
/**
 * 相比ProgressReporter，有准确并且容易理解的生命周期
 */
export class ProgressAPI {
  /**
     * 场景加载进度，
     */
  static progress: IProgressNumber = 0;
  /**
     * -AppInitEnd 是程序刚完成初始化，显示场景，可以再这里隐藏Loading
     *
     * -ResourceLoadEnd 是加载完资源，正在解析资源，例如标签配置文件等，此时还不能进行漫游
     *
     * -SceneLoadEnd 是场景全部加载完成，可以进行漫游
     *
     * @event
     */
  static event = new EventEmitter< "AppInitEnd" | "ResourceLoadEnd" | "SceneLoadEnd">();
  /**
     * @ignore
     */
  static reload = reload;
  /**
     * @ignore
     */
  static dispose = dispose;
}
function init(){
  ProgressReporter.onLoaded.subscribe(()=>{
    ProgressAPI.progress = IProgressNumber.AppInitEnd;
    // 加个延时，先执行内部的状态改变。
    setTimeout(()=>{
      ProgressAPI.event.emit("AppInitEnd");
    },0)
  })
  ProgressReporter.modelLoaded.subscribe(()=>{
    ProgressAPI.progress = IProgressNumber.ResourceLoadEnd;
    setTimeout(()=>{
      ProgressAPI.event.emit("ResourceLoadEnd");
    },0)
  })
  ProgressReporter.canMove.subscribe(()=>{
    ProgressAPI.progress = IProgressNumber.SceneLoadEnd;
    setTimeout(()=>{
      ProgressAPI.event.emit("SceneLoadEnd");
    },0)
  })
}
init();

function dispose(){
  ProgressAPI.event.removeAllListeners("AppInitEnd");
  ProgressAPI.event.removeAllListeners("ResourceLoadEnd");
  ProgressAPI.event.removeAllListeners("SceneLoadEnd");
  ProgressAPI.progress = 0;

  ProgressReporter.onLoaded.complete();
  ProgressReporter.modelLoaded.complete();
  ProgressReporter.canMove.complete();
  ProgressReporter.onProgress.complete();
}
function reload(){
  ProgressAPI.event = new EventEmitter< "AppInitEnd" | "ResourceLoadEnd" | "SceneLoadEnd">(),
  ProgressAPI.progress = 0;

  ProgressReporter.onLoaded = new Subject();
  ProgressReporter.modelLoaded = new Subject();
  ProgressReporter.canMove = new Subject();
  ProgressReporter.onProgress = new Subject();
  init();
}
MessageCenter.DisposeEvent.on("dispose",()=>{
  dispose();
})
MessageCenter.ReloadEvent.on("reload",()=>{
  reload();
})
