import { BasicState, expJson, InjectState } from '../StatesFrame';
import { RobotInfo } from './RobotCtr';
import { ConsoleLog } from "../../lib/log";

@InjectState('RobotStop')
export class RobotStop extends BasicState<RobotInfo> {
  @expJson('info')
  public info = 'stop';

  @expJson('onWalkState')
  public onWalkState: BasicState<RobotInfo> | undefined;

  onEnter () {
    ConsoleLog.info('RobotStop', 'onEnter', this.info);
    this.srcInfo.entity.deactivate();
  }

  onExit () {
    ConsoleLog.info('RobotStop', 'onExit', this.info);
    this.srcInfo.entity.activate();
  }

  resume() {
    this.onWalkState?.changeTo();
  }
}
