import { Entity, PrimitiveType, Renderer, Loader, SceneManager, Texture2D, Camera, MeshCollider, Constants, MeshFilter, RenderQueue } from "@ali/tidejs";
import { mat4, vec2, vec3, vec4 } from "gl-matrix";
import { Application } from "../../../Application";
import { Spot } from "../../../Behaviors/spot/Spot";
import { getImage, WanHuaTongUtility } from "../../../GameLogic/WanHuaTongUtility";
import { SpotMaterial } from "../../../Material/SpotMaterial";
import { MessageCenter } from "../../../GameLogic/MessageCenter";
import { ConfigPrivate } from "../../../api/config/lib/private";
import { TransitionPrivate, defaultActiveFloor } from "../../../api/transition/lib/private";
import { TransitionState } from "../../../api/transition/lib/interface";
import { Subject } from "rxjs"
import EventEmitter from "eventemitter3";
import { APIBaseClass } from "@sdkCore/lib/utils/eca";
import { PanoramaVideo } from "@sdkCore/api/panovideo/lib/private";
import { defaultSpot } from '@sdkCore/assets/icon'
import { DataCenter } from '../../../GameLogic/DataCenter';
interface ISpotsItem {
  "id": string,
  "title": string,
  "spotTextureUrl": string,
  "position": [number,number,number],
  "toSpotID": string,
}
interface ISpotsList {
  [name: string]: Array<ISpotsItem>
}
interface IState {
  currentSpotID: string,
  spotEntityMap: Map<string, Entity>,
  spotsConfig: ISpotsList
}

export interface SpotPose {
  opticalCenter: vec3;
  upwardDirection: vec3;
  forwardDirection: vec3;
  groundCoord: vec3;
  name: string;
  // mesh?: Mesh;
  // neighbor: SpotPose[];
  // isDeleted = false;
}
function isSpotVisible(spotPose: any){
  // 默认数据没有visible这个字段，只有用户编辑过才会有，所以必须用 visible === false 来判定
  if (spotPose.visible === false) {
    return false;
  }
  return true;
}
/**
 * 导航点就是地上的小圆点，点击会跳转到那个位置
 * ![图片](https://intranetproxy.alipay.com/skylark/lark/0/2021/png/154291/1611559334134-639ae88f-94de-4574-8d5a-d6ffe7f236bf.png?x-oss-process=image%2Fresize%2Cw_566)

 */
export class SpotPrivate extends APIBaseClass{
  static Config = {
    clickDistance: 1.0,
    spot:{
      normalSpotTexture: "",
      activeSpotTexture: ""
    }
  }

  private _spots = new Map<string, Spot>();
  private _firstSpot!: Spot;
  private _lastSpot: Spot | undefined;
  private _currentSpot!: Spot;
  private _spotMaterial!: SpotMaterial;

  private _tempMat4_0: mat4 = mat4.create();
  private _tempMat4_1: mat4 = mat4.create();

  protected normalColor = vec4.fromValues(0.9, 0.9, 0.9, 0.6);
  protected disabledColor = vec4.fromValues(0.85, 0.61, 0.61, 0.6);

  public set currentSpot(spot: Spot) {
    this._currentSpot = spot;
  }

  public get currentSpot() {
    return this._currentSpot;
  }

  public get firstSpot() {
    return this._firstSpot;
  }

  public get lastSpot() {
    return this._lastSpot;
  }

  /**
     * 订阅hotspot点击事件，返回spot点名称
     *
     * @type {Observable<string>}
     * @memberof HotSpotAPI
     */
  hotspotClicked: Subject<string> = new Subject();

  static ModuleName = "hotSpotAPI";

  public event = new EventEmitter<"click", string>();

  public state: IState = {
    currentSpotID: "p0",
    spotEntityMap: new Map<string, Entity>(),
    spotsConfig: {}
  }

  constructor(){
    super();
    const transitionAPI = Application.getInstance<TransitionPrivate>("transitionControlAPI");

    transitionAPI.transitionProcessObserve.subscribe((event)=>{

      const { state, to } = event;

      if(!state || !to){ return }
      if(state === TransitionState.beforeTransition){
        this._lastSpot?.hideNeighbor();
      } else {
        if (to.spotId === 'normal' || to.spotId === 'top') { // normal和top视图显示所有spot点
          for (const spot of this._spots.values()) {
            const render = spot.entity.getComponentByType<Renderer>(Renderer);
            if (transitionAPI.activeFloor === defaultActiveFloor) {
              render!.isActive = true;
            } else {
              if (transitionAPI.activeFloor === spot.floorId) { // 多楼层模型的单层激活状态下，只显示激活层的spot点
                render!.isActive = true;
              }
            }
          }
        } else {
          this._lastSpot = this.getHotSpotById(to.spotId)
          this._lastSpot?.showNeighbor();
        }
      }
    })
  }
  /**
     * 设置当前点位
     * @param spotId
     * @returns
     */
  public setCurrentSpotId(spotId: string){
    if(!spotId || typeof spotId !== "string"){ return }
    this.setState({
      currentSpotID: spotId
    })
    const spot = this.getHotSpotById(spotId)
    if(spot){
      this.currentSpot = spot;
    }
  }
  /**
     * 获得当前SpotID，返回值为 "p-" | normal | top
     */
  public getCurrentSpotId(){
    return this.state.currentSpotID;
  }
  /**
     * 获取场景中的所有spot点
     * @returns
     */
  public getHotSpots(){
    return this._spots;
  }
  /**
     * 根据id获取spot点
     * @param spotID
     * @returns
     */
  public getHotSpotById(spotID: string){
    return this._spots.get(spotID);
  }
  /**
     * 获取指定位置坐标附近最近的spot点
     * @param position
     * @returns
     */
  public getNearestSpotByPosition(position: vec3){
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName)
    const config = configAPI.getConfig();
    if(!config) { return }

    let minDistance = 99;
    let minSpotID = "";
    for(const [key, value] of Object.entries(config.scenes)){
      const { spotPose } = value as any;
      const { groundCoord } = spotPose;

      const distance = vec3.distance(position, vec3.fromValues(groundCoord[0], groundCoord[2], -groundCoord[1]));
      if(distance < minDistance && distance > 1){
        minSpotID = key;
        minDistance = distance;
      }
    }
    return minSpotID;
  }
  /**
     * 获取场景中的第一个点位
     * @returns
     */
  public getFirstSpotId(){
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    // 以后可能需要以另外的方式传入
    return configAPI.getConfig()?.default.firstScene;
  }

  /**
     * 获取当前spot点附近的可见spot点
     * @returns
     */
  public getCurrentVisibleSpots() {
    const currentSpotID = this.getCurrentSpotId();
    const arr = [currentSpotID];
    if (!currentSpotID || currentSpotID === "normal" || currentSpotID === "top") {
      return arr;
    }
    const neighbors = this.getHotSpotById(currentSpotID)?.neighbors;
    if (!neighbors) { return arr }
    for (const item of neighbors) {
      arr.push(item.name);
    }
    return arr;
  }

  /**
     * 初始化加载所有点位
     */
  public async init(){
    const panoramaVideo = Application.getInstance<PanoramaVideo>(PanoramaVideo.ModuleName);
    const panorama_config = panoramaVideo.getConfig();
    let texture = undefined;
    if(SpotPrivate.Config.spot.normalSpotTexture){
      texture = await Loader.LoadTexture2D(SpotPrivate.Config.spot.normalSpotTexture, true)!;
    } else {
      const image = await getImage(defaultSpot);
      texture = new Texture2D(image.width, image.height);
      texture.setTextureImageSource(image, 0, true);
      texture.magFilter = Constants.LINEAR;
      texture.minFilter = Constants.LINEAR_MIPMAP_LINEAR;
      texture.anisotropic = 16;
      texture.generateMipmaps();
    }

    const isVideoSpot = (spotID: string) => {
      if (!panorama_config) {
        return false
      }
      return !!panorama_config.videoList.filter((item: any) => item.spotID === spotID)[0];
    }

    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName)
    //从数据中心拉数据
    const data = configAPI.getConfig();
    //保存相邻点数据
    if (data) {
      const currentScene = SceneManager.GetInstance().currentScene;
      const firstId = data.default.firstScene;
      for (const spotId in data.scenes) {
        const spotInfo = data.scenes[spotId];
        if (!isSpotVisible(spotInfo.spotPose) && Application.mode !== "editor") {
          continue;
        }
        const entity = Entity.CreatePrimitive(PrimitiveType.Plane);
        if (entity) {
          entity.name = spotId;
          const tempPos = WanHuaTongUtility.matchCoordSys(spotInfo.spotPose.groundCoord);
          tempPos[1] += 0.05;
          entity.transform.position = tempPos;
          entity.transform.setEulerAngles(vec3.fromValues(-90, 0, 0 ));

          //添加 spot 组件，并维护列表
          const spotCom: Spot = entity.addComponent(Spot);
          if(isVideoSpot(spotId)){
            spotCom.type = "video-spot"
          }
          spotCom.upwardDirection = WanHuaTongUtility.matchCoordSys(spotInfo.spotPose.upwardDirection);
          spotCom.forwardDirection = WanHuaTongUtility.matchCoordSys(spotInfo.spotPose.forwardDirection);
          spotCom.opticalCenter = WanHuaTongUtility.matchCoordSys(spotInfo.spotPose.opticalCenter);
          spotCom.name = spotId;
          if (spotInfo.floor) {
            spotCom.floorId = spotInfo.floor;
          }

          if( Application.mode === "editor"){
            // spotCom.disableUpdate = true;
            if (!isSpotVisible(spotInfo.spotPose)) {
              // 编辑器中隐藏点位的颜色设置
              spotCom.color = this.disabledColor
              spotCom.enterColor =  this.disabledColor
            }
          }
          entity.addComponent(MeshCollider); // mousemove 效果

          const meshFilter = entity.getComponentByType(MeshFilter)! as MeshFilter;
          DataCenter.PanoramaMeshFilters.push(meshFilter); // 加入到全景图中点击相应物的集合中，避免重复点击spot点时触发点击到大模型引起的重复跳转

          spotCom.event.on("click",()=>{
            this.hotspotClicked.next(spotId)
          })

          const renderer = entity.getComponentByType<Renderer>(Renderer);
          if(renderer) {
            const m = new SpotMaterial();
            m.name = entity.name;
            m.albedo = texture!;
            m.transparent = true;
            m.renderQueue = RenderQueue.Transparent;
            renderer.material = m;
            spotCom.material = m;
          }
          if (spotCom) {
            this._spots.set(spotId, spotCom);
          }
          currentScene.addEntity(entity);
          spotCom.spotMgr = this;

          if (firstId === spotId) {
            this._firstSpot = spotCom;
            this._lastSpot = this._firstSpot;
            this._currentSpot = this._firstSpot;
          }
        }
        const normal = vec3.fromValues(0, 1, 0);
        const endPosition = vec3.create();
        vec3.add(endPosition, entity!.transform.position, normal);
      }
      //设置相邻关系
      for (const spotId in data.scenes) {
        const spotInfo = data.scenes[spotId];
        if (!isSpotVisible(spotInfo.spotPose) && Application.mode !== "editor") {
          continue;
        }
        for (const key of spotInfo.hotSpots) {
          const temp = this._spots.get(spotId);
          const target = this._spots.get(key)
          if (temp && target) {
            temp.addNeighbor(target);
          }
        }
      }

      //监听事件,模型加载 完毕才显示spot点
      MessageCenter.GlobalEvent.once('modelLoaded', name => {
        this._firstSpot?.showNeighbor();
      })
    }
  }

  /**
     * 根据名称获取点位数据
     * @param name
     * @returns
     */
  public getSpotByName(name: string): Spot | undefined{
    return this._spots.get(name);
  }

  /**
     * 隐藏所有点位
     * @returns
     */
  public hideAllSpots() {
    if (!this._spots) return;
    for (const spot of this._spots.values()) {
      const render = spot.entity.getComponentByType<Renderer>(Renderer);
      if(!render) return;
      render!.isActive = false;
    }
  }

  /**
     * 显示所有点位
     * @returns
     */
  public showAllSpots() {
    if (!this._spots) return;
    for (const spot of this._spots.values()) {
      const render = spot.entity.getComponentByType<Renderer>(Renderer);
      if(!render) return;
      render!.isActive = true;
    }
  }

  /**
     * 获取指定点位的姿势
     * @param spotID
     * @returns
     */
  public getPoseBySpotId(spotID: string) {
    const spot = this._spots.get(spotID);
    if(!spot){
      return undefined
    }
    return {
      forward:spot.forwardDirection,
      up:spot.upwardDirection,
      pos:spot.entity.transform.position,
    }
  }

  private _tempVec3_1 = vec3.create();
  private _tempVec2_1 = vec2.create();
  private _tempVec2_2 = vec2.create();
  private _tempVec2_3 = vec2.create();

  public getNearestSpotByWorldPos(pos: vec3): Spot | undefined{
    const isAllowPierce = ConfigPrivate.Config.enablePierce;
    const isMultiFloor = ConfigPrivate.Config.multiFloor;
    let neighbors = this.currentSpot.neighbors;
    if (isAllowPierce) {
      const AllSpotExceptCurSpot = new Map<string, Spot>()
      for (const [key, value] of this.getHotSpots().entries()) {
        if (key === this.getCurrentSpotId()) {
          continue
        }
        if (isMultiFloor && value.floorId !== this.currentSpot.floorId) { // 多楼层的穿墙不允许跨楼层
          continue
        }
        AllSpotExceptCurSpot.set(key, value)
      }
      neighbors = AllSpotExceptCurSpot.values() as any;
    }
    if (!neighbors) return undefined;

    const camera = Camera.MainCamera!;
    mat4.invert(this._tempMat4_0,camera.entity.transform.localToWorldMatrix());
    mat4.multiply(this._tempMat4_1,camera.projectionMatrix(),this._tempMat4_0);
    vec3.transformMat4(this._tempVec3_1, pos, this._tempMat4_1);
    //_tempVec2_2 是鼠标坐标, 不需要对z 判断 0-1区间，是因为pos 假定来自于前方射线求交结果
    this._tempVec2_2[0] = this._tempVec3_1[0];
    this._tempVec2_2[1] = this._tempVec3_1[1];

    let minDis = SpotPrivate.Config.clickDistance;
    let fitSpot: Spot | undefined;

    for (const spot of neighbors) {
      vec3.transformMat4(this._tempVec3_1, spot.entity.transform.position, this._tempMat4_1);
      if (this._tempVec3_1[2] < 0 || this._tempVec3_1[2] > 1) {
        continue;
      }
      //_tempVec2_1 是模型坐标
      this._tempVec2_1[0] = this._tempVec3_1[0];
      this._tempVec2_1[1] = this._tempVec3_1[1];


      vec2.sub(this._tempVec2_3,this._tempVec2_1,this._tempVec2_2);
      const dis = vec2.dot(this._tempVec2_3, this._tempVec2_3);


      if ( dis < minDis) {
        minDis = dis;
        fitSpot = spot;
      }
    }
    return fitSpot;
  }
  /**
     * 从neighbors中找到最近的, 并且与pos的屏幕坐标也比较接近的
     * @param pos
     */
  public getNearestSpotByWorldPosV2(pos: vec3): Spot | undefined{
    const neighbors = this.currentSpot.neighbors;
    if (!neighbors) return undefined;

    let minDis = 1.5 * 1.5;//最小平方距离
    let fitSpot: Spot | undefined;

    for (const spot of neighbors) {
      vec3.sub(this._tempVec3_1,pos,spot.entity.transform.position);
      const dis = vec3.dot(this._tempVec3_1,this._tempVec3_1);
      if ( dis < minDis) {
        minDis = dis;
        fitSpot = spot;
      }
    }

    if (fitSpot) {
      const camera = Camera.MainCamera!;
      mat4.invert(this._tempMat4_0,camera.entity.transform.localToWorldMatrix());
      mat4.multiply(this._tempMat4_1,camera.projectionMatrix(),this._tempMat4_0);

      vec3.transformMat4(this._tempVec3_1, pos, this._tempMat4_1);
      this._tempVec2_2[0] = this._tempVec3_1[0];
      this._tempVec2_2[1] = this._tempVec3_1[1];

      vec3.transformMat4(this._tempVec3_1, fitSpot.entity.transform.position, this._tempMat4_1);
      this._tempVec2_1[0] = this._tempVec3_1[0];
      this._tempVec2_1[1] = this._tempVec3_1[1];
      vec2.sub(this._tempVec2_3,this._tempVec2_1,this._tempVec2_2);
      if (vec2.dot(this._tempVec2_3, this._tempVec2_3) >  1) {
        return undefined;
      }
    }
    return fitSpot;
  }
  /**
     * 从所有spot中找到最近的
     */
  public getNearestSpotByWorldPosAll(pos: vec3): Spot | undefined{
    let minDis = Number.MAX_VALUE;
    let fitSpot: Spot | undefined;
    for (const spotId of this._spots.keys()) {
      const spot = this._spots.get(spotId);
      if(!spot){ continue }
      vec3.sub(this._tempVec3_1,pos,spot.entity.transform.position);
      const dis = vec3.dot(this._tempVec3_1,this._tempVec3_1);
      if ( dis < minDis) {
        minDis = dis;
        fitSpot = spot;
      }
    }
    return fitSpot;
  }

  /**
     * 从所有指定楼层中的spot中找到最近的
     */
  public getNearestSpotByWorldPosFloor(pos: vec3, floorId: string): Spot | undefined{
    let minDis = Number.MAX_VALUE;
    let fitSpot: Spot | undefined;
    for (const spotId of this._spots.keys()) {
      const spot = this._spots.get(spotId);
      if(!spot || spot.floorId !== floorId){ continue }
      vec3.sub(this._tempVec3_1,pos,spot.entity.transform.position);
      const dis = vec3.dot(this._tempVec3_1,this._tempVec3_1);
      if ( dis < minDis) {
        minDis = dis;
        fitSpot = spot;
      }
    }
    return fitSpot;
  }

  /**
     * 设置地面点位的纹理图片
     * @param url
     */
  public async setNormalSpotTexture(url: string) {
    const newTexture = await Loader.LoadTexture2D(url, true)!;
    for (const spotId of this._spots.keys()) {
      const spot = this._spots.get(spotId);
      spot!.material.albedo = newTexture!
      SpotPrivate.Config.spot.normalSpotTexture = url;
    }
  }

  /**
     * 设置hover时地面点位的纹理图片
     * @param url
     */
  public async setActiveSpotTexture(url: string) {
    if(!SpotPrivate.Config.spot.normalSpotTexture) return;
    SpotPrivate.Config.spot.activeSpotTexture = url;
  }

  public dispose(){

  }
}
