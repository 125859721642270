
import { BasicState, expJson, InjectState } from '../StatesFrame';
import { RobotInfo } from './RobotCtr';

@InjectState('RobotSpeak')
export class RobotSpeak extends BasicState<RobotInfo> {
  @expJson('info')
  public info = 'speak';

  @expJson('onEndState')
  public onEndState: BasicState<RobotInfo> | undefined;

  private _event: ((spotID: string) => void)  | undefined;

  update (deltaTime: number) {
  }

  /**
     * 解说模式下，进入时，建立对跳转的监听；当跳转时，立即跳转到下一个状态；
     */
  onEnter () {
    if (this._event === undefined) {
      this._event = this.srcInfo.onJumpPoint.on((id) => {
        if (this.onEndState) {
          this.onEndState.changeTo();
        }
      });
    }
    this.srcInfo.emotionMgr.changeTo('speak');

    this.srcInfo.enableDialog = false;
  }

  /**
     * 解说模式下，离开时，取消对跳转的监听
     */
  onExit () {
    if (this._event) {
      this.srcInfo.onJumpPoint.off(this._event);
      this._event = undefined;
    }
    this.srcInfo.emotionMgr.changeTo('idle')
  }
}
