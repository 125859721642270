import { RenderQueue, IShader, UniformType } from "@ali/tidejs";
import { mat3, mat4, vec3 } from "gl-matrix";

const SpotToNormalShader: IShader = {
    name: "SpotToNormalShader",
    renderQueue: RenderQueue.Geometry,

    uniform: {
        u_cube: { type: UniformType.TextureCube, value: undefined },
        u_diffuse: { type: UniformType.Texture2D, value: undefined },
        u_center: { type: UniformType.vec3, value: vec3.create() },
        u_worldToCameraMatrix: { type: UniformType.mat3, value: mat3.create() },
        u_time: { type: UniformType.float, value: 0 },
        u_p2otime: { type: UniformType.float, value: 0 },
        u_orthographicProjection: { type: UniformType.mat4, value: mat4.create() },
        u_cubeSource2K: { type: UniformType.TextureCube, value: undefined },
        u_cubeSourceLevel: { type: UniformType.TextureCube, value: undefined },
        u_displayRange: { type: UniformType.vec2, value: [0, -1] }
    },

    vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;
        varying vec2 v_texCoord;
        varying vec4 v_worldPos;
        uniform float u_time;
        uniform float u_p2otime;
        uniform mat4 u_orthographicProjection;

        void main() {
            v_worldPos = u_model * vec4(position, 1.0);

            mat4 finalProjectionMatrix = u_projection;
            // if (u_time == 1.0) {
            finalProjectionMatrix = u_projection * (1.0 - u_p2otime) + u_orthographicProjection * u_p2otime;
            // }
            gl_Position = finalProjectionMatrix * u_view * v_worldPos;
            v_texCoord = texcoord;
        }
    `,

    fragmentShader: `
        precision highp float;
        uniform samplerCube u_cube;
        uniform sampler2D u_diffuse;
        uniform samplerCube u_cubeSource2K;
        uniform samplerCube u_cubeSourceLevel;

        uniform float u_time;
        uniform mat3 u_worldToCameraMatrix;
        uniform vec3 u_center;

        uniform vec2 u_displayRange;
        varying vec2 v_texCoord;
        varying vec4 v_worldPos;

        void main() {
            float base = u_displayRange.x;
            float height = u_displayRange.y;

            if(height > 0.0) { // 多楼层展示效果处理
                float y = v_worldPos.y;
                if(y < base || y > base + height){
                    gl_FragColor = vec4(1.0, 1.0, 1.0, 0.05);
                    return;
                }
            }

            vec3 u_sourceTextureDir = normalize(u_worldToCameraMatrix * (v_worldPos.xyz - u_center));
            u_sourceTextureDir.z = - u_sourceTextureDir.z;

            vec3 srcLevel = textureCube(u_cubeSourceLevel, u_sourceTextureDir).rgb;

            vec3 colorSrc;
            colorSrc = srcLevel.r > 0.0 ? textureCube(u_cube, u_sourceTextureDir).rgb : textureCube(u_cubeSource2K, u_sourceTextureDir).rgb;

            vec4 res = mix(vec4(colorSrc.rgb, 1.0), texture2D(u_diffuse, vec2(v_texCoord.x, v_texCoord.y)), pow(u_time, 2.0));
            gl_FragColor = vec4(res.xyz, 1.0);
        }
    `,
};

export default SpotToNormalShader;
