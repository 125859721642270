import { CameraPrivate } from "../../api/camera/lib/private";
import { TransitionPrivate } from "../../api/transition/lib/private";
import { Application } from "../../Application";
import { SDKAudio } from "../../lib/audio/audio";
import { ConsoleLog, ErrorType } from "../../lib/log";
import { API } from "../../lib/utils/eca";
import { checkAnimation, RoamingPrivate } from "./lib/private";
import { IOldRoamingConfig, IOldRoamingItem, IRoamingItem } from "./lib/Roaming";

/**
 * 根据roaming.json 和 roamingGroup.json 生成一条漫游路径
 * 注意：这里的 defaultKey 是根据 roamingGroup 里的 selectedRoute 字段标识的
 * Gets roaming path from json
 * @param oldRoamingConfig
 * @param groupConfig
 * @param index
 * @returns
 */
function getRoamingPathFromJson(oldRoamingConfig: IOldRoamingConfig, groupConfig: IRoamingGroup, index: number) {
  const defaultKey = groupConfig.selectedRoute;
  if (!defaultKey) { return [] }
  const oldRoamingPath: IOldRoamingItem[] = oldRoamingConfig[defaultKey];
  const roamingGroupMap = groupConfig.routes[defaultKey];
  const roamingGroupList = Object.keys(roamingGroupMap);
  ConsoleLog.log("RoamingRuntime", "getRoamingPathFromJson", "oldRoamingPath", oldRoamingPath)
  ConsoleLog.log("RoamingRuntime", "getRoamingPathFromJson", "roamingGroupList", roamingGroupList)
  // 如果默认的路径不存在
  if (!oldRoamingPath || oldRoamingPath.length < 1) { return [] }
  if (!roamingGroupList || roamingGroupList.length < 1) { return [] }

  const config: IRoamingItem[] = [];

  const group = roamingGroupMap[roamingGroupList[index]];
  // 如果index超过了路径的数量
  if (!group) { return [] }
  const indexList = group.indices;
  ConsoleLog.log("RoamingRuntime", "getRoamingPathFromJson", "indexList", indexList)
  for (const index of indexList) {
    const oldItem = oldRoamingPath[index];
    if (!oldItem) {
      ConsoleLog.error("RoamingRuntime", "getRoamingPathFromJson", ErrorType.InternalError, "oldItem 不存在");
      continue
    }
    const item: IRoamingItem = {
      spotId: oldItem.spotId,
      transition_duration: oldItem.transitionParams?.duration,
      animation_duration: oldItem.animation?.duration,
      stay: RoamingRuntime.Config.stay,
    }
    if (!oldItem.animation) {
      config.push(item);
      continue;
    }
    if (checkAnimation(oldItem.animation.from)) {
      ConsoleLog.info("RoamingRuntime", "getRoamingPathFromJson", "配置有 from 朝向", oldItem.animation.from);
      const { theta, phi, fov } = oldItem.animation.from;
      item.from = [theta, phi, fov];
    }
    if (checkAnimation(oldItem.animation.to)) {
      ConsoleLog.info("RoamingRuntime", "getRoamingPathFromJson", "配置有 to 朝向", oldItem.animation.to);
      const { theta, phi, fov } = oldItem.animation.to;
      item.to = [theta, phi, fov];
    }
    config.push(item);
  }
  return config
}
function getAllAudioFromJson(groupConfig: IRoamingGroup) {
  const defaultKey = Object.keys(groupConfig)[0];
  if (!defaultKey) { return [] }
  const defaultGroupKey = groupConfig.selectedRoute;
  const roamingGroupMap = groupConfig.routes[defaultGroupKey];
  const roamingGroupList = Object.keys(roamingGroupMap);
  if (!roamingGroupList || roamingGroupList.length < 1) { return [] }
  const audioList = [];
  for (const item of roamingGroupList) {
    const value = roamingGroupMap[item];
    if (value.audio && value.audio.url) {
      audioList.push(value.audio.url);
    } else {
      audioList.push(undefined);
    }
  }
  return audioList;
}
/**
 * 检查两个Config的路径是否匹配
 * @param config
 * @param groupJson
 * @returns
 */
function checkTwoJson(config: IOldRoamingConfig, groupJson: IRoamingGroup) {
  if (!config) {
    ConsoleLog.error("RoamingRuntime", "checkTwoJson", ErrorType.DataError, "roaming.json 不存在");
    return false
  }
  if (!groupJson || !groupJson.selectedRoute || !groupJson.routes[groupJson.selectedRoute]) {
    ConsoleLog.error("RoamingRuntime", "checkTwoJson", ErrorType.DataError, "groupJson.json 错误");
    return false;
  }

  return true
}
/**
 * 检查config是否正确
 * @param groupJson
 * @returns
 */
function checkJson(config: any) {
  if (config) {
    const keyList = Object.keys(config);
    if (keyList && keyList.length > 0) {
      return true
    }
  }
  return false;
}
/**
 * 生成默认的 roamingGroup.json
 * 注意： 这里只会默认读取roaming.json第一个key，如果有多条路径，可能不会选取最准确的一条
 * Gets default group json
 * @param config
 * @returns
 */
function getDefaultGroupJson(config: IOldRoamingConfig) {
  const groupJson: any = {
    routes: {}
  };
  const defaultKey = Object.keys(config)[0];
  const path = config[defaultKey];
  const group: IRoamingGroupItem = {
    indices: [],
    name: defaultKey,
  };

  for (let i = 0; i < path.length; i++) {
    group.indices.push(i);
  }
  groupJson.routes = {}
  groupJson.routes[defaultKey] = {};
  groupJson.routes[defaultKey][defaultKey] = group;
  groupJson.selectedRoute = defaultKey
  return groupJson;
}
export interface IRoamingGroupItem {
  indices: number[],
  name?: string,
  text?: string,
  audio?: {
    url: string
  }
}

/**
 * 第一个 key 是有多个路径，例如极速路线、精细路线
 * 第二个 key 是路径中有多个分组，例如 group1 group2
 * Iroaming group
 */
export interface IRoamingGroup {
  routes: {
    [name: string]: {
      [name: string]: IRoamingGroupItem
    }
  },
  selectedRoute: string,
}
export interface IRoamingPath {
  // 路径名称
  [name: string]: IRoamingItem[]
}
/**
简易版的自动漫游接口
 # 路线和分组的概念

 * * 路线： roaming.json 里面是整条路径，默认会沿着这条路径一直走下去
 * * 分组： roamingGroup.json 里面是根据路线索引建立的分组，播放完一个分组，继续播放下一个分组。

使用示例

```typescript

import { RoamingAPI } from "@ali/tide-wanhuatong-sdk/runtime"

await RoamingAPI.getInstance().init();
RoamingAPI.getInstance().play(0);

RoamingAPI.event.on("play", (index)=>{
  正在播放第 index 条分组
});

RoamingAPI.event.on("end", ()=>{
  自动漫游结束
});

RoamingAPI.event.on("audioLoading", ()=>{
  音频正在加载
});
RoamingAPI.event.on("audioLoaded", (audio)=>{
  音频加载完成
});

RoamingAPI.start()

RoamingAPI.pause()

RoamingAPI.resume()
```
 */
@API()
export class RoamingRuntime extends RoamingPrivate {
  private oldRoamingConfig!: IOldRoamingConfig;
  private roamingGroupConfig!: IRoamingGroup;
  private audioCached: any[] = [];
  public isError = false;
  /**
   * 手动设置漫游的路径和分组。用于没有 roamingGroup.json 分组存在的情况，需要生成一个默认的分组，将整条漫游路径串起来。
   * 生成方法：遍历所有的 roaming.json ，将索引放到 roamingGroup.json 中
   * @param oldRoamingConfig
   * @param roamingGroupConfig
   * @returns
   */
  private async _setConfig(oldRoamingConfig: any, roamingGroupConfig: any) {
    this.oldRoamingConfig = oldRoamingConfig;
    this.roamingGroupConfig = roamingGroupConfig;

    if (!checkJson(this.oldRoamingConfig)) {
      ConsoleLog.error("RoamingRuntime", "_setConfig", ErrorType.DataError, "roaming.json 不存在");
      (this as any).deactivate();
      return
    }
    if (!checkJson(this.roamingGroupConfig)) {
      ConsoleLog.error("RoamingRuntime", "_setConfig", ErrorType.DataError, "roamingGroup.json 不存在");
      this.roamingGroupConfig = getDefaultGroupJson(this.oldRoamingConfig)
    }
    if (!checkTwoJson(this.oldRoamingConfig as any, this.roamingGroupConfig)) {
      (this as any).deactivate();
      return
    }
    this.audioCached = [];
    const audioUrlList = getAllAudioFromJson(this.roamingGroupConfig) as string[];
    this._setAudioList(audioUrlList);
  }
  /**
     * 设置分组语音链接，传入一个URL数组，每个语音对应一段分组
     * Sets audio list
     */
  private async _setAudioList(audioUrlList: string[]) {
    if (!audioUrlList) {
      return
    }
    for (let url of audioUrlList) {
      if (!url) {
        this.audioCached.push(undefined);
        continue;
      }
      // 避免资源缓存
      if (url.indexOf('?') > -1) {
        url = url + `&time=${+new Date()}`;
      } else {
        url = url + `?time=${+new Date()}`;
      }
      const audio = new SDKAudio(url);
      this.audioCached.push(audio);
      const index = this.audioCached.length - 1;
      audio.load().catch(() => {
        this.audioCached[index] = undefined;
      })
    }
  }
  /**
     * 初始化，必须首先调用并且await
     * Inits roaming apiv2
     */
  public async init(roamingConfig: any, roamingGroupConfig: any) {
    // await this.fetchConfig();
    await this._setConfig(roamingConfig, roamingGroupConfig);
    this.transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    this.cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
  }
  /**
     * 开始漫游整条路径，传入index，第几条漫游路径
     * Plays roaming apiv2
     * @param [index]
     * @param [loop]
     */
  public play(index = 0, loop = true) {
    const roamingPath = getRoamingPathFromJson(this.oldRoamingConfig, this.roamingGroupConfig, index);
    ConsoleLog.log("RoamingRuntime", "play", "roamingPath", roamingPath)
    if (roamingPath.length < 1) {
      ConsoleLog.info("RoamingRuntime", "play", "自动漫游播放完成");
      this.pause();
      this.event.emit("end");
      return
    }
    this.setRoamingConfig(roamingPath)
    this.currentAudio = this.audioCached[index];
    ConsoleLog.info("RoamingRuntime", "play", "准备播放第", index, "个漫游路径");
    this.start(false, index).then(() => {
      ConsoleLog.info("RoamingRuntime", "play", "第", index, "个漫游路径播放完成");
      if (loop) {
        this.play(++index);
      }
    })
  }
}
