import { Entity, Constants, VideoPlayer } from "@ali/tidejs";
import { CameraPrivate } from "@sdkCore/api/camera/lib/private";
import { SpotPrivate } from "@sdkCore/api/spot/lib/private";
import { ElementsManager } from '@sdkCore/api/elements/ElementsManager'
import { TransitionEventParams, TransitionState } from "@sdkCore/api/transition/lib/interface";
import { Application } from "@sdkCore/Application";
import { CubeInterpolator } from "@sdkCore/Behaviors/jumpAnimation/CubeInterpolator";
import { UpdatePanorama } from "@sdkCore/Behaviors/UpdatePanorama";
import { APIBaseClass } from "@sdkCore/lib/utils/eca";
import { PersonGuider } from '@sdkCore/api/personGuider/lib/private'
import { RoamingPrivate } from '@sdkCore/api/roaming/lib/private'

let ratio = 0;
const delta = 0.01;
function blend() {
  ratio = ratio + delta;
  if (ratio >= 1) {
    CubeInterpolator.getInstance().modelMaterial.panoVideoBlendRatio = 1;
    return
  } else {
    CubeInterpolator.getInstance().modelMaterial.panoVideoBlendRatio = ratio;
    requestAnimationFrame(blend)
  }
}
export interface IVideo {
  spotID: string,
  fileFormat: string,
  url: string,
  direction: [number, number],
  rotation?: [number, number, number],
  startTime?: number,
  endTime?: number
}

interface IConfig {
  videoList: IVideo[]
}

interface playOptions {
  startTime?: number
  endTime?: number
  auto?: boolean
}

export class PanoramaVideo extends APIBaseClass {
  static ModuleName = "panoramaVideoAPI";
  public currentPlayer!: VideoPlayer | undefined;
  public playTimer!: number | any
  public config!: IConfig;
  public async init(videoConfig?: IConfig) {
    let config = videoConfig || (await Application.Config.configResolver.resolve("panorama_videos/videos.json"));
    if(!config) {
      config = {
        videoList: []
      }
    }
    this.config = config;
    // 初始添加全景视频
    this.config.videoList.forEach(video => this.addPanoramaVideoConfig(video));
  }

  public async addPanoramaVideoConfig(videoConfig: IVideo) {
    const { spotID } = videoConfig
    this.config.videoList = this.config.videoList.filter(video => video.spotID !== spotID).concat(videoConfig)
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName)
    const spot = spotAPI.getSpotByName(videoConfig.spotID)
    spot?.changeSpotType('video-spot')
  }

  protected async ResourceLoadEnd() {
    // 获取配置文件，加载视频
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName)
    const currentSpotID = spotAPI.getCurrentSpotId();
    this.showVideo(currentSpotID)
  }

  protected async onTransition(event: TransitionEventParams) {
    const { state, to } = event;
    if (!state || !to) { return }
    const personGuiderAPI = Application.getInstance<PersonGuider>(PersonGuider.ModuleName)
    const roamingAPI = Application.getInstance<RoamingPrivate>(RoamingPrivate.ModuleName)
    if (personGuiderAPI && personGuiderAPI.ctr && personGuiderAPI.ctr.isActive) {
      return
    }
    if (roamingAPI && roamingAPI.isPlaying) {
      return
    }
    if (state === TransitionState.beforeTransition) {
      await this.hideVideo()
    } else {
      // 跳转完成后，也需要再次清除，避免上一次的视频仍会继续播放
      await this.hideVideo()
      await this.showVideo(to.spotId);
    }
  }

  private loadVideo2(spotID: string) {
    if (!this.config) {
      return
    }
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName)
    const videoItem = this.config.videoList.filter((item: any) => item.spotID === spotID)[0];
    if (!videoItem) { return }
    let { url } = videoItem
    const { startTime, endTime, rotation = [] } = videoItem
    if (!url) {
      const fileFormat = videoItem.fileFormat || "mp4";
      url = Application.Config.publicResourceResolver.resolve("panorama_videos/" + videoItem.spotID + `.${fileFormat}`);
    }
    const entity = new Entity("")
    return new Promise<any>((resolve) => {
      const videoPlayer = entity!.addComponent<VideoPlayer>(VideoPlayer);
      videoPlayer.loadVideo(url, false).then((tex) => {
        videoPlayer.videotexture!.data.preload = true
        const currentSpotID = spotAPI.getCurrentSpotId();
        if (videoItem.spotID !== currentSpotID) {
          videoPlayer.dispose();
          return
        }
        resolve({
          texture: tex!,
          videoPlayer,
          rotation,
          ...(startTime ? {
            startTime
          } : {}),
          ...(endTime ? {
            endTime
          } : {}),
        });
      })
    })
  }
  public async hideVideo() {
    CubeInterpolator.getInstance().modelMaterial.panoVideoBlendRatio = 0;
    ratio = 0;
    if (this.currentPlayer) {
      const elementsAPI = Application.getInstance<ElementsManager>(ElementsManager.ModuleName)
      if(elementsAPI.allVisible) {
        elementsAPI.showAllElement(true)
      }
      this.playTimer && clearInterval(this.playTimer)
      this.currentPlayer.videotexture!.data!.muted = true;
      this.currentPlayer.pause();
      this.currentPlayer.dispose();
      this.currentPlayer = undefined;
    }
  }

  public async showVideo(spotID: string) {
    const result = await this.loadVideo2(spotID);
    if (!result) {
      return
    }
    const elementsAPI = Application.getInstance<ElementsManager>(ElementsManager.ModuleName)
    elementsAPI.hiddenAllElement(true)
    await this.rotate(spotID);
    const { texture, videoPlayer, startTime, endTime, rotation } = result;
    texture.wrapS = Constants.REPEAT;
    texture.wrapT = Constants.REPEAT;
    CubeInterpolator.getInstance().modelMaterial.panoVideoTexture = texture;
    if(rotation.length) {
      CubeInterpolator.getInstance().modelMaterial.panoVideoUOffset = rotation[0]
    }
    videoPlayer.videotexture!.data!.currentTime = 0;
    videoPlayer.videotexture!.data!.muted = false;
    this.currentPlayer = videoPlayer;
    this.currentPlayer!.videotexture!.data.loop = true;
    await this.play({
      startTime,
      endTime
    })
    blend()
    UpdatePanorama.getInstance().cancelDownload();
  }

  public async play(options: playOptions) {
    if (!this.currentPlayer) {
      return
    }
    const { startTime, endTime } = options
    this.playTimer && clearInterval(this.playTimer)
    if (startTime) {
      this.currentPlayer?.jump(startTime)
    }
    this.currentPlayer && this.currentPlayer?.play()
    this.playTimer = setInterval(() => {
      const currentTime = this.currentPlayer?.getCurrentTime() as number
      if (startTime && (currentTime < startTime)) {
        this.currentPlayer?.jump(startTime)
      }
      if (endTime && (currentTime > endTime)) {
        this.currentPlayer?.jump(startTime || 0)
      }
    }, 200)
  }
  /**
   * 根据 SpotID 显示全景视频
   * Shows panorama video by spot id
   * @param spotID
   */

  public async rotate(spotID: string) {
    const videoItem = this.config.videoList.filter((item: any) => item.spotID === spotID)[0];
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName)
    if (!videoItem) { return }
    const { direction } = videoItem!;
    if (!direction) { return }
    const currentDir = cameraAPI.getCameraThetaPhiForAnnotation(spotID)
    return await cameraAPI.rotateCamera({
      theta: currentDir[0],
      phi: currentDir[1],
    }, {
      theta: direction[0],
      phi: direction[1],
    })
  }

  public getConfig() {
    return this.config;
  }

  dispose() {}
}
