import { BaseBehavior, InputContext, IUpdatable, Component, IOnStart, Camera } from "@ali/tidejs";
import { fadeInOut } from "../../lib/utils/tools";
/**
 * camera Shake
 */
@Component("CameraShake")
export class CameraShake extends BaseBehavior implements IOnStart , IUpdatable{
  _cam!: Camera;
  _time = 0;
  _ratio = 0;
  _fov  = 60;
  _endFov = 40;
  onStart(): void {
    this._cam = Camera.MainCamera!;
    this.isActive = false;
  }
  play(time = 0.15,callback?: Function) {
    this._fov = this._cam.fov;
    this._endFov = this._fov * 0.93;
    this._time = 1/time;
    this._ratio = 0;
    this.callback = callback;
    this.isActive = true;
  }

  private callback!: Function | undefined;

  update(deltaTime: number, inputContext: InputContext): void {
    this._ratio += deltaTime * this._time;
    const newRatio = fadeInOut(this._ratio);

    if (this._ratio > 1) this._ratio = 1;
    let r = newRatio * 2;
    
    if (r > 1) r = 2 - r;
    this._cam.fov = this._fov * (1 - r) + this._endFov * r;
    if (this._ratio === 1) {
      this.isActive = false;
      if (this.callback) {
        this.callback();
      }
    }
  }

}
