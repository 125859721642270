import { ShaderMaterial } from "@ali/tidejs";
import MovingIconShader from "./Shader/MovingIconShader";

export class MovingIconMaterial extends ShaderMaterial {
  constructor() {
    super(MovingIconShader);
    this.cullface = -1;
  }

  public get time(): number {
    return this._tmpUniforms["u_time"].value;
  }
  public set time(time: number) {
    this._tmpUniforms["u_time"].value = time;
  }

  public get albedo(): WebGLTexture {
    return this._tmpUniforms["u_diffuse"];
  }
  public set albedo(albedo: WebGLTexture) {
    this._tmpUniforms["u_diffuse"].value = albedo;
  }
}