import { ShaderMaterial } from "@ali/tidejs";
import { vec4 } from "gl-matrix";
import SpotShader from "./Shader/SpotShader";

export class SpotMaterial extends ShaderMaterial {
  constructor() {
    super(SpotShader);
    this.cullface = -1;
  }

  public get color(): vec4 {
    return this._tmpUniforms["u_color"].value;
  }
  public set color(color: vec4) {
    this._tmpUniforms["u_color"].value = color;
  }

  public get albedo(): WebGLTexture {
    return this._tmpUniforms["u_diffuse"];
  }
  public set albedo(albedo: WebGLTexture) {
    this._tmpUniforms["u_diffuse"].value = albedo;
  }
}
