import  EventEmitter from "eventemitter3";
import { HotElement } from "./hotElement";
import { getVisibleSpotsByPosition, hasIncludeSpots } from "../../../../api/elements/lib/util";
import { ElementAPIBaseClass } from "../../../../lib/utils/eca";
import { API } from "../../../../lib/utils/eca";
import { SDKElementType } from "../../../../api/elements/interface";


@API()
export class HotElementPrivate extends ElementAPIBaseClass<HotElement>{

  static ModuleName = "hotElementAPI";

  static Config = {
    contain: {
      pointerEvents: 'auto',
      cursor: "pointer",
      display: "flex",
      position: "absolute",
      alignItems: "center",
      padding: "2px"
    },
    title: {
      position: "absolute",
      display: "flex",
      left: "calc(100% + 5px)",
      borderRadius: "3px",
      lineHeight: "20px",
      padding: '5px 10px'
    },
  }
  /**
     * @ignore
     * Element type of annotation2 private
     */
  public elementType = SDKElementType.hot;

  /**
     * * loaded: 标签加载完成
     * * enter: 鼠标移入标签
     * * exit: 鼠标移出标签
     * * click: 鼠标点击标签
     * * enterIcon: 鼠标移入标签图标
     * * exitIcon: 鼠标移出标签图标
     * * clickIcon: 鼠标点击标签图标
     *  @event
     */
  public event = new EventEmitter<"loaded" | "enter" | "exit" | "click" | "enterIcon" | "exitIcon" | "clickIcon", string>();

  /**
     * @ignore
     * Apps init end
     */
  AppInitEnd(){
    this.initVisibleSpots();
  }

  /**
     * 如果没有配置 includeSpots，则自动生成一个
     */
  private async initVisibleSpots(){
    const elements = this.getAllElements()
    for(const element of elements){
      if(!hasIncludeSpots(element.elementConfig.includeSpots)){
        element.elementConfig.includeSpots = getVisibleSpotsByPosition(element.entity.transform.position);
      }
    }
  }
  public dispose(){
  }
}
