export interface ICS {
  update(): void;
  exit(): void;
  enter(): void;
}

export enum ICSStates {
  "CSFollow" = "CSFollow",
  "CSJump2Mark" = "CSJump2Mark",
  "CSLookAt" = "CSLookAt",
  "CSRotate2Mark" = "CSRotate2Mark"
}
