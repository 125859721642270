import { Entity, Loader, Mesh, MeshFilter, Renderer, RenderQueue, UnlitMaterial, UnpackedAttrs } from "@ali/tidejs";
import { SpotPrivate } from "../../api/spot/lib/private";
import { TransitionEventParams, TransitionState } from "../../api/transition/lib/interface";
import { Application } from "../../Application";
import { vec3 } from "gl-matrix";
import { ConsoleLog } from "../../lib/log";
import { Utils } from "../../lib/utils";
import { API } from "../../lib/utils/eca";
import { APIBaseClass } from "../../lib/utils/eca";
import { TextureMaterial } from "../../Material/TextureMaterial";

function createCircle(){
  const positionArr = [];
  const indexArr = [];
  const texcoordArr = [];
  const normsArr = [];
  const segment = 5;
  const pre = Math.PI * 2 /segment;
  positionArr.push(0,0,0);
  texcoordArr.push(0.5,0.5);
  normsArr.push(0,1,0,0);
  let angle = 0;
  for (let index = 0; index < segment; index++) {
    const x = Math.cos(angle);
    const z = Math.sin(angle);
    positionArr.push(x,z,0);
    normsArr.push(0,1,0);
    texcoordArr.push(x * 0.5 + 0.5,z * 0.5 + 0.5);
    angle -= pre;
  }
  for (let index = 0; index < segment-1; index++) {
    indexArr.push(0,index+1,index+2);
  }
  indexArr.push(0,segment,1);
  const arr: UnpackedAttrs = {
    verts: positionArr,
    indices: indexArr,
    uvs: texcoordArr,
    norms:normsArr,
    min: vec3.fromValues(-1, -1, -1),
    max: vec3.fromValues(1, 1, 1),
    isBufferGeometry: false,
  }
  const mesh = new Mesh('circle',arr,'');
  mesh.upLoad();
  const entity = new Entity("circle");
  ( entity.addComponent(MeshFilter) as any).mesh = mesh;
  return entity
}
interface IState{
  isFirstCreate: boolean;
}
interface IConfig{
  top: {
    url: string,
  },
  bottom:{
    url: string,
  }
}
/**
 * 全景图顶部和底部贴图
 */
@API()
export class ChassisAPI extends APIBaseClass<IState>{
  static ModuleName = "chassisAPI"

  private topUrl = "";
  private bottomUrl = "";
  private isFirstCreate = true;

  private topImage!: Entity| undefined;
  private bottomImage!: Entity | undefined;

  private config!: IConfig;

  public init(chassisConfig: any) {
    this.config = chassisConfig;
    this.topUrl = chassisConfig.top?.url;
    this.bottomUrl = chassisConfig.bottom?.url;
    const SpotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    this.showChassisBySpotID(SpotAPI.getCurrentSpotId() || "p0");
  }

  protected onTransition(event: TransitionEventParams){
    const { state, to } = event;
    if(!state || !to){ return }
    if(state === TransitionState.beforeTransition){
      this.hideChassis();
    } else {
      this.showChassisBySpotID(to.spotId);
    }
  }
  private hideChassis(){
    ConsoleLog.log("Chassis","hideChassis");
    this.topImage?.deactivate();
    this.bottomImage?.deactivate();
  }
  private async createChassis(url: string){
    if(!url){ return }
    const circle = createCircle()
    if(!circle){ return }
    const render = circle.addComponent<Renderer>(Renderer);
    render.material = new TextureMaterial();
    render.isActive = false;
    const texture = await Loader.LoadTexture2D(Utils.autoAddProtocol(url));
    if(!texture){ return }
    texture.generateMipmaps();
    render.isActive = true;
    render.material!.cullface = -1;
    render!.material!.renderQueue = RenderQueue.Transparent;
    render!.material!.transparent = true;
    render!.material!.depthTest = false;
    render!.material!.depthWrite = false;
    // render!.material!.depthTest = false;
    (render.material as UnlitMaterial).albedo = texture;
    // circle.transform.localScale = vec3.fromValues(0.5,  0.5, 0.5);
    circle.deactivate();
    return circle;
  }
  private moveChassisBySpotID(spotID: string){
    const SpotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = SpotAPI.getHotSpotById(spotID);
    if(!spot){ return }
    const groundCoord = spot.groundCoord;
    const opticalCenter = spot.opticalCenter;
    const upwardDirection = spot.upwardDirection;
    const distance = vec3.dist(opticalCenter, groundCoord);
    const scale = distance * 0.5;
    if(this.topImage){
      this.topImage.transform.position = vec3.fromValues(groundCoord[0], groundCoord[1] + distance * 2, groundCoord[2]);
      this.topImage.transform.localScale = vec3.fromValues(scale, scale, scale);
      this.topImage.transform.setForward([upwardDirection[0],-upwardDirection[1],upwardDirection[2]]);
      this.topImage.activate();
    }
    if(this.bottomImage){
      this.bottomImage.transform.position = groundCoord;
      this.bottomImage.transform.setForward(upwardDirection);
      this.bottomImage.transform.localScale = vec3.fromValues(scale, scale, scale);
      this.bottomImage.activate();
    }
  }
  /**
     * 根据 SpotID 显示全景图顶部和底部区域贴图
     * Shows chassis by spot id
     * @param spotID
     */
  public async showChassisBySpotID(spotID: string){
    ConsoleLog.log("Chassis","showChassisBySpotID", spotID);
    if(this.isFirstCreate){
      this.isFirstCreate = false;
      this.topImage = await this.createChassis(this.topUrl);
      this.bottomImage = await this.createChassis(this.bottomUrl);
    }
    this.moveChassisBySpotID(spotID);
  }
  public getConfig(){
    return this.config;
  }
  dispose(){

  }
}
