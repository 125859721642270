import { quat, vec3 } from "gl-matrix";
import { Entity, Renderer, UnlitMaterial, PrimitiveType, RenderQueue, SceneManager, VideoPlayer, Texture2D, UnpackedRawImage, TextureFormat, Ray, Intersection, Constants, Transform } from "@ali/tidejs";
import { Application } from "../../../Application";
import { PanoImageApp } from "../../../PanoImageApp";
import { ElementHighLightMaterial } from "../../../Material/ElementHighLightMaterial";
import { ConsoleLog, ErrorType } from "../../../lib/log";
import { SpotPrivate } from "../../../api/spot/lib/private";
import { DataCenter } from "../../../GameLogic/DataCenter";
import { IAnnotationConfigItem } from "../interface";
import { Utils } from "../../../lib/utils";
import { isEmpty } from "lodash";
import { playIcon, pauseIcon } from "../../../assets/icon";

export function getImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = Utils.autoAddProtocol(url);
    image.onload = () => resolve(image);
    image.crossOrigin = "anonymous";
  })

}
function getMaterialEditor(entity: Entity) {
  const render = entity.getComponentByType<Renderer>(Renderer)
  if (!render) { return }
  render.material = new ElementHighLightMaterial();
  const dataArray: number[] = [];
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 4; j++) {
      dataArray.push(229, 229, 234, 255);
    }
  }
  const rawImage: UnpackedRawImage = {
    channels: 4,
    height: 4,
    width: 4,
    rawDataFormat: TextureFormat.R8G8B8A8,
    isRGBE: false,
    rawData: new Uint8Array(dataArray)
  };

  const nullTex: Texture2D = new Texture2D(4, 4, rawImage.rawDataFormat);
  nullTex.setTextureImageData(rawImage);
  (render.material as UnlitMaterial).albedo = nullTex;
  render.material.renderQueue = RenderQueue.Transparent;
  render.material.transparent = true;
  render.material.cullface = Constants.BACK;

  (render.material as any).highLightStrength = 0;
  const material = render.material as ElementHighLightMaterial;
  return material;
}
export function getMaterial(entity: Entity) {
  if (Application.mode == "editor") {
    return getMaterialEditor(entity)
  }
  const render = entity.getComponentByType<Renderer>(Renderer)
  if (!render) { return }
  const m = new UnlitMaterial();
  m.name = entity.name;
  render.material = m;
  render.material.cullface = Constants.BACK;

  return m;
}
export function isUC() {
  const navigator = window.navigator;
  if (!navigator || !navigator.userAgent) { return false }
  const u = navigator.userAgent;
  return u.includes("AliApp") || u.includes("UC");
}
export async function addImageElement(item: IAnnotationConfigItem) {
  const { id, anchor, info, scale, rotation, normal } = item;
  ConsoleLog.log("utils", "addImageElement", "开始", id);
  const url = info.src;
  if (!url) {
    ConsoleLog.error("utils", "addImageElement", ErrorType.DataError, "info.src 未传入");
    return
  }

  const entity = Entity.CreatePrimitive(PrimitiveType.DoublePlane);

  if (!entity) {
    ConsoleLog.error("utils", "addImageElement", ErrorType.InternalError, "entity 创建失败");
    return
  }

  entity.name = id;
  const render = entity.getComponentByType<Renderer>(Renderer)
  if (!render) { return }
  render.isActive = false;
  const material = getMaterial(entity) as UnlitMaterial;

  material.transparent = true;

  material.renderQueue = RenderQueue.Transparent;
  if (!material) { return }

  const image = await getImage(url);
  const texture = new Texture2D(image.width, image.height);
  texture.setTextureImageSource(image, 0, true);
  texture.generateMipmaps();
  material.albedo = texture;
  entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
  entity.transform.localScale = vec3.fromValues(scale.x || 0.2, scale.y || 0.2, 0.2);
  render.isActive = true;
  entity.transform.rotation = quat.fromEuler(quat.create() ,Transform.Rad2Deg * rotation.x, Transform.Rad2Deg * rotation.y, Transform.Rad2Deg * rotation.z)
  if (info.control) {
    // 因为会有图片和视频的切换逻辑，所以一开始图片标也要添加视频播放控制图标，并进行隐藏
    const iconEntity = await addVideoControlElement(entity, false);
    if (iconEntity) {
      const iconEntityRender = (iconEntity as Entity).getComponentByType<Renderer>(Renderer);
      iconEntityRender!.isActive = false;
    }
  }
  return entity;
}

export async function addVideoElement(item: IAnnotationConfigItem) {
  const { id, anchor, info, scale, normal, rotation } = item;
  ConsoleLog.log("utils", "addVideoElement", "开始", id);
  const url = Utils.autoAddProtocol(info.src);
  if (!url) {
    ConsoleLog.error("utils", "addVideoElement", ErrorType.DataError, "info.src 未传入");
    return
  }
  const entity = Entity.CreatePrimitive(PrimitiveType.DoublePlane);
  if (!entity) { return }
  entity.name = id;
  const render = entity.getComponentByType<Renderer>(Renderer);
  if (!render) { return }
  render.isActive = false;

  entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
  entity.transform.localScale = vec3.fromValues(scale.x || 0.2, scale.y || 0.2, scale.z || 0.2);
  entity.transform.rotation = quat.fromEuler(quat.create() ,Transform.Rad2Deg * rotation.x, Transform.Rad2Deg * rotation.y, Transform.Rad2Deg * rotation.z)

  if (info.control) {
    // 添加视频播放控制图标
    const iconEntity = await addVideoControlElement(entity, false);
    if (iconEntity) {
      const iconEntityRender = (iconEntity as Entity).getComponentByType<Renderer>(Renderer);
      iconEntityRender!.isActive = false;
    }
  }

  const scene = SceneManager.GetInstance().currentScene;
  await replaceElementVideo(entity, {
    url,
    startTime: info.startTime,
    endTime: info.endTime,
    muted: info.muted
  });
  render.isActive = true;
  scene.addEntity(entity);
  return entity;
}
/* eslint-disable @typescript-eslint/naming-convention*/
interface VideoInfo {
  url: string
  startTime?: number
  endTime?: number
  muted?: boolean
}

async function addVideoControlElement(parentEntity: Entity, isPaused: boolean) {
  const iconEntity = Entity.CreatePrimitive(PrimitiveType.Plane);
  if (!iconEntity) { return }
  const iconRender = iconEntity.getComponentByType<Renderer>(Renderer);
  const m = new UnlitMaterial();
  iconRender!.material = m;
  iconRender!.material!.renderQueue = RenderQueue.Transparent;
  iconRender!.material!.transparent = true;
  const controlIcon = isPaused ? playIcon : pauseIcon;
  const image = await getImage(controlIcon);
  const texture = new Texture2D(image.width, image.height);
  texture.setTextureImageSource(image, 0, true);
  texture.generateMipmaps();
  if (!texture) { return }

  (iconRender!.material! as any).albedo = texture;
  iconEntity.transform.localPosition[2] = 0.01; // z轴微调避免遮挡
  iconEntity.name = 'videoControl';
  iconEntity.transform.localScale = [0.4, 0.4, 0.4];
  iconEntity.setParent(parentEntity);
  return iconEntity;
}

async function replaceElementVideo(entity: Entity, info: VideoInfo): Promise<void> {
  const { url, startTime, endTime, muted } = info
  const material = getMaterial(entity);
  if (!material) {
    return;
  }
  let videoPlayer = entity!.getComponentByType<VideoPlayer>(VideoPlayer);
  if (!videoPlayer) {
    videoPlayer = entity!.addComponent<VideoPlayer>(VideoPlayer);
  }
  const texture = await videoPlayer.loadVideo(url);
  if (!texture) { return }
  material.albedo = texture;
  videoPlayer.play();
  videoPlayer.videotexture!.data.loop = true;
  videoPlayer.videotexture!.data.muted = muted;
  const playTimer = setInterval(() => {
    if (!videoPlayer) {
      clearInterval(playTimer)
    }
    const currentTime = videoPlayer?.getCurrentTime() as number
    if (!currentTime) {
      clearInterval(playTimer)
    }
    if (startTime && (currentTime < startTime)) {
      videoPlayer?.jump(startTime)
    }
    if (endTime && (currentTime > endTime)) {
      videoPlayer?.jump(startTime || 0)
    }
  }, 200)
}
export function getVisibleSpotsByPosition(targetPosition: vec3) {
  const SpotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
  const arr: string[] = [];
  const octree = DataCenter.octree;
  const trans = DataCenter.trans;
  const currentSpotID = SpotAPI.getNearestSpotByPosition(targetPosition);
  if (!currentSpotID) { return arr }

  function isVisibleElement(spotID: string) {
    const currentPosition = SpotAPI.getPoseBySpotId(spotID)!.pos;
    const targetDistance = vec3.distance(currentPosition, targetPosition);
    const dir = vec3.sub(vec3.create(), targetPosition, currentPosition)
    const d = trans.inverseTransformDirection(dir);
    const o = trans.inverseTransformPoint(currentPosition);
    const r: Ray = new Ray(o, d);
    // PC上要1ms
    const its: Intersection | undefined = octree.rayIntersect(r);
    if (its) {
      if (targetDistance - its.distance < 0.2) {
        if (arr.includes(spotID)) { return }
        arr.push(spotID)
      }
    }
  }

  const neighbors = SpotAPI.getHotSpotById(currentSpotID)!.neighbors;
  if (!octree || !trans || !neighbors) { return arr; }
  isVisibleElement(currentSpotID);
  for (const key of neighbors) {
    isVisibleElement(key.name);
  }
  return arr;
}
export function hasIncludeSpots(includeSpots: string[]) {
  if (includeSpots && includeSpots[0] && includeSpots[0].includes("p")) {
    return true
  }
  return false;
}

export function judgeImageOrVideo(url: string) {
  if (!url) return false;
  url = url.split('?')[0];
  const imgType = ["gif", "jpeg", "jpg", "bmp", "png", "webp"];
  const videoType = ["avi", "wmv", "mkv", "mp4", "mov", "rm", "3gp", "flv", "mpg", "rmvb"];
  if (url.includes("base64")) {
    return 'image';
  }
  if (RegExp("\.(" + imgType.join("|") + ")$", "i").test(url.toLowerCase())) {
    return 'image';
  } else if (RegExp("\.(" + videoType.join("|") + ")$", "i").test(url.toLowerCase())) {
    return 'video';
  } else {
    return false;
  }
}

export function isInModelApp() {
  return !!Application.instance;
}


export function getAppInstance() {
  return PanoImageApp.instance || Application.instance;
}
