import { mat4, vec3 } from "gl-matrix";
import { BaseBehavior, Camera, Component, InputContext, IOnStart, IUpdatable, Transform, Viewer } from "@ali/tidejs";

@Component("KeepPos")
export class KeepPos extends BaseBehavior implements IOnStart , IUpdatable{
  private vi = mat4.create();
  private pi = mat4.create();
  private v3 = vec3.create();
  private spos = vec3.fromValues(0.6, 0.0, 0.2);
  private trans!: Transform;
  public arm!: Transform;
  update(deltaTime: number, inputContext: InputContext): void {
    const temp = Viewer.GetScreen2World(this.spos);
    this.trans.position = temp;
  }
  onStart(): void {
    this.trans = this.entity.transform;
    mat4.invert(this.pi,Camera.MainCamera!.projectionMatrix());
  }



}
