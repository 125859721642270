import { Annotation2DPrivate } from "../../../../api/elements/annotation2d/lib/private";
import { CSS2DElement } from "../../../../Components/Css2DElement";
import { Entity, SceneManager } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import { ConsoleLog, ErrorType } from "../../../../lib/log";
import { Application } from "../../../../Application";
import { Element } from "../../../../lib/utils/eca";
import { IAnnotationConfigItem, SDKElementType } from "../../../../api/elements/interface";
import { BaseElement } from "../../../../lib/utils/eca";
import { SetVisibleBySpot } from "../../../../Components/SetVisbleBySpot";
import { SpotPrivate } from "../../../../api/spot/lib/private";
import { Utils } from "../../../../lib/utils";
import { isInModelApp } from "../../../../api/elements/lib/util";

export const AnnotationHTMLPrefix = "css2d-annotation-";
export const AnnotationImagePrefix = "css2d-annotation-image-";
export const DetailHTMLPrefix = "css2d-annotation-detail-";
export const DetailImagePrefix = "css2d-annotation-detail-image-";
function createDetailElement(id: string, detailImgUrl: string, direction: number){
  const size = Annotation2DPrivate.Config.imageSize;

  const { detailWidthScale, detailHeightScale } = Annotation2DPrivate.Config;
  const image = new Image();
  image.id = DetailImagePrefix + id;
  image.src = Utils.autoAddProtocol(detailImgUrl);
  image.draggable = false;
  image.style.userSelect = "none";
  image.onload = ()=>{
    const width = image.naturalWidth || image.width || 440;
    const height = image.naturalHeight || image.height || 140;
    image.style.maxWidth =  width * detailWidthScale + "px";
    image.style.maxHeight = height * detailHeightScale+ "px";
    dom.appendChild(image);
  }

  const dom = document.createElement("div");
  dom.style.pointerEvents = "auto";
  dom.style.cursor = "pointer";
  dom.id = `${DetailHTMLPrefix}${id}`;
  dom.style.position = "absolute";
  dom.style.display = "none";
  // 左：-1 右：1 上：2 下：-2
  const position = `${size + 10}px`;
  if(direction === 1){
    dom.style.left = position;
  } else if(direction === -1){
    dom.style.right = position;
  } else if(direction === 2){
    dom.style.bottom = position;
  } else {
    dom.style.top = position;
  }
  return dom;
}

function createAnnotationElement(id: string, url: string){
  const size = Annotation2DPrivate.Config.imageSize;
  // const css3DElement = `<img id=${AnnotationHTMLPrefix}${id} src="${url}" height="${size}" width="${size}" ></img>`;
  const dom = document.createElement("div");
  dom.style.pointerEvents = "auto";
  dom.style.cursor = "pointer";

  const image = new Image(size,size,);
  image.src = Utils.autoAddProtocol(url);
  image.id = AnnotationImagePrefix + id;
  image.draggable = false;
  image.style.userSelect = "none";
  dom.style.display = "flex";
  dom.appendChild(image);
  // dom.insertAdjacentHTML('beforeend', css3DElement);

  return [
    dom,
    image,
  ]
}
function build1(elementConfig: IAnnotationConfigItem){
  const annotation2D = Application.getInstance<Annotation2DPrivate>(Annotation2DPrivate.ModuleName)
  const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName)
  const { id ,anchor, info, label, labelOrientation } = elementConfig;
  const position = vec3.fromValues(anchor.x,  anchor.z, -anchor.y);
  const imageUrl = info.dotSrc || Annotation2DPrivate.Config.imageUrl;
  const plane: Entity = new Entity(id);

  const scene = SceneManager.GetInstance().currentScene;
  scene.addEntity(plane);

  const [container, annotationImg] = createAnnotationElement(id, imageUrl);
  const detailImg = createDetailElement(id, info.src || label, labelOrientation); // 兼容老数据label
  container.appendChild(detailImg);

  annotationImg.addEventListener("mouseenter",()=>{
    ConsoleLog.log("Annotation","build1","annotation enter", annotation.id);
    annotation2D.event.emit("enter", id);
  })
  annotationImg.addEventListener("mouseleave",()=>{
    ConsoleLog.log("Annotation","build1","annotation exit", annotation.id);
    annotation2D.event.emit("exit", id);
  })
  annotationImg.addEventListener("click",()=>{
    ConsoleLog.log("Annotation","build1","annotation click", annotation.id);
    annotation2D.event.emit("click", id);
  })
  detailImg.addEventListener("click",()=>{
    ConsoleLog.log("Annotation","build1","annotation clickDetail", annotation.id);
    annotation2D.event.emit("clickDetail", id);
  })
  plane.transform.position = position;

  const annotation = new Annotation(plane, elementConfig, annotation2D, imageUrl);
  // 这里有先后顺序，要先判断可见性，再设置CSS2DElement
  const isInModelPanorama = isInModelApp();
  if (isInModelPanorama) {
    const com2 = annotation.addComponent(SetVisibleBySpot);
    com2.preAwake(spotAPI.getFirstSpotId())
  }
  const com: CSS2DElement = annotation.addComponent(CSS2DElement);
  com.container = Application.instance.container;
  com.elementNode = container;
  com.elementNode.id = AnnotationHTMLPrefix + id;
  com.preAwake();
  showDetailByCount(annotation);
  annotation2D.addElement(annotation);
  return annotation;
}
function showDetailByCount(annotation: Annotation){
  if(Annotation2DPrivate.Config.detailAutoOpen){
    const annotation2D = Application.getInstance<Annotation2DPrivate>(Annotation2DPrivate.ModuleName)
    const detailShowCount = annotation2D.getAllElements().filter((item)=> item.state.isDetailShow).length;
    if(detailShowCount >= Annotation2DPrivate.Config.maxDetailAutoShowCount){
      return
    }
    annotation.showDetail();
  }
}
interface IState{
  isDetailShow: boolean;
  _labelOrientation: number;
  isShow: boolean;

}

/**
 * 2d锚点标签，由一个锚点和详情弹框共同组成，点击锚点可以切换详情弹框的显示和隐藏，标签样式如下所示：
 * * 详情隐藏状态：
 *
 * ![图片](https://intranetproxy.alipay.com/skylark/lark/0/2021/png/358286/1624429618518-1f66c291-6d1c-4d72-bc0a-bbb66b20bb00.png)
 *
 * * 详情展开状态：
 *
 * ![图片](https://intranetproxy.alipay.com/skylark/lark/0/2021/png/358286/1624429659430-15ba2870-5075-4867-a85e-f318945938d6.png?x-oss-process=image%2Fresize%2Cw_423)
 *
 */
@Element({
  type: SDKElementType.annotation2d,
  build: build1,
})
export class Annotation extends BaseElement<IState> {

  public state: IState = {
    isDetailShow: false,
    _labelOrientation: -1,
    isShow: true,
  }
  public sdkType: SDKElementType;

  constructor( entity: Entity, item: IAnnotationConfigItem, private annotationAPI: Annotation2DPrivate, private _texture: string ){
    super(entity, item);
    const { label, labelOrientation, info } = item;
    this.sdkType = SDKElementType.annotation2d;
    this.label = info.src || label; // label兼容老数据
    this.labelOrientation = labelOrientation;
    this.event.on("show",()=>{
      ConsoleLog.log("Annotation", "event","监听到show事件，显示 showDetail");
      this.setState({
        isShow: true
      })
      // 这里是想标签手动隐藏了就不自动展开了，但cbu不想这样
      // if(this.isDetailShow){
      showDetailByCount(this);
      this.annotationAPI.event.emit("show", item.id);
      // }
    })
    this.event.on("hide",()=>{
      this.setState({
        isShow: false
      })
      this.annotationAPI.event.emit("hide", item.id);
      ConsoleLog.log("Annotation", "event","监听到 hide 事件，显示 showDetail");
      this.hideDetail();
    })
  }

  get position(){
    return this.entity.transform.position;
  }

  set image(url: string){
    this._texture = url;
  }
  get image(){
    return this._texture;
  }
  set labelOrientation(value: number){
    // this._labelOrientation = value
    this.setState({
      _labelOrientation: value
    })
  }
  get labelOrientation(){
    return this.state._labelOrientation;
  }
  set label(value: string){
    // todo: 设置图片改变
    this._texture = value;
    const image = document.getElementById(DetailImagePrefix + this.id) as HTMLImageElement;
    if(!image){ return }
    image.src = Utils.autoAddProtocol(value);
  }
  get label(){
    return this._texture;
  }
  get detailImage(){
    return document.getElementById(DetailImagePrefix + this.id) as HTMLImageElement;
  }

  /**
     * 显示标签卡片
     */
  showDetail(){
    if(!this.state.isShow){ return }
    const container = document.getElementById(DetailHTMLPrefix + this.id);
    if(!container){
      ConsoleLog.error("Annotation", "showDetail", ErrorType.InternalError,`找不到${this.id} annotation`);
      return
    }
    ConsoleLog.log("Annotation", "showDetail", this.id);
    container.style.display = "flex";
    // this.isDetailShow = true;
    this.setState({
      isDetailShow: true
    })
    this.annotationAPI.event.emit("showDetail", this.id);
  }
  /**
     * 隐藏标签卡片
     */
  hideDetail(){
    const container = document.getElementById(DetailHTMLPrefix + this.id);
    if(!container){
      ConsoleLog.error("Annotation", "hideDetail", ErrorType.InternalError,`找不到${this.id} annotation`);
      return
    }
    ConsoleLog.log("Annotation", "hideDetail", this.id);
    container.style.display = "none";
    // this.isDetailShow = false;
    this.setState({
      isDetailShow: false
    })
    this.annotationAPI.event.emit("hideDetail", this.id);
  }

  dispose(){
    super.dispose();
    ConsoleLog.info("Annotation","dispose", this.id);
  }
  /**
     * 高亮标签，其实就是设置 backgroundColor 颜色
     * @param color rgb数组
     */
  highlight(color: number[]){
    const container = document.getElementById(DetailHTMLPrefix + this.id);
    const image = document.getElementById(AnnotationImagePrefix + this.id);
    if(!container || !image){ return }
    container.style.backgroundColor = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    image.style.backgroundColor = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }
  /**
     * 取消高亮
     */
  unhighlight(){
    const container = document.getElementById(DetailHTMLPrefix + this.id);
    const image = document.getElementById(AnnotationImagePrefix + this.id);
    if(!container || !image){ return }
    container.style.backgroundColor = "";
    image.style.backgroundColor = "";
  }
  /**
     * 移动标签
     * @param position
     */
  moveTo(position: vec3) {
    this.entity.transform.position = position;
  }

  /**
     * 设置标签的位置
     * @param anchor
     */
  public setPositon(anchor: any) {
    this.entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
  }

  /**
     * 设置标签的图案
     * @param url
     * @returns
     */
  public setLabel(url: string) {
    this.label = url;
  }

  /**
     * 改变标签dom的pointerEvents属性
     * @param value
     * @returns
     */
  public changePointerEvents(value: string) {
    const container = document.getElementById(AnnotationHTMLPrefix + this.id);
    if(!container){ return }
    container.style.pointerEvents = value;
  }
}
