import { Application } from "../Application";
import { mat4, vec3, vec4 } from "gl-matrix";
import { Scene, Entity, AssetManager, Mesh, MeshFilter, Renderer, SceneManager, PureColorMaterial, RenderQueue } from "@ali/tidejs";
import { SpotPrivate } from "../api/spot/lib/private";
import { ConsoleLog } from "../lib/log";
import { Utils } from "../lib/utils";
import { DataCenter } from "./DataCenter";
import { ConfigPrivate } from "../api/config/lib/private";
import { ModelType } from "../Behaviors/GameEnter";

export const PANORAMA_FACES = ['r', 'l', 'u', 'd', 'f', 'b'];

export async function getCubeTextureUrl(spotID: string) {
  if (spotID === '') {
    return [];
  }
  const arr: string[] = [];
  await Promise.all(PANORAMA_FACES.map(async (item) => {
    const url = await Application.Config.publicResourceResolver.resolve(`panorama/${spotID}/1/${item}.jpg`)
    arr.push(url);
  }))
  return arr;
}
export async function getModelTextureUrl() {
  const url = await Application.Config.publicResourceResolver.resolve(`model/dense_trim_tex0.jpg`)
  return url;
}
function parseURL(url: string): Array<string> {
  url = url.split('?')[0]; // 去除参数，排除oss鉴权参数的影响
  const _temp = url.split('/');
  const assetName = _temp.pop()!;
  const _temp2 = assetName.split('.');
  const extension = _temp2.pop()!;
  const filename = _temp2.join('.');
  const path = _temp.join('/');
  return [path, filename, extension];
}
export const getImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = Utils.autoAddProtocol(url);
    // for ios 9
    image.crossOrigin = "anonymous";
    image.onload = () => { resolve(image) }
  })
}

export const WanHuaTongUtility = {
  matchCoordSys(data: any): vec3 {
    return vec3.fromValues(data[0], data[2], -data[1]);
  },
  tempvec3_1: vec3.create(),
  tempvec3_2: vec3.create(),
  tempY: vec3.fromValues(0, 1, 0),


  /**
     * 返回数组[right:vec3, up:vec3, forward:vec3]
     *
     * @param theta
     * @param phi
     */
  getDirByThetaPhi(theta: number, phi: number): any {
    //摄像机的 forward 是 -Z；
    const dir = vec3.fromValues(-Math.cos(theta) * Math.sin(phi),
      -Math.cos(phi),
      -Math.sin(theta) * Math.sin(phi)
    );
    //vec3.normalize(dir,dir);
    // _1 x
    vec3.cross(this.tempvec3_1, this.tempY, dir);
    // _2 y
    vec3.cross(this.tempvec3_2, dir, this.tempvec3_1);
    return [vec3.clone(this.tempvec3_1), vec3.clone(this.tempvec3_2), dir];
  },

  /**
     * 返回数组[right:vec3, up:vec3, forward:vec3]
     * @param dir
     */
  getRUFdirByForward(dir: vec3) {
    // _1 x
    vec3.cross(this.tempvec3_1, this.tempY, dir);
    // _2 y
    vec3.cross(this.tempvec3_2, dir, this.tempvec3_1);
    return [vec3.clone(this.tempvec3_1), vec3.clone(this.tempvec3_2), dir];

  },

  tempvec4_1: vec4.create(),
  tempvec4_2: vec4.create(),
  tempvec000: vec3.fromValues(0, 0, 0),
  tempvec010: vec3.fromValues(0, 1, 0),

  tempvec0100: vec4.fromValues(0, 1, 0, 0),
  tempvec00n10: vec4.fromValues(0, 0, -1, 0),
  tempMat4_1: mat4.create(),
  /**
     *
     * @param pitchAndYaw
     */
  getDirByThetaPhiv2(theta: number, phi: number): Array<vec3> {
    const posY = Math.cos(phi) * Math.cos(theta);
    const posX = Math.cos(phi) * Math.sin(theta);
    const posZ = Math.sin(phi);

    mat4.targetTo(this.tempMat4_1, this.tempvec000, vec3.fromValues(posX, posZ, -posY), this.tempvec010);

    vec4.transformMat4(this.tempvec4_1, this.tempvec0100, this.tempMat4_1);
    vec4.transformMat4(this.tempvec4_2, this.tempvec00n10, this.tempMat4_1);
    return [vec3.fromValues(this.tempvec4_1[0], this.tempvec4_1[1], this.tempvec4_1[2]), vec3.fromValues(this.tempvec4_2[0], this.tempvec4_2[1], this.tempvec4_2[2])];
  },
  getDirByThetaPhiv3(theta: number, phi: number) {
    const posY = Math.cos(phi) * Math.cos(theta);
    const posX = Math.cos(phi) * Math.sin(theta);
    const posZ = Math.sin(phi);
    const target = vec3.fromValues(posX, posZ, -posY);
    return target;
  },
  getThetaPhiByDir(direction: vec3): [number, number] {
    const transformedDirection = vec3.fromValues(direction[0], -direction[2], direction[1])
    const theta = Math.atan2(transformedDirection[0], transformedDirection[1]);
    const phi = Math.atan2(transformedDirection[2], Math.sqrt(transformedDirection[0] * transformedDirection[0] + transformedDirection[1] * transformedDirection[1]));
    return [theta, phi];
  },

  getOurThetaPhiFromAreaAnnotation(pitch: number, yaw: number, spotId: string): [number, number] {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getPoseBySpotId(spotId);
    if (!spot) { return [0, 0]; }

    const [theta, phi] = this.pitchYawToThetaPhi(pitch, yaw, spotId);
    const posY = Math.cos(phi) * Math.cos(theta);
    const posX = Math.cos(phi) * Math.sin(theta);
    const posZ = Math.sin(phi);

    const rotationMatrix = mat4.targetTo(mat4.create(), vec3.fromValues(0, 0, 0), vec3.fromValues(posX, posZ, -posY), vec3.fromValues(0, 1, 0));
    const spotTranform = mat4.targetTo(mat4.create(), [0, 0, 0], spot.forward, spot.up);
    mat4.mul(rotationMatrix, spotTranform, rotationMatrix);
    const forward = vec4.transformMat4(vec4.create(), vec4.fromValues(0, 0, -1, 0), rotationMatrix);

    const resPhi = Math.atan2(forward[1], Math.sqrt(forward[0] * forward[0] + forward[2] * forward[2]));
    const resTheta = Math.atan2(forward[0] / Math.cos(resPhi), -forward[2] / Math.cos(resPhi))

    return [resTheta, resPhi];
  },
  getOurThetaPhiFromConfig(theta: number, phi: number, spotId: string): [number, number] {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getPoseBySpotId(spotId);
    if (!spot) { return [0, 0]; }

    const posY = Math.cos(phi) * Math.cos(theta);
    const posX = Math.cos(phi) * Math.sin(theta);
    const posZ = Math.sin(phi);

    const rotationMatrix = mat4.targetTo(mat4.create(), vec3.fromValues(0, 0, 0), vec3.fromValues(posX, posZ, -posY), vec3.fromValues(0, 1, 0));
    const spotTranform = mat4.targetTo(mat4.create(), [0, 0, 0], spot.forward, spot.up);
    mat4.mul(rotationMatrix, spotTranform, rotationMatrix);
    const forward = vec4.transformMat4(vec4.create(), vec4.fromValues(0, 0, -1, 0), rotationMatrix);

    const resPhi = Math.atan2(forward[1], Math.sqrt(forward[0] * forward[0] + forward[2] * forward[2]));
    const resTheta = Math.atan2(forward[0] / Math.cos(resPhi), -forward[2] / Math.cos(resPhi))

    return [resTheta, resPhi];
  },

  getOldSDKConfigThetaPhiFromOurDirection(direction: vec3, spotId: string): [number, number] {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getPoseBySpotId(spotId);
    if (!spot) { return [0, 0]; }

    const spotTranform = mat4.targetTo(mat4.create(), [0, 0, 0], spot.forward, spot.up);
    vec3.transformMat4(direction, direction, mat4.invert(spotTranform, spotTranform));
    const transformedDirection = vec3.fromValues(direction[0], -direction[2], direction[1])
    const theta = Math.atan2(transformedDirection[0], transformedDirection[1]);
    const phi = Math.atan2(transformedDirection[2], Math.sqrt(transformedDirection[0] * transformedDirection[0] + transformedDirection[1] * transformedDirection[1]));
    return [theta, phi];
  },

  pitchYawToXYZ(pitch: number, yaw: number, radius = 1, spotId: string): vec3 {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getPoseBySpotId(spotId);
    if (!spot) { return vec3.create(); }
    const opticalCenter = spot.pos;
    const mat3d = mat4.targetTo(mat4.create(), [0, 0, 0], vec3.sub(vec3.create(), vec3.create(), spot.forward), spot.up);
    let direction = vec3.fromValues(Math.cos(pitch) * Math.cos(yaw), Math.sin(pitch), Math.cos(pitch) * Math.sin(yaw));
    vec3.scale(direction, direction, radius);
    direction = vec3.transformMat4(vec3.create(), direction, mat3d);

    vec3.add(direction, direction, opticalCenter);
    return direction;
  },

  /**
     * 在相同位置下，把老中台导航入口设置的值转成入口设置的值。为了兼容老中台数据，所以新中台需要存储一样的值。
     * @param pitch
     * @param yaw
     * @param spot
     * @returns
     */

  pitchYawToThetaPhi(pitch: number, yaw: number, spotId: string): [number, number] {
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getPoseBySpotId(spotId);
    if (!spot) { return [0, 0] }


    // let direction = vec3.fromValues(Math.cos(pitch) * Math.cos(yaw), Math.sin(pitch), Math.cos(pitch) * Math.sin(yaw));

    // direction = vec3.sub(direction, this.pitchYawToXYZ(pitch, yaw, 1, spot), spot.opticalCenter);

    // vec3.normalize(direction, direction);

    // const theta = Math.atan2(direction[0], direction[2]);
    // const phi = Math.atan2(direction[1], Math.sqrt(direction[0] * direction[0] + direction[2] * direction[2]));

    // return [theta, phi];

    yaw = 0.5 * Math.PI - yaw;
    pitch = pitch - 0.5 * Math.PI;

    const posZ = Math.cos(pitch) * Math.cos(yaw);
    const posX = Math.cos(pitch) * Math.sin(yaw);
    const posY = Math.sin(pitch);

    let direction = vec3.fromValues(posX, posY, posZ);

    const mat3d = mat4.targetTo(mat4.create(), [0, 0, 0], vec3.sub(vec3.create(), vec3.create(), spot.forward), spot.up);
    direction = vec3.transformMat4(vec3.create(), direction, mat3d);
    vec3.normalize(direction, direction);

    const phi = Math.atan2(direction[1], Math.sqrt(direction[0] * direction[0] + direction[2] * direction[2]));
    const theta = Math.atan2(direction[0] / Math.cos(pitch), direction[2] / Math.cos(pitch));

    return [theta, phi];
  },

  /**
     * 在相同位置下，把老中台入口设置的值转成导航入口设置的值。为了兼容老中台数据，所以新中台需要存储一样的值。
     * 入口角度是local的pitch yaw，不知道为何需要再乘上该spot的local矩阵的逆，不知道物理意义是什么，需要老中台开发者释疑
     * @param theta
     * @param phi
     * @param spot
     * @returns
     */
  thetaPhiToPitchYaw(theta: number, phi: number, spotId: string): [number, number] {
    const posZ = Math.cos(theta) * Math.cos(phi);
    const posX = Math.sin(theta) * Math.cos(phi);
    const posY = Math.sin(phi);
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const spot = spotAPI.getPoseBySpotId(spotId);
    if (!spot) { return [0, 0] }
    let direction = vec3.fromValues(posX, posY, posZ);
    const mat3d = mat4.targetTo(mat4.create(), [0, 0, 0], vec3.sub(vec3.create(), vec3.create(), spot.forward), spot.up);
    mat4.invert(mat3d, mat3d);
    direction = vec3.transformMat4(vec3.create(), direction, mat3d);

    vec3.normalize(direction, direction);

    const pitch = Math.atan2(direction[1], Math.sqrt(direction[0] * direction[0] + direction[2] * direction[2]));
    const yaw = Math.atan2(direction[0] / Math.cos(pitch), direction[2] / Math.cos(pitch));

    // let yaw = 0;
    // if (pitch < Math.PI / 2.0 - 0.001 || pitch > -Math.PI / 2.0 + 0.001) {
    //     const cosPitch = Math.cos(pitch);
    //     let cosYaw = direction[0] / cosPitch;
    //     const sinYaw = direction[2] / cosPitch;
    //     if (cosYaw > 1) { cosYaw = 1; }
    //     if (cosYaw < -1) { cosYaw = -1; }
    //     if (sinYaw >= 0) {
    //         yaw = Math.acos(cosYaw);
    //     } else {
    //         yaw = Math.acos(-cosYaw) + Math.PI;
    //     }
    // }
    return [pitch + 0.5 * Math.PI, 0.5 * Math.PI - yaw];
  },

  /**
     * 加载XYZ格式
     *
     * v1 版本的xyz,是单个模型与单个纹理，存在在同级目录
     * @param url xyz文件的路径
     */
  async LoadXYZ_v1(url: string, scene?: Scene): Promise<Entity> {
    ConsoleLog.info("WanHuaTongUtility", "LoadXYZ_v1", "开始加载万花筒XYZ模型", url);
    const paths = parseURL(url);
    let uuid = '';
    await AssetManager.GetInstance().loadAsset(paths[1], url, {
      UUID: paths[1],
      extension: "xyz"
    }).then((meshes) => {
      if (meshes && Array.isArray(meshes)) {
        meshes.forEach(element => {
          (element as Mesh).upLoad();
          uuid = (element as Mesh).uuid;
        });
      }
    });
    ConsoleLog.info("WanHuaTongUtility", "LoadXYZ_v1", "加载万花筒XYZ模型结束");

    // await AssetManager.GetInstance().loadAsset(paths[1]+'0jpg', paths[0]+"/"+paths[1]+"0.jpg", paths[1]+'0jpg');
    const entity: Entity = new Entity(paths[1]);
    entity.addComponent<MeshFilter>(MeshFilter).mesh = AssetManager.GetInstance().getAsset<Mesh>(uuid);
    entity.addComponent<Renderer>(Renderer);

    if (scene) {
      scene.addEntity(entity);
    } else {
      SceneManager.GetInstance().currentScene.addEntity(entity);
    }
    return entity;
  },

  /**
     * 加载XYZ格式
     *
     * v1 版本的xyz,是单个模型与单个纹理，存在在同级目录
     * @param url xyz文件的路径
     */
  async LoadXYZ_v1_LYJ(url: string, scene?: Scene): Promise<Entity> {
    ConsoleLog.info("WanHuaTongUtility", "LoadXYZ_v1", "开始加载凌云镜XYZ模型", url);
    const paths = parseURL(url);
    const uuids: string[] = [];
    await AssetManager.GetInstance().loadAsset(paths[1], url, { extension:"xyz" }).then((meshes) => {
      if (meshes && Array.isArray(meshes)) {
        meshes.forEach(element => {
          (element as Mesh).upLoad();
          uuids.push((element as Mesh).uuid);
        });
      }
    });
    ConsoleLog.info("WanHuaTongUtility", "LoadXYZ_v1", "加载凌云镜XYZ模型结束");
    const entity_0: Entity = new Entity(paths[1]);
    entity_0.addComponent<MeshFilter>(MeshFilter).mesh = AssetManager.GetInstance().getAsset<Mesh>(uuids[0]);
    entity_0.addComponent<Renderer>(Renderer);

    const entity_1: Entity = new Entity(paths[1] + "outer");
    entity_1.addComponent<MeshFilter>(MeshFilter).mesh = AssetManager.GetInstance().getAsset<Mesh>(uuids[1]);
    const renderer = entity_1.addComponent<Renderer>(Renderer);
    if (renderer) {
      const mtl = new PureColorMaterial();
      mtl.color = [1, 1, 1, 0.0];
      mtl.transparent = true;
      mtl.renderQueue = RenderQueue.Geometry;
      renderer.material = mtl;
    }

    entity_1.setParent(entity_0);

    if (scene) {
      scene.addEntity(entity_0);
    } else {
      SceneManager.GetInstance().currentScene.addEntity(entity_0);
    }
    return entity_0;
  },

  /**
     * 改变外墙透明度(lyj模型使用)
     * @param destTransparency
     */
  changeTransparencyOfTheWall(destTransparency: number) {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const configDefault = configAPI.getConfig();
    const modelType = configDefault.type;
    if (modelType === ModelType.lyj) {
      const children = DataCenter.RootModel.getChildrenEntitiesRecursively();
      children.forEach(entity => {
        if (entity.name.includes('outer')) {
          const renderer = entity.getComponentByType<Renderer>(Renderer);
          if (renderer && renderer.material && renderer.material instanceof PureColorMaterial) {
            renderer.material.color = [1, 1, 1, destTransparency];
          }
        }
      })
    }
  }
};
