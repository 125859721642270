import { BaseBehavior } from "@ali/tidejs";
import { TransitionEventParams } from "../api/transition/lib/interface";
import { TransitionPrivate } from "../api/transition/lib/private";
import { Application } from "../Application";
import { Css2DContainer } from "../GameLogic/Css2dContainer";
import { MessageCenter } from "../GameLogic/MessageCenter";
import { ConsoleLog } from "../lib/log";
import { ProgressAPI } from "../lib/progress/ProgressReporter";
import { BaseElement } from "../lib/utils/eca";
// todo: Onstart 的时候执行事件
export class BaseBehaviorVR extends BaseBehavior{
  element!: BaseElement;
  log(className: string, funcName: string,...params: any){
    if(ConsoleLog){
      ConsoleLog.log(className, funcName, ...params)
    }
  }
  _init(){
    const onTransition = ()=>{
      const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
      transitionAPI.transitionProcessObserve.subscribe((event: TransitionEventParams)=>{
        this.onTransition(event)
      })
    }
    if(ProgressAPI.progress > 0){
      onTransition()
    } else {
      ProgressAPI.event.once("AppInitEnd",()=>{
        onTransition()
      })
    }
  }
  get Css2DContainer(){
    return Css2DContainer;
  }
  get MessageCenter(){
    return MessageCenter;
  }

  onTransition(event: TransitionEventParams){

  }
  onDeactivate(){

  }
  onActive(){

  }

}
