import { RenderQueue, IShader, UniformType} from "@ali/tidejs";

const SpotShader: IShader = {
  name: "SpotShader",
  renderQueue: RenderQueue.Transparent,

  uniform: {
    u_diffuse: { type: UniformType.Texture2D, value: undefined},
    u_color: {type: UniformType.vec4, value: [1,1,1,1]}
  },

  vertexShader: `
        attribute vec3 position;
        attribute vec2 texcoord;
        attribute vec3 normal;
        //attribute vec3 color;
        uniform mat4 u_view;
        uniform mat4 u_model;
        uniform mat4 u_projection;

        varying vec2 v_texCoord;

        void main() {
            v_texCoord = texcoord;
            gl_Position = u_projection * u_view * u_model * vec4(position, 1.0);
        }
    `,

  fragmentShader: `
        precision highp float;

        uniform sampler2D u_diffuse;
        varying vec2 v_texCoord;

        uniform vec4 u_color;
        void main() {
            // 矩阵写法也可以
            // mat4 scaleMat = mat4(
            //   3.0, 0.0, 0.0, 0.0,
            //   0.0, 3.0, 0.0, 0.0,
            //   0.0, 0.0, 1.0, 0.0,
            //   -1.0, -1.0, 0.0, 1.0
            // );
            // vec2 uv = vec2(scaleMat * vec4(v_texCoord, 1.0, 1.0));

            float u = v_texCoord.x * 3.0 - 1.0;
            float v = v_texCoord.y * 3.0 - 1.0;
            vec2 uv = vec2(u, v);

            if (uv.x < 0.0 || uv.y < 0.0 || uv.x > 1.0 || uv.y > 1.0) {
              gl_FragColor = vec4(0.0);
            } else {
              gl_FragColor = texture2D(u_diffuse, uv) * u_color;
            }
        }
    `,
};

export default SpotShader;
