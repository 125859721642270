import { WanHuaTongUtility } from "../../../GameLogic/WanHuaTongUtility";
import { Application } from "../../../Application";
import { SpotPrivate } from "../../../api/spot/lib/private";
import { TransitionPrivate } from "../../../api/transition/lib/private";
import EventEmitter from "eventemitter3";
import { Camera } from "@ali/tidejs";
import { CameraAutoRotate } from "../../../Behaviors/CameraAutoRotate";
import { CameraPrivate } from "../../../api/camera/lib/private";
import { ConsoleLog } from "../../../lib/log";

export enum Status {
  START = 'start',
  PAUSE = 'pause',
  STOP = 'stop',
  AUTO = 'AUTO',
  SemiAuto = 'SemiAuto',
}

export enum Mode {
  AUTO = 'AUTO',
  SemiAuto = 'SemiAuto',
}
export interface IRoamingItem {
  "spotId": string,
  "transition_duration"?: number,
  "animation_duration"?: number;
  "from"?: [number, number,number],
  "to"?: [number, number,number],
  "stay"?:number
}
export interface IOldRoamingItem{
  spotId: string,
  transitionParams?: {blink: false, duration: number},
  animation: {
    duration: number,
    easeFunc: {name: "Sine", _name: "Sine", easeInOut: "easeInOut", _easeInOut: "easeInOut"},
    from: {fov: number, phi: number, zoom: number, theta: number},
    to: {fov: number, phi: number, zoom: number, theta: number}
  }
}
export interface IOldRoamingConfig {
  [name: string]: IOldRoamingItem[];
}
export type AsyncTask = (x: Roaming) => Promise<any>;
export class Roaming {
  public mode = Mode.AUTO;
  public loop = false;
  public api_event!: EventEmitter<any>;
  public event = new EventEmitter<"start" | "pause" | "end" | "replay">();
  public isTasking = false;
  public playing = false;
  private _paused = false;
  // 漫游点位的index
  private _index = 0;
  // 某个漫游点位的任务index
  private _task_index = 0;

  public get index(){
    return this._index;
  }
  private currentRoamingItem!: IRoamingItem;
  public config: IRoamingItem[] = []
  public taskQueue: AsyncTask[] = [];
  public _private_taskQueue: AsyncTask[] = [];
  public _public_taskQueue: AsyncTask[] = [];
  constructor(){
    this._private_taskQueue.push(async (roaming: Roaming)=>{
      await roaming.transition();
    })
    this._private_taskQueue.push(async (roaming: Roaming)=>{
      await roaming.rotateCamera();
    })
    this._private_taskQueue.push(async (roaming: Roaming)=>{
      await new Promise( resolve => setTimeout(resolve, roaming.currentRoamingItem.stay));
    })
  }
  setCurrentRoamingItem(){
    this.currentRoamingItem = this.config[this._index];
    const spotsAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    function getThetaPhi(){
      let [theta, phi] = [1.5,1.5];
      const fov = 80
      if(spotsAPI.getCurrentSpotId() !== "normal" && spotsAPI.getCurrentSpotId() !== "top"){
        [theta, phi] =  cameraAPI.getCameraThetaPhiForAnnotation(spotsAPI.getCurrentSpotId());
      }
      return [theta, phi, fov]
    }
    if(!this.currentRoamingItem.from || (this.currentRoamingItem.from[0] === 0 && this.currentRoamingItem.from[1] === 0 )){
      this.currentRoamingItem.from = getThetaPhi() as any;
      ConsoleLog.info("Roaming","setCurrentRoamingItem","没有配置 from，自动配置了 from ", this.currentRoamingItem.from);
    }
    if(!this.currentRoamingItem.to || (this.currentRoamingItem.to[0] === 0 && this.currentRoamingItem.to[1] === 0 )){
      this.currentRoamingItem.to = getThetaPhi() as any;
      ConsoleLog.info("Roaming","setCurrentRoamingItem","没有配置 to，自动配置了 to ", this.currentRoamingItem.to);
    }

  }


  /**
     * 尝试暂停，如果正在跳转中，会等待跳转完毕
     */
  pause() {
    this._paused = true;
    this.playing = false;
  }

  async task(){
    this.setCurrentRoamingItem();
    const spotsAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    // 如果这个Spot点不可见，则播放下个动画
    if(!spotsAPI.getHotSpotById(this.currentRoamingItem.spotId)){
      return true
    }
    if(this._task_index === 0){
      // 这里是第一次执行某个点的任务
      this.api_event.emit("to-spot-start", this.currentRoamingItem.spotId);
      // 设置该点的漫游任务队列
      this.taskQueue = this._private_taskQueue.concat(this._public_taskQueue);
    } else if(spotsAPI.getCurrentSpotId() !== this.currentRoamingItem.spotId){
      // 如果某个点漫游的过程恢复了，但是移动到其他位置了，则重新开始执行
      this._task_index = 0;
    } else {
      ConsoleLog.info("Roaming", "task", "resume", this._task_index, this.currentRoamingItem.spotId);
    }
    ConsoleLog.info("Roaming", "task", "this.taskQueue", this.taskQueue);

    for(let i = this._task_index;i < this.taskQueue.length;i++){
      const task2 = this.taskQueue[i];
      const isReturn = await task2(this);
      this._task_index++;
      ConsoleLog.info("Roaming", "task", "running", i, this._task_index, this.currentRoamingItem.spotId);
      // 如果某个任务返回了 true，则结束该点的剩下任务
      if(isReturn){ return isReturn}
      // 如果暂停了，则返回 false ，标记该点任务未完成
      if (this._paused)  {
        ConsoleLog.info("Roaming", "task", "pause", this._task_index, this.currentRoamingItem.spotId);
        return false;
      }
    }
    this.api_event.emit("to-spot-end", this.currentRoamingItem.spotId)
    return true
  }

  /**
     * 继续播放
     */
  async play() {
    if(this.isTasking){
      ConsoleLog.info("Roaming","play","Roaming play 正在执行task，不能重复执行");
      return
    }
    this._paused = false;
    this.playing = true;

    while(this._index < this.config.length) {
      this.isTasking = true;
      const result = await this.task();
      this.isTasking = false;
      // 如果中途被人暂停，要return，不能index+1
      if (!result)  {
        return ;
      }
      // 这里是一个点的漫游任务执行完
      this._task_index = 0;
      this._index += 1;
    }
    if(this.loop){
      this.rePlay();
      this.event.emit("replay");
    } else {
      ConsoleLog.info("Roaming","play","漫游结束");
      this.playing = false;
      this.event.emit("end");
    }
  }

  /**
     * 从头开始播放
     */
  async rePlay() {
    ConsoleLog.info("Roaming","rePlay");
    this.event.removeAllListeners("end");
    this.event.removeAllListeners("replay");
    this._task_index = 0;
    this.isTasking = false;
    this._index = 0;
    this.play();
  }
  //跳转
  async transition() {
    const { spotId, transition_duration, from  } = this.currentRoamingItem;

    const transitionControlAPI = Application.getInstance<TransitionPrivate>("transitionControlAPI");
    if (!spotId) return;
    const [theta, phi, fov] = from!;
    const isOurThetaPhi = false

    await transitionControlAPI.transitionTo(spotId, {
      duration: transition_duration  || 1,
      cameraAnimation: {
        theta, phi, fov
      }
    }, isOurThetaPhi, false, true)
  }
  async rotateCamera(){
    const com: CameraAutoRotate = Camera.MainCamera!.entity.addComponent(CameraAutoRotate);
    if(!this.currentRoamingItem.from || !this.currentRoamingItem.to) { return }
    com.duration = this.currentRoamingItem.animation_duration || 1;
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const getOurThetaPhi = (theta: number, phi: number) => {
      const currentSpotID = spotAPI.getCurrentSpotId();
      return WanHuaTongUtility.getOurThetaPhiFromAreaAnnotation(phi, theta, currentSpotID);
    }
    if(this.currentRoamingItem.from){
      const [ theta, phi, fov] = this.currentRoamingItem.from;
      const [theta1, phi1] = getOurThetaPhi(theta, phi);
      com.from.theta = theta1;
      com.from.phi = phi1;
      com.from.fov = fov;

    }
    if(this.currentRoamingItem.to){
      const [theta, phi, fov] = this.currentRoamingItem.to;
      const [theta1, phi1] = getOurThetaPhi(theta, phi);
      com.to.theta = theta1;
      com.to.phi = phi1;
      com.to.fov = fov;
    }
    com.start();
    return new Promise(resolve => com.event.once("end", resolve));
  }
}
