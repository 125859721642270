import { TransitionState } from '../../api/transition/lib/interface';
import { TransitionPrivate } from '../../api/transition/lib/private';
import { Application } from '../..//Application';
import { APIBaseClass } from '../../lib/utils/eca';
import EventEmitter from 'eventemitter3';
import { API } from "../../lib/utils/eca";
import { ConsoleLog, ErrorType } from '../../lib/log';

/**
 * 是否是全景图 是否超时
 * @param entry
 */
function isPanoramaTimeOut(entry: PerformanceResourceTiming): boolean {
  if (entry.initiatorType === 'img' && entry.name.includes('.jpg') && entry.name.includes('panorama')) {
    if (entry.duration > PerformanceRecorderAPI.Config.panorama_download_timeout) {
      return true;
    }
  }
  return false;
}
/**
 * [[include: ./src/api/recorder/performance.md]]
 */
@API()
export class PerformanceRecorderAPI extends APIBaseClass {
  static ModuleName = 'performanceRecorderAPI';
  static Config = {
    lowFPSLimit: 30,
    transition_delay_timeout: 500,
    panorama_download_timeout: 500,
  };

  public event = new EventEmitter<"imageTimeOut" | "transitionTimeout" | "lowFPS">();
  transitionTimeArr = [];
  avgFps = -1;
  lastFrameTime = -1;
  lastFpsTime = -1;
  fpsCount = 0;
  fpsArr: number[] = [];
  private observer!: PerformanceObserver;
  private rafHandle!: number;

  private _isAvailable(){
    return performance && !!performance.mark && !!performance.measure && !!performance.getEntriesByName;
  }

  /**
     * 开启性能监听
     * @returns
     */
  public addEventListener(){
    if(!this._isAvailable()){
      ConsoleLog.error('PerformanceRecorderAPI', 'addEventListener', ErrorType.ExternalError, '在此设备上不支持');
      return
    }
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    transitionAPI.transitionProcessObserve.subscribe((event) => {
      if (event.state === TransitionState.afterTransition) {
        if(event.to!.spotId !== "normal" && event.to!.spotId !== "top" && event.from!.spotId !== "normal" && event.from!.spotId !== "top"){
          this._dealTransitionTime(event.to!.spotId);
        }
      }
    });
    this.observer = new PerformanceObserver((list) => {
      this._dealImageDownloadTime(list);
    });
    this.observer.observe({ entryTypes: ['resource'] });
    this._reset();
    this._recordFPS();
  }
  /**
    * 从页面加载完成到进入场景的时长 一次性
    *
    * @memberof PerformanceRecorderAPI
    */
  public getShowSceneTime() {
    performance.measure(
      'show-scene',
      'show-scene-begin',
      'show-scene-end',
    );

    const measure = performance.getEntriesByName('show-scene')[0];
    const enterSceneTime = measure ? measure.duration : 0;

    return Math.floor(enterSceneTime * 100) / 100;
  }
  /**
   * 记录模型的下载和解析时间，一次性 todo
   *
   * @memberof PerformanceRecorderAPI
   */
  public getModelDownloadTime() {
    performance.measure(
      'model-download-time',
      'model-download-begin',
      'model-download-end',
    );

    const downloadMeasure = performance.getEntriesByName('model-download-time')[0];
    let downloadTime = downloadMeasure ? downloadMeasure.duration : 0;

    downloadTime = Math.floor(downloadTime * 100) / 100;
    return downloadTime;
  }
  /**
   * 从页面加载完成到可以移动的时长 一次性
   *
   * @memberof PerformanceRecorderAPI
   */
  public getSceneCanMoveTime() {
    performance.measure(
      'can-move-time',
      'can-move-begin',
      'can-move-end',
    );

    const canMoveMeasure = performance.getEntriesByName('can-move-time')[0];
    let canMoveTime = canMoveMeasure ? canMoveMeasure.duration : 0;

    canMoveTime = Math.floor(canMoveTime * 100) / 100;
    return canMoveTime;
  }
  /**
   * 记录transition 的过渡时间，重复性，纹理上传时间和 图片下载时间 低于阈值报错
   *
   * @memberof PerformanceRecorderAPI
   */
  private _dealTransitionTime(toSpotId: string) {
    performance.measure('transition', 'transition-begin', 'transition-end');
    performance.measure('getimage', 'getImage-begin', 'getImage-end');
    performance.measure('transition-duration', 'transition-duration-begin', 'transition-duration-end');
    performance.measure('transitionDelay', 'transition-begin', 'transition-duration-begin');
    // 图片下载时间
    const getimageMeasure = performance.getEntriesByName('getimage')[0];
    let getimageTime = getimageMeasure ? getimageMeasure.duration : 0;

    // 过渡动画时间
    const transitionDelayMeasure = performance.getEntriesByName('transitionDelay')[0];
    let transitionDelayTime = transitionDelayMeasure ? transitionDelayMeasure.duration : 0;

    // 纹理上传总时长 暂时拿不到
    // let sumImageUploadTime = transitionTime - getimageTime - transitionDuration;
    getimageTime = Math.floor(getimageTime * 100) / 100;
    transitionDelayTime = Math.floor(transitionDelayTime * 100) / 100;

    performance.clearMeasures('transition');
    performance.clearMeasures('getimage');
    performance.clearMeasures('transition-duration');
    performance.clearMeasures('transitionDelay');

    // 总时间减去过渡动画时间 则是过渡的等待时间
    if (transitionDelayTime > PerformanceRecorderAPI.Config.transition_delay_timeout) {
      this.event.emit("transitionTimeout", {
        toSpotId,
        transitionDelayTime,
        transitionPanoramaDownloading: getimageTime,
      });
    }
  }
  /**
   * 记录 FPS 重复性，低于阈值报错
   *
   * @memberof PerformanceRecorderAPI
   */
  private _recordFPS() {
    this.fpsCount++;
    if (this.lastFrameTime === -1) {
      this.lastFrameTime = performance.now();
      this.lastFpsTime = performance.now();
    } else {
      this.lastFrameTime = performance.now();

      // 超过了一秒钟，就计算下平均FPS
      if (performance.now() - this.lastFpsTime >= 1000) {
        if(this.fpsCount < PerformanceRecorderAPI.Config.lowFPSLimit){
          this.event.emit("lowFPS", this.fpsCount);
        }
        this.fpsArr.push(this.fpsCount);
        this.fpsCount = 0;
        this.lastFpsTime = performance.now();

        // 如果超过了30分钟，就清空FPS
        if(this.fpsArr.length >= 1200){
          this.fpsArr = [];
        }
      }
    }
    this.rafHandle = requestAnimationFrame(this._recordFPS.bind(this));
  }
  /**
     * 获取所有历史的FPS数据
     * @returns
     */
  public getAllFPS(){
    return this.fpsArr;
  }
  /**
   * 上报下载时长过长的图片
   * @param entry
   */
  private _dealImageDownloadTime(list: PerformanceObserverEntryList) {
    for (const entry of list.getEntries()) {
      if ((entry instanceof PerformanceResourceTiming) && isPanoramaTimeOut(entry)) {
        let dns = entry.domainLookupEnd - entry.domainLookupStart;
        let { duration } = entry;
        let ttfb = entry.responseStart - entry.requestStart;
        let downloadTime = entry.responseEnd - entry.responseStart;
        // const name = entry.name.split('/panorama/')[1];

        duration = Math.floor(duration * 100) / 100;
        dns = Math.floor(dns * 100) / 100;
        downloadTime = Math.floor(downloadTime * 100) / 100;
        ttfb = Math.floor(ttfb * 100) / 100;

        this.event.emit("imageTimeOut", entry);
      }
    }
  }
  private _reset() {
    this.transitionTimeArr = [];
    this.avgFps = -1;
    this.lastFrameTime = -1;
    this.lastFpsTime = -1;
    this.fpsCount = 0;
    this.fpsArr = [];
  }
  dispose(){
    cancelAnimationFrame(this.rafHandle);
    this.observer?.disconnect();
    this._reset();
  }
}
