import { vec3 } from 'gl-matrix';
import { BasicState, expJson, InjectState } from '../../StatesFrame';
import { VGInfo } from '../VGInfo';

@InjectState('VGMove')
export class VGMove extends BasicState<VGInfo> {
  @expJson('info')
  public info = 'move';

  @expJson('onArrivedSpot')
  public onArrivedSpot: BasicState<VGInfo> | undefined;

  @expJson('onFinished')
  public onFinished: BasicState<VGInfo> | undefined;

  protected _dir: vec3 = vec3.create();

  // 移动的起点，终点，时长
  protected _p1: vec3 | undefined;
  protected _p2: vec3 | undefined;
  protected _time = 1;

  protected _currentTime = 0;
  protected _ratio = 0;
  protected _temp: vec3 = vec3.create();

  update (deltaTime: number) {
    if (!this._p1 || !this._p2) {
      return;
    }

    if (!this.srcInfo.soundMgr2.isFinished()) {
      this.srcInfo.playAnimation('walk_speak')
    } else {
      this.srcInfo.playAnimation('walk')
    }

    this._currentTime += deltaTime;
    this._ratio = this._currentTime / this._time;
    if (this._currentTime >= this._time) {
      this._ratio = 1;
    }
    vec3.lerp(this._temp,this._p1,this._p2,this._ratio);
    this.srcInfo.entityTransform.position = this._temp;

    if (this._ratio === 1) {
      if (this.onArrivedSpot) {
        this.reset();
        this.srcInfo.ArriedTarget();//到达终点后，更新spot点名称
        this.onArrivedSpot.changeTo();
        return;
      }
    }
  }

  faceToDir() {
    this.srcInfo.entityTransform.setForward(this._dir,[0,1,0]);
  }

  reset(){
    this._currentTime = 0;
    this._ratio = 0;
  }
  pause() {
    if (this.srcInfo.entity.isActive) this.srcInfo.entity.deactivate();
    this.srcInfo.stopAnimation();
  }

  resume() {
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();
    this.srcInfo.resumeAnimation();
  }

  onEnter () {
    const r = this.srcInfo.getNextMoveInfo();
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();

    //到达终点
    if (!r) {
      if (this.onFinished) {
        this.reset();
        this.onFinished.changeTo();
      }
      return;
    }

    //计算起点终点，方向，时间长度，并朝向前进方向
    this._p1 = r[0];
    this._p2 = r[1];
    if (!this._p2 || !this._p1) return;
    const distance = vec3.distance(this._p1,this._p2);
    this._time = distance/this.srcInfo.speed;
    vec3.subtract(this._dir,this._p2,this._p1);
    this._dir[1] = 0;
    vec3.normalize(this._dir,this._dir);
    this.srcInfo.playAnimation('walk');
    this.faceToDir();

  }

  onExit () {
    this.reset();
  }

}
