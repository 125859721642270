import { Annotation2DPrivate } from '../../api/elements/annotation2d/lib/private';
import { CameraPrivate } from '../../api/camera/lib/private';
import { TransitionState } from '../../api/transition/lib/interface';
import { TransitionPrivate } from '../../api/transition/lib/private';
import { Application } from '../../Application';
import { API, APIBaseClass } from "../../lib/utils/eca";
import { throttleTime } from 'rxjs/operators';


interface ITransitionRecord{
  // 过渡次数
  count: number;
  // 停留时长
  time: number;
}
/**
 * [[include: ./src/api/recorder/action.md]]
 */
@API()
export class ActionRecorderAPI extends APIBaseClass {
  static ModuleName = 'actionRecorderAPI';
  static Config = {
    enableTransition: true,
    enableAnnotation: true,
    enableCamera: true,
  };

  private transitionCount = 0;
  private transitionRecordMap = new Map<string, ITransitionRecord>();
  private nowTime = 0;

  private annotationClickRecordMap = new Map<string, number>();
  private annotationDetailClickRecordMap = new Map<string, number>();
  private annotationShowRecordMap = new Map<string, number>();
  private annotationDetailShowRecordMap = new Map<string, number>();

  private cameraRotateTimes = 0;

  /**
     * 开启场景事件的记录
     */
  public addEventListener(){
    this.nowTime = performance.now();
    this.addTransitionListener();
    this.addAnnotationListener();
    this.addCameraListener();
  }
  /**
     * 开启移动事件的记录
     * @returns
     */
  public addTransitionListener(){
    if(!ActionRecorderAPI.Config.enableTransition){ return }
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    transitionAPI.transitionProcessObserve.subscribe((event) => {
      if (event.state === TransitionState.afterTransition) {
        this.transitionCount++ ;
        this._dealTransitionCount(event.to!.spotId);
      } else {
        this._dealTransitionTime(event.from!.spotId);
      }
    });
  }
  /**
     * 开启标签事件的记录
     * @returns
     */
  public addAnnotationListener(){
    if(!ActionRecorderAPI.Config.enableAnnotation){ return }
    const annotationAPI = Application.getInstance<Annotation2DPrivate>(Annotation2DPrivate.ModuleName);
    annotationAPI.event.on("click", (id)=>{
      this._dealAnnotationClick(id);
    })
    annotationAPI.event.on("clickDetail", (id)=>{
      this._dealAnnotationDetailClick(id);
    })
    annotationAPI.event.on("show", (id)=>{
      this._dealAnnotationShow(id);
    })
    annotationAPI.event.on("showDetail", (id)=>{
      this._dealAnnotationDetailShow(id);
    })
  }
  /**
     * 开启相机事件的记录
     * @returns
     */
  public addCameraListener(){
    if(!ActionRecorderAPI.Config.enableCamera){ return }
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    const rotate = cameraAPI.cameraControlSubject.pipe(throttleTime(500));
    rotate.subscribe(()=>{
      this.cameraRotateTimes++ ;
    })
  }
  /**
     * 获取相机旋转次数
     * @returns
     */
  public getCameraRecord(){
    return this.cameraRotateTimes;
  }
  /**
     * 获取漫游记录
     * @returns
     */
  public getTransitionRecord(){
    return {
      transitionCount: this.transitionCount,
      transitionRecord: this.transitionRecordMap
    }
  }
  /**
     * 获取标签记录
     * @returns
     */
  public getAnnotationRecord(){
    return {
      clickRecord: this.annotationClickRecordMap,
      detailClickRecord: this.annotationDetailClickRecordMap,
      showRecord: this.annotationShowRecordMap,
      detailShowRecord: this.annotationDetailShowRecordMap
    }
  }

  private _dealTransitionCount(spotID: string){
    const transitionRecord = this.transitionRecordMap.get(spotID);
    if(!transitionRecord){
      this.transitionRecordMap.set(spotID, {
        count: 1,
        time: 0,
      });
    } else {
      this.transitionRecordMap.set(spotID, {
        count: transitionRecord.count + 1,
        time: transitionRecord.time,
      });
    }
  }
  private _dealTransitionTime(spotID: string){
    const transitionRecord = this.transitionRecordMap.get(spotID);
    const time = performance.now() - this.nowTime;
    this.nowTime = performance.now();
    if(!transitionRecord){
      this.transitionRecordMap.set(spotID, {
        count: 1,
        time,
      });
    } else {
      this.transitionRecordMap.set(spotID, {
        count: transitionRecord.count,
        time: transitionRecord.time + time,
      });
    }
  }

  private _dealAnnotationClick(id: string){
    const count = this.annotationClickRecordMap.get(id) || 0;
    this.annotationClickRecordMap.set(id, count + 1);
  }
  private _dealAnnotationDetailClick(id: string){
    const count = this.annotationDetailClickRecordMap.get(id) || 0;
    this.annotationDetailClickRecordMap.set(id, count + 1);
  }
  private _dealAnnotationShow(id: string){
    const count = this.annotationShowRecordMap.get(id) || 0;
    this.annotationShowRecordMap.set(id, count + 1);
  }
  private _dealAnnotationDetailShow(id: string){
    const count = this.annotationDetailShowRecordMap.get(id) || 0;
    this.annotationDetailShowRecordMap.set(id, count + 1);
  }

  dispose(){

  }
}
