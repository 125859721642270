import { HotElementPrivate } from "./private";
import { TransitionPrivate } from "@sdkCore/api/transition/lib/private";
import { TransitionState } from "@sdkCore/api/transition/lib/interface";
import { ElementsManager } from "@sdkCore/api/elements/ElementsManager";
import { CSS2DElement } from "@sdkCore/Components/Css2DElement";
import { Entity, SceneManager } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import { ConsoleLog } from "../../../../lib/log";
import { Application } from "../../../../Application";
import { Element } from "../../../../lib/utils/eca";
import { IAnnotationConfigItem, SDKElementType } from "../../../../api/elements/interface";
import { BaseElement } from "../../../../lib/utils/eca";
import { SetVisibleBySpot } from "../../../../Components/SetVisbleBySpot";
import { SpotPrivate } from "../../../../api/spot/lib/private";
import { Utils } from "../../../../lib/utils";
import { getAppInstance, isInModelApp } from "../../../../api/elements/lib/util";

export const HotAnnotationPrefix = "annotation-";
export const HotAnnotationIconDivPrefix = "annotation-icon-div-";
export const HotAnnotationIconPrefix = "annotation-icon-";
export const HotAnnotationTitlePrefix = "annotation-title-";


interface IconConfig {
  width?: string
  url?: string
  height?:string
  sprites?: boolean
  opacity?: string
}

function createIconElement(id: string, iconConfig: IconConfig): HTMLDivElement {
  const dom = document.createElement("div");
  createImageOrDivElement(dom, id, iconConfig)
  dom.id = HotAnnotationIconDivPrefix + id;
  return dom;
}

function createImageOrDivElement(parentElement: HTMLDivElement, id: string ,iconConfig: IconConfig) {
  const { url, opacity, height, width, sprites, ...customStyle } = iconConfig;
  let image: any
  if (sprites) {
    const checkStyle = document.getElementById('hotEleAnimate-style-' + id)
    if (!checkStyle) {
      const sty = document.createElement('style')
      sty.id = 'hotEleAnimate-style-' + id
      sty.innerHTML = `
        @keyframes hotEleAnimate${id}{
          from{
            background-position: 0 ${height};
          }
          to {
            background-position: 0 100%;
          }
        }
      `
      document.head.appendChild(sty)
    }
    image = document.createElement('div')
    image.draggable = false;
    image.id = HotAnnotationIconPrefix + id;
    image.style.background = `url(${url})`
    image.style.backgroundSize = 'cover'
    image.style.animation = `hotEleAnimate${id} 2s steps(50) infinite`
    image.style.userSelect = "none";
    image.style.opacity = opacity || '1';
    image.style.width = width || '40px';
    image.style.height = height || '40px';
    Object.assign(image.style, customStyle)
    parentElement.appendChild(image)
  } else {
    image = new Image()
    image.id = HotAnnotationIconPrefix + id;
    image.src = Utils.autoAddProtocol(url!);
    image.draggable = false;
    image.style.userSelect = "none";
    image.style.opacity = opacity || '1';;
    image.style.width = width || '40px';
    image.style.height = height || '40px';
    Object.assign(image.style, customStyle)
    image.onload = () => {
      parentElement.appendChild(image);
    }
  }
  return image
}

function createTitleElement(id: string, titleConfig: any) {
  const { content, fontSize, color, backgroundColor, opacity, ...customStyle } = titleConfig;
  const { position, display, left, borderRadius, lineHeight, padding } = HotElementPrivate.Config.title;
  const dom = document.createElement("div");
  dom.id = HotAnnotationTitlePrefix + id;
  dom.innerHTML = content;
  dom.style.fontSize = fontSize;
  dom.style.opacity = opacity;
  dom.style.backgroundColor = backgroundColor;
  dom.style.color = color;
  dom.style.position = position;
  dom.style.display = display;
  dom.style.left = left;
  dom.style.borderRadius = borderRadius;
  dom.style.lineHeight = lineHeight;
  dom.style.padding = padding;
  Object.assign(dom.style, customStyle)
  return dom;
}

function createContainElement(id: string){
  const { pointerEvents, cursor, display, position, alignItems, padding } = HotElementPrivate.Config.contain;
  const dom = document.createElement("div");
  dom.id = HotAnnotationPrefix + id;
  dom.style.pointerEvents = pointerEvents;
  dom.style.cursor = cursor;
  dom.style.display = display;
  dom.style.position = position;
  dom.style.alignItems = alignItems;
  dom.style.padding = padding;
  return dom
}

function build(elementConfig: IAnnotationConfigItem){
  const hotElement = Application.getInstance<HotElementPrivate> (HotElementPrivate.ModuleName) as any
  const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName)
  const { id, anchor, info } = elementConfig;
  const position = vec3.fromValues(anchor.x,  anchor.z, -anchor.y);

  const plane: Entity = new Entity(id);
  const scene = SceneManager.GetInstance().currentScene;
  scene.addEntity(plane);

  const containElement = createContainElement(id);
  const titleElement = createTitleElement(id, info.titleConfig);
  const iconElement = createIconElement(id, info.iconConfig);
  containElement.appendChild(iconElement);
  containElement.appendChild(titleElement);

  plane.transform.position = position;

  const annotation = new HotElement(plane, elementConfig);

  // 这里有先后顺序，要先判断可见性，再设置CSS2DElement
  const isInModelPanorama = isInModelApp();
  if (isInModelPanorama) {
    const com2 = annotation.addComponent(SetVisibleBySpot);
    com2.preAwake(spotAPI.getFirstSpotId())
  }
  const com: CSS2DElement = annotation.addComponent(CSS2DElement);
  com.container = getAppInstance().container;
  com.elementNode = containElement;
  com.preAwake();

  hotElement.addElement(annotation);
  if (info.titleConfig?.showStrategy) {
    annotation.setTitleShowRule(info.titleConfig?.showStrategy)
  }

  containElement.addEventListener("mouseenter",()=>{
    ConsoleLog.log("hotAnnotation","build","hotAnnotation enter", annotation.id);
    hotElement.event.emit("enter", id);
  })
  containElement.addEventListener("mouseleave",()=>{
    ConsoleLog.log("hotAnnotation","build","hotAnnotation exit", annotation.id);
    hotElement.event.emit("exit", id);
  })
  containElement.addEventListener("click",()=>{
    ConsoleLog.log("hotAnnotation","build","hotAnnotation click", annotation.id);
    hotElement.event.emit("click", id);
    (ElementsManager as any).getInstance().event.emit("click", id);
  })
  iconElement.addEventListener("mouseenter",()=>{
    ConsoleLog.log("hotAnnotationIcon","build","hotAnnotationIcon enter", annotation.id);
    hotElement.event.emit("enterIcon", id);
  })
  iconElement.addEventListener("mouseleave",()=>{
    ConsoleLog.log("hotAnnotationIcon","build","hotAnnotationIcon exit", annotation.id);
    hotElement.event.emit("exitIcon", id);
  })
  iconElement.addEventListener("click",()=>{
    ConsoleLog.log("hotAnnotationIcon","build","hotAnnotationIcon click", annotation.id);
    hotElement.event.emit("clickIcon", id);
  })
  annotation.event.emit('built')
  return annotation;
}
@Element({
  type: SDKElementType.hot,
  build,
})
export class HotElement extends BaseElement {

  private _enterIconHandler: any;
  private _exitIconHandler: any;
  public sdkType: SDKElementType;
  private _transitionListener: any = null

  constructor( entity: Entity, item: IAnnotationConfigItem){
    super(entity, item);
    this.sdkType = SDKElementType.hot;
    this._enterIconHandler = this._enterIcon.bind(this);
    this._exitIconHandler = this._exitIcon.bind(this);
    this.event.on('built', () => this.updateVisibleSpots())
  }

  public updateVisibleSpots(newVisibleSpots?: string[]) {
    const transitionAPI = Application.getInstance<TransitionPrivate>(TransitionPrivate.ModuleName);
    const elementsAPI = Application.getInstance<ElementsManager>(ElementsManager.ModuleName)
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const currentSpotId = spotAPI.getCurrentSpotId();
    if (!this.elementConfig.extra) {
      this.elementConfig.extra = {}
    }
    if (!this.elementConfig.extra.visibleSpots) {
      this.elementConfig.extra.visibleSpots = this.elementConfig.includeSpots
    }
    if(Array.isArray(newVisibleSpots)) {
      this.elementConfig.extra!.visibleSpots = newVisibleSpots
    }
    if(!this.elementConfig.extra!.visibleSpots) {
      return
    }
    if (this.elementConfig.extra!.visibleSpots!.includes(currentSpotId)) {
      if (elementsAPI.allVisible) {
        this.activate()
      }
    } else {
      this.deactivate()
    }
    if (this._transitionListener) {
      this._transitionListener.unsubscribe()
    }
    this._transitionListener = transitionAPI.transitionProcessObserve.subscribe((params) => {
      if (!params || !params.to || !params.from) { return }
      const toSpotID = params.to.spotId;
      if (params.state === TransitionState.afterTransition) {
        if (this.elementConfig.extra!.visibleSpots!.includes(toSpotID)) {
          if(elementsAPI.allVisible) {
            this.activate()
          }
        } else {
          this.deactivate()
        }
      }
    });
  }

  private _enterIcon() {
    this.elementTitleNode!.style.display = 'flex';
  }

  private _exitIcon() {
    this.elementTitleNode!.style.display = 'none';
  }

  public setTitleShowRule(type: 'always' | 'hover') {
    if (type === 'hover') {
      this.hideTitle();
      this.elementIconNode?.addEventListener("mouseenter", this._enterIconHandler)
      this.elementIconNode?.addEventListener("mouseleave", this._exitIconHandler)
    } else {
      this.showTitle();
      this.elementIconNode?.removeEventListener("mouseenter", this._enterIconHandler)
      this.elementIconNode?.removeEventListener("mouseleave", this._exitIconHandler)
    }
    const info = this.elementConfig?.info;
    this.updateElementConfig('info', {...info, titleConfig: {...info.titleConfig, showStrategy: type}});
  }

  get position(){
    return this.entity.transform.position;
  }

  get elementContainNode() {
    return document.getElementById(HotAnnotationPrefix + this.id);
  }
  get elementIconNode() {
    return document.getElementById(HotAnnotationIconDivPrefix + this.id);
  }
  get elementTitleNode() {
    return document.getElementById(HotAnnotationTitlePrefix + this.id);
  }

  /**
     * 设置标题值
     */
  public setTitle(value: string) {
    const titleDom = document.getElementById(HotAnnotationTitlePrefix + this.id) as HTMLElement;
    if (!titleDom) {
      return
    }
    titleDom.innerHTML = value;
    const info = this.elementConfig?.info;
    this.updateElementConfig('info', {...info, titleConfig: {...info.titleConfig, content: value}});
  }
  /**
     * 设置标题样式
     * @param key 标题样式属性
     * @param value 属性值
     */
  public setTitleStyle(key: any, value: any) {
    const titleDom = document.getElementById(HotAnnotationTitlePrefix + this.id) as HTMLElement;
    if (!titleDom) {
      return
    }
    titleDom.style[key] = value;
    const info = this.elementConfig?.info;
    this.updateElementConfig('info', {...info, titleConfig: {...info.titleConfig, [key]: value}});
  }

  /**
   * @deprecated
   * 设置图标值
   */
  public setIcon(url: string) {
    const titleDom = document.getElementById(HotAnnotationIconPrefix + this.id) as HTMLImageElement;
    if (!titleDom) {
      return
    }
    titleDom.src = Utils.autoAddProtocol(url);
    const info = this.elementConfig?.info;
    this.updateElementConfig('info', {...info, iconConfig: {...info.iconConfig, url}});
  }

  /**
   * 设置图标样式
   * @deprecated
   * @param key 图标样式属性
   * @param value 属性值
   * @returns
   */
  public setIconStyle(key: any, value: any) {
    console.warn('该方法已废弃，建议使用setIconConfig')
    const iconDom = document.getElementById(HotAnnotationIconPrefix + this.id) as HTMLImageElement;
    if (!iconDom) {
      return
    }
    iconDom.style[key] = value;
    const info = this.elementConfig?.info;
    this.updateElementConfig('info', {...info, iconConfig: {...info.iconConfig, [key]: value}});
  }

  public setIconConfig(iconConfig: IconConfig) {
    let iconDom = document.getElementById(HotAnnotationIconPrefix + this.id) as HTMLImageElement | HTMLDivElement
    if (!iconDom) {
      return
    }
    const iconDivDom = this.elementIconNode as HTMLDivElement
    const { url, sprites, ...otherStyle } = iconConfig
    const info = this.elementConfig?.info;

    if(iconDom.tagName === 'DIV') {
      // div肯定是走雪碧图，如果再没有雪碧，就需要div换成img
      if(!sprites) {
        iconDivDom!.removeChild(iconDom)
        iconDom = createImageOrDivElement(iconDivDom, this.id, {
          ...info.iconConfig,
          ...iconConfig
        })
        iconDivDom?.appendChild(iconDom)
      }
    } else {
      // img肯定不走雪碧图，如果传进来雪碧，就需要img就需要走div
      if (sprites) {
        iconDivDom!.removeChild(iconDom)
        iconDom = createImageOrDivElement(iconDivDom, this.id, {
          ...info.iconConfig,
          ...iconConfig
        })
        iconDivDom?.appendChild(iconDom)
      }
    }
    if(url) {
      if(sprites) {
        // 说明是div
        iconDom.style.background = `url(${url})`
        iconDom.style.backgroundSize = 'cover'
      } else {
        // 说明img
        (<HTMLImageElement>iconDom).src = Utils.autoAddProtocol(url);
      }
    }
    if(otherStyle.width) {
      iconDom.style.width = otherStyle.width
    }
    if(otherStyle.height) {
      iconDom.style.height = otherStyle.height
      // 如果新的是sprites并且改变了高度，就需要重置动画
      if(sprites) {
        const annimateStyle = document.getElementById('hotEleAnimate-style-' + this.id)
        annimateStyle!.innerHTML = `
          @keyframes hotEleAnimate${this.id}{
            from{
              background-position: 0 ${otherStyle.height};
            }
            to {
              background-position: 0 100%;
            }
          }
        `
      }
    }
    if(otherStyle) {
      Object.assign(iconDom.style, {
        ...otherStyle
      })
    }
    this.updateElementConfig('info', { ...info, iconConfig: { ...info.iconConfig, ...iconConfig } })
  }

  /**
   * 设置业务自定义附加信息
   * @param extraInfo 附加信息
   */
  public setExtra(extraInfo: any) {
    this.updateElementConfig('extra', extraInfo)
  }

  /**
   * 设置标签的整体配置信息，一次性修改所有需要的信息
   * @param config
   */
  public setTagConfig(config: any) {
    const onlyDataKey = ['spotId', 'includeSpots', 'extra'];
    onlyDataKey.forEach((item) => {
      if (config[item]) {
        this.updateElementConfig(item, config[item]);
      }
    })
    const info = config.info;
    if (info) {
      const titleConfigKeys = Object.keys(info.titleConfig);
      const iconConfigKeys = Object.keys(info.iconConfig);
      titleConfigKeys.forEach((item) => {
        switch(item) {
          case 'content': {
            this.setTitle(info.titleConfig.content);
            break;
          }
          case 'showStrategy': {
            const preStrategy = this.elementConfig.info.titleConfig?.showStrategy;
            if (preStrategy !== info.titleConfig.showStrategy) {
              this.setTitleShowRule(info.titleConfig.showStrategy);
            }
            break;
          }
          default: {
            this.setTitleStyle(item, info.titleConfig[item]);
            break;
          }
        }
      });
      iconConfigKeys.forEach((item) => {
        switch (item) {
          case 'url': {
            this.setIcon(info.iconConfig.url);
            break;
          }
          default: {
            this.setIconStyle(item, info.iconConfig[item]);
            break;
          }
        }
      })
    }
    if (config.anchor) {
      this.setPosition(config.anchor);
    }
  }

  /**
     * 显示标签卡片
     */
  showTitle(){
    const container = document.getElementById(HotAnnotationTitlePrefix + this.id);
    if (!container) {
      return
    }
    container.style.display = "flex";
  }
  /**
     * 隐藏标签卡片
     */
  hideTitle(){
    const container = document.getElementById(HotAnnotationTitlePrefix + this.id);
    if (!container) {
      return
    }
    container.style.display = "none";
  }

  dispose(){
    super.dispose();
    this._transitionListener && this._transitionListener.unsubscribe()
  }
  /**
     * 高亮标签，其实就是设置 border 颜色
     * @param color rgb数组
     */
  public highlight(color: number[]){
    const container = document.getElementById(HotAnnotationPrefix + this.id);
    if(!container){ return }
    container.style.border = `1px dashed rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }
  /**
     * 取消高亮
     */
  public unhighlight(){
    const container = document.getElementById(HotAnnotationPrefix + this.id);
    if(!container){ return }
    container.style.border = 'none';
  }
  /**
     * 移动标签
     * @param position
     */
  public moveTo(position: vec3) {
    this.entity.transform.position = position;
    this.updateElementConfig('anchor', {
      x: position[0],
      y: -position[2],
      z: position[1]
    });
  }

  /**
     * 设置标签的位置
     * @param anchor
     */
  public setPosition(anchor: any) {
    this.entity.transform.position = vec3.fromValues(anchor.x, anchor.z, -anchor.y);
    this.updateElementConfig('anchor', anchor);
  }

  /**
     * 改变标签dom的pointerEvents属性
     * @param value
     * @returns
     */
  public changePointerEvents(value: string) {
    const container = document.getElementById(HotAnnotationPrefix + this.id);
    if(!container){ return }
    container.style.pointerEvents = value;
  }
}
