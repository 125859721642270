import { SpotPrivate } from "../../../api/spot/lib/private";
import { Application } from "../../../Application";
import { TransitionEventParams, TransitionParams, TransitionState } from "./interface";
import { Subject } from "rxjs";
import { MessageCenter } from "../../../GameLogic/MessageCenter";
import { JumpController } from "../../../Behaviors/jumpAnimation/JumpController";
import { CameraShake } from '../../../Behaviors/jumpAnimation/CameraShake';
import { WanHuaTongUtility } from "../../../GameLogic/WanHuaTongUtility";
import { UpdatePanorama } from "../../../Behaviors/UpdatePanorama";
import { performanceMark } from "../../../lib/utils";
import { Entity, Renderer } from "@ali/tidejs";
import { ConsoleLog, ErrorType } from "../../../lib/log";
import { APIBaseClass } from "../../../lib/utils/eca";
import { ProgressReporter } from "../../../lib/progress/ProgressReporter";
import { CameraPrivate } from "../../../api/camera/lib/private";
import { vec3 } from "gl-matrix";
import { getThetaPhiByDir } from "../../../lib/utils/eca";
import EventEmitter from "eventemitter3";
import { ConfigPrivate } from '../../../api/config/lib/private';
import { CubeInterpolator } from "../../../Behaviors/jumpAnimation/CubeInterpolator";
import { DataCenter } from '../../../GameLogic/DataCenter';

function getTransitionPrams(params?: TransitionParams){
  let duration = undefined;
  let fov = undefined;
  let theta = undefined;
  let phi = undefined;
  if(!params){
    return {
      duration, fov , theta, phi
    }
  }
  duration = params.duration;
  const { cameraAnimation } = params;
  if(cameraAnimation){
    fov = cameraAnimation.fov;
    theta = cameraAnimation.theta;
    phi = cameraAnimation.phi;
  }
  return {
    duration, fov , theta, phi
  }
}
interface IState{
  canMove: boolean;
  disableUserTransition: boolean;
  disableClickTransition: boolean;
  isInTransition: boolean;
}

interface TransitionOptions {
  cameraMinRatio?: number
}

const isSpotName = (spotId: string) => {
  return spotId !== 'normal' && spotId !== 'top';
};

export const defaultActiveFloor = 'total';

/**
  控制在全景图中漫游的API

# 分为三种视图
## 全景图
![全景图](https://virutalbuy-public.oss-cn-hangzhou.aliyuncs.com/tide/docs/tide-wanhuatong/assets/1611734081500-6e766b75-bcce-4e49-91ee-c513cab9c9ef.png)

## 模型视图
可旋转模型进行查看

![模型视图](https://virutalbuy-public.oss-cn-hangzhou.aliyuncs.com/tide/docs/tide-wanhuatong/assets/1611734097549-2931cf47-5ff7-49a0-b375-c28c79b7b2ef.png)

## 顶视图
不能进行操作，通常配合户型图使用

![顶视图](https://virutalbuy-public.oss-cn-hangzhou.aliyuncs.com/tide/docs/tide-wanhuatong/assets/1611734108886-76e85a1c-1bbf-4411-a870-0519df2f7562.png)

# 使用方法
## 跳转到某个Spot点
```typescript
  const transitionControlAPI = app.getInstance("transitionControlAPI");
  transitionControlAPI.transitionTo("p0");
```
## 监听跳转事件

```typescript
transitionAPI.transitionProcessObserve.subscribe((event)=>{
      const { state, from, to } = event;

      if(state === TransitionState.beforeTransition){
        const fromSpotID = from.spotId;
      } else if(state === TransitionState.afterTransition){
        const toSpotID = to.spotId;
      }
})
```
 */
export class TransitionPrivate extends APIBaseClass<IState>{

  static ModuleName = "transitionControlAPI";
  /**
     * 跳转事件，使用示例
     * ```typescript
     * transitionAPI.transitionProcessObserve.subscribe((event)=>{
     *      const { state, from, to } = event;
     *      if(state === TransitionState.beforeTransition){
     *          const fromSpotID = from.spotId;
     *      } else if(state === TransitionState.afterTransition){
     *          const toSpotID = to.spotId;
     *      }
     * })
     * ```
     * Transition process observe of transition private
     */
  public transitionProcessObserve: Subject<TransitionEventParams> = new Subject();

  public event = new EventEmitter<"toNormalView" | "toTopView" | "toSpot", string>();

  /**
     * State  of transition private
     */
  public state = {
    canMove: false,
    disableUserTransition: false,
    disableClickTransition: false,
    isInTransition: false,
    isInCameraShake: false,
  }
  /**
     * @ignore
     * Model  of transition private
     */
  public model!: Entity;
  /**
     * @ignore
     * Jump controller of transition private
     */
  public jumpController!: JumpController;
  public cameraShake!: CameraShake;

  private preSpotId = "p0";
  public activeFloor = defaultActiveFloor; // 激活的楼层，单楼层为"total", 多楼层为"total"或具体的某一层
  public isModelActive = true;
  constructor(){
    super();
    MessageCenter.JumpEvent.on('toSpot', (spotID, upwardDirection, forwardDirection, opticalCenter) => {
      ConsoleLog.log("transitionControlAPI","onToSpot","用户点击跳转", spotID);
      if(this.state.disableClickTransition){
        return
      }
      this.transitionTo(spotID)
    });
    ProgressReporter.canMove.subscribe(()=>{
      this.setState({
        canMove: true
      })
    })
    this.transitionProcessObserve.subscribe((params)=>{
      if(!params || !params.to || !params.from){ return }
      const fromSpotID = params.from.spotId;
      if (isSpotName(fromSpotID)) {
        this.preSpotId = fromSpotID;
      }
    })
    MessageCenter.JumpEvent.on('cameraShake', async ()=> {
      if(this.state.isInCameraShake) {
        return;
      }
      this.setState({
        isInCameraShake: true
      })
      this.cameraShake.play(1,()=>{
        this.setState({
          isInCameraShake: false
        })
      })
    })
  }
  /**
   * 跳转前进行检查，是否可以跳转，是否在跳转中
   * @returns boolean
   */
  private _checkTransitionState(){
    if(!this.state.canMove){
      MessageCenter.JumpEvent.emit('cameraShake');
      return false
    }
    if(this.state.isInTransition){
      return false
    }
    if (this.state.isInCameraShake) {
      ConsoleLog.warn("transitionControlAPI","_checkTransitionState", "还不可以进行移动，正在结束上一次寻找不到可跳点位的相机抖动动画");
      return false
    }
    return true;
  }

  /**
   * 显示&隐藏纹理模型
   */
  private switchRootModelActive (isActive: boolean) {
    const render = DataCenter.RootModel.getComponentByType<Renderer>(Renderer)
    if(render){
      render!.isActive = isActive
    }
  }

  /**
   * 显示&隐藏地面点位
   */
  private hideSpots (isActive: boolean) {
    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    const allSpots = spotAPI.getHotSpots();
    for (const spot of allSpots.values()) {
      const render = spot.entity.getComponentByType<Renderer>(Renderer);
      if(!render) return;
      render!.isActive = isActive;
    }
  }

  public switchModelActive (isActive: boolean) {
    this.isModelActive = isActive;
    this.switchRootModelActive(isActive);
    this.hideSpots(isActive)
  }

  /**
     * 跳转到全景图
     */
  public switchToPanoramaView(){
    this.transitionTo(this.preSpotId);
  }
  private async _switchToTop(){
    if(!this._checkTransitionState()){ return }
    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    const currentSpotID = spotAPI.getCurrentSpotId();
    UpdatePanorama.getInstance().cancelDownload();

    this.setState({
      isInTransition: true
    })
    this.transitionProcessObserve.next(new TransitionEventParams(
      TransitionState.beforeTransition,
      { spotId: currentSpotID },
      { spotId: 'top' }
    ));
    this.model.deactivate();
    spotAPI.setCurrentSpotId('top');

    await this.jumpController.jumpToTop();

    this.transitionProcessObserve.next(new TransitionEventParams(
      TransitionState.afterTransition,
      { spotId: currentSpotID },
      { spotId: 'top' }
    ));

    this.event.emit('toTopView')
    this.setState({
      isInTransition: false
    })
  }
  /**
     * 跳转到顶视图
     * Switchs to top
     * @returns
     */
  public async switchToTop(){
    if(this.state.disableUserTransition){
      return
    }
    return this._switchToTop();
  }
  /**
     * 跳转到顶视图
     * Switchs to top view
     * @returns
     */
  public async switchToTopView(){
    return this.switchToTop();
  }
  private async _switchToNormalView(){
    if(!this._checkTransitionState()){ return }

    UpdatePanorama.getInstance().cancelDownload();

    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    const currentSpotID = spotAPI.getCurrentSpotId();
    this.setState({
      isInTransition: true
    })
    spotAPI.setCurrentSpotId('normal');
    this.transitionProcessObserve.next(new TransitionEventParams(
      TransitionState.beforeTransition,
      { spotId: currentSpotID },
      { spotId: 'normal' }
    ));
    this.model.deactivate();

    await this.jumpController.jumpToNormal();

    this.transitionProcessObserve.next(new TransitionEventParams(
      TransitionState.afterTransition,
      { spotId: currentSpotID },
      { spotId: 'normal' }
    ));
    this.event.emit('toNormalView')
    this.setState({
      isInTransition: false
    })
  }
  /**
     * 跳转到模式视图
     * Switchs to normal view
     * @returns
     */
  public async switchToNormalView(options: TransitionOptions = {}){
    if(this.state.disableUserTransition){
      return
    }
    await this._switchToNormalView();
    if(typeof options === 'object') {
      const cameraAPI = Application.getInstance<CameraPrivate>("cameraAPI");
      const { cameraMinRatio = 1 } = options
      cameraAPI.setOrbitMinRadius(cameraMinRatio)
    }
  }
  /**
   * @ignore
   */
  private async _transitionTo(toSpotID: string, params?: TransitionParams, isOurThetaPhi = false, rotate = false): Promise<void>{
    if(!this._checkTransitionState()){ return }
    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    const cameraAPI = Application.getInstance<CameraPrivate>("cameraAPI");
    const toSpot = spotAPI.getHotSpotById(toSpotID);
    const toFloor = this._getSpotFloor(toSpotID);
    if(!toSpot) {
      return
    }
    this.setState({
      isInTransition: true
    })
    UpdatePanorama.getInstance().cancelDownload();
    const { upwardDirection, forwardDirection, opticalCenter }= toSpot;
    const currentSpotID = spotAPI.getCurrentSpotId();

    spotAPI.setCurrentSpotId(toSpotID);

    this.transitionProcessObserve.next(new TransitionEventParams(
      TransitionState.beforeTransition,
      { spotId: currentSpotID},
      { spotId: toSpotID  }
    ));
    cameraAPI.setCameraZoomEnable(false);
    performanceMark('transition-begin');

    const { duration, fov, theta, phi } = getTransitionPrams(params);
    if(theta && phi){
      let [theta1, phi1] = [theta,phi];
      if(!isOurThetaPhi){
        //如果是老中台坐标系的，则转为我们坐标系的
        [theta1, phi1] = WanHuaTongUtility.getOurThetaPhiFromAreaAnnotation(phi, theta, toSpotID);
      }
      const dir = WanHuaTongUtility.getDirByThetaPhiv3(theta1, phi1);
      await this.jumpController.toSpotTransition(toSpotID, upwardDirection, dir, opticalCenter, fov, duration, rotate)
    } else {
      await this.jumpController.toSpotTransition(toSpotID, upwardDirection, forwardDirection, opticalCenter, fov, duration)
    }

    this.model.activate();
    cameraAPI.setCameraZoomEnable(true);
    performanceMark('transition-end');

    this.transitionProcessObserve.next(new TransitionEventParams(
      TransitionState.afterTransition,
      { spotId: currentSpotID },
      { spotId: toSpotID  }
    ));
    this.event.emit('toSpot', toSpotID, toFloor)
    this.setState({
      isInTransition: false
    })
    this._resetActiveFloor();
    ConsoleLog.log("transitionControlAPI","_transitionTo","跳转结束", toSpotID);
  }
  /**
     * 全景图中跳转接口
     * @param toSpotID
     * @param params TransitionParams
     * @param isOurThetaPhi 是否是SDK的theta phi
     * @param rotate 是否需要旋转相机，默认是false
     * ~~~
     * 通常情况下，点击跳转时，或者从top\normal 进入spot点时，不需要进行相机的对齐操作，它将自动计算一个合适的角度；
     * 如果需要将摄像机对准一个特定对象，就需要强行制定相机旋转，此时，需要设置为true
     * ~~~
     * @returns
     */
  public async transitionTo(toSpotID: string, params?: TransitionParams, isOurThetaPhi = false, rotate = false, enforce = false): Promise<void>{
    if(!enforce && this.state.disableUserTransition){
      ConsoleLog.warn("transitionControlAPI","transitionTo","已禁止用户跳转 disableUserTransition 跳转失败");
      return
    }
    return this._transitionTo(toSpotID, params, isOurThetaPhi, rotate);
  }

  /**
     * 跳转到目标spot点，并朝向指定spot点
     * @param toSpot 跳转到spot点
     * @param faceSpot 朝向的spot点
     */
  public async transitionAndFaceToSpot(toSpotId: string, faceSpotId?: string, params: TransitionParams = {}) {
    if (!faceSpotId) {
      return this.transitionTo(toSpotId, params);
    }
    const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);
    const toSpot = spotAPI.getHotSpotById(toSpotId);
    const faceSpot =  spotAPI.getHotSpotById(faceSpotId);
    if(!toSpot || !faceSpot) {
      throw new Error(`找不到spot点, toSpot:${toSpot}, faceSpot:${faceSpot}`)
    }
    const dir = vec3.create();
    vec3.sub(dir, faceSpot.groundCoord, toSpot.groundCoord);
    vec3.normalize(dir, dir);
    const [ theta, phi ] = getThetaPhiByDir(dir);
    return this.transitionTo(toSpotId, {
      cameraAnimation: {
        theta,
        phi,
      },
      ...params
    }, true, true)
  }

  /**
   * 跳转到全景视图下的指定楼层
   * @param floorId
   * @returns
   */
  public switchToFloor(floorId: string) {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const configDefault = configAPI.getConfig();
    const isMultiFloor = ConfigPrivate.Config.multiFloor;
    if (!isMultiFloor) {
      this.switchToPanoramaView();
      return;
    }
    const multiFloor = configDefault?.multiFloor;
    const multiFloorScene = multiFloor.floorScene;
    if (!multiFloorScene[floorId]) {
      return;
    }
    const allScenes = configDefault.scenes;
    for (const spotId in allScenes) {
      const spotInfo = allScenes[spotId];
      if (spotInfo.floor === floorId) {
        this.transitionTo(spotId);
        return;
      }
    }
  }

  /**
   * 在normal或top视图下选中多楼层
   * @param floorId
   */
  public selectBuildingFloor(floorId: string) {
    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");
    const currentSpotID = spotAPI.getCurrentSpotId();
    if (currentSpotID != 'top' && currentSpotID != 'normal') {
      return;
    }
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const configDefault = configAPI.getConfig();
    const isMultiFloor = ConfigPrivate.Config.multiFloor;
    if (!isMultiFloor) {
      return;
    }
    const multiFloor = configDefault?.multiFloor;
    const multiFloorScene = multiFloor.floorScene;
    const allSpots = spotAPI.getHotSpots();
    if (floorId === defaultActiveFloor) {
      const baseMin = configAPI.getBuildingBase();
      CubeInterpolator.getInstance().setMultiFloorMaterial(baseMin, -1);
      for (const spot of allSpots.values()) {
        const render = spot.entity.getComponentByType<Renderer>(Renderer);
        render!.isActive = true;
      }
    } else {
      const curFloorInfo = multiFloorScene[floorId];
      const floorBase = curFloorInfo.base;
      const floorHeight = curFloorInfo.height;
      CubeInterpolator.getInstance().setMultiFloorMaterial(floorBase, floorHeight);

      // 隐藏未选中的楼层的地面spot点, 显示选中楼层的地面spot点
      for (const spot of allSpots.values()) {
        if (spot.floorId !== floorId) {
          const render = spot.entity.getComponentByType<Renderer>(Renderer);
          render!.isActive = false;
        }else{
          const render = spot.entity.getComponentByType<Renderer>(Renderer);
          render!.isActive = true;
        }
      }
    }

    this.activeFloor = floorId;
  }

  /**
   * 获取spot所属楼层
   * @param spotId
   */
  private _getSpotFloor(spotId: string) {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const configDefault = configAPI.getConfig();
    const allScenes = configDefault.scenes;
    const curScene = allScenes[spotId];
    if (!curScene) {
      return;
    }
    return curScene.floor;
  }

  private _resetActiveFloor() {
    this.activeFloor = defaultActiveFloor;
  }


  public dispose(){
    //
  }
}
