import { ShaderMaterial } from "@ali/tidejs";
import { vec3 } from "gl-matrix";
import PanoVideoShader from "./Shader/PanoVideoShader";

export class PanoVideoMaterial extends ShaderMaterial {
  constructor() {
    super(PanoVideoShader);
    this.cullface = -1;
  }

  public get albedo(): WebGLTexture {
    return this._tmpUniforms["u_diffuse"].value;
  }
  public set albedo(albedo: WebGLTexture) {
    this._tmpUniforms["u_diffuse"].value = albedo;
  }


  public get center(): vec3 {
    return this._tmpUniforms["u_center"].value;
  }
  public set center(albedo: vec3) {
    this._tmpUniforms["u_center"].value = albedo;
  }

  // public get worldToCameraMatrix(): mat3 {
  //     return this._tmpUniforms["u_worldToCameraMatrix"].value;
  // }
  // public set worldToCameraMatrix(value: mat3) {
  //     this._tmpUniforms["u_worldToCameraMatrix"].value = value;
  // }
}
