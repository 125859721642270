import { getAppInstance } from "../api/elements/lib/util";
import { ConsoleLog, ErrorType } from "../lib/log";
import { MessageCenter } from "./MessageCenter";

function createCss2dContainer(){
  const css2dContainer = document.createElement("div");
  css2dContainer.id = "css-2d-container";
  css2dContainer.style.position = "absolute";
  css2dContainer.style.width = "100%";
  css2dContainer.style.height = "100%";
  css2dContainer.style.overflow = "hidden";
  css2dContainer.style.whiteSpace = "nowrap";
  css2dContainer.style.pointerEvents = "none";
  css2dContainer.style.textAlign = "center";
  css2dContainer.style.willChange = "transform";
  // // for ios 10
  // css2dContainer.style.left = "0px";
  // css2dContainer.style.top = "0px";
  return css2dContainer
}
export class Css2DContainer {
  // css 容器
  public static css2dContainer: HTMLElement | null = null ;
  public static appendToCss2dContainer(elementNode: HTMLElement) {
    if(!Css2DContainer.css2dContainer){
      const css2dContainer = createCss2dContainer();
      getAppInstance().container.appendChild(css2dContainer);
      Css2DContainer.css2dContainer = css2dContainer;
      checkContainerSize();
    }
    Css2DContainer.css2dContainer?.appendChild(elementNode)
  }
  public static removeChild(elementNode: HTMLElement){
    if(Css2DContainer.css2dContainer){
      Css2DContainer.css2dContainer.removeChild(elementNode);
    }
  }
}
MessageCenter.DisposeEvent.on("dispose", ()=>{
  if(Css2DContainer.css2dContainer){
    getAppInstance().container.removeChild(Css2DContainer.css2dContainer);
  }
  Css2DContainer.css2dContainer = null;
})
function checkContainerSize(){
  if(!Css2DContainer.css2dContainer){ return }
  const rect = Css2DContainer.css2dContainer.getBoundingClientRect();
  const rect2 = getAppInstance().canvas.getBoundingClientRect();
  ConsoleLog.info("CSS2DElement", "logContainerSize", `Css2D大小: 高${rect.height} 宽${rect.width} left:${rect.left} top:${rect.top} `);
  ConsoleLog.info("CSS2DElement","logContainerSize",`canvas大小: 高${rect2.height} 宽${rect2.width} left:${rect2.left} top:${rect2.top} `);

  const isEqual = (a: number ,b: number)=> Math.abs(a - b) < 10;
  if( !(isEqual(rect.height , rect2.height) && isEqual(rect.width , rect2.width) && isEqual(rect.left , rect2.left) && isEqual(rect.top , rect2.top))){
    ConsoleLog.error("DataCenter", "logContainerSize",ErrorType.InternalError, "Css2D大小 和 canvas大小 不相等！！ 请检查样式");
  }
}
