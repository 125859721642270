import { BaseBehavior, Camera, Component, InputContext, IUpdatable, IAwake } from "@ali/tidejs"
import { StateEvent } from "../../../GameLogic/StateEvent";
import { VGInfo } from "../VGInfo";
import { BasicCS } from './BasicCS';
import { CSFollow } from "./CSFollow";
import { CSFree, CSLookAt } from "./CSLookAt";
import { CSJump2Mark } from "./CSJump2Mark";
import { CSRotate2Mark } from "./CSRotate2Mark";

/**
 * 机器人状态机管理器组件
 *
 * 维持一个状态机管理器，一个共享数据实例
 */
@Component("CSMgr", true)
export class CSMgr extends BaseBehavior implements IUpdatable, IAwake {
  followState!: CSFollow;
  freeState!: CSFree;
  lookAtState!: CSLookAt;
  jump2MarkState!: CSJump2Mark;
  rotate2MarkState!: CSRotate2Mark;

  currentState: BasicCS | undefined;

  awake(): void {
    const a = VGInfo.GetInstance();
    this.followState = new CSFollow(this, Camera.MainCamera!, this.entity, a);
    this.lookAtState = new CSLookAt(this, Camera.MainCamera!, this.entity, a);
    this.freeState = new CSFree(this, Camera.MainCamera!, this.entity, a);
    this.jump2MarkState = new CSJump2Mark(this, Camera.MainCamera!, this.entity, a);
    this.rotate2MarkState = new CSRotate2Mark(this, Camera.MainCamera!, this.entity, a);


    this.changeTo(this.freeState);

    //虚拟人到01状态时，进入到跟随状态
    StateEvent.onStateChange.on('after', (name) => {
      switch (name) {
        case '01':
          if (this.currentState != this.followState) {
            if (this.currentState) {
              this.currentState.onFinished = this.followState;
            }
          }
          break;
        case '04':
          if (this.currentState) {
            this.currentState.onFinished = this.freeState;
          }
          break;
        case '02':
          if (this.currentState) {
            this.currentState.onFinished = this.lookAtState;
          }
          break;
        case '05':
          if (this.currentState) {
            this.currentState.onFinished = this.lookAtState;
          }
          break;
        case '06':
          if (this.currentState) {
            this.currentState.onFinished = this.followState;
          }
          break;
        case '07':
          if (this.currentState) {
            this.currentState.onFinished = this.jump2MarkState;
          }
          break;
        case '08':
          if (this.currentState) {
            this.currentState.onFinished = this.followState;
          }
          break;
        default:
          break;
      }

    });

    StateEvent.onStateChange.on('pause', () => {
      this.changeTo(this.freeState);
    })
    StateEvent.onStateChange.on('resume', () => {
      this.changeTo(this.followState);
    })

  }

  changeTo(cs: BasicCS) {
    if (this.currentState) this.currentState.exit();
    this.currentState = cs;
    this.currentState.enter();
  }

  update(deltaTime: number, inputContext: InputContext): void {
    this.currentState?.update()
  }
}
