import { InjectState } from "../../../../GameLogic/StatesFrame";
import { VGCheck } from "./VGCheck";

@InjectState('VGMarkCheck')
export class VGMarkCheck extends VGCheck {
  public test(): boolean {
    const stopMark = this.srcInfo.getNextStopMark();
    return !!stopMark
  }

  onEnter() {
    super.onEnter();
  }
}
