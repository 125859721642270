export const guideTestJson = {
  modelConfig:{
    modelURL:'https://virtualbuy-cdn.alibaba.com/glbSource/robot_Virtual_03.glb',
    scale: 0.02,
    faceName:'face',
    dufaultEmotion: 'idle',
    emotion:[
      {
        cname:'speak',
        texrul:'https://virtualbuy-cdn.alibaba.com/glbSource/robot_Virtual_face_voice.jpg',
        columnCount:7,
        rowCount:6,
        duration:700,
      },
      {
        cname:'idle',
        texrul:'https://virtualbuy-cdn.alibaba.com/glbSource/robot_Virtual_face_walk.jpg',
        columnCount:4,
        rowCount:4,
        duration:100,
        delayTime:2000,
        delayRandom:0.3,
      }
    ],
    commponentName: 'RobotCtr' // 定义该组件的入口组件
  },
  actionConfig: {
    distance: 0.4
  }
}
export const DescriptionInfo = {
  version: 0,
  list:[
    {
      spot:'p0',
      url:"https://virtualbuy-cdn.alibaba.com/audioSource/%E4%BB%8B%E7%BB%8D.wav"
    },
    {
      spot:'p1',
      url:"https://virtualbuy-cdn.alibaba.com/audioSource/%E4%BB%8B%E7%BB%8D.wav"
    },
    {
      spot:'p2',
      url:"https://virtualbuy-cdn.alibaba.com/audioSource/%E4%BB%8B%E7%BB%8D.wav"
    },
  ]
}

export const RobotJson = {
  version: '0.0.1',
  root: '02',
  autoPlay:true,
  list: [
    {
      className: 'RobotIdle',
      id: '01',
      parameters: {
        info: '待机',
        time: 0.5,//0.3秒做一次超时检查
        distance: 0.3 //距离判断，离开屏幕的距离
      },
      events: [
        {
          name: 'onDistanceState',
          delay:0,
          target: '02',
        },
        {
          name: 'onForwardState',
          delay:0,
          target: '03',
        }
      ]
    },
    {
      className: 'RobotWalk',
      id: '02',
      parameters: {
        info: '飞行',
        speed: 2.16,//正常人速度 m/s styleb 建议值为 6.16;
      },
      events: [
        {
          name: 'onArrivedState',
          delay: 0,
          target: '01'
        },
        {
          name: 'onStopState',
          delay: 0,
          target: '04'
        },
      ]
    },
    {
      className: 'RobotRotate',
      id: '03',
      parameters: {
        info: '朝向屏幕',
        time: 0.6
      },
      events: [
        {
          name: 'onArrivedState',
          delay: 0,
          target: '01'
        }
      ]
    },
    {
      className: 'RobotStop',
      id: '04',
      parameters: {
        info: '停止',
      },
      events: [
        {
          name: 'onWalkState',
          delay: 0,
          target: '02'
        }
      ]
    }

  ]
}

//RobotRotateIdle
