import  EventEmitter from "eventemitter3";
import { Annotation } from "./annotation";
import { ConsoleLog, ErrorType } from "@sdkCore/lib/log";
import { getVisibleSpotsByPosition, hasIncludeSpots } from "@sdkCore/api/elements/lib/util";
import { ElementAPIBaseClass } from "@sdkCore/lib/utils/eca";
import { API } from "@sdkCore/lib/utils/eca";
import { SDKElementType } from "@sdkCore/api/elements/interface";
import { defaultAnnotation2dTagIcon } from "@sdkCore/assets/icon";
@API()
export class Annotation2DPrivate extends ElementAPIBaseClass<Annotation>{

  static ModuleName = "annotation2DAPI";

  static Config = {
    maxDepth: 0.1,
    maxDetailAutoShowCount: 3,
    imageSize: 32,
    detailWidthScale: 0.5,
    detailHeightScale: 0.5,
    detailAutoOpen: true,
    imageUrl: defaultAnnotation2dTagIcon,

  }
  /**
     * @ignore
     * Element type of annotation2 private
     */
  public elementType = SDKElementType.annotation2d;

  /**
     * * loaded: 标签加载完成事件
     * * enter: 鼠标移入小圆点
     * * exit: 鼠标移出小圆点
     * * click: 小圆点点击事件， 点击到标签会触发，回调值为标签的id
     * * clickDetail: 标签详情点击事件，点击到标签详情会触发，回调值为标签的id
     * * show： 标签整体显示
     * * hide: 标签整体隐藏
     * * showDetail：标签详情显示事件
     * * hideDetail: 标签详情隐藏事件
     *  @event
     */
  public event = new EventEmitter<"loaded" | "enter" | "exit" | "click" | "clickDetail" | "show" | "hide" | "showDetail" | "hideDetail", string>();

  /**
     * @ignore
     * Apps init end
     */
  AppInitEnd(){
    this.initVisibleSpots();
  }

  /**
     * 如果没有配置 includeSpots，则自动生成一个
     */
  private async initVisibleSpots(){
    const elements = this.getAllElements() as any
    for(const element of elements){
      if(!hasIncludeSpots(element.elementConfig.includeSpots)){
        element.elementConfig.includeSpots = getVisibleSpotsByPosition(element.entity.transform.position);
      }
    }
  }
  /**
     * 设置所单个标签的卡片可见性
     * @param id
     */
  public showAnnotationDetail(id: string){
    const annotation = this.getElementById(id);
    if(!annotation){
      ConsoleLog.error("annotation2DAPI","showAnnotationDetail", ErrorType.InternalError,"未找到标签", id);
      return
    }
    annotation.showDetail();
  }

  /**
     * 设置所单个标签的卡片可见性
     * @param id
     */
  public hideAnnotationDetail(id: string){
    const annotation = this.getElementById(id);
    if(!annotation){
      ConsoleLog.error("annotation2DAPI","hideAnnotationDetail", ErrorType.InternalError,"未找到标签", id);
      return
    }
    annotation.hideDetail();
  }
  /**
     * 设置所有标签的卡片可见性
     * @param bool
     */
  public setAllLabelVis(bool: boolean){
    for( const value of this.getAllElements()){
      if(bool){
        value.showDetail()
      } else {
        value.hideDetail();
      }
    }

  }
  public dispose(){
  }
}
