import { Player, InputContext } from '@ali/tidejs'
import * as Tide from '@ali/tidejs';
import { OssResolver } from './lib/oss';
import { APIInstanceContainer, ecaDispose, ecaReload } from './lib/utils/eca';

import EventEmitter from 'eventemitter3';
import { ConsoleLog, ErrorType } from '../src/lib/log';
import { MessageDispose, MessageReload } from './GameLogic/MessageCenter';
import { performanceMark } from '../src/lib/utils';
import { ApplicationModeNormal } from '../src/api/mode/ApplicationModeNormal';
import { ProgressReporter } from '../src/lib/progress/ProgressReporter';
import { vec3 } from 'gl-matrix';

export interface IOssParams {
  oss_path: string;
  endpoint?: string;
  access_key_id?: string;
  access_key_secret?: string;
  security_token?: string;
}
export interface IResolver<T> {
  resolve(resourcePath?: string): T | Promise<T>;
}

export interface IConfig {
  [propName: string]: any;
}

export interface ILocation {
  spotName: string;
  theta: number;
  phi: number;
  zoom: number;
}
export interface IRenderOption {
  preserveDrawingBuffer?: boolean;
  antialias?: boolean;
  dpr?: number;
}
export interface IHdrOption {
  enableHdr?: boolean;
  extraResource?: Array<IHdrResource>
}

export interface IHdrResource {
  name: string,
  value: Array<string>
}

export interface ISpot {
  normalSpotTexture?: string,
  activeSpotTexture?: string
}

export interface ApplicationInitOptions {
  /**
   * 必传，CDN上项目ID
   */
  sceneId?: string;
  /**
   * 可选，自定义OSS路径
   */
  ossParams?: IOssParams;
  /**
   * 所有json配置文件的加载器，返回值为json对象
   */
  config?: IResolver<any>;
  /**
   * 所有public类型资源的加载器，默认为全景图的图片，返回值为Url
   */
  publicResourceResolver?: OssResolver;
  /**
   * 所有Private类型资源的加载器，默认为模型文件,返回值为Url
   */
  privateResourceResolver?: OssResolver;

  spot?: ISpot;
  mouseTexture?: string;
  enablePierce?: boolean;
  background?: vec3;

  entry?: 'panorama' | 'model';
  withModelTexture?: boolean;
  entryLocation?: ILocation;
  enableEditorMode?: boolean;
  renderOption?: IRenderOption;
  hdrOption?: IHdrOption;
}
export function fetchConfig(url: string): Promise<JSON> {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("get", url);
    xhr.responseType = "json"
    xhr.onload = () => {
      resolve(xhr.response);
    }
    xhr.send();
  })
}

type PluginInstallFunction = (app: Application, ...options: any[]) => any

export type Plugin =
  | (PluginInstallFunction & { install?: PluginInstallFunction })
  | {
    install: PluginInstallFunction
  }

export class Application {
  static mode: "runtime" | "editor" | "mini";
  static instance: Application;
  static sceneID = "";
  static Config = {
    configResolver: {
      resolve: async (path: string): Promise<any> => {
        const url = Application.Config.publicResourceResolver.resolve(path);
        const response = await fetchConfig(url);
        return response;
      }
    },
    publicResourceResolver: new OssResolver({ oss_path: "oss://virtualbuy-cdn/datastore/8fb41e07-477f-4794-8dab-57e55f857bcf/latest" }),
    privateResourceResolver: new OssResolver({ oss_path: "oss://virtualbuy-cdn/datastore/8fb41e07-477f-4794-8dab-57e55f857bcf/latest" }),
  }
  static getInstance<T>(moduleName: string): T {
    const instance = APIInstanceContainer.get(moduleName);
    if (!instance) {
      ConsoleLog.error("Application", "getInstance", ErrorType.InternalError, "没有找到API：" + moduleName)
    }
    return instance;
  }

  public container!: HTMLElement;
  public canvas!: HTMLCanvasElement;
  public event = new EventEmitter<"">();
  public mode: "runtime" | "editor" | undefined = undefined;
  public player!: Player;
  public installedPlugins = new Map();
  private appMode!: ApplicationModeNormal;
  public lifeHooks = {
    appInitEnd: function(){},
    resourceLoadEnd: function(){},
    sceneLoadEnd: function(){
      ProgressReporter.pluginsLoaded.next()
    },
    update: function(deltaTime: number, inputContext: InputContext){}
  }
  public tide = Tide;
  constructor(container: HTMLElement, public options: ApplicationInitOptions) {
    performanceMark('show-scene-begin');
    this.setResolver(container, options)
  }
  /**
   * 设置OssResolver
   * Sets resolver
   * @param container
   * @param options
   */
  setResolver(container: HTMLElement, options: ApplicationInitOptions) {
    const { config, sceneId, ossParams, publicResourceResolver, privateResourceResolver } = options;
    this.container = container;
    // 优先使用 ossParams
    if (ossParams) {
      Application.Config.publicResourceResolver = new OssResolver(ossParams);
      Application.Config.privateResourceResolver = new OssResolver(ossParams);
    } else if (sceneId) {
      const oss = {
        oss_path: `oss://virtualbuy-cdn/datastore/${sceneId}/latest`,
        // oss_path: `oss://holo-cdn-daily/datastore/${sceneId}/latest`,
        // endpoint: 'holoworld.com.cn'
      }
      Application.Config.publicResourceResolver = new OssResolver(oss);
      Application.Config.privateResourceResolver = new OssResolver(oss);
      Application.sceneID = sceneId;
    }

    if (config) {
      Application.Config.configResolver = config;
    }
    if (publicResourceResolver) {
      Application.Config.publicResourceResolver = publicResourceResolver;
    }
    if (privateResourceResolver) {
      Application.Config.privateResourceResolver = privateResourceResolver;
    }
    Application.instance = this;
  }
  getInstance<T>(moduleName: string): T {
    return Application.getInstance(moduleName);
  }
  getPlugin(name: string) {
    const plugin = this.installedPlugins.get(name);
    if (!plugin) {
      ConsoleLog.warn('Application', 'getPlugin', `找不到插件${name}，请检查插件是否正确注册`);
    }
    return plugin;
  }
  /**
   * 设置VR场景模式，有正常模式、单点模式等
   * Sets application mode
   * @param mode
   */
  setApplicationMode(mode: any) {
    this.appMode = mode;
  }
  /**
   * 程序初始化
   * Starts application
   * @returns
   */
  start() {
    if (!this.appMode) {
      this.appMode = new ApplicationModeNormal();
    }
    return this.appMode.init(this);
  }
  /**
   * 重置画布大小
   */
  resize() {
    this.player.inputManager.resize();
  }
  /**
   * 加载一个新的场景
   * Loads scene
   * @param sceneId
   */
  loadScene(sceneId: string) {
    this.appMode.loadScene(sceneId);
  }
  reload() {
    this.dispose();
    MessageReload();
    ecaReload()
  }
  /**
   * 销毁当前VR场景
   * Disposes application
   */
  dispose() {
    ecaDispose()
    MessageDispose();
  }
  // 插件化注册
  use(plugin: Plugin, name: string, ...options: any[]) {
    if (this.installedPlugins.has(name)) {
      throw new Error(`Plugin ${name} has already been applied to application or the name is used in application.`)
    } else if(plugin && typeof plugin.install === 'function') {
      this.installedPlugins.set(name, plugin);
      plugin.install(this, ...options);
    } else if (typeof plugin === 'function') {
      this.installedPlugins.set(name, plugin);
      (plugin as any)(this, ...options);
    } else {
      throw new Error(`A plugin must either be a function or an object with an "install" ` +
            `function.`)
    }
    return this;
  }
}

