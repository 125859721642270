import { AudioPlayer,Component,ComponentBase, InputContext, IUpdatable} from "@ali/tidejs"
import { ConsoleLog } from "../lib/log";

@Component("AudioPlayerMgr")
export class AudioPlayerMgr extends ComponentBase implements IUpdatable {

  dispose(): void {
    if (this.audioAsset) {
      this.audioAsset = undefined;
    }
    this.playChangeCallBack = undefined;
    this.callBack = undefined;
  }

  private audioAsset: HTMLAudioElement | undefined;
  private errorCode = 0;
  public onFinished: (() => void) | undefined;
  public url = '';
  private isStop = true;
  private isPausing = true;
  private isReady = false;
  public audioPlayer!: AudioPlayer

  private clock!: Date;
  public currentTime = 0;

  public callBack: ((time: number,totalTime: number) => void ) | undefined;
  public playChangeCallBack: ((type: 'pause'| 'resume' | 'finish' | 'play', time: number, totalTime: number) => void ) | undefined;

  isFinished() {
    if (!this.url.split('?')[0]) return true; //语音为空 直接算播放结束
    if (this.errorCode > 0) return true;
    if (!this.audioAsset) return false;

    if (this.audioAsset.ended) {
      if (this.onFinished) {
        this.onFinished();
      }
      if (this.playChangeCallBack) {
        const total = this.getSoundTotalTime();
        this.playChangeCallBack('finish', this.currentTime, (total || 1000));
      }
      return true;
    }
    return false;
  }

  getSoundTotalTime() {
    return this.audioPlayer.getTotalTime();
  }

  update(deltaTime: number, inputContext: InputContext): void {
    if (this.isPausing || this.isStop || !this.isReady) return;
    this.currentTime += deltaTime;
    if (this.callBack) {
      const total = this.getSoundTotalTime();
      this.callBack(this.currentTime,(total || 1000));
    }
    // 某些浏览器需要触发用户点击之后才能播放声音，所以tide底层会去触发，因此有可能出现声音播放完点击屏幕后再次播放的情形，因此需要声音播放完主动停止声音播放
    if(this.isFinished() && !this.isStop) {
      this.stop()
    }
  }

  play(url: string) {
    this.isStop = false;
    this.isPausing = false;
    if (url === this.url && !this.isFinished()){
      ConsoleLog.warn('AudioPlayMgr', 'play', '同一个声音正在播放,forbidden!', url);
      return;
    }
    this.url = url;
    this.audioPlayer.setAudioUrl(url);
    this.audioPlayer.playAudio().then((audio) => {
      if (audio) {
        this.isReady = true;
        this.audioAsset = audio.audioAsset;
        if (audio.audioAsset) {
          audio.audioAsset.muted = false;
          audio.audioAsset.autoplay = false;
          audio.audioAsset.loop = false;
          this.currentTime = 0;
        }
        if (this.playChangeCallBack) {
          const total = this.getSoundTotalTime();
          this.playChangeCallBack('play', this.currentTime, (total || 1000));
        }
      }
    }).catch(()=>{
      this.errorCode = 1;
    })
  }

  pause() {
    if (this.isStop) return;
    this.isPausing = true;
    this.audioPlayer.pause();
    if (this.playChangeCallBack) {
      const total = this.getSoundTotalTime();
      this.playChangeCallBack('pause', this.currentTime, (total || 1000));
    }
  }

  resume() {
    if (this.isStop) return;
    this.isPausing = false;
    this.audioPlayer.play();
    if (this.playChangeCallBack) {
      const total = this.getSoundTotalTime();
      this.playChangeCallBack('resume', this.currentTime, (total || 1000));
    }
  }

  stop() {
    if (this.isStop) return;
    this.audioPlayer.reset();
    this.audioPlayer.pause();
    this.currentTime = 0;
    this.url = '';
    this.isStop = true;
    this.isReady = false;
  }

}
