import { SDKAudio } from "../../lib/audio/audio";
import { VGInfo } from "./VGInfo";
export class VGRoamingConfg {
  spot = '';
  stay = 0;
  dir = 'next';
  stop: [];
  constructor(spot: any) {
    this.spot = spot.name;
    this.stay = spot.stay;
    this.dir = spot.dir;
    this.stop = spot.stop;
  }
}
export class PathGroup {
  duration = 0;
  area: string[] = [];
  audio!: SDKAudio;
  name='';
  url='';
  indices: number[]= [];
}

export class VGPath {
  public spots: VGRoamingConfg[] = new Array<VGRoamingConfg>();
  public groups: PathGroup[] = new Array<PathGroup>();
  public id = "";
  /** 当前行进到的spot点 */
  public currentSpotIndex = 0;
  public currentSpot ='';
  public currentGroup: PathGroup | undefined;
  public currentGroupIndex = 0;

  public currentSpotStopIndex = -1;

  constructor(public vgInfo: VGInfo) {
  }


  public findGroupIndex() {
    let currentGroupIndex
    for (let i = 0; i !== this.groups.length; i++) {
      const group = this.groups[i]
      if (group.indices.includes(this.currentSpotIndex)) {
        currentGroupIndex = i
        break
      }
    }
    return currentGroupIndex === void 0 ? -1 : currentGroupIndex;
  }
  public findSpotIndex(spotID: string) {
    for (let index = 0; index < this.spots.length; index++) {
      const element = this.spots[index];
      if (spotID === element.spot) return index;
    }
    return -1;
  }




  /**
   * 返回当前spot 处于整个声音控制区域中的百分比，如果是第一个返回0，最后一个，返回1\
   * 当区域声音对应的spot 只有一个时，既可以是0，又可以是1，此时，返回-1；
   * ~~~
   * [p1,p3,p4]
   * p1 = 0, p3 = 0.5, p4 = 1
   *
   * [p1]中，p1 = -1;
   */
  public getRateInPath(): number | undefined {
    const group = this.currentGroup!
    if (!this.currentGroup) return undefined;
    const index = group.indices.findIndex(v=>v === this.currentSpotIndex);
    if (index !== -1) {
      if (group.indices.length === 1) return -1;
      return index / (group.indices.length-1);
    }
    return undefined;
  }

  public isFirstSpotInGroup() {
    const r = this.getRateInPath();
    if (r === 0 || r === -1) return true;
    return false;
  }

  public isLastSpotInGroup() {
    const r = this.getRateInPath();
    if (r === 1 || r === -1) return true;
    return false;
  }

  public MoveToNextSpot() {
    //到达终点
    if (this.currentSpotIndex >= this.spots.length - 1) {
      this.currentSpotIndex = -1;
      return undefined;
    }

    const thisSpot = this.spots[this.currentSpotIndex];
    this.currentSpot = thisSpot.spot;

    const nextSpot = this.spots[this.currentSpotIndex+1];
    const p1 = this.vgInfo.getSpotPos(thisSpot.spot);
    const p2 = this.vgInfo.getSpotPos(nextSpot.spot);

    return [p1,p2];
  }

  public getNextPathSpot() {
    //到达终点
    if (this.currentSpotIndex >= this.spots.length - 1) {
      return undefined;
    }
    const nextSpot = this.spots[this.currentSpotIndex+1];
    return this.vgInfo.getSpotPos(nextSpot.spot);
  }

  public getNextPathSpotId() {
    //到达终点
    if (this.currentSpotIndex >= this.spots.length - 1) {
      return undefined;
    }
    const nextSpot = this.spots[this.currentSpotIndex+1];
    return nextSpot.spot
  }

  public ArriedTarget() {
    this.currentSpotIndex += 1
    const thisSpot = this.spots[this.currentSpotIndex];
    this.currentSpot = thisSpot.spot;
    const currentGroupIndex  = this.findGroupIndex()
    if (currentGroupIndex !== -1) {
      this.currentGroupIndex = currentGroupIndex
      this.currentGroup = this.groups[currentGroupIndex]
    }
  }

  public placeToGroup(index: number) {
    if (index >= this.groups.length) {
      return false;
    }
    let spotIndex = 0
    for(let i = 0; i !== index; i++) {
      spotIndex += this.groups[i].area.length
    }
    this.placeToSpotByIndex(spotIndex)
    return true;
  }

  public placeToGroupByName(name: string) {
    for (let index = 0; index < this.groups.length; index++) {
      const element = this.groups[index];
      if (element.name === name) {
        this.placeToGroup(index);
        return true;
      }
    }
    return false;
  }

  public palceToNextGroup() {
    this.currentGroupIndex += 1;
    if (this.currentGroupIndex >= this.groups.length) {
      this.currentGroupIndex = 0;
    }
    return this.placeToGroup(this.currentGroupIndex)
  }

  /**
     * 将角色放置到Spot点上，会更新当前group等重要信息
     * @param index
     * @returns
     */
  public placeToSpotByIndex(index: number) {
    this.currentSpotIndex = index;
    const spotID = this.spots[this.currentSpotIndex]?.spot;
    this.currentSpot = spotID;
    const r = this.findGroupIndex();
    if (r !== -1) {
      this.currentGroupIndex = r;
      this.currentGroup = this.groups[this.currentGroupIndex];
    }else {
      this.currentGroupIndex = -1;
      this.currentGroup = undefined;
    }
    this.currentSpotStopIndex = -1;

    const pos = this.vgInfo.getSpotPos(spotID);
    if (pos)
    {
      this.vgInfo.entityTransform.position = pos;
      return true;
    }
    return false;
  }

  public placeToSpot(spotID: string) {
    const index = this.findSpotIndex(spotID);
    if (index === -1) {
      return false;
    }
    return this.placeToSpotByIndex(index);

  }

  public getNextStopMark() {
    const spotStopArray = this.spots[this.currentSpotIndex]?.stop;
    if (!spotStopArray || !spotStopArray.length) {
      this.currentSpotStopIndex = -1;
      return undefined;
    }
    this.currentSpotStopIndex++;
    if (this.currentSpotStopIndex >= spotStopArray.length) {
      this.currentSpotStopIndex = -1;
      return undefined;
    }
    return spotStopArray[this.currentSpotStopIndex]
  }

  public getCurStopMark() {
    const spotStopArray = this.spots[this.currentSpotIndex]?.stop;
    if (!spotStopArray || !spotStopArray.length) {
      return undefined;
    }
    return spotStopArray[this.currentSpotStopIndex];
  }
}
