import { Camera, InputContext, PlantForm, RayCasterMgr } from "@ali/tidejs";
import { Spot } from "../../spot/Spot";
import { BaseState, CameraStateType } from "./BaseState";
import { mat4, vec3, vec4 } from "gl-matrix";
import { CubeInterpolator } from "../CubeInterpolator";
import { DataCenter } from "../../../GameLogic/DataCenter";
import { MessageCenter } from "../../../GameLogic/MessageCenter";
import { SpotPrivate } from "../../../api/spot/lib/private";
import { SurveyDrawAction } from "@sdkPlugins/surveyDraw"
import { PanoramaOpt } from "../../PanoramaOpt";
import { BaseCameraInterpolator } from "./TransformInterpolator/BaseCameraInterpolator";
import { Spot2NormalInterpolator } from "./TransformInterpolator/Spot2NormalInterpolator";
import { Panorama2TopInterpolator } from "./TransformInterpolator/Panorama2TopInterpolator";
import { Application } from "@sdkCore/Application";
import { ConsoleLog, ErrorType } from "@sdkCore/lib/log";
import { CameraPrivate } from "@sdkCore/api/camera/lib/private";
import { WanHuaTongUtility } from "@sdkCore/GameLogic/WanHuaTongUtility";
import { fadeInOut } from '../../../lib/utils/tools';

export class PanoramState  extends BaseState{
  static _surveyDrawAction = SurveyDrawAction.end
  //摄像机变换器
  private _camerTrans: BaseCameraInterpolator;
  private _spot2NormalInterpolator: Spot2NormalInterpolator;
  private _panoramaopt!: PanoramaOpt;
  private _2Top: Panorama2TopInterpolator;
  private _rotate = false; //是否需要旋转

  constructor(spotMgr: SpotPrivate) {
    super(spotMgr);
    this._camerTrans = new BaseCameraInterpolator();
    this._spot2NormalInterpolator = new Spot2NormalInterpolator();
    this._panoramaopt =  Camera.MainCamera!.entity.addComponent(PanoramaOpt);
    this._2Top = new Panorama2TopInterpolator();
  }
  enter() {
    this._panoramaopt.isActive = true;
  }
  exit() {
    // this.spotMgr.hideAllSpots();
  }
  /**
     * Spot2Spot2State
     * ~~~
     * 初始状态设置，1，设置摄像机姿态；2，设置默认全景图
     * 并非任何状态都需要设置初始态
     * ~~~
     * @param data
     */
  async setDefault(data: any){
    const cameraAPI = Application.getInstance<CameraPrivate>(CameraPrivate.ModuleName);
    const spot: Spot|undefined = this.spotMgr.getHotSpotById(data.firstScene);
    if (spot) {
      this.spotMgr.setCurrentSpotId(spot.name);
      cameraAPI.setCameraFov(data.fov);
      const pitchAndYaw: Array<number> = data.directionIn;
      const phi = pitchAndYaw[0]; // phi
      const theta = pitchAndYaw[1]; // theta
      const posY = Math.cos(phi) * Math.cos(theta);
      const posX = Math.cos(phi) * Math.sin(theta);
      const posZ = Math.sin(phi);

      const rotationMatrix = mat4.targetTo(mat4.create(), vec3.fromValues(0, 0, 0), vec3.fromValues(posX, posZ, -posY), vec3.fromValues(0, 1, 0));
      const spotTranform = mat4.targetTo(mat4.create(), [0, 0, 0], spot.forwardDirection, spot.upwardDirection);
      mat4.mul(rotationMatrix, spotTranform, rotationMatrix);
      const forward = vec4.transformMat4(vec4.create(), vec4.fromValues(0, 0, -1, 0), rotationMatrix);

      this._camerTrans.setdest(spot.opticalCenter,vec3.fromValues(0, 1, 0),vec3.fromValues(forward[0], forward[1], forward[2]),data.fov);
      this._camerTrans.update(this.curveFunc(1));

      await CubeInterpolator.getInstance().setDest(this.spotMgr.firstSpot.name,spot.opticalCenter);
      CubeInterpolator.getInstance().update(1);
    } else {
      ConsoleLog.error("PanoramaState","setDefault",ErrorType.UseError," 跳转失败，找不到spot: ", data);
    }
  }
  /**
     * 鼠标点击事件，特定状态下，需要做不同的事情；比如，normal2top的状态下，该事件什么也不做
     */
  click(inputCtx: InputContext, params?: any) {
    if(!DataCenter.RootModelMF){ return }
    //移动平台，不会自动执行move,因此当点击时，需要主动执行一次move;
    if (!PlantForm.IsPc())
    {
      this.move(inputCtx);
    }
    const info = RayCasterMgr.GetInstance().intersectioinEntityMesh(inputCtx, ...DataCenter.PanoramaMeshFilters);

    if(!info || !info.entity){
      MessageCenter.JumpEvent.emit('cameraShake');
      return
    }
    const spotNameMatch =  /^p\d+$/; // 地面spot点的名称匹配正则
    // 如果点击到了大模型
    if(info.entity.name === "dense_trim_tex"){
      const theSpot = this.spotMgr.getNearestSpotByWorldPos(info.position);
      const spotAPI = Application.getInstance<SpotPrivate>(SpotPrivate.ModuleName);

      const currentSpotID = spotAPI.getCurrentSpotId();
      if(PanoramState._surveyDrawAction === SurveyDrawAction.start) {
        MessageCenter.GlobalEvent.emit('click', {
          position: info.position,
          cancel: !!params.cancel
        })
      } else if (theSpot && currentSpotID != theSpot.name){
        theSpot.onClick();
      } else {
        MessageCenter.JumpEvent.emit('cameraShake');
      }
    } else if(spotNameMatch.test(info.entity.name)) { // 如果点击到了地面spot点，进行跳转
      this.spotMgr.getHotSpotById(info.entity.name)?.onClick();
    } else {
      MessageCenter.GlobalEvent.emit("click", info.entity.uuid)
    }
  }

  /**
     * 鼠标移动事件，只有PC平台才有
     * 每帧执行
     */
  move(inputCtx: InputContext): void {
    if (PanoramState._surveyDrawAction === SurveyDrawAction.start) {
      const info = RayCasterMgr.GetInstance().intersectioinEntityMesh(inputCtx, ...DataCenter.PanoramaMeshFilters);
      if (!info || !info.entity) {
        return
      }
      if (info.entity.name === "dense_trim_tex") {
        MessageCenter.GlobalEvent.emit('move', {
          position: info!.position,
        })
      }
    }
    //1，计算射线求焦点
    //2，找到距离该焦点最近的spot点，发送消息
    //3, 如果有点击按下，则直接发送消息
    // if (!DataCenter.RootModelMF) return;
    // const info = RayCasterMgr.GetInstance().intersectioinEntityMesh(inputCtx,DataCenter.RootModelMF);

    // if (info) {
    //     const theSpot = this.spotMgr.getNearestSpotByWorldPos(info.position);
    //     if (theSpot && this._spot != theSpot){
    //         theSpot.onEnter();
    //         if (this._spot) this._spot.onExit();
    //         this._spot = theSpot;
    //     }
    //     if (!theSpot) {
    //         if (this._spot) {
    //             this._spot.onExit();
    //             this._spot = undefined;
    //         }
    //     }
    // }
  }

  /**
     * 获取当前状态
     */
  getState(): CameraStateType {
    return CameraStateType.panorama;
  }


  /**
     * 设置目标
     *
     * @param parameter 一个array;
     * [targetType, pos, up, forward, spotID, fov, rotation]
     */
  async setDest(parameter: any): Promise<void> {
    this.targetType = parameter[0];
    this._rotate = parameter[6] || false;
    if (this.targetType === CameraStateType.panorama) {
      await this._camerTrans.setdest(parameter[1],parameter[2],parameter[3], parameter[5]);
      await CubeInterpolator.getInstance().setDest( parameter[4],parameter[1]);
    }
    if (this.targetType === CameraStateType.normal) {
      this._panoramaopt.isActive = false;
      await this._spot2NormalInterpolator.setdest();
      await CubeInterpolator.getInstance().setNormalAtEnd();
    }
    if (this.targetType === CameraStateType.top) {
      this._panoramaopt.isActive = false;
      await this._2Top.setdest();
      await CubeInterpolator.getInstance().setNormalAtEnd();
    }
  }

  //全景图模式的渐变，摄像机并不需要旋转
  update(ratio: number): void {
    const fadeInOutRatio = fadeInOut(ratio);
    if (this.targetType === CameraStateType.panorama) {
      this._camerTrans.update(this.curveFunc(fadeInOutRatio),this._rotate);
      CubeInterpolator.getInstance().update(this.curveFunc(ratio));
      return;
    }
    if (this.targetType === CameraStateType.normal) {
      this._spot2NormalInterpolator.update(fadeInOutRatio);
      CubeInterpolator.getInstance().update(this.curveFunc(ratio));
      WanHuaTongUtility.changeTransparencyOfTheWall(0.67 * ratio);
      return;
    }
    if (this.targetType === CameraStateType.top) {
      this._2Top.update(fadeInOutRatio);
      CubeInterpolator.getInstance().update(this.curveFunc(ratio));
      WanHuaTongUtility.changeTransparencyOfTheWall(0.67 * ratio);
      return;
    }
  }
}
