import { SDKElementType } from "../../../../api/elements/interface";
import { ElementsManager } from "../../../../api/elements/ElementsManager";
import { SkyElement } from "./SkyElement";
import { ConsoleLog } from "../../../../lib/log";
import { API, ElementAPIBaseClass } from "../../../../lib/utils/eca";
import  EventEmitter from "eventemitter3";

@API()
export class SkyElementPrivate extends ElementAPIBaseClass<SkyElement, any> {
  static ModuleName = "skyElementAPI";
  public elementType = SDKElementType.sky;
  public event = new EventEmitter<"enter" | "exit" | "click", string>();

  /**
     * 设置所有区域标签的可见性
     * Sets sky element visibility
     * @param bool
     */
  setSkyElementVisibility(bool: boolean){
    ConsoleLog.log("SkyElementAPI", "setSkyElementVisibility ", bool);
    const elements: SkyElement[] = (ElementsManager as any).getInstance().getElementsByType(SDKElementType.sky);
    elements.forEach(element => {
      bool? element.activate() : element.deactivate()
    });
  }
  /**
     * 兼容旧接口，其实跟 setSkyElementVisibility 一样
     * Sets all elements visible
     * @param bool
     */
  setAllElementsVisible(bool: boolean){
    this.setSkyElementVisibility(bool);
  }
  dispose(){

  }
}
