
import { BasicCS } from "./BasicCS";
import { ICSStates } from './CSInterface'
export class CSLookAt extends BasicCS {
  public stateName = ICSStates.CSLookAt

  update(): void {
    if(!this.isJump) {
      this.finish();
    } else {
      // 摄像机跳转过程中，始终朝向人物，体验更好
      this.lookAt();
    }
  }

  exit(): void {
    //
  }
  async enter(): Promise<void> {
    this.enableCameraRot(true);
    this.enablePickAndJump(false);
    await this.jumpCheck(true);
    this.lookAt();
  }
}

export class CSFree extends BasicCS {
  update(): void {
    if(!this.isJump) {
      this.finish();
    }
  }

  exit(): void {
  }
  enter(): void {
    this.enableCameraRot(true);
    this.enablePickAndJump(true);
  }
}

