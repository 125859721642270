import { Camera } from '@ali/tidejs'
import { Application } from '../../Application';
import { GameEnter } from '../../Behaviors/GameEnter';
import { ApplicationModeBase } from './ApplicationModeBase';


export class ApplicationModeNormal extends ApplicationModeBase{
  _init(app: Application){
    super._init(app);
    if(!Camera.MainCamera || !Camera.MainCamera.entity){
      throw Error("ApplicationModeNormal 初始化错误");
    }
    Camera.MainCamera.entity.addComponent(GameEnter);
  }
}
