import { BaseBehavior, Component, InputContext, IOnStart, IUpdatable, PlantForm } from '@ali/tidejs';
import { NormalState } from './InterpolatorStates/NormalState';
import { PanoramState as PanoramaState } from './InterpolatorStates/PanoramaState';
import { TopState } from './InterpolatorStates/TopState';
import { CameraStateType, ICameraState } from './InterpolatorStates/BaseState';
import { MessageCenter } from '../../GameLogic/MessageCenter';
import { vec2, vec3 } from 'gl-matrix';
import { SpotPrivate } from '../../api/spot/lib/private';
import { Application } from '../../Application';
import { performanceMark } from '../../lib/utils';
import { ConfigPrivate } from '../../api/config/lib/private';

@Component("JumpController")
export class JumpController extends BaseBehavior implements IOnStart, IUpdatable{

  private _time = 0;
  private _preTime = 1;
  private isPlaying = false;

  private _currentState!: ICameraState;
  private _normalState!: ICameraState;
  private _topState!: ICameraState;
  private _panoramaState!: ICameraState;
  private _nextState!: ICameraState;

  private _spotMgr!: SpotPrivate;

  private _pressDown = false;
  private _pressRightDown = false;
  private _pressDownTime = 0;
  private _startPos = vec2.create();
  private _endPos = vec2.create();
  private _tempVec2_1 = vec2.create();
  private _tempVec2_2 = vec2.create();
  private _startCount = false;

  private _init = false;

  /** 点击判断，只有当按住一定毫秒内，并放开时，位移不大，才判定为点击，并引发点击事件*/
  private clickCheck(deltaTime: number,inputCtx: InputContext) {
    let buttonDown: boolean;
    let rightButtonDown = false
    let thispos: vec2;
    if (PlantForm.IsPc()) {
      buttonDown = inputCtx.leftDown;
      rightButtonDown = inputCtx.rightDown
      thispos = inputCtx.position;
    } else {
      buttonDown = inputCtx.touchedNum > 0;
      if (buttonDown) {
        thispos = inputCtx.touchedPosition[0];
        vec2.copy(this._tempVec2_2,thispos);
      }
      else
      {
        // 注意，一般是不应该修改 inputCtx 信息的，此处特殊需要
        inputCtx.touchedPosition[0] = this._tempVec2_2;
        thispos = this._tempVec2_2;
      }
    }
    //点击的开始
    if (!this._pressDown && buttonDown) {
      this._startCount = true;
      this._pressDown = true;
      vec2.copy(this._startPos, thispos);
    }
    // 判断鼠标右键的点击开始
    if (!this._pressRightDown && rightButtonDown) {
      this._startCount = true;
      this._pressRightDown = true;
      vec2.copy(this._startPos, thispos);
    }
    //持续及时
    if (this._startCount) {
      this._pressDownTime += deltaTime;
    }
    //放开时进行判断
    if ((this._pressDown && !buttonDown) || (this._pressRightDown && !rightButtonDown)) {
      this._startCount = false;
      this._pressDown = false;
      vec2.copy(this._endPos, thispos);
      vec2.sub(this._tempVec2_1,this._endPos,this._startPos);
      if (this._pressDownTime < 0.2 && vec2.dot(this._tempVec2_1,this._tempVec2_1)< 10) {
        this._currentState.click(inputCtx, {
          cancel: this._pressRightDown
        });
      }
      this._pressRightDown = false
      this._pressDownTime = 0;
    }
  }

  async update(deltaTime: number,inputCtx: InputContext) {
    this.clickCheck(deltaTime,inputCtx);

    if (PlantForm.IsPc()) {
      this._currentState.move(inputCtx);
    }

    if(!this.isPlaying) return;
    this._time += deltaTime * this._preTime;
    if (this._time >= 1) this._time = 1;
    this._currentState.update(this._time);
    if (this._time === 1) {
      this.isPlaying = false;
      //改变状态
      this.changeState();
      MessageCenter.JumpEvent.emit('finish');
    }


  }

  changeState() {
    if (this._currentState === this._nextState) return;

    this._currentState.exit();
    this._currentState = this._nextState;
    this._currentState.enter();
  }
  async onStart() {

    const spotAPI = Application.getInstance<SpotPrivate>("hotSpotAPI");

    //建立状态，并制定默认状态
    this._spotMgr = spotAPI;
    this._normalState = new NormalState(this._spotMgr);
    this._panoramaState = new PanoramaState(this._spotMgr);
    this._topState = new TopState(this._spotMgr);

    this._currentState = this._panoramaState;
    this._init = true;

  }
  public async jumpToTop(){
    if (this._currentState.getState() === CameraStateType.top) return;
    this._nextState = this._topState;
    await this.translation2Top();
  }

  public async jumpToNormal(){
    if (this._currentState.getState() === CameraStateType.normal) return ;
    this._nextState = this._normalState;
    await this.translation2Normal();
  }

  /**
     * 跳转到spot点
     * @param spotID 目标spot点
     * @param up
     * @param forward
     * @param spotPos
     * @param fov
     * @param duration
     * @param rotate
     * @returns
     */
  public async toSpotTransition(spotID: string, up: vec3, forward: vec3, spotPos: vec3, fov: number | undefined = undefined, duration = 1.5, rotate?: boolean){
    this._nextState = this._panoramaState;
    duration = duration || 0.75;
    if (this._currentState === this._topState || this._currentState === this._normalState) {
      duration = 1.5;
    }
    await this.translation2Spot(spotID,[null,spotPos,up,forward,null,fov,rotate], duration);
  }

  /**
     * @param spotID
     * @param parameter
     * ~~~
     *  //parameter[0] 跳转类型
     *  //parameter[1] pos
     *  //parameter[2] up
     *  //parameter[3] forward
     *  //parameter[4] tartget spotId
     *  //parameter[5] fov
     *  //parameter[6] rotate 是否旋转
     * ~~~
     * @param duration
     */
  private async translation2Spot(spotID: string,parameter: any ,duration = 1.5) {
    this._time = 0;
    this._preTime = 1/duration;
    parameter[0] = CameraStateType.panorama;
    parameter[4] = spotID;
    await this._currentState.setDest(parameter);

    performanceMark('transition-duration-begin');
    await this.play();
    performanceMark('transition-duration-end');
  }

  private async translation2Normal(duration = 1) {
    this._time = 0;
    this._preTime = 1/duration;
    //parameter[0] 是 跳转类型
    //normal 不需要其他额外参数
    await this._currentState.setDest([CameraStateType.normal]);
    await this.play();
  }

  private async translation2Top(duration = 1) {
    this._time = 0;
    this._preTime = 1/duration;
    //parameter[0] 是 跳转类型
    //top 不需要其他额外参数
    await this._currentState.setDest([CameraStateType.top]);
    await this.play();
  }
  async play(){
    this.isPlaying = true;
    return new Promise((resolve)=>{
      MessageCenter.JumpEvent.once('finish',()=>{
        resolve(1);
      })
    });
  }

  public async gotoDefault(type: string) {
    const configAPI = Application.getInstance<ConfigPrivate>(ConfigPrivate.ModuleName);
    const configDefault = configAPI.getConfig().default;
    if (type === 'normal') {
      this._currentState = this._normalState;
    }
    await this._currentState.setDefault(configDefault);
  }

  async init() {
    return new Promise(resolve=>{
      const test1 = ()=> {
        if (this._init) return resolve(true);
        else (setTimeout(() => {
          test1();
        }, 10))
      }
      test1();
    })
  }

}

