import { BasicCS } from "./BasicCS";
import { ICSStates } from './CSInterface'
export class CSFollow extends BasicCS {
  public stateName = ICSStates.CSFollow

  exit(): void {
    this.enableCameraRot(false);
    this.enablePickAndJump(false);
  }
  enter(): void {
    this.enableCameraRot(false);
    this.enablePickAndJump(false);
  }
  update(): void {
    this.lookAt();
    this.jumpCheck();
    if (!this.isJump) {
      this.finish();
    }
  }
}


