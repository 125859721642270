import { BasicState, expJson, InjectState } from '../../StatesFrame';
import { VGInfo } from '../VGInfo';
import { VGMove } from './VGMove';
import { vec3 } from 'gl-matrix';
import { ConsoleLog } from '../../../lib/log';

@InjectState('VGMoveToMark')
export class VGMoveToMark extends VGMove {
  @expJson('info')
  public info = 'moveToMark';

  @expJson('onArrivedMark')
  public onArrivedMark: BasicState<VGInfo> | undefined;

  @expJson('noMark')
  public noMark: BasicState<VGInfo> | undefined;

  private _stopMark: any
  private _p3: vec3 = vec3.create();


  update (deltaTime: number) {
    if (!this.srcInfo.soundMgr2.isFinished()) {
      this.srcInfo.playAnimation('walk_speak')
    } else {
      this.srcInfo.playAnimation('walk')
    }

    if (this._stopMark && this._p1 && this._p2) {
      this._currentTime += deltaTime;
      this._ratio = this._currentTime / this._time;
      if (this._currentTime >= this._time) {
        this._ratio = 1;
      }
      vec3.lerp(this._temp,this._p1,this._p3,this._ratio);
      this.srcInfo.entityTransform.position = this._temp;

      if (this._ratio === 1) {
        if (this.onArrivedMark) {
          this.reset();
          this.onArrivedMark.changeTo();
        }
      }

    } else {
      if (this.noMark) {
        this.noMark.changeTo();
      }
    }

  }

  onEnter() {
    if (!this.srcInfo.entity.isActive) this.srcInfo.entity.activate();
    this._time = 0;
    this._stopMark = this.srcInfo.getNextStopMark();

    if (!this._stopMark) {
      return
    }

    this._p1 = this.srcInfo.entityTransform.position;
    this._p2 = this.srcInfo.getAnnotationPos(this._stopMark.markId);

    if (!this._p2) {
      throw new Error(`标识点位置获取失败, ${this._stopMark.markId}`)
    }

    // 以行走人的y坐标为准（mark的y坐标在半空中）
    this._p2[1] = this._p1[1];

    // 选取mark前方0.7m, 右侧1.1m的点作为行走人驻足的点（直接走到mark的位置会遮挡商品）
    const normVec3 = vec3.create();
    vec3.subtract(normVec3, this._p1, this._p2);
    vec3.normalize(normVec3, normVec3)

    const vertVec3 = [0, 1, 0] as vec3;
    const croVec3 = vec3.create();
    vec3.cross(croVec3, vertVec3, normVec3);
    vec3.normalize(croVec3, croVec3);

    vec3.scale(normVec3, normVec3, 0.7);
    vec3.scale(croVec3, croVec3, 1.1)
    const tempVec3 = vec3.create();
    vec3.add(tempVec3, normVec3, croVec3);

    vec3.add(this._p3, tempVec3, this._p2);

    // 计算起点终点，方向，时间长度，并朝向前进方向
    const distance = vec3.distance(this._p1, this._p3);
    this._time = distance / this.srcInfo.speed;

    vec3.subtract(this._dir,this._p3,this._p1);
    this._dir[1] = 0;
    vec3.normalize(this._dir,this._dir);
    this.faceToDir();

    ConsoleLog.log('state', this.info, 'enter');

  }

}
